<template>
  <LinkOrText
    v-if="!hideIfBlank || value > 0"
    :to="to"
    :class="['align-self-start change-stat d-flex flex-row flex-nowrap align-items-center gap-1', variant && value > threshold ? `bg-${variant} bg-opacity-25` : `bg-${(value || 0) === 0 ? 'light' : 'gray'}`]"
  >
    <b class="change-count">{{ formatNumber(value || 0) }}</b>
    <span class="change-label">{{ label }}</span>
    <BTooltip v-if="description" :title="description">
      <i class="bi-info-circle me-1" />
    </BTooltip>
  </LinkOrText>
</template>

<script>
import LinkOrText from "@/components/dashboard/LinkOrText.vue";
import BTooltip from "@/components/common/BTooltip.vue";

export default {
  components: {BTooltip, LinkOrText},
  props: {
    value: {
      type: Number,
      default: () => 0
    },
    label: String,
    to: Object,
    hideIfBlank: Boolean,
    description: String,
    variant: String,
    threshold: {
      type: Number,
      default: () => 0
    }
  }
}
</script>

<style>
.change-stat {
  color: var(--bs-dark);
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  border-radius: 4px;
}
.change-count {
  font-weight: bold;
}
.change-label {
  font-weight: 400;
}
.change-count a {
  text-decoration: none;
  color: var(--bs-dark);
}
</style>
