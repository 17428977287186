<template>
  <div class="mb-3" v-if="model.formattedAddress">
    Please provide contact information for the owner of <b>{{ model.formattedAddress }}</b>.
    <template v-if="!!model.property">
      Owner information was found in the <a :href="model.property.url" target="_blank">assessor record</a>.
      Please make any adjustments if needed.
    </template>
  </div>
  <div v-if="model.loading" class="mb-3">
    <SpinnerView small /> Checking assessor records for owner information. One moment please.
  </div>
  <form action="#" @submit.prevent="$emit('submit')">
    <div class="mb-3">
      <label class="form-label">
        Name(s)
      </label>
      <textarea class="form-control" v-model="model.contact.name" :disabled="model.loading"/>
      <div class="form-text">If more than one, please list each contact's name on its own line.</div>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Mailing Address
      </label>
      <textarea class="form-control" v-model="model.contact.address" :disabled="model.loading"/>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Email Address
      </label>
      <input type="email" class="form-control" v-model="model.contact.email" @keydown.enter="$emit('submit')"/>
    </div>
    <div class="mb-3">
      <label class="form-label">
        Phone Number
      </label>
      <PhoneInput v-model="model.contact.phone" class="form-control" @keydown.enter="$emit('submit')"/>
    </div>
  </form>
</template>

<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import SpinnerView from "@/components/common/SpinnerView.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";

export default {
  components: {PhoneInput, SpinnerView},
  props: {
    model: LicenseEditorModel
  },
  emits: ['submit']
}
</script>
