<template>
  <LayoutCard
    title="Violation Settings"
    description="These settings control which types of listings should be considered violations."
    class="mb-4">

    <template #footer v-if="changed">
      <spinner-view v-if="saving" small class="me-3" />
      <button type="button" class="btn btn-flat ms-1" @click="reset" :disabled="saving">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit" :disabled="saving">
        Save Changes
      </button>
    </template>

    <div class="d-flex fled-row">
      <div class="flex-grow-1 flex-column d-flex">
        <div class="row mb-3 d-flex align-items-center">
          <label class="col-sm-12 col-md-2 col-form-label">Minimum Night Stay Exemption Threshold</label>
          <div class="col d-flex flex-row align-items-center gap-2">
            <BCheckbox v-model="hasMinNightStayExemptionThreshold" />
            <input
              type="text"
              class="form-control"
              v-model="general.minNightStayExemptionThreshold"
              :disabled="!hasMinNightStayExemptionThreshold"
            />
          </div>
        </div>

        <div class="row mb-3 d-flex align-items-center">
          <label class="col-sm-12 col-md-2 col-form-label">Treat Inactive Licenses as Violations</label>
          <div class="col d-flex flex-row align-items-center gap-2">
            <BCheckbox v-model="general.treatInactiveLicenseAsViolation" />
          </div>
        </div>
      </div>
    </div>
  </LayoutCard>
</template>

<script>
import {City} from "@/models/City";
import LayoutCard from "@/components/common/LayoutCard.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import BCheckbox from "@/components/common/BCheckbox.vue";

export default {
  components: {BCheckbox, SpinnerView, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      general: {
        treatInactiveLicenseAsViolation: false,
        minNightStayExemptionThreshold: null
      },
      saving: false
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        this.reset();
      }
    }
  },
  computed: {
    changed() {
      let changed = false;
      Object.keys(this.general).forEach(key => {
        if (this.general[key] !== this.city.general[key]) {
          changed = true;
        }
      });
      return changed;
    },
    hasMinNightStayExemptionThreshold: {
      get() {
        return this.general.minNightStayExemptionThreshold !== null;
      },
      set(h) {
        this.general.minNightStayExemptionThreshold = h ? 28 : null;
      }
    }
  },
  methods: {
    reset() {
      this.general = { ...this.general, ...this.city.general };
    },
    commit() {
      this.saving = true;
      this.city.updateGeneral(this.general)
      .finally(() => this.saving = false);
    }
  }
}
</script>
