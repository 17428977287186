import { db } from '@/db';
import { collection, query, where, onSnapshot, doc, getDocs, addDoc, setDoc, deleteDoc } from "firebase/firestore";
import { City } from './City';
import {Address} from "@/models/Address";
import {Session} from "@/models/User";
import {Listing} from "@/models/Listing";
import {compareLicensesByStatus, License} from "@/models/License";
import {callApi} from "@/api";
import LicenseParseResult from "@/models/LicenseParseResult";
import readFile from "@/readFile";

class Index {
  constructor() {
    this._index = {};
  }
  clear() {
    this._index = {};
  }
  put(keys, itemId) {
    keys
    .map(key => key.toLowerCase()).forEach(key => {
      let entry = this._index[key] || [];
      if (entry.indexOf(itemId) < 0) {
        entry.push(itemId);
        this._index[key] = entry;
      }
    });
  }
  get(keys) {
    console.log(`Searching for keys ${keys}`);
    let matches = {};
    keys.map(key => key.toLowerCase()).forEach(key => {
      Object.keys(this._index).forEach(otherKey => {
        if (otherKey.startsWith(key)) {
          const bonus = otherKey === key ? 1 : 0;
          this._index[otherKey].forEach(itemId => {
            matches[itemId] = (matches[itemId] || 0) + 1 + bonus;
          });
        }
      });
    });
    return Object.keys(matches).sort((itemId1, itemId2) => {
      return matches[itemId2] - matches[itemId1];
    }).map(itemId => {
      return {id: itemId, matchCount: matches[itemId]};
    });
  }
}

export class Collection {
  constructor(name, cityId, /* QueryConstraint[] */ conditions, /* (Array, City): Array */ postProcess) {
    this.name = name;
    this.cityId = cityId;
    this.city = null;
    this.conditions = conditions;
    this.postProcess = postProcess || (items => items);
    this.items = [];
    this.itemLookup = {};
    this.index = new Index();
    this.unsubscribe = null;
    this.loaded = false;
    this.loadSnapshotTask = null;
    this.updateCount = 0;
  }
  get count() {
    return this.items.length;
  }
  get db() {
    return this.city && this.city.db;
  }
  async load() {
    console.log(`Query ${this.name} with city == ${this.cityId}`);
    this.city = await City.loadCity(this.cityId, true);

    this.unsubscribe = onSnapshot(query(this.collectionRef(), this.conditions), snapshot => {
      const docs = snapshot.docs;
      console.log(`Received collection update with ${docs.length} documents. Queueing for load...`);
      const updates = docs.filter(doc => doc.metadata.hasPendingWrites);
      console.log(`Includes ${updates.length} updates`);
      clearTimeout(this.loadSnapshotTask);
      this.loadSnapshotTask = setTimeout(() => {
        try {
          this.loadSnapshot(docs);
        } catch (error) {
          console.error('Could not load snapshot');
          console.error(error);
        }
      }, this.loaded ? 500 : 100);
    });
  }
  async loadSnapshot(docs) {
    console.log(`Rebuilding ${this.name} collection with ${docs.length} documents`);
    this.items = await this.postProcess(docs, this.city);
    console.log(`Post-process done`);
    this.index.clear();
    console.log('Cleared index');
    const start = new Date();
    this.items.forEach(item => {
      this.index.put(item.indexKeys(), item.id);
      this.itemLookup[item.id] = item;
    });
    console.log(`Building index took ${new Date() - start}ms`);
    this.loaded = true;
    this.updateCount++;
  }
  search(query) {
    return Promise.resolve(query.split(/ /g))
    .then(keys => this.index.get(keys))
    .then(results => results.map(({id, matchCount}) => {
      return {
        matchCount,
        item: this.itemLookup[id]
      };
    }).filter(result => !!result.item));
  }
  getById(id) {
    return this.items.filter(item => item.id === id)[0];
  }
  filter(predicate) {
    return this.items.filter(predicate);
  }
  unload() {
    this.unsubscribe && this.unsubscribe();
  }
  collectionRef() {
    return collection(this.db, 'cities', this.cityId, this.name);
  }
  addItem(item) {
    return addDoc(this.collectionRef(), item);
  }
  docRef(id) {
    return doc(this.db, 'cities', this.cityId, this.name, id);
  }
  setItem(id, item) {
    item.id = id;
    return setDoc(this.docRef(id), item, {merge:true});
  }
  deleteItem(id) {
    return deleteDoc(this.docRef(id));
  }
}

export class AppModel {
  constructor(cityId) {
    this.cityId = cityId;

    this.listings = new Collection(
      'listings',
      cityId,
      [
        where('status', '==', 'active'),
        where('listing.isInCorrectCity', '==', true)
      ],
      (docs, city) => {
        return docs.map(Listing.docConverter(city))
        .filter(listing => listing.isDefinitelyInCorrectCity && !listing.isInactive && !city.isListingExempt(listing));
      }
    );

    this.licensePropertyIndex = {};
    this.licenses = new Collection(
      'licenses',
      cityId,
      null,
      (docs, city) => {
        let licenses = docs.map(License.docConverter(city));
        this.licensePropertyIndex = {};
        licenses.forEach(license => {
          this.licensePropertyIndex[license.propertyId] = this.licensePropertyIndex[license.propertyId] || [];
          this.licensePropertyIndex[license.propertyId].push(license);
        });
        for (let id in this.licensePropertyIndex) {
          // TODO BUG
          this.licensePropertyIndex[id] = this.licensePropertyIndex[id].sort(compareLicensesByStatus);
        }
        return licenses;
      }
    );
    this.cityInfo = null;
    this.selectedListingId = null;
    this.selectedLicenseId = null;
    this.highlightedListings = new Set();
    this.highlightedLicenses = new Set();
    this.streetViewListingId = null;
    this.importingListingDetails = false;
  }

  get currentUser() {
    return Session.currentUser.value;
  }

  get currentUserId() {
    let user = this.currentUser;
    return !user ? null : user.uid;
  }

  get currentUserChecked() {
    return Session.currentUserChecked.value;
  }

  login(email, password) {
    return Session.login(email, password);
  }

  logout() {
    return Session.logout();
  }

  sendPasswordReset(email) {
    return Session.sendPasswordReset(email);
  }

  _resolveAddresses(items) {
    let itemsByAddressId = {};
    items.forEach(item => {
      if (item.addressId && (!item.address || item.address.id !== item.addressId)) {
        itemsByAddressId[item.addressId] = item
      }
    });

    let addressIds = Object.keys(itemsByAddressId);
    if (addressIds.length > 0) {
      console.log(`Retrieving addresses: ${addressIds}`);

      let promises = [];
      while (addressIds.length > 0) {
        promises.push(getDocs(query(
          collection(db, 'cities', this.cityId, 'addresses'),
          where('id', 'in', addressIds.splice(0, Math.min(10, addressIds.length)))
        )));
      }

      return Promise.all(promises)
      .then(snapshots => {
        snapshots.forEach(snapshot => {
          snapshot.docs.forEach(doc => {
            itemsByAddressId[doc.id]._data.address = new Address(doc.data());
          });
        });
        return items;
      });
    } else {
      return items;
    }
  }

  get isAuthenticated() {
    return !!this.currentUser;
  }

  get cityLabel() {
    return (this.cityInfo && this.cityInfo.name) || this.cityId;
  }

  get assessorUrl() {
    return this.cityInfo && this.cityInfo.recordsUrl;
  }

  selectListing(id) {
    this.selectedListingId = id;
    this.highlightedListings.clear();
  }

  selectLicense(id) {
    this.selectedLicenseId = id;
    this.highlightedLicenses.clear();
  }

  isListingHighlighted(id) {
    return this.highlightedListings.has(id);
  }
  setListingHighlighted(id, isHighlighted) {
    if (isHighlighted) {
      this.highlightedListings.add(id);
    } else {
      this.highlightedListings.delete(id);
    }
  }

  isLicenseHighlighted(id) {
    return this.highlightedLicenses.has(id);
  }
  setLicenseHighlighted(id, isHighlighted) {
    if (isHighlighted) {
      this.highlightedLicenses.add(id);
    } else {
      this.highlightedLicenses.delete(id);
    }
  }

  get activeLicenses() {
    return this.licenses.items.filter(license => license.isActive);
  }

  get confirmedViolations() {
    return this.verifiedListings.filter(listing => this.isViolation(listing));
  }

  get verifiedListings() {
    return this.listings.items.filter(listing => listing.isAddressVerified);
  }

  get potentialViolations() {
    return this.listings.items.filter(listing => !listing.isAddressVerified && !listing.isWhitelisted);
  }

  get activeLicenseCount() {
    return this.activeLicenses.length;
  }

  get confirmedViolationCount() {
    return this.confirmedViolations.filter(listing => !listing.isWhitelisted).length;
  }

  get potentialViolationCount() {
    return this.potentialViolations.filter(listing => !listing.isWhitelisted).length;
  }

  get dateLastChecked() {
    let checked = this.cityInfo && this.cityInfo.dateChecked;
    return checked && checked.toDate();
  }

  get checkStatus() {
    return this.cityInfo && this.cityInfo.status;
  }

  get canCheckListingsNow() {
    const dateChecked = this.dateLastChecked;
    const status = this.checkStatus;
    return this.cityInfo && (!dateChecked || (new Date() - dateChecked) > 15 * 60 * 1000)
      && (!status || status === 'checked');
  }

  checkListingsNow() {
    if (this.canCheckListingsNow) {
      console.log(`Checking listings now...`);
      let docRef = doc(db, 'cities', this.cityInfo.id);
      setDoc(docRef, {status: 'queued'}, {merge: true});
    }
  }

  async importListingDetails() {
    this.importingListingDetails = true;
    try {
      await callApi('importListingDetailsBatch', {cityId: this.cityId}, 'listings');
    } finally {
      this.importingListingDetails = false;
    }
  }

  load() {
    City.loadCity(this.cityId)
    .then(city => {
      this.cityInfo = city;
      this.listings.load();
      this.licenses.load();
    });
  }

  unload() {
    this.listings.unload();
    this.licenses.unload();
  }

  saveLicense(license) {
    return this.licenses.setItem(license.number, license._data);
    // license.id = `${this.city}_${license.number}`;
    // license.city = this.city;
    // return this.licenses.setItem(license.id, license._data);
  }

  deleteLicense(licenseId) {
    return this.licenses.deleteItem(licenseId);
  }

  lookupLicenseForPropertyId(propertyId) {
    const licenses = this.licensePropertyIndex[propertyId];
    return licenses ? licenses[0] : null;
  }

  isViolation(listing) {
    const license = this.lookupLicenseForListing(listing);
    return !license || license.isInactive;
  }

  lookupLicenseForListing(listing) {
    let propertyId = listing.verifiedPropertyId;
    if (propertyId) {
      return this.lookupLicenseForPropertyId(propertyId);
    } else {
      return null;
    }
  }

  lookupLicenseForProperty(property) {
    return this.lookupLicenseForPropertyId(property.id);
  }

  lookupListingsForLicense(license) {
    const propertyId = license.propertyId;
    return this.lookupListingsForPropertyId(propertyId);
  }

  lookupListingsForPropertyId(propertyId) {
    if (propertyId) {
      return this.listings.items.filter(listing => listing.verifiedPropertyId === propertyId);
    } else {
      return [];
    }
  }

  getLicenseForSuggestedAddress(addressId) {
    return this.licenses.items.filter(license => license.addressId === addressId)[0];
  }

  getLicenseForSuggestedAddressLabel(addressLabel) {
    return this.licenses.items.filter(license => {
      const licenseAddress = license.address;
      const formatted = licenseAddress && licenseAddress.formatStreet();
      return !!formatted && formatted === addressLabel;
    })[0];
  }

  approveLicenseApplication(application) {
    // Update application with approved status, approveBy, dateApproved
    return this.licenseApplications.setItem(application.id, {
      status: 'approved',
      approvedByUserId: this.currentUser.id,
      dateApproved: new Date()
    });
  }

  denyLicenseApplication(application) {
    return this.licenseApplications.setItem(application.id, {
      status: 'denied',
      deniedByUserId: this.currentUser.id,
      dateDenied: new Date()
    });
  }

  lookupReportsForListing(listing) {
    return this.reports.items.filter(report => report.listingId === listing.id);
  }

  submitReport(listingId, report) {
    console.log(`About to submit ${JSON.stringify(report._data)}`);
    report.listingId = listingId;
    report.city = this.city;
    report.dateSubmitted = new Date();
    console.log(`Submitting ${JSON.stringify(report._data)}`);
    return this.reports.addItem(report._data);
  }

  async parseLicenses(/* File */ file) {
    const {input,format} = await readFile(file);
    const data = await callApi('importLicenses', {
      cityId: this.cityId,
      input,
      format,
      excludeLicensesFromOutput: false,
      excludeUnchangedFromOutput: false
    }, 'data');
    return new LicenseParseResult(this.cityInfo, data);
  }
}
