<template>
  <LayoutCard
    title="Map"
    description="The geographical center of town is needed to set the default position of the map."
    class="mb-4">
    <div class="d-flex flex-row gap-2">
      <div class="flex-grow-1 flex-column d-flex gap-3">
        <div class="row">
          <div class="col-sm-12 col-md-2 col-form-label">Latitude</div>
          <div class="col">
            <input type="number" v-model="city.latlng.lat" class="form-control" disabled/>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-2 col-form-label">Longitude</div>
          <div class="col">
            <input type="number" v-model="city.latlng.lng" class="form-control" disabled/>
          </div>
        </div>
        <div>
          <button type="button" class="btn btn-primary d-flex flex-row align-items-center gap-2" @click="fetchBoundary">
            <spinner-view small v-if="fetchingBoundary" />
            <div>Lookup municipal boundaries</div>
          </button>
          <div class="form-text">
            {{ city.geoJson ? 'Boundary has been looked up' : 'Boundary has not been looked up yet' }}
          </div>
        </div>
      </div>
      <div style="width:240px;height:240px" class="rounded overflow-hidden">
        <!-- Move this to a wrapper component that simplifies map instances, and then use it in GMap too -->
        <GMap
          :city="city"
          :items="[city]"
          :initial-zoom="10"
          hide-buttons>
          <template #marker="{item}">
            <GMapCityMarker :city="item" />
          </template>
        </GMap>
      </div>
    </div>
  </LayoutCard>
</template>

<script>
import {City} from "@/models/City";
import LayoutCard from "@/components/common/LayoutCard.vue";
import GMap from "@/components/common/GMap.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import GMapCityMarker from "@/components/common/GMapCityMarker.vue";

export default {
  components: {GMapCityMarker, SpinnerView, GMap, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      fetchingBoundary: false
    };
  },
  methods: {
    async fetchBoundary() {
      this.fetchingBoundary = true;
      try {
        await this.city.fetchBoundary();
      } finally {
        this.fetchingBoundary = false;
      }
    }
  }
}
</script>
