<template>
  <ModalView
    v-model="show"
    :title="license && !license.isNew ? `Editing License #${license.number}` : `Add License${model && model.licenseId ? ` #${model.licenseId}` : ''}`"
    :busy="model && model.submitting"
    scrollable
    size="lg"
    :ok-title="`Save ${license && license.isNew ? 'New ' : ''}License`"
    @ok="commitLicense"
    :disable-ok="!model">
    <template #buttons>
      <button v-if="!deleting && canDelete" type="button" class="btn btn-flat text-danger" @click="deleting = true">
        <i class="bi-trash" /> Delete
      </button>
    </template>
    <template v-if="license && model">
      <BCollapse :show="!deleting">
        <form @submit.prevent="commitLicense">
          <div class="mb-3 pb-3">
            <div class="fw-semibold mb-3">Property</div>
            <AddressRegistrationForm :model="model" />
          </div>
          <div class="mb-3 pb-3">
            <div class="fw-semibold mb-3">Emergency Contact</div>
            <OwnerRegistrationForm :model="model" />
          </div>
          <div class="mb-3 pb-3">
            <div class="fw-semibold mb-3">Rental Details</div>
            <CustomRegistrationForm :model="model" />
          </div>
          <div>
            <div class="fw-semibold mb-3">Administrative Use Only</div>
            <div class="mb-3" v-if="license.isNew">
              <label class="form-label">License Number</label>
              <div class="d-flex flex-row align-items-center">
                <input type="text" class="form-control flex-grow-1" v-model="model.licenseId"  />
                <button type="button" class="btn btn-flat ms-1" @click="model.generateLicenseId()" :disabled="model.generatingLicenseId">
                  <div class="d-flex flex-row align-items-center" v-if="model.generatingLicenseId">
                    <spinner-view small class="me-1"/> Generating
                  </div>
                  <template v-else>
                    Generate
                  </template>
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Date Applied</label>
              <div class="d-flex flex-row align-items-center">
                <DateInput v-model="model.dateApplied" class="form-control flex-grow-1" />
                <button type="button" class="btn btn-flat ms-1" @click="model.dateApplied = new Date()">
                  Today
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Date Issued</label>
              <div class="d-flex flex-row align-items-center">
                <DateInput v-model="model.dateIssued" class="form-control flex-grow-1" />
                <button type="button" class="btn btn-flat ms-1" @click="model.dateIssued = new Date()">
                  Today
                </button>
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label">Status</label>
              <select class="form-select" v-model="model.status">
                <option disabled>Select one:</option>
                <option v-for="status in license.statusOptions" :key="status.code" :value="status.code">{{ status.label }}</option>
              </select>
            </div>
            <div class="mb-3 form-check">
              <label class="form-check-label">Paid</label>
              <input type="checkbox" class="form-check-input" v-model="model.paid" />
            </div>
          </div>
        </form>
      </BCollapse>
      <BCollapse :show="deleting">
        <div class="p-3">
          <div class="card card-body shadow">
            <label class="fw-bold mb-3">Are you sure you want to delete this license?</label>
            <div class="d-flex flex-row">
              <div class="w-50 pe-1">
                <a class="btn btn-outline-danger w-100" @click="commitDelete">Yes, delete it</a>
              </div>
              <div class="w-50 ps-1">
                <a class="btn btn-outline-dark w-100" @click="deleting = false">No, keep it</a>
              </div>
            </div>
          </div>
        </div>
      </BCollapse>
    </template>
    <template v-else>
      Select a license to edit.
    </template>
  </ModalView>
</template>

<script>
import ModalView from "@/components/common/ModalView.vue";
import {License} from "@/models/License";
import BCollapse from "@/components/common/BCollapse.vue";
import AddressRegistrationForm from "@/components/register/AddressRegistrationForm.vue";
import OwnerRegistrationForm from "@/components/register/OwnerRegistrationForm.vue";
import CustomRegistrationForm from "@/components/register/CustomRegistrationForm.vue";
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import DateInput from "@/components/common/DateInput.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
export default {
  components: {
    SpinnerView,
    DateInput,
    CustomRegistrationForm, OwnerRegistrationForm, AddressRegistrationForm, BCollapse, ModalView
  },
  emits: ['update:license'],
  props: {
    license: License
  },
  data() {
    return {
      model: null,
      deleting: false
    };
  },
  watch: {
    license: {
      handler() {
        this.model = !this.license ? null : new LicenseEditorModel(this.license.city, this.license.data, this.license.id);
      },
      immediate: true
    }
  },
  computed: {
    canDelete() {
      return false;
    },
    show: {
      get() { return !!this.model; },
      set(show) {
        if (!show) {
          this.$emit('update:license', null);
        }
      }
    },
    city() {
      if (this.model) {
        return this.model.city;
      } else {
        return null;
      }
    }
  },
  methods: {
    async commitLicense() {
      try {
        let license = await this.model.submit();
        console.log(`Created / Updated license ${license.id}`);
        this.show = false;
      } catch (e) {
        this.error = e;
      }
    }
  }
}
</script>
