import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import VueGtagPlugin from 'vue-gtag';

// Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import * as bootstrap from 'bootstrap';
import Popper from 'popper.js';

import './app.scss';
import './assets/static.css';

import moment from "moment";

import App from './App.vue';
import HomeView from './components/HomeView.vue';
import LicensesComponent from "@/components/LicensesComponent";
import ViolationsComponent from "@/components/ViolationsComponent.vue";
import NeedsAttentionComponent from "@/components/NeedsAttentionComponent.vue";
import LicenseRegistrationComponent from "@/components/LicenseRegistrationComponent.vue";
import {LocalStorage} from "@/models/LocalStorage";
import AdminUsersComponent from "@/components/AdminUsersComponent.vue";
import AdminCitiesComponent from "@/components/AdminCitiesComponent.vue";
import AdminCityComponent from "@/components/admin-city/AdminCityComponent.vue";
import NewCityComponent from "@/components/admin-city/NewCityComponent.vue";
import HomePage from "@/homepage/HomePage.vue";
import CityHomeComponent from "@/components/CityHomeComponent.vue";

let app = createApp(App);
app.mixin({
  data() {
    return { bootstrap, Popper, LocalStorage };
  },
  methods: {
    formatAddress(address) {
      return `${address.streetNumber} ${address.street}`;
    },
    formatDate(date, valueIfNull) {
      let m = null;
      if (date && date.match && date.match(/\d\d\d\d-\d\d-\d\d/)) {
        m = moment(date, 'YYYY-MM-DD')
      }
      if (date && date.toDate) {
        date = date.toDate();
      }
      if (date instanceof Date) {
        m = moment(date);
      }
      if (date && date.toDate && date.format) {
        m = date;
      }
      if (m) {
        return m.format('MMMM D, YYYY');
      }
      return valueIfNull || '(No date)';
    },
    formatDuration(duration) {
      let d = moment.duration(duration);
      let hours = d.hours();
      let minutes = d.minutes();
      let seconds = d.seconds();
      return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    formatCount(count, singular, plural) {
      return `${count} ${count === 1 ? singular : (plural || `${singular}s`)}`;
    },
    formatNumber(number, noun, pluralForm) {
      if (typeof number !== 'number') {
        return '---';
      } else {
        let n = number.toLocaleString();
        if (!noun) {
          return n;
        } else if (number === 1) {
          return `${n} ${noun}`;
        } else {
          return `${n} ${pluralForm || `${noun}s`}`;
        }
      }
    },
    formatBytes(bytes) {
      const GB = Math.pow(1024, 3);
      const MB = Math.pow(1024, 2);
      const KB = Math.pow(1024, 1);
      if (bytes > GB) {
        return `${(bytes / GB).toFixed(2)} GB`;
      } else if (bytes > MB) {
        return `${(bytes / MB).toFixed(2)} MB`;
      } else if (bytes > KB) {
        return `${(bytes / KB).toFixed(2)} KB`;
      } else {
        return `${bytes.toFixed(2)} B`;
      }
    },
    formatDollars(amount) {
      return (amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    },
    formatPhone(phone) {
      return phone && phone.replace(/[^\d]/g, '')
        .replace(/^(\d{6})/, '$1-')
        .replace(/^(\d{3})/, '($1) ');
    }
  },
  computed: {
    isAdminView() {
      return this.model && this.model.currentUser;
    },
    isMobile() {
      const userAgent = window.navigator.userAgent;
      return userAgent.match(/android|webos|iphone|ipad|ipod|blackberry|windows phone/i);
    }
  }
});

function mapProps(route) {
  let props = {...route.query, ...route.params};
  for (let key in props) {
    if (props[key] === 'true') {
      props[key] = true;
    } else if (props[key] === 'false') {
      props[key] = false;
    }
  }
  return props;
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: 'marketing',
      path: '/marketing',
      component: HomePage
    },
    {
      name: 'home',
      path: '/',
      component: HomeView
    },
    {
      name:'city',
      path: '/:cityId',
      component: CityHomeComponent,
      props: true
    },
    {
      name: 'licenses',
      path: '/:cityId/licenses',
      component: LicensesComponent,
      props: mapProps
    },
    {
      name: 'license-detail',
      path: '/:cityId/licenses/:selectedLicenseId',
      component: LicensesComponent,
      props: mapProps
    },
    {
      name: 'violations',
      path: '/:cityId/violations',
      component: ViolationsComponent,
      props: mapProps
    },
    {
      name: 'violation-detail',
      path: '/:cityId/violations/:selectedListingId',
      component: ViolationsComponent,
      props: mapProps
    },
    {
      name: 'listings',
      path: '/:cityId/listings',
      component: NeedsAttentionComponent,
      props: mapProps
    },
    {
      name: 'listing-detail',
      path: '/:cityId/listings/:selectedListingId',
      component: NeedsAttentionComponent,
      props: mapProps
    },
    {
      name: 'register',
      path: '/:cityId/register',
      component: LicenseRegistrationComponent,
      props: true
    },
    {
      name: 'admin',
      path: '/admin',
      redirect: '/admin/cities'
    },
    {
      name: 'admin-users',
      path: '/admin/users',
      component: AdminUsersComponent
    },
    {
      name: 'admin-cities',
      path: '/admin/cities',
      component: AdminCitiesComponent
    },
    {
      name: 'admin-city',
      path: '/admin/cities/:cityId',
      redirect: to => {
        return {
          name: 'admin-city-tab',
          params: {
            ...to.params,
            tabId: 'general'
          }
        };
      }
    },
    {
      name: 'admin-city-tab',
      path: '/admin/cities/:cityId/:tabId',
      component: AdminCityComponent,
      props: true
    },
    {
      name: 'admin-new-city',
      path: '/admin/new-city',
      component: NewCityComponent
    }
  ]
});

app.use(router);

app.use(VueGtagPlugin, {
  config: { id: 'G-G2VNY7QRSC' }
}, router);

app.mount('#app');
