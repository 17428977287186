<template>
  <div class="d-flex flex-column gap-2">
    <template v-if="!properties">
      <div>Would you like to verify the address at {{ streetViewLabel }}?</div>
      <button type="button" class="btn btn-primary" @click="lookup" :disabled="loading">
        <spinner-view small v-if="loading" /> Look up property records
      </button>
    </template>
    <div v-else-if="properties.length === 0">
      We couldn't find any matching property records at {{ streetViewLabel }}.
    </div>
    <template v-else>
      <SuggestedListingProperty
        v-for="property in properties"
        :key="property.id"
        :listing="listing"
        :property="property"
        :model="model"
        @verify="verify"
      />
    </template>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import SpinnerView from "@/components/common/SpinnerView.vue";
import SuggestedListingProperty from "@/components/SuggestedListingProperty.vue";
import {AppModel} from "@/models/AppModel";

export default {
  components: {SuggestedListingProperty, SpinnerView},
  props: {
    model: AppModel,
    listing: Listing,
    streetViewLabel: String
  },
  data() {
    return {
      loading: false,
      saving: false,
      properties: null
    }
  },
  watch: {
    listing() {
      this.reset();
    },
    streetViewLabel() {
      this.reset();
    }
  },
  methods: {
    reset() {
      this.loading = false;
      this.saving = false;
      this.properties = null;
    },
    async lookup() {
      this.loading = true;
      try {
        this.properties = await this.listing.city.normalizePropertiesWithGoogle(this.streetViewLabel);
      } finally {
        this.loading = false;
      }
    },
    async verify({property, notes}) {
      this.saving = true;
      try {
        await this.listing.verifyProperty(property, notes);
      } finally {
        this.saving = false;
      }
    }
  }
}
</script>
