<template>
  <GeneralListingsCard :city="city" />
  <TaskView label="Listings" import-title="Import Listings" count-label="Listings Parsed" task-name="importListings" :city="city">
    <template #description>
      Import listings from popular listings services. Listings will initially only include information like title,
      lat/lng, and pictures. To import additional details (e.g. host name, # bedrooms, etc.), you will need to
      import details separately.
    </template>
    <template #buttons>
      <router-link :to="{name:'admin-city-tab',params:{cityId:city.id,tabId:'listings'}}" class="btn btn-flat text-primary">
        See the listings
      </router-link>
    </template>
  </TaskView>
  <TaskView label="Listing Details" import-title="Parse Listings" count-label="Listings Parsed" task-name="importListingDetails" :city="city">
    <template #description>
      The daily monitoring service only pulls in listing summaries and does not include the host's name or a detailed
      listing description. To find this information, we need to do a deeper dive and parse each listing page
      individually.
    </template>
  </TaskView>
  <TaskView label="Image Cache" import-title="Cache Images" count-label="Images Cached" task-name="cacheListingImages" :city="city">
    <template #description>
      All of the listings' relevant images exist on other vendors' sites. To improve the performance of our
      matching algorithms, we can download the images to our site.
    </template>
  </TaskView>
  <TaskView label="Listing Screenshots" import-title="Take Screenshots" count-label="Screenshots Taken" task-name="takeListingScreenshots" :city="city">
    <template #description>
      In order to include evidence with violation letters, we can automatically take screenshots of all active listings
      in your municipality.
    </template>
  </TaskView>
  <DefaultListingValuesCard :city="city"/>
</template>

<script>
import {City} from "@/models/City";
import TaskView from "@/components/admin-city/TaskView.vue";
import GeneralListingsCard from "@/components/admin-city/listings/GeneralListingsCard.vue";
import DefaultListingValuesCard from "@/components/admin-city/listings/DefaultListingValuesCard.vue";

export default {
  components: {DefaultListingValuesCard, GeneralListingsCard, TaskView},
  props: {
    city: City
  },
  computed: {
    running: {
      get() {
        return !this.city.paused;
      },
      set(running) {
        this.city.updatePausedState(!running);
      }
    }
  }
}
</script>
