<template>
  <div class="d-flex flex-column align-items-stretch hover-target">
    <div class="d-flex flex-row w-100">
      <b-image
        v-if="license.imageUrl"
        :src="license.imageUrl"
        image-class="rounded me-2"
      />
      <div class="d-flex flex-column align-items-stretch flex-grow-1">
        <!-- Row 1: Address + Status -->
        <div class="d-flex flex-row align-items-center">
          <div class="fw-semibold me-auto">
            {{ license.addressFormatted || license.unverifiedAddress || 'Unknown address' }}
          </div>
          <LicenseStatusView :license="license" class="ms-2 small" />
        </div>

        <div v-if="!license.isLinkedToProperty" class="text-danger small">
          <i class="bi-info-circle" /> Missing parcel ID
        </div>

        <!-- Row 2: License # + Date -->
        <div class="d-flex flex-row align-items-center small">
          <div class="me-auto text-muted d-flex flex-row flex-wrap gap-0">
            <div v-if="license.number">License #{{ license.number }}</div>
            <template v-for="field in customFields" :key="field.id">
              <template v-if="license.getCustomFieldValue(field.id)">
                <i class="bi-dot"/>
                <div>
                  {{ license.getCustomFieldValue(field.id) }}
                </div>
              </template>
            </template>
          </div>
          <LicenseDateView :license="license" class="text-muted" />
        </div>

        <!-- Row 3: Contact -->
        <div v-if="license.contact && license.contact.owners" class="d-flex flex-row align-items-start small">
          <div class="me-auto text-muted">{{ license.contact.owners.join(', ') }}</div>
          <div v-if="!license.isExpired && license.expirationDateFormatted" class="text-nowrap text-muted">
            Expires {{ license.expirationDateFormatted }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {AppModel} from "@/models/AppModel";
import {License} from "@/models/License";
import BImage from "@/components/common/BImage.vue";
import LicenseStatusView from "@/components/LicenseStatusView.vue";
import LicenseDateView from "@/components/LicenseDateView.vue";

export default {
  components: {
    LicenseDateView,
    LicenseStatusView,
    BImage
  },
  emits: ['edit'],
  props: {
    license: License,
    model: AppModel
  },
  computed: {
    customFields() {
      return this.model.cityInfo.customFields.filter(field => field.filter);
    }
  }
}
</script>
