<template>
  <CitySplashView :city="city">
    <div v-if="!model || !form" class="w-100 p-4 d-flex flex-column align-items-center">
      <SpinnerView />
    </div>
    <div v-else-if="!form.published" class="w-100 p-4 text-center h5 mb-0">
      We're sorry. The self-service license application form for {{ city.name }} hasn't been configured yet.
    </div>
    <div v-else-if="!!error" class="w-100 p-4 text-center text-danger h5 mb-0">
      <p>We're sorry. An error occurred.</p>
      <p>{{ error }}</p>
    </div>
    <div v-else>
      <BCollapse :show="currentStepKey !== 'done'">
        <div class="d-flex flex-row py-4">
          <div class="d-flex flex-column w-100 align-items-center" v-for="(s, i) in steps" :key="`step_${i}`" style="min-width:64px">
            <div class="d-flex flex-row align-items-center w-100 mb-1">
              <div
                :class="`flex-grow-1 border-top border-2 ${i === 0 ? 'border-clear' : ''}`"
                :style="i <= step && i !== 0 ? `border-color:#${this.cityColor}!important` : ''"
              />
              <div
                class="rounded-circle border border-2 text-center small fw-semibold"
                :style="styleForStep(i)">
                {{ i + 1 }}
              </div>
              <div
                :class="`flex-grow-1 border-top border-2 ${i === steps.length - 1 ? 'border-clear' : ''}`"
                :style="i < step && i !== steps.length - 1 ? `border-color:#${this.cityColor}!important` : ''"
              />
            </div>
            <div :class="`small ${step === i ? 'fw-semibold' : ''}`" :style="`color:#${this.cityColor}`">
              {{ s.label }}
            </div>
          </div>
        </div>
      </BCollapse>

      <div class="p-md-4 p-2" v-if="model">

        <!-- Step 1: Address -->
        <BCollapse :show="currentStepKey === 'address'">
          <AddressRegistrationForm :model="model" />
        </BCollapse>

        <!-- Step 2: Owner -->
        <BCollapse :show="currentStepKey === 'owner'">
          <OwnerRegistrationForm :model="model" @submit="next" />
        </BCollapse>

        <!-- Step 3: Listings -->
        <BCollapse :show="currentStepKey === 'listings'">
          <ListingsRegistrationForm :model="model" />
        </BCollapse>

        <!-- Step 4: Custom Fields -->
        <BCollapse :show="currentStepKey === 'other'">
          <CustomRegistrationForm :model="model" />
        </BCollapse>

        <!-- Step 5: Finish -->
        <BCollapse :show="currentStepKey === 'review'">
          <ReviewRegistrationForm :model="model" />
        </BCollapse>

        <!-- Done -->
        <BCollapse :show="currentStepKey === 'done'">
          <div class="p-4">
            <p>
              Your application has been submitted. Your license application # is <b>{{ model.id }}</b>
            </p>
            <p>
              {{ city.licenseApplicationConfirmationText }}
            </p>
            <p>Thank you!</p>
          </div>
        </BCollapse>
      </div>

      <BCollapse :show="currentStepKey !== 'done'">
        <div class="d-flex flex-row align-items-center justify-content-between mt-3">
          <button class="btn btn-flat me-3 fw-semibold" @click.stop.prevent="back" v-if="step > 0">
            <i class="bi-arrow-left"/> Back
          </button>
          <div v-else></div>
          <button class="btn btn-flat fw-semibold" @click.stop.prevent="next" :disabled="!isCurrentStepComplete || submitting" :style="nextButtonStyle">
            <spinner-view v-if="submitting" class="me-2" small />
            <template v-if="step === steps.length - 1">
              Finish
            </template>
            <template v-else>
              Next <i class="bi-arrow-right"/>
            </template>
          </button>
        </div>
      </BCollapse>
    </div>
  </CitySplashView>
</template>

<script>
import {City} from "@/models/City";
import CitySplashView from "@/components/common/CitySplashView.vue";
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import SpinnerView from "@/components/common/SpinnerView.vue";
import AddressRegistrationForm from "@/components/register/AddressRegistrationForm.vue";
import OwnerRegistrationForm from "@/components/register/OwnerRegistrationForm.vue";
import ListingsRegistrationForm from "@/components/register/ListingsRegistrationForm.vue";
import BCollapse from "@/components/common/BCollapse.vue";
import CustomRegistrationForm from "@/components/register/CustomRegistrationForm.vue";
import ReviewRegistrationForm from "@/components/register/ReviewRegistrationForm.vue";

export default {
  components: {
    ReviewRegistrationForm,
    CustomRegistrationForm,
    BCollapse,
    ListingsRegistrationForm,
    AddressRegistrationForm,
    OwnerRegistrationForm,
    SpinnerView,
    CitySplashView
  },
  props: {
    cityId: String
  },
  data() {
    return {
      model: null,
      submitting: false,
      step: 0,
      error: null
    };
  },
  mounted() {
    City.loadCity(this.cityId).then(city => {
      this.model = new LicenseEditorModel(city);
    });
  },
  // watch: {
  //   'model.addressId'() {
  //     if (this.step === 0 && this.model.addressId) {
  //       this.next();
  //     }
  //   }
  // },
  methods: {
    back() {
      this.step = Math.max(0, this.step - 1);
    },
    next() {
      if (this.step < this.steps.length - 1) {
        this.step = this.step + 1;
      } else {
        this.finish();
      }
    },
    finish() {
      this.submitting = true;
      this.model.submit()
      .then(() => {
        this.submitting = false;
      })
      .catch(error => {
        this.error = error;
      });
    },
    styleForStep(step) {
      const selected = this.step === step;
      const done = step < this.step;
      return {
        minWidth: '26px',
        borderColor: `${done || selected ? `#${this.cityColor}` : 'var(--bs-lightgray)'} !important`,
        color: `#${selected ? 'ffffff' : this.cityColor}`,
        backgroundColor: `#${!selected ? 'ffffff' : this.cityColor}`
      };
    }
  },
  computed: {
    form() {
      return this.model && this.model.form;
    },
    city() {
      return this.model && this.model.city;
    },
    cityColor() {
      return (this.city && this.city.backgroundColor) || '333333';
    },
    isCurrentStepComplete() {
      return this.steps[this.step].isComplete();
    },
    nextButtonStyle() {
      if (this.isCurrentStepComplete) {
        return {
          color: `#${this.cityColor}!important`,
          borderColor: `#${this.cityColor}!important`
        };
      } else {
        return {
          color: `#888!important`,
          borderColor: 'transparent'
        };
      }
    },
    currentStepKey() {
      if (this.model.isNew) {
        return this.steps[this.step].value;
      } else {
        return 'done';
      }
    },
    steps() {
      return [
        {
          value: 'address',
          label: 'Address',
          isComplete: () => this.model.isAddressComplete
        },
        {
          value: 'owner',
          label: 'Contact',
          isComplete: () => this.model.contact.isComplete
        },
        {
          value: 'listings',
          label: 'Listings',
          isComplete: () => true
        },
        this.model && this.model.customFields.length > 0 ?
        {
          value: 'other',
          label: 'Other',
          isComplete: () => true
        } : null,
        {
          value: 'review',
          label: 'Review',
          isComplete: () => true
        }
      ].filter(step => !!step);
    }
  }
}
</script>
