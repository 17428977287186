<template>
  <ModalView title="New User" ok-title="Create User" v-model="show" @ok="confirm" :busy="creating">
    <form @submit.prevent v-if="newUser">
      <div class="mb-3">
        <label class="form-label">Username/Email</label>
        <input type="text" v-model="newUser.username" class="form-control"/>
      </div>
      <div class="mb-3">
        <label class="form-label">Password</label>
        <input type="password" v-model="newUser.password" class="form-control" />
      </div>
      <div class="mb-3">
        <label class="form-label">Display Name</label>
        <input type="text" v-model="newUser.displayName" class="form-control" />
      </div>
      <div v-if="errorMessage" class="alert alert-danger">
        {{ errorMessage }}
      </div>
    </form>
  </ModalView>
</template>

<script>
import {NewUser} from "@/models/User";
import ModalView from "@/components/common/ModalView.vue";
export default {
  components: {ModalView},
  emits: ['update:modelValue', 'create'],
  props: {
    modelValue: Boolean
  },
  data() {
    return {
      newUser: null,
      creating: false,
      errorMessage: null
    };
  },
  watch: {
    modelValue: {
      handler(show) {
        this.newUser = show ? new NewUser() : null;
      },
      immediate: true
    }
  },
  computed: {
    show: {
      get() { return !!this.modelValue; },
      set(s) { if (!s) this.$emit('update:modelValue', false); }
    }
  },
  methods: {
    confirm() {
      this.creating = true;
      this.newUser.save()
      .then(user => {
        this.show = false;
        this.$emit('create', user);
      })
      .catch(err => {
        this.errorMessage = err.message;
      })
      .finally(() => this.creating = false);
    }
  }
}
</script>
