<template>
  <div class="d-flex flex-column">
    <div class="position-relative">
      <input
        ref="input"
        :class="`form-control mb-0 ${showSuggestions ? 'rounded-0 rounded-top' : ''}`"
        placeholder="e.g. 21 Main St"
        enterkeyhint="search"
        v-model="queryDisplay"
        :disabled="!typeAhead && loading"
        @focus="onFocus"
        @blur="onBlur"
        @click.stop="onFocus"
        @keyup.enter.stop.prevent="onKeyUp"
        @keydown.enter.stop.prevent
      />
      <div class="position-absolute h-100 d-flex flex-column justify-content-center align-items-center" style="right:0;top:0;">
        <spinner-view v-if="loading" small class="mx-3"/>
        <button v-else type="button" class="btn btn-primary btn-sm me-1" @click="runSearch" :disabled="!queryDisplay">
          Search
        </button>
      </div>
    </div>
    <BCollapse :show="showSuggestions">
      <div class="card overflow-auto mt-0 border-top-0 rounded-0 rounded-bottom" style="max-height:255px">
        <div v-if="showLabels && suggestions.length > 0" class="form-text mt-0 px-3 py-1 bg-light">
          Potential matches:
        </div>
        <div v-if="suggestions.length === 0" class="small p-3 bg-light d-flex flex-column align-items-start gap-3">
          <template v-if="!query">
            Start typing an address to see matching properties below
          </template>
          <template v-else-if="loading">
            Searching properties...
          </template>
          <template v-else>
            <div>
              We're sorry! We couldn't find any matches in the assessor database. If you believe this is an error,
              please click the button below to inform our staff.
            </div>
            <div v-if="errorReportStatus === 'sent'">
              Thanks for sending the error report! We'll get this resolved shortly.
            </div>
            <button v-else type="button" class="btn btn-primary btn-sm" @click="sendErrorReport" :disabled="errorReportStatus === 'sending'">
              <spinner-view small v-if="errorReportStatus === 'sending'" class="me-1" /> Send Error Report
            </button>
          </template>
        </div>
        <ul v-else class="list-group list-group-flush">
          <li
            v-for="(suggestion, index) in suggestions"
            :key="suggestion.property.id"
            @click.stop="selectProperty(suggestion)"
            :class="`list-group-item list-group-item-action border-0 ${index === selectedIndex ? 'bg-primary bg-opacity-10' : ''}`"
          >
            <div class="nav-link">
              <div>
                {{ suggestion.property.addressFormatted }}
              </div>
              <div v-if="showLabels" class="small text-muted">
                {{ suggestion.reason }}
              </div>
              <div class="d-flex flex-row">
                <AssessorLink :property="suggestion.property" @click.stop />
              </div>
              <div v-if="suggestion.property.owners.length > 0" class="text-muted small">
                Owners: {{ suggestion.property.owners.join(', ') }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </BCollapse>
    <div v-if="error" class="text-danger mt-2">
      {{ error }}
    </div>
  </div>
</template>

<script>
import {City} from "@/models/City";
import BCollapse from "@/components/common/BCollapse";
import SpinnerView from "@/components/common/SpinnerView.vue";
import AssessorLink from "@/components/common/AssessorLink.vue";
export default {
  components: {AssessorLink, SpinnerView, BCollapse},
  emits: ['update:modelValue','update:property'],
  props: {
    city: {
      type: City,
      required: true
    },
    alwaysShowResults: Boolean,
    showHelpText: Boolean,
    modelValue: String,
    showLabels: {
      type: Boolean,
      default: () => true
    },
    typeAhead: Boolean
  },
  data() {
    return {
      property: null,
      query: '',
      normalizeTask: null,
      focused: false,
      matches: [],
      selectedIndex: -1,
      error: null,
      sendingErrorReport: false,
      searchStatus: null,
      errorReportStatus: null
    };
  },
  mounted() {
    console.log('mounted');
    this.lookupProperty();
  },
  watch: {
    modelValue() {
      console.log('watch modelValue');
      this.lookupProperty();
    },
    property() {
      console.log('watch property');
      if (this.property) {
        this.focused = false;
      }
      this.$emit('update:property', this.property);
    },
    query() {
      this.searchStatus = null;
      this.error = null;
      this.errorReportStatus = null;
      this.matches = [];
    }
  },
  computed: {
    loading() {
      return !!this.normalizeTask;
    },
    showInput() {
      return true;
    },
    showSuggestions() {
      return this.alwaysShowResults ||
        (this.showInput && this.suggestions.length > 0) ||
        (!this.loading && this.suggestions.length === 0 && !!this.searchStatus && !this.property);
    },
    isQueryLongEnoughToAutoRun() {
      return this.query.length > 4;
    },
    suggestions() {
      let suggestions = [];
      this.matches.forEach(property => {
        suggestions.push({
          property, reason: 'Based on your input'
        })
      });
      return suggestions;
    },
    queryDisplay: {
      get() {
        if (!!this.searchStatus || this.focused || !this.property) {
          return this.query;
        } else {
          return this.property.addressFormatted;
        }
      },
      set(v) {
        console.log(`set queryDisplay`, v);
        this.query = v;
      }
    }
  },
  methods: {
    selectProperty({ property }) {
      console.log(`Selected ${property.id}`);
      this.$emit('update:modelValue', property.id);
      this.property = property;
      this.query = this.property.addressFormatted;
      this.matches = [];
      this.onBlur();
    },
    lookupProperty() {
      console.log('lookupProperty');
      if (!this.modelValue) {
        this.property = null;
      } else if (!this.property || this.property.id !== this.modelValue) {
        let match = this.matches.filter(a => a.id === this.modelValue)[0];
        if (match) {
          this.property = match;
        } else {
          this.city.getProperty(this.modelValue)
          .then(property => {
            if (property && property.id === this.modelValue) {
              this.property = property;
            }
          });
        }
      }
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      setTimeout(() => {
        this.$refs.input.blur();
        this.focused = false;
      }, 50);
    },
    onKeyUp(event) {
      if (this.typeAhead) {
        if (event.keyCode === 38) {
          // Up arrow
          this.selectedIndex--;
          if (this.selectedIndex < 0) {
            this.selectedIndex = this.suggestions.length - 1;
          }
        } else if (event.keyCode === 40) {
          // Down arrow
          this.selectedIndex++;
          if (this.selectedIndex >= this.suggestions.length) {
            this.selectedIndex = -1;
          }
        } else if (event.keyCode === 13) {
          // Enter
          let selected = this.suggestions[this.selectedIndex];
          console.log(`Pressed enter with selection ${this.selectedIndex}: ${selected}`);
          if (selected) {
            this.selectProperty(selected);
          }
        } else {
          console.log(`Pressed another key. Running a new search.`);
          this.runSearch();
        }
      } else {
        if (event.keyCode === 13) {
          console.log(`Pressed enter. Run search now.`);
          this.runSearch();
        }
      }
    },
    runSearch() {
      clearTimeout(this.normalizeTask);
      this.normalizeTask = null;

      const query = this.query;
      if (!this.typeAhead || this.isQueryLongEnoughToAutoRun) {
        // if (parts.length > 2 || (parts.length > 1 && parts[1].length > 4)) {
        const task = setTimeout(() => {
          // this.$emit('update:modelValue', null);
          console.log(`Pressed another key. Running a new search on ${query}.`);
          this.searchStatus = 'searching';
          return this.city.normalizeProperty(query)
          .then(properties => {
            console.log('Normalized property');
            if (task === this.normalizeTask) {
              console.log('Updating matches');
              this.matches = properties;
              this.normalizeTask = null;
            }
          })
          .catch(error => {
            console.warn(error);
            this.matches = [];
            this.normalizeTask = null;
            this.error = error.toString();
          })
          .finally(() => {
            this.searchStatus = 'searched';
          });
        }, this.typeAhead ? Math.max(10, 500 - query.length * 50 ) : 0);
        this.normalizeTask = task;
      } else {
        this.matches = [];
      }
    },
    async sendErrorReport() {
      this.errorReportStatus = 'sending';
      try {
        await this.city.sendErrorReport('property-search-no-matches', {
          input: this.query,
          error: this.error
        });
      } finally {
        this.errorReportStatus = 'sent';
      }
    }
  }
}
</script>
