<template>
  <ListingsComponent
    :city-id="cityId"
    :selected-listing-id="selectedListingId"
    tab="listings"
    :filter="filter"
    :default-show-partially-imported="onlyShowPartiallyImported"
    :default-show-whitelist="onlyShowWhitelist"
  />
</template>
<script>
import ListingsComponent from "@/components/ListingsComponent.vue";
export default {
  components: {ListingsComponent},
  props: {
    cityId: String,
    selectedListingId: String,
    onlyShowPartiallyImported: Boolean,
    onlyShowWhitelist: Boolean
  },
  methods: {
    filter(listing) {
      return !listing.isPropertyVerified;
    }
  }
}
</script>
