import { mkConfig, asBlob, generateCsv } from "export-to-csv";

export function generateCsvText(delim, /* Array */ header, rows) {
  delim = delim || '\t';
  return header.join(delim) + rows.map(row => {
    row = row.map(cell => (cell||'').replace(/[\t\n\r]/g, '').replace(/,$/, ''));
    return `\n${row.join(delim)}`;
  }).join('');
}

export function generateCsvBlob(delim, header, rows) {
  const config = mkConfig({
    columnHeaders: header,
    useKeysAsHeader: true,
    fieldSeparator: delim,
  });
  const data = rows.map(row => {
    let item = {};
    for (let i = 0; i < header.length; i++) {
      item[header[i]] = row[i];
    }
    return item;
  });
  const csv = generateCsv(config)(data);
  return asBlob(config)(csv);
  //
  // return new Blob([generateCsvText(delim, header, rows)], {type:'text/csv'});
}
