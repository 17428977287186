<template>
    <div class="custom-marker-icon rounded-circle" :style="style">
      <img
        class="marker-icon"
        :src="`https://www.google.com/maps/vt/icon/name=assets/icons/spotlight/spotlight_pin_v4_outline-2-medium.png,assets/icons/spotlight/spotlight_pin_v4-2-medium.png&highlight=${borderColor},${bgColor}?scale=${scale}`"/>
      <img
        v-if="!!icon"
        class="sub-icon"
        :src="icon" />
      <div
        v-else-if="iconName"
        :class="`bi-${iconName} marker-text`"
      />
      <div
        v-else-if="count"
        class="marker-text"
      >
        {{ count }}
      </div>
    </div>
</template>

<script>
// import {FaIcon, FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  props: {
    color: String,
    icon: String,
    iconName: String,
    count: Number
  },
  computed: {
    borderColor() {
      return 'ffffff';
    },
    bgColor() {
      return this.color;
    },
    scale() {
      return 2;
    },
    style() {
      return {
        'background-color': this.color
      };
    }
  }
}
</script>

<style>
.custom-marker-icon {
  opacity: 1;
  width: 0px;
  height: 0px;
}
/*.custom-marker-icon.selected {*/
/*  opacity: 1;*/
/*  width: 0px;*/
/*  height: 0px;*/
/*}*/
.custom-marker-icon img.marker-icon {
  width: 20px;
  position: absolute;
  bottom: 50%;
  right: 50%;
  margin-right: -10px;
  /*transition: width 250ms, margin 250ms;*/
}
/*.custom-marker-icon.selected img.marker-icon {*/
/*  width: 32px;*/
/*  margin-right: -16px;*/
/*}*/
.custom-marker-icon img.sub-icon {
  width: 10px;
  height: 10px;
  object-fit: contain;
  position: absolute;
  margin-bottom: 12px;
  bottom: 0;
  margin-left: -5px;
  filter: brightness(100);
  /*transition: margin 250ms, width 250ms, height 250ms;*/
}
/*.custom-marker-icon.selected img.sub-icon {*/
/*  width: 16px;*/
/*  height: 16px;*/
/*  margin-bottom: 19px;*/
/*  margin-left: -8px;*/
/*}*/
.custom-marker-icon .marker-text {
  color: #fff;
  font-weight: 700;
  position: absolute;
  text-align: center;
  width: 48px;
  margin-left: -24px;
  left: 50%;
  top: 50%;
  margin-top: -22px;
}
</style>
