<template>
  <ListView
    :items="listings"
    :selected-item-id="model.selectedListingId"
    @select="selectListing"
    @highlight="id => model.setListingHighlighted(id, true)"
    @unhighlight="id => model.setListingHighlighted(id, false)"
    v-bind="$attrs"
  >
    <template #item="{item}">
      <ListingItemView
        :model="model"
        :value="item"
        :show-license="model.cityInfo.treatInactiveLicenseAsViolation"
        @street_view="$emit('street_view', item.id)"
      />
    </template>
  </ListView>
</template>

<script>
import {AppModel} from "@/models/AppModel";
import ListingItemView from "@/components/ListingItemView";
import ListView from "@/components/common/ListView.vue";

export default {
  components: {ListView, ListingItemView},
  props: {
    model: AppModel,
    listings: Array,
    query: String
  },
  emits: ['street_view'],
  methods: {
    selectListing(id) {
      this.model.selectListing(id);
    }
  }
}
</script>
