<template>
  <img :src="listing.listingSource.iconUrl" width="14" alt="" />
</template>

<script>
import {Listing} from "@/models/Listing";

export default {
  props: {
    listing: Listing
  }
}
</script>
