<template>
  <div class="position-relative">
    <input
      ref="input"
      :class="`form-control mb-0 ${inputClass}`"
      :placeholder="placeholder"
      enterkeyhint="search"
      v-model="value"
      :disabled="loading"
      @keyup.enter.stop.prevent="run"
      @keydown.enter.stop.prevent
    />
    <div class="position-absolute h-100 d-flex flex-row justify-content-center align-items-center gap-1" style="right:0;top:0;">
      <spinner-view v-if="loading" small class="mx-3"/>
      <template v-else>
        <icon-button v-if="!!modelValue" icon="x-lg" @click="clear" small />
        <button type="button" class="btn btn-primary btn-sm me-1" @click="run" :disabled="!value">
          {{ buttonTitle }}
        </button>
      </template>
    </div>
  </div>
</template>
<script>
import SpinnerView from "@/components/common/SpinnerView.vue";
import IconButton from "@/components/common/IconButton.vue";
export default {
  components: {IconButton, SpinnerView},
  emits: ['update:modelValue', 'go'],
  props: {
    buttonTitle: String,
    placeholder: String,
    inputClass: String,
    loading: Boolean,
    modelValue: String
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  methods: {
    run() {
      this.$emit('go');
    },
    clear() {
      this.value = '';
      this.run();
    }
  }
}
</script>
