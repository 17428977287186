<template>
  <ModalView v-model="show" title="Delete User" ok-title="Delete" ok-variant="danger" @ok="confirm" :busy="deleting">
    <template v-if="modelValue">
      Are you sure you want to delete <b>{{ modelValue.email }}</b>?
    </template>
  </ModalView>
</template>

<script>
import {User} from "@/models/User";
import ModalView from "@/components/common/ModalView.vue";

export default {
  components: {ModalView},
  emits: [
    'update:modelValue',
    'delete'
  ],
  props: {
    modelValue: User
  },
  data() {
    return {
      deleting: false
    };
  },
  computed: {
    show: {
      get() { return !!this.modelValue; },
      set(s) { if (!s) this.$emit('update:modelValue', null); }
    }
  },
  methods: {
    confirm() {
      const user = this.modelValue;
      this.deleting = true;
      user.delete()
      .then(() => {
        this.show = false;
        this.$emit('delete', user);
      })
      .finally(() => this.deleting = false);
    }
  }
}
</script>
