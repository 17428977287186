<template>
  <LayoutCard :title="label" :description="description">
    <template #description><slot name="description"/></template>
    <div class="mb-3">
      <div v-if="!task">
        <spinner-view small /> Loading task status
      </div>
      <div v-else>
        <table class="table table-bordered">
          <tbody>
          <tr>
            <td colspan="2" :class="`text-${status.variant} bg-${status.variant} bg-opacity-10 fw-semibold`">
              <spinner-view small v-if="status.busy" /> {{ status.text }}
              <div v-if="task.isFailed && !!task.error" class="text-danger">
                {{ task.error }}
              </div>
            </td>
          </tr>

          <tr v-if="task.isRepeated && task.progressPercent < 100">
            <td colspan="2">
              <div class="progress">
                <div class="progress-bar" :style="`width:${task.progressPercent}%;transition:none`"></div>
              </div>
            </td>
          </tr>
          <tr v-if="liveCounts">
            <td>
              {{ countLabel }} (live)
            </td>
            <td class="text-end font-monospace">
              <template v-if="liveCounts.completed == null">
                {{ formatNumber(liveCounts.total) }}
              </template>
              <template v-else>
                {{ formatNumber(liveCounts.completed) }} of {{ formatNumber(liveCounts.total) }}
                ({{ Math.round(100 * liveCounts.completed / liveCounts.total) }}%)
              </template>
            </td>
          </tr>
          <tr>
            <td>
              {{ countLabel }}<template v-if="liveCounts"> (as of last run)</template>
            </td>
            <td class="text-end font-monospace">
              {{ formatNumber(task.completed) }}
              <template v-if="task.isRepeated"> of {{ formatNumber(task.total) }} ({{ task.progressPercent}}%)</template>
            </td>
          </tr>
          <tr>
            <td>
              Duration
            </td>
            <td class="text-end font-monospace">
              {{ formatDuration(task.duration) }}
            </td>
          </tr>

          <tr>
            <td>Run Count</td>
            <td class="font-monospace text-end">
              {{ task.runCount }}
            </td>
          </tr>
          <tr>
            <td>Avg. Run Duration</td>
            <td class="font-monospace text-end">
              {{ formatDuration(task.durationPerRun) }}
            </td>
          </tr>

          <template v-if="showCurrentRun">
            <tr>
              <td colspan="2" class="fw-semibold bg-light">
                Current Run
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="progress">
                  <div class="progress-bar" :style="`width:${currentRun.progressPercent}%;transition:none`"></div>
                </div>
                <div v-if="currentRun.description" class="mt-1 small">
                  {{ currentRun.description }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                {{ countLabel }}
              </td>
              <td class="text-end font-monospace">
                {{ formatNumber(currentRun.count) }}
              </td>
            </tr>
          </template>

          <template v-if="!task.hasNotStarted && lastRun">
            <tr>
              <td colspan="2" class="fw-semibold bg-light">
                Last Run
              </td>
            </tr>
            <tr>
              <td>
                {{ countLabel }}
              </td>
              <td class="text-end font-monospace">
                {{ formatNumber(lastRun.completed) }}
              </td>
            </tr>
            <tr>
              <td>
                Duration
              </td>
              <td class="text-end font-monospace">
                {{ formatDuration(lastRun.duration) }}
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <slot name="status" :task="task" />
      </div>
    </div>
    <div class="d-flex flex-row align-items-center justify-content-start" v-if="!!task">
      <button type="button" :disabled="!task.canRequest" class="me-1 btn btn-primary" @click="task.request()">
        {{ importTitle }}
      </button>
      <button type="button" v-if="showForceReset" :disabled="!task.canRequest" class="me-1 btn btn-primary" @click="task.request(true)">
        {{ importTitle }} (from scratch)
      </button>
      <button v-if="task.canCancel" type="button" class="me-1 btn btn-danger" @click="task.cancel()">
        {{ task.isBatched ? 'Pause' : 'Cancel' }}
      </button>
      <slot name="buttons"/>
    </div>
  </LayoutCard>
</template>

<script>
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";
import moment from "moment";
import LayoutCard from "@/components/common/LayoutCard.vue";

export default {
  components: {LayoutCard, SpinnerView},
  props: {
    city: City,
    taskName: String,
    collectionName: String,
    doneFieldName: String,
    label: String,
    description: String,
    countLabel: {
      type: String,
      default: () => 'Total'
    },
    importTitle: {
      type: String,
      default: () => 'Import'
    },
    showForceReset: Boolean
  },
  data() {
    return {
      task: null,
      currentRun: null,
      lastRun: null,
      unsubscribe: null,
      liveCounts: null
    };
  },
  mounted() {
    let unsubscribes = [
      this.city.onTask(this.taskName, task => this.task = task),
      this.city.onCurrentTaskRun(this.taskName, currentRun => this.currentRun = currentRun),
      this.city.onLastTaskRun(this.taskName, lastRun => this.lastRun = lastRun)
    ];
    this.unsubscribe = () => unsubscribes.forEach(unsubscribe => unsubscribe());
  },
  unmounted() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  watch: {
    async task() {
      this.liveCounts = this.task ? (await this.task.fetchLiveCounts()) : null;
    }
  },
  computed: {
    showCurrentRun() {
      return this.task && this.currentRun && (this.task.isRequested || this.task.isStarted);
    },
    moment() {
      return moment
    },
    status() {
      const t = this.task;
      if (t.isRequested) {
        return {
          busy: true,
          text: 'Queued',
          variant: 'primary'
        };
      } else if (t.isStarted) {
        return {
          busy: true,
          text: 'Running',
          variant: 'primary'
        };
      } else if (t.isCanceled) {
        return {
          text: t.isBatched ? 'Paused' : 'Canceled',
          variant: 'muted'
        };
      } else if (t.isCompleted) {
        return {
          text: 'Completed',
          variant: 'success'
        };
      } else if (t.isFailed) {
        return {
          text: 'Failed',
          variant: 'danger'
        };
      } else {
        return {
          text: 'Ready',
          variant: 'dark'
        };
      }
    }
  }
}
</script>
