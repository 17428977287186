import readXlsxFile from "read-excel-file";

export default function(/* File */ file) {
  return new Promise((resolve, reject) => {
    let format = file.name.replace(/.*\.([^.]+)$/, '$1');
    if (format === 'xls' || format === 'xlsx') {
      readXlsxFile(file, {
        parseNumber: s => s
      }).then(rows => {
        let header = rows[0];
        let records = rows.slice(1).map(row => {
          let record = {};
          header.forEach((field, i) => {
            record[field] = (row[i] || '').trim();
          });
          return record;
        });
        resolve({
          format: 'json',
          input: records
        });
      }).catch(reject);
    } else {
      let reader = new FileReader();
      reader.onload = () => {
        resolve({
          format,
          input: reader.result
        });
      };
      reader.onerror = reject;
      reader.readAsText(file);
    }
  });
}
