<template>
  <SearchList
    :search-fields-options="searchFieldOptions"
    :sort-options="sortOptions"
    :do-search="city.listingsSearch"
    :item-class="getClassForItem"
  >

    <template #table-header>
      <th>&nbsp;</th>
      <th>Title</th>
      <th>Host</th>
      <th class="text-end">Images</th>
      <th class="text-center">Details</th>
      <th class="text-center">Screenshot</th>
    </template>

    <template #table-row="{item:listing}">
      <td class="text-center">
        <img :src="listing.iconUrl" width="14" />
      </td>
      <td>
        <a :href="listing.url" target="_blank" class="btn-link" @click.stop>
          {{ listing.title }}
          <template v-if="listing.status === 'inactive'"> (inactive)</template>
        </a>
      </td>
      <td>{{ listing.host || '--' }}</td>
      <td class="text-end">{{ listing.imageCount }}</td>
      <td class="text-center">
        <i v-if="listing.detailsImported" class="bi-check-lg" />
      </td>
      <td class="text-center">
        <i v-if="listing.screenshotTaken" class="bi-check-lg" />
      </td>
    </template>

    <template #table-detail="{item:listing}">
      <td colspan="10">
        <div class="border p-4 mx-2 mb-2 bg-white rounded">
          <ListingDetailView :listing="listing" />
        </div>
      </td>
    </template>
  </SearchList>
</template>

<script>
import {City} from "@/models/City";
import ListingDetailView from "@/components/admin-city/ListingDetailView.vue";
import SearchList from "@/components/admin-city/SearchList.vue";

export default {
  components: {SearchList, ListingDetailView},
  props: {
    city: City
  },
  computed: {
    searchFieldOptions() {
      return [
        {value:'listing.title',text:'Title'},
        {value:'listing.host',text:'Host'},
        {value:'listing.id',text:'ID'}
      ];
    },
    sortOptions() {
      return [
        {value:'listing.title',text:'Title'},
        {value:'listing.host',text:'Host'}
      ];
    }
  },
  methods: {
    getClassForItem(listing) {
      if (listing.status === 'inactive') {
        return 'inactive';
      } else {
        return 'active';
      }
    }
  }
}
</script>

<style>
tr.inactive {
  opacity: 30%;
}
</style>
