<template>
  <div :class="`w-100 h-100 ${flush ? '' : 'p-md-3'} d-flex flex-column bg-light position-relative overflow-hidden`">
    <GoogleMap
      ref="mapRef"
      api-key="AIzaSyDOqYud38oaZms1EpdoLsxoBA63oDD-wes"
      :class="`${flush ? '' : 'rounded overflow-hidden'} w-100 h-100`"
      @maptypeid_changed="mapTypeChanged"
      @zoom_changed="zoomChanged"
      @click="clearSelection"
      :map-type-id="LocalStorage.get('mapTypeId') || 'satellite'"
      :fullscreen-control="false"
      :disable-default-ui="hideButtons"
      :disable-double-click-zoom="hideButtons"
      :draggable="!hideButtons"
      :clickable-icons="!hideButtons"
      :styles="[{featureType:'poi',elementType:'labels',stylers:[{visibility:'off'}]}]"
      :center="center"
      :zoom="zoom">
      <template #default="{map, api}">
        {{ setMap(map, api) }}

        <template v-for="item in items" :key="item.id">
          <slot name="marker" :item="item" />
        </template>

        <Polygon
          v-for="(boundary, i) in boundaries"
          :key="`boundary_${i}`"
          :options="boundary"
          />
        <Circle
          v-if="radiusOptions"
          :options="radiusOptions"
          @center_changed="radiusChanged"
          @radius_changed="radiusChanged"
          ref="circle"
        />
        <CustomControl position="TOP_LEFT" v-if="$slots['top-left']">
          <slot name="top-left" :streetView="streetView" />
        </CustomControl>
        <div :class="['map-left-bar', {open:!!$slots['left-bar'], streetview: !!streetView}]">
          <div class="map-left-bar-content d-flex flex-column overflow-auto">
            <slot name="left-bar" :streetView="streetView" />
          </div>
        </div>
      </template>
    </GoogleMap>
    <div v-if="!!$slots.info && !!selected" class="border-top w-100 bg-white d-block d-md-none d-flex flex-column align-items-stretch">
      <div class="d-flex flex-column align-items-end py-2 px-3 border-bottom">
        <button type="button" class="btn btn-close btn-flat" @click="$emit('deselect')"/>
      </div>
      <div class="w-100 p-3">
        <slot name="info" :data="selected" />
      </div>
    </div>
  </div>
</template>

<script>
import { GoogleMap, Polygon, Circle, CustomControl } from 'vue3-google-map';
import {City} from "@/models/City";
export default {
  emits: [
    'deselect',
    'street_view_address_changed'
  ],
  components: {
    GoogleMap,
    Polygon,
    Circle,
    CustomControl
  },
  props: {
    city: City,
    selectedMarkerId: String,
    radius: Number,
    items: Array,
    flush: {
      type: Boolean,
      default: () => true
    },
    initialZoom: Number,
    hideButtons: Boolean
  },
  data() {
    return {
      center: (this.city && this.city.latlng) || { lat: 43.5428189, lng: -70.32978830000002 },
      zoom: this.initialZoom || 12,
      map: null,
      isLoaded: false,
      streetView: null
    };
  },
  mounted() {
    setTimeout(() => {
      this.isLoaded = true;
    }, 10000);
  },
  watch: {
    city() {
      if (this.city && this.city.latlng) {
        this.center = this.city.latlng;
      }
    },
    selectedMarkerId(newValue, oldValue) {
      // Only adjust zoom and center if changed
      if (newValue !== oldValue) {
        this.closeStreetView();
        if (this.selectedLatLng) {
          this.center = this.selectedLatLng;
        }
      }
    }
  },
  computed: {
    selectedLatLng() {
      return this.selected ? this.selected.latlng : null;
    },
    selected() {
      return this.itemIndex[this.selectedMarkerId];
    },
    itemIndex() {
      let index = {};
      this.items.forEach(item => index[item.id] = item);
      return index;
    },
    boundaries() {
      if (this.city) {
        const geoJson = this.city.geoJson;
        if (geoJson) {
          // let color = '#' + (this.city.backgroundColor || '4171d9');
          let coords = geoJson.coordinates;
          if (typeof coords[0][0][0] === 'number') {
            coords = [coords];
          }
          return coords.map(coords2 => {
            return {
              paths: coords2.map(path => {
                return path.map(coord => {
                  return {lat: coord[1], lng: coord[0]};
                })
              }),
              fillOpacity: 0.0,
              // fillColor: '4171d922',
              strokeColor: '#4171d9cc',
              strokeWeight: 4
            };
          });
        }
      }
      return [];
    },
    radiusOptions() {
      const radius = 0; // 200 feet
      if (radius > 0) {
        return {
          center: this.center,
          radius: radius,
          editable: true,
          fillOpacity: 0.2,
          fillColor: '#4171d9',
          strokeColor: '#4171d9',
          strokeWeight: 3
        };
      } else {
        return null;
      }
    }
  },
  methods: {
    setMap(map, api) {
      this.map = map;
      if (this.map) {
        let streetViewListener = () => {
          let sv = this.map.getStreetView();
          let svLocation = sv.getLocation();
          if (sv.visible && !!svLocation) {
            this.streetView = svLocation;
            this.$emit('street_view_address_changed', svLocation.shortDescription);
          } else {
            this.streetView = null;
            this.$emit('street_view_address_changed', null);
          }
        };
        this.map.getStreetView().addListener('pano_changed', streetViewListener);
        this.map.getStreetView().addListener('position_changed', streetViewListener);
        this.map.getStreetView().addListener('pov_changed', streetViewListener);
        this.map.getStreetView().addListener('visible_changed', streetViewListener);
      }

      // Reposition the map-type control because the prop doesn't work
      if (map && api) {
        map.setOptions({
          mapTypeControlOptions: {
            position: api.ControlPosition.TOP_RIGHT
          }
        });
      }
      return '';
    },
    mapTypeChanged() {
      if (this.map) {
        const mapTypeId = this.map.mapTypeId;
        this.LocalStorage.set('mapTypeId', mapTypeId);
      }
    },
    zoomChanged() {
      if (this.map) {
        this.zoom = this.map.zoom;
      }
    },
    radiusChanged() {
      console.log(this.$refs.circle);
    },
    clearSelection() {
      this.$emit('deselect');
    },
    setZoom(zoom) {
      if (this.map) {
        this.map.setZoom(zoom);
      }
    },
    setCenter(latlng, zoom) {
      if (this.map) {
        this.map.setCenter(latlng);
        if (zoom) {
          this.map.setZoom(zoom);
        }
      }
    },
    openStreetView(latlng) {
      if (latlng && this.map) {
        let svp = this.map.getStreetView();
        svp.setVisible(true);
        svp.setPosition(latlng);
        return true;
      } else {
        return false;
      }
    },
    closeStreetView() {
      if (this.map) {
        this.map.getStreetView().setVisible(false);
      }
    }
  }
}
</script>

<style>
.map-left-bar {
  position: absolute;
  z-index: 1;
  left: -450px;
  top: 10px;
  max-height: calc(100% - 20px);
  max-width: 33%;
  width: 432px;
  border-radius: 2px;
  overflow: hidden;
  transition: left 250ms, top 250ms;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.map-left-bar.open {
  left: 10px;
}
.map-left-bar.streetview {
  justify-content: end;
}
.map-left-bar.open.streetview {
  top: unset;
  bottom: 10px;
  max-height: calc(100% - 84px);
}
.map-left-bar .map-left-bar-content {
  background-color: #fff;
  box-shadow: 0px 1px 3px #0003;
  max-height: 100%;
}
</style>
