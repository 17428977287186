<template>
  <input type="date" v-model="value" />
</template>

<script>
import moment from "moment";
import {toMoment} from "@/dateUtils";

export default {
  props: {
    modelValue: Object
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        if (this.modelValue) {
          return toMoment(this.modelValue).format('YYYY-MM-DD');
        } else {
          return null;
        }
      },
      set(v) {
        if (v) {
          this.$emit('update:modelValue', moment(v, 'YYYY-MM-DD').toDate());
        } else {
          this.$emit('update:modelValue', null);
        }
      }
    }
  }
}
</script>
