<template>
  <BCollapsible class="border rounded">
    <template #title>
      {{ formatNumber(errors.length, noun) }}
    </template>
    <div class="d-flex flex-column gap-3">
      <div v-for="error in errors" :key="error.line" class="border rounded d-flex flex-column gap-3 p-3">
        <div>Line {{ formatNumber(error.line) }}: {{ error.error }}</div>
        <div class="font-monospace small bg-light p-3 border">{{ error.content }}</div>
      </div>
    </div>
  </BCollapsible>
</template>

<script>
import BCollapsible from "@/components/common/BCollapsible.vue";

export default {
  components: {BCollapsible},
  props: {
    noun: String,
    errors: /* LicenseParseError[] */ Array
  }
}
</script>
