<template>
  <li class="nav-item dropdown no-caret" v-if="!!currentUser">
    <!-- Show current tab, plus current user info -->
    <!-- Click to drop down list of other tabs, or log out -->
    <a ref="dropdownToggle"
       class="dropdown-toggle btn nav-link rounded-circle btn-primary d-flex flex-row align-items-center justify-content-center"
       style="width:36px;height:36px;line-height:36px;padding:0"
       href="#"
       data-bs-toggle="dropdown">
      <span class="text-uppercase text-white">{{ currentUser.initials }}</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-end overflow-auto" :style="`max-height:${maxMenuHeight}px`">
      <li>
        <h6 class="dropdown-header pb-0">
          {{ currentUser.displayName || 'Logged in as' }}
        </h6>
        <h6 class="dropdown-header">{{ currentUser.email }}</h6>
      </li>
      <li><hr class="dropdown-divider"/></li>
      <template v-if="currentUser.role === 'admin'">
        <li>
          <router-link :to="{name:'home'}" class="dropdown-item">
            Home
          </router-link>
        </li>
        <li v-if="$route.params.cityId">
          <router-link :to="{name:'admin-city',params:{cityId:$route.params.cityId}}" class="dropdown-item">
            Admin Console
          </router-link>
        </li>
        <li v-else>
          <router-link :to="{name:'admin'}" class="dropdown-item">
            Admin Console
          </router-link>
        </li>
      </template>
      <!--            <li>-->
      <!--              <a class="dropdown-item" disabled>Edit Profile</a>-->
      <!--            </li>-->
      <!--            <li>-->
      <!--              <a class="dropdown-item">Change Password</a>-->
      <!--            </li>-->
      <!--            <li><hr class="dropdown-divider"/></li>-->
      <li><a class="dropdown-item" href="#" @click.prevent="() => logout()">Logout</a></li>
    </ul>
  </li>
</template>

<script>
import {Session} from "@/models/User";
export default {
  computed: {
    currentUser() {
      return Session.currentUser.value;
    },
    maxMenuHeight() {
      return document.getElementById('app').clientHeight - 70;
    }
  },
  methods: {
    logout() {
      Session.logout()
      .then(() => {
        this.$router.push({name:'home'});
      });
    }
  }
}
</script>
