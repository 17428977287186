<template>
  <template v-if="listingCounts">
    <template v-if="licenseCounts">

      <!-- Licenses -->
      <DashboardTile
        title="Licenses"
        :count="licenseCounts.total"
        :to="{name:'licenses'}"
      >
        <DashboardStat
          label="active"
          :value="stats.licenses.groups.statusCategory.active"
          :to="{name:'licenses',query:{onlyShowActive:true}}"
          variant="success"
        />
        <DashboardStat
          label="pending"
          :value="stats.licenses.groups.statusCategory.pending"
          :to="{name:'licenses',query:{onlyShowPending:true}}"
          variant="warning"
        />
        <DashboardStat
          label="missing parcel IDs"
          :value="licenseCounts.missingPropertyId"
          :to="{name:'licenses',query:{onlyShowMissingPropertyId:true}}"
          variant="warning"
        />
        <DashboardStat
          label="expired"
          :value="licenseCounts.expired"
          :to="{name:'licenses',query:{onlyShowMissingPropertyId:true}}"
          variant="danger"
        />
        <DashboardStat
          label="expiring soon"
          :value="licenseCounts.expiringNextMonth"
          :to="{name:'licenses',query:{onlyShowMissingPropertyId:true}}"
          variant="warning"
        />
      </DashboardTile>
    </template>

    <!-- Listed Properties -->
    <DashboardTile
      title="Listed Properties"
      :count="listingCounts.uniquePropertyIds">
      <DashboardStat label="found in last week" :value="listingCounts.foundLastWeek" />
      <DashboardStat label="found in last month" :value="listingCounts.foundLastMonth"  />
      <DashboardStat label="Airbnb" :value="stats.listings.groups.source.airbnb" />
      <DashboardStat label="Vrbo" :value="stats.listings.groups.source.vrbo" />
    </DashboardTile>

    <!-- Violations -->
    <DashboardTile
      title="Violations"
      :count="listingCounts.uniqueViolations"
      :to="{name:'violations'}"
      variant="danger"
    >
      <DashboardStat
        label="whitelisted"
        :value="listingCounts.uniqueWhitelistedViolations"
        :to="{name:'violations',query:{onlyShowWhitelist:true}}"
      />
    </DashboardTile>

    <!-- Needs Attention -->
    <DashboardTile
      title="Listings Needing Attention"
      variant="warning"
      description="These listings' addresses have not been verified yet. Click below to find and verify the remaining listings' addresses."
      :count="listingCounts.unverified"
      :to="{name:'listings'}"
    >
      <DashboardStat
        label="partially imported"
        description="These listings' details (e.g. host name, full image gallery, etc.) have not been imported yet."
        variant="warning"
        :value="listingCounts.unverifiedPartiallyImported"
        :to="{name:'listings',query:{onlyShowPartiallyImported:true}}"
      />
      <DashboardStat
        label="whitelisted"
        :value="listingCounts.whitelistedUnverified"
        :to="{name:'listings',query:{onlyShowWhitelist:true}}"
      />
      <DashboardStat
        label="leaving whitelist soon"
        :value="listingCounts.whitelistedUnverifiedExpiringNextMonth"
        :to="{name:'listings',query:{onlyShowWhitelist:true}}"
        variant="warning"
      />
    </DashboardTile>

  </template>
</template>

<script>
/**
 * The point of the dashboard is to get a quick overview of the state of STR enforcement in town, and also to
 * see the data from a task standpoint (e.g. these are the things I should look into). Tasks might include:
 * - Checking on listings that recently came off the whitelist
 * - Verifying new listings I haven't seen
 * - Following up on violations that haven't been followed up on in while
 * - Fixing any data inconsistencies in licenses (e.g. missing property ID)
 * - Following up on licenses that are stalled
 */
import {AppModel} from "@/models/AppModel";
import DashboardTile from "@/components/dashboard/DashboardTile.vue";
import DashboardStat from "@/components/dashboard/DashboardStat.vue";
import {createStats} from "@/models/CityStats";

export default {
  components: {DashboardStat, DashboardTile},
  props: {
    model: AppModel
  },
  data() {
    return {
      status: null
    };
  },
  watch: {
    model: {
      immediate: true,
      handler() {
        this.stats = createStats(this.model);
      }
    }
  },
  computed: {
    listingCounts() {
      return this.stats?.listings?.counts;
    },
    licenseCounts() {
      return this.stats?.licenses?.counts;
    }
  },
  methods: {
    onImportResult(result) {
      console.log(result);
      this.listingCounts.partiallyImported = result.data.remaining;
    },
    onImportError(error) {
      console.error(error);
    }
  }
}
</script>
