import {PersistentCityModel} from "@/models/PersistentCityModel";
import {toTimestamp,toMoment} from "@/dateUtils";
import {callApi} from "@/api";
import {Property} from "@/models/Property";
import {addDoc} from "firebase/firestore";
import {User} from "@/models/User";
import {License} from "@/models/License";

// TODO move this to a setting in City
const AUTO_SYNC_THRESHOLD_DAYS = 7;

export class Listing extends PersistentCityModel {

  constructor(city, doc) {
    super(city, doc);
  }

  get idFieldNameForApi() {
    return 'listingId';
  }

  get isDefinitelyInCorrectCity() {
    if (this.details.isInCorrectCity) {
      return true;
    }
    let addresses = this.data.addresses;
    if (addresses) {
      let address = addresses[0];
      if (address) {
        return address.city.toLowerCase() === this.city.name.toLowerCase();
      }
    }
    return false;
  }

  async importDetails() {
    if (!this.isImportingDetails) {
      await this.doApiCall('importListingDetails');
    }
  }

  get isImportingDetails() {
    return this.isRequesting('importListingDetails');
  }

  get dateLoaded() {
    return toTimestamp(this.data.dateLoaded);
  }

  get dateFirstFound() {
    return toTimestamp(this.data.dateFirstFound);
  }

  get isSeen() {
    return this.data.seen || false;
  }

  async updateSeen(seen) {
    return await this.update({
      seen: seen || false
    }, null, true);
  }

  get dateDetailsImported() {
    return toTimestamp(this.data.dateDetailsImported);
  }

  get status() {
    return this.data.status;
  }

  get details() {
    return this.data.listing || {};
  }

  get title() {
    return this.details.title;
  }

  get source() {
    return this.details.source;
  }

  get host() {
    return this.details.host;
  }

  get url() {
    let url = this.details.url;
    if (url && this.source === 'vrbo') {
      // VRBO requires a noDates parameter in order to trigger their availability calendar
      url = url + (url.match(/\?/) ? '&' : '?') + 'noDates=true';
    }
    return url;
  }

  get imageCount() {
    return (this.details.images || []).length;
  }

  get detailsImported() {
    return this.data.detailsImported;
  }

  get isDueForDetailImport() {
    const dateDetailsImported = this.dateDetailsImported;
    if (!this.data.detailsImported || !dateDetailsImported) {
      return true;
    }
    if (dateDetailsImported) {
      if (new Date() - dateDetailsImported.toDate() > AUTO_SYNC_THRESHOLD_DAYS * 24 * 60 * 60 * 1000) {
        return true;
      }
    }
    return false;
  }

  get dateScreenshotTaken() {
    return toTimestamp(this.data.dateScreenshotTaken);
  }

  get screenshotTaken() {
    return this.data.screenshotTaken;
  }

  get screenshotUrl() {
    if (this.screenshotTaken) {
      return `/screenshot?cityId=${encodeURIComponent(this.city.id)}&listingId=${encodeURIComponent(this.id)}`;
    } else {
      return null;
    }
  }

  takeScreenshot() {
    return this.doApiCall('screenshot');
  }

  get listingSource() {
    if (this.source === 'airbnb') {
      return {
        iconUrl: 'https://a0.muscache.com/airbnb/static/icons/android-icon-192x192-c0465f9f0380893768972a31a614b670.png',
        name: 'Airbnb'
      };
    } else {
      return {
        iconUrl: 'https://csvcus.homeaway.com/rsrcs-crs/cdn-logos/5.1.2/sitename/vrbo/web/favicon.ico',
        name: 'Vrbo'
      };
    }
  }

  get iconUrl() {
    const src = this.listingSource;
    return src && src.iconUrl;
  }

  get latlng() {
    return {
      lat: this.details.latitude,
      lng: this.details.longitude
    };
  }

  get loadCount() {
    return this.data.loadCount || 1;
  }

  get dateLoadedFormatted() {
    const m = toMoment(this.data.dateLoaded);
    if (!m) {
      return null;
    } else {
      return m.fromNow();
      // .replace(/ seconds?/, ' s')
      // .replace(/ minutes?/, ' m')
      // .replace(/ hours?/, ' h')
      // .replace(/ days?/, ' d')
      // .replace(/ weeks?/, ' w')
      // .replace(/ month/, ' mo');
    }
  }

  findNearbyProperties(radius) {
    return this.city.findNearbyProperties({ ...this.latlng, radius });
  }

  get isInactive() {
    return this._get('status') === 'inactive';
  }

  //
  // TODO OLD STUFF COPIED OVER
  //

  get isActive() {
    return this._get('loadId') === this.city.lastLoadId;
  }

  get assessorUrl() {
    return this.city.recordsUrl;
  }

  get isPropertyVerified() {
    return !!this.verifiedPropertyId;
  }

  get verifiedPropertyId() {
    return this.data.verifiedPropertyId;
  }

  get verifiedProperty() {
    const data = this.data.verifiedProperty;
    if (data) {
      return Property.dataConverter(this.city)({
        id: this.verifiedPropertyId,
        data: data
      });
    } else {
      return null;
    }
  }

  get dateVerified() {
    return toTimestamp(this._data.verifiedAt);
  }

  get verifiedByUser() {
    return this._data.verifiedBy;
  }

  get verifiedNotes() {
    return this._data.verifiedNotes;
  }

  get isAddressVerified() {
    return this.isPropertyVerified;
  }

  get verifiedAddress() {
    const property = this.verifiedProperty;
    return property ? property.address : null;
  }

  get isWhitelisted() {
    let whitelisted = this._get('whitelisted') || false;
    if (whitelisted) {
      let expiration = this.whitelistExpiration;
      return !expiration || expiration.isAfter();
    }
    return false;
  }

  get whitelistReason() {
    return this._get('whitelistReason') || '';
  }

  get whitelistExpiration() {
    const expiration = this._get('whitelistExpiration');
    return expiration ? toMoment(expiration) : null;
  }

  get whitelistUpdatedAt() {
    return toMoment(this._get('whitelistUpdatedAt'));
  }

  get whitelistUpdatedBy() {
    const user = this._get('whitelistUpdatedBy');
    return user ? new User(user) : null;
  }

  get notes() {
    return this._get('notes') || '';
  }

  get bestAddress() {
    return this.verifiedAddress;
  }

  get listingTitle() {
    return this._get('listing.title');
  }

  get listingSubtitle() {
    return this._get('listing.subtitle');
  }

  get listingPrice() {
    return this._get('listing.price');
  }

  get listingDescription() {
    return this._get('listing.description');
  }

  get listingHost() {
    return this._get('listing.host');
  }

  get listingSpecs() {
    return this._get('listing.specs');
  }

  get listingBedrooms() {
    return this._get('listing.bedrooms');
  }

  get listingBathrooms() {
    return this._get('listing.bathrooms');
  }

  get listingHalfBathrooms() {
    return this._get('listing.halfBathrooms');
  }

  get minNightStay() {
    return this._get('listing.minNightStay') || 1;
  }

  get images() {
    return (this._get('listing.images') || []).map(image => image.url || image);
  }

  get latLng() {
    return this.latlng;
  }

  get addressFormatted() {
    const property = this.verifiedProperty;
    return property ? property.addressFormatted : '';
  }

  get owners() {
    const property = this.verifiedProperty;
    return (property && property.owners) || [];
  }

  get ownerAddress() {
    const property = this.verifiedProperty;
    return (property && property.ownerAddress) || this.addressFormatted;
  }

  matches(query) {
    const address = this.addressFormatted.toLowerCase();
    const title = (this.listingTitle || '').toLowerCase();
    // TODO break query up into words, and test all n, then n-1, etc. Maybe include scoring to indicate how many
    // of the first n words matched.
    return address.indexOf(query) >= 0 || title.indexOf(query) >= 0;
  }

  indexKeys() {
    const address = this.addressFormatted.toLowerCase();
    const title = (this.listingTitle || '').toLowerCase();
    const host = (this.listingHost || '').toLowerCase();
    return (address + ' ' + title + ' ' + host)
    .split(/ /g)
    .map(word => word.trim())
    .filter(word => !!word);
  }

  async verifyProperty(property, notes) {
    const data = {
      verifiedPropertyId: property?.id || null,
      verifiedProperty: property?.data || null,
      verifiedAt: new Date(),
      verifiedBy: this.currentUser,
      verifiedNotes: notes || null
    };

    await this.update(data, 'verify');

    // TODO is this still needed?
    addDoc(this.subCollection('verifications'), {...data, synced: false});

    return this;
  }

  async updateWhitelisted(whitelisted, reason, expiration) {
    return this.update({
      whitelisted: whitelisted,
      whitelistReason: reason,
      whitelistExpiration: expiration,
      whitelistUpdatedAt: new Date(),
      whitelistUpdatedBy: this.currentUser
    }, 'whitelist', true);
  }

  updateNotes(notes) {
    return this.update({notes}, 'notes', true);
  }

  get primaryImageIndex() {
    return this._get('primaryImageIndex') || 0;
  }

  get primaryImage() {
    return this.images[this.primaryImageIndex];
  }

  updatePrimaryImageIndex(index) {
    return this.update({primaryImageIndex:index}, true);
  }

  fetchScreenshot() {
    return callApi('screenshot', {
      cityId: this.city.id,
      listingId: this.id
    })
  }

  createLicenseDraft() {
    const property = this.verifiedProperty;
    if (property) {
      return new License(this.city, {
        data: {
          propertyId: property.id,
          property,
          contact: {
            owners: property.owners || [],
            address: property.details && property.details.ownerAddress
          },
          listings: [this.url]
        }
      });
    } else {
      return null;
    }
  }

}
