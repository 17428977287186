<template>
  <AppLayout :access-status="model.isAuthenticated ? 'granted' : model.currentUserChecked ? 'blocked' : 'pending'">
    <template #top>
      <TopBar
        :model="model"
        :selected-tab="tab"
        :buttons="buttons"
        :show-search="!selectedListing"
        @search="q => query = q"
        :search-placeholder="`Search ${noun}s`"
      />
    </template>
    <template #left>
      <FilterableList
        :count="listings.length"
        :page-start="pageStart"
        :page-end="pageEnd"
        @previous-page="page = page - 1"
        @next-page="page = page + 1"
        @updated="applyFilterChanges"
        @canceled="cancelFilterChanges"
        :noun="`${filters.showPartiallyImported ? 'partially imported ' : ''}${filters.showWhitelist ? 'whitelisted ' : ''}${noun}`"
        :query="query"
        :loading="loading"
      >
        <template #filters v-if="draftFilters">
          <div class="p-3 d-flex flex-column gap-3">
            <div class="d-flex flex-row align-items-center w-100">
              <div class="form-text flex-grow-1 my-0" v-if="model.dateLastChecked">
                Last sync: {{ formatDate(model.dateLastChecked) }}
              </div>
              <button type="button" class="btn btn-primary btn-sm" :disabled="model?.importingListingDetails" @click="sync">
                <spinner-view v-if="model?.importingListingDetails" small class="me-1" />
                Sync{{ model?.importingListingDetails ? 'ing' : '' }}
              </button>
            </div>
            <div>
              <div class="d-flex flex-row align-items-center gap-2 small">
                <label class="form-label text-nowrap mb-0">Sort</label>
                <select v-model="draftFilters.sort.field" class="form-select form-select-sm flex-grow-1">
                  <option value="found">Date Found</option>
                  <option value="seen">Seen / Unseen</option>
                  <option value="title">Title</option>
                  <template v-if="sortOptions">
                    <option v-for="option in sortOptions" :key="option.value" :value="option.value">{{ option.text }}</option>/
                  </template>
                </select>
                <button v-if="draftFilters.sort.field.match(/found|seen|title/)" type="button" class="btn btn-outline-dark border btn-sm text-nowrap" @click="draftFilters.sort.ascending = !draftFilters.sort.ascending">
                  <template v-if="draftFilters.sort.field === 'found'">
                    <template v-if="draftFilters.sort.ascending">Old <i class="bi-arrow-right"/> New</template>
                    <template v-else>New <i class="bi-arrow-right"/> Old</template>
                  </template>
                  <template v-else-if="draftFilters.sort.field === 'seen'">
                    <template v-if="draftFilters.sort.ascending">Seen First</template>
                    <template v-else>Unseen First</template>
                  </template>
                  <template v-if="draftFilters.sort.field === 'title'">
                    <template v-if="draftFilters.sort.ascending">A <i class="bi-arrow-right"/> Z</template>
                    <template v-else>Z <i class="bi-arrow-right"/> A</template>
                  </template>
                </button>
              </div>
            </div>
            <div>
              <BCheckbox v-model="draftFilters.showUnread" :label="`Show only unread ${noun}s`" />
            </div>
            <div>
              <BCheckbox v-model="draftFilters.showWhitelist" :label="`Show only whitelisted ${noun}s`" />
            </div>
            <div>
              <BCheckbox v-model="draftFilters.showAirbnb" label="Show Airbnb listings" />
              <BCheckbox v-model="draftFilters.showVrbo" label="Show Vrbo listings" />
            </div>
            <div>
              <slot name="filters"/>
            </div>
          </div>
        </template>
        <template #list>
          <LoadingMessage v-if="loading" :message="`Loading ${noun}`" />
          <LoadingMessage v-else-if="filtering" :message="`Updating ${noun}`" />
          <template v-else-if="listings.length === 0">
            <slot name="empty" />
          </template>
          <ListingsList
            v-if="!selectedListing"
            :model="model"
            :listings="pagedListings"/>
          <ListingDetailView
            v-else
            :listing="selectedListing"
            :model="model"
            @open-street-view="openStreetView"
            @show-location="showLocation"
            @close="closeDetail"
          >
            <template #buttons>
              <div class="text-muted small mx-1 text-nowrap">
                {{ selectedListingIndex + 1 }} of {{ listings.length }}
              </div>
              <IconButton icon="chevron-up" class="flex-shrink-0" @click="() => nextListing(true)" />
              <IconButton icon="chevron-down" class="flex-shrink-0" @click="() => nextListing(false)" />
            </template>
          </ListingDetailView>
        </template>
      </FilterableList>
    </template>
    <GMap
      ref="map"
      @deselect="selectListing(null)"
      :city="model.cityInfo"
      :selected-marker-id="model.selectedListingId"
      :items="mapItems"
      @street_view_address_changed="address => streetViewAddress = address"
    >
      <template #marker="{item}">
        <GMapListingMarker v-if="item instanceof Listing" :listing="item" :model="model" />
        <GMapLicenseMarker v-else-if="item instanceof License" :license="item" :model="model" />
      </template>
      <template #info="{data:listing}">
        <ListingItemView :model="model" :value="listing" />
      </template>
      <template #top-left="{streetView}">
        <div v-if="!streetView" class="bg-white" style="margin: 10px;border-radius:2px;box-shadow:0px 1px 3px rgba(0,0,0,0.2)">
          <button
            type="button"
            :class="`btn btn-flat ${showLicenses ? '' : 'text-muted fw-normal'}`"
            style="height:40px;line-height:40px;padding:0 10px;"
            @click="showLicenses = !showLicenses">
            <i :class="`bi-${showLicenses ? 'check-' : ''}square me-1`"/> Show Licenses
          </button>
        </div>
      </template>
      <template #left-bar="{streetView}" v-if="selectedListing && !selectedListing.isAddressVerified">
        <ListingAddressEditor
          v-if="!streetView"
          :listing="selectedListing"
          :model="model"
          :street-view-address-label="streetViewAddress"
          :selected-license="selectedLicense"
          @show-location="showLocation"
          @open-street-view="openStreetView"
          @committed="closeDetail"
        />
        <div v-else-if="selectedListing" class="p-3">
          <StreetViewVerificationButton
            :model="model"
            :listing="selectedListing"
            :street-view-label="streetView.shortDescription"
          />
        </div>
      </template>
    </GMap>
    <div ref="toast" class="toast bg-primary text-white position-fixed" style="bottom:10px;left:10px;z-index:2">
      <div class="toast-header bg-primary text-white">
        <strong class="me-auto" v-if="closeMessage && closeMessage.title">{{ closeMessage.title }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ (closeMessage && closeMessage.body) || closeMessage }}
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/common/AppLayout";
import TopBar from "@/components/common/TopBar";
import {AppModel} from "@/models/AppModel";
import GMap from "@/components/common/GMap";
import ListingsList from "@/components/ListingsList";
import ListingItemView from "@/components/ListingItemView.vue";
import ListingDetailView from "@/components/ListingDetailView.vue";
import {Toast} from "bootstrap";
import IconButton from "@/components/common/IconButton.vue";
import BCheckbox from "@/components/common/BCheckbox.vue";
import {generateCsvBlob} from "@/csv";
import ListingAddressEditor from "@/components/listing/ListingAddressEditor.vue";
import GMapListingMarker from "@/components/common/GMapListingMarker.vue";
import StreetViewVerificationButton from "@/components/listing/StreetViewVerificationButton.vue";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {Listing} from "@/models/Listing";
import {License} from "@/models/License";
import FilterableList from "@/components/common/FilterableList.vue";
import GMapLicenseMarker from "@/components/common/GMapLicenseMarker.vue";

export default {
  components: {
    GMapLicenseMarker,
    FilterableList,
    SpinnerView,
    LoadingMessage,
    StreetViewVerificationButton,
    GMapListingMarker,
    ListingAddressEditor,
    BCheckbox,
    IconButton,
    ListingDetailView,
    ListingItemView, ListingsList, GMap, TopBar, AppLayout
  },
  // TODO Find out how to make the following extensible from a base component
  props: {
    cityId: String,
    selectedListingId: String,
    noun: {
      type: String,
      default: () => 'listing'
    },
    tab: String,
    filter: Function, /* (Listing, AppModel) -> Boolean */
    sortOptions: Array /* [{value, text, compare: (Listing, Listing, AppModel) -> int}] */,
    defaultShowWhitelist: Boolean,
    defaultShowPartiallyImported: Boolean
  },
  data() {
    return {
      model: new AppModel(this.cityId),
      editListing: null,
      query: '',
      filtering: false,
      listings: [],
      page: 0,
      exportUrl: null,
      closeMessage: null,
      streetViewAddress: null,
      showLicenses: false,
      selectedLicense: null,
      filters: {
        showWhitelist: false,
        showUnread: false,
        showPartiallyImported: false,
        showAirbnb: true,
        showVrbo: true,
        sort: {
          field: 'found',
          ascending: false
        }
      },
      draftFilters: null,
      selectedListingIndex: 0
    };
  },
  watch: {
    defaultShowWhitelist: {
      immediate: true,
      handler() {
        this.showWhitelist = this.defaultShowWhitelist;
      }
    },
    defaultShowPartiallyImported: {
      immediate: true,
      handler() {
        this.showPartiallyImported = this.defaultShowPartiallyImported;
      }
    },
    cityId() {
      this.model.unload();
      this.model = new AppModel(this.cityId);
      this.model.load();
    },
    query() {
      this.filterListings();
    },
    'model.listings.updateCount'() {
      this.filterListings();
    },
    'model.licenses.updateCount'() {
      this.filterListings();
    },
    filters() {
      this.draftFilters = {...this.filters};
    },
    selectedListingId() {
      this.model.selectListing(this.selectedListingId);
      this.filterListings();
    },
    selectedListing() {
      this.selectedLicense = null;
      let listingId = this.model.selectedListingId;
      if (listingId) {
        this.$router.push({
          name: `${this.noun}-detail`,
          params: {
            selectedListingId: listingId
          }
        });
      } else {
        this.$router.push({name:`${this.noun}s`});
      }
      this.updateSelectedListingIndex();
    },
    listings() {
      this.clearExportUrl();
      let listings = [...this.listings];
      listings.sort((v1, v2) => {
        return (v1.verifiedPropertyId || '').localeCompare(v2.verifiedPropertyId || '');
      });
      const blob = generateCsvBlob(
        '\t',
        [
          'Parcel ID', 'Street Number', 'Street Name', 'Unit', 'Address', 'License Status',
          'Listing Title', 'Listing URL'
        ],
        listings.map(listing => {
          const license = this.model.lookupLicenseForListing(listing);
          return [
            listing.verifiedPropertyId,
            listing.verifiedProperty?.address?.streetNumber || '',
            listing.verifiedProperty?.address?.street || '',
            listing.verifiedProperty?.address?.unit || '',
            listing.addressFormatted,
            !license ? 'none' : license.status,
            listing.listingTitle,
            listing.url
          ]
        })
      );
      this.exportUrl = URL.createObjectURL(blob);
      this.updateSelectedListingIndex();
    },
    closeMessage() {
      let toast = Toast.getOrCreateInstance(this.$refs.toast);
      if (this.closeMessage) {
        toast.show();
      } else {
        toast.hide();
      }
    }
  },
  mounted() {
    this.model.load();
    this.model.selectListing(this.selectedListingId);
    this.draftFilters = {...this.filters};
  },
  unmounted() {
    this.clearExportUrl();
  },
  // End extensible piece here
  computed: {
    Listing() {
      return Listing;
    },
    License() {
      return License;
    },
    loading() {
      return this.filtering || !this.model.licenses.loaded || !this.model.listings.loaded;
    },
    buttons() {
      let buttons = [];
      if (this.model.isAuthenticated && this.exportUrl) {
        buttons.push({
          id: 'export',
          href: this.exportUrl,
          filename: `${this.cityId.replace(/ /g, '_')}_violations.tsv`,
          // href: `/downloadViolations?cityId=${this.cityId}`,
          tooltip: 'Export violations to CSV file',
          icon: 'download',
          showOnMobile: true
        });
      }
      if (this.addListingLabel) {
        buttons.push({
          id: 'add',
          text: this.addListingLabel,
          icon: 'plus-lg',
          click: () => this.addListing(),
          variant: 'primary',
          showOnMobile: true
        });
      }
      return buttons;
    },
    // markers() {
    //   let listings = this.listings.map(listing => {
    //     return createMarkerForListing(this.model, listing, {
    //       // zIndex: 99,
    //       // selectedId: this.model.selectedListingId,
    //       // // click: () => this.selectListing(this.model.selectedListingId === listing.id ? null : listing.id)
    //     });
    //   });
    //   return listings;
    //   // if (this.showLicenses) {
    //   //   // Show license markers too if we're in the verification UI
    //   //   let licenses = this.model.licenses.items.map(license => {
    //   //     return createMarkerForLicense(this.model, license, {
    //   //       zIndex: 98,
    //   //       selectedId: this.selectedLicense ? this.selectedLicense.id : null,
    //   //       click: () => this.selectedLicense = this.selectedLicense === license ? null : license
    //   //     });
    //   //   });
    //   //   return [...licenses, ...listings];
    //   // } else {
    //   //   return listings;
    //   // }
    // },
    highlightedIds() {
      let ids = new Set(this.model.highlightedListings);
      if (this.selectedLicense) {
        ids.add(this.selectedLicense.id);
      }
      return ids;
    },
    selectedListing() {
      return this.model.listings.getById(this.model.selectedListingId);
    },
    pageSize() {
      return 100;
    },
    actualPage() {
      const maxPage = Math.floor(this.listings.length / this.pageSize);
      return Math.min(this.page, maxPage);
    },
    pageStart() {
      return this.actualPage * this.pageSize;
    },
    pageEnd() {
      return Math.min(this.listings.length, this.pageStart + this.pageSize);
    },
    hasPages() {
      return this.listings.length > this.pageSize;
    },
    pagedListings() {
      return this.listings.slice(this.pageStart, this.pageEnd);
    },
    mapItems() {
      let items = [...this.pagedListings];
      if (this.showLicenses) {
        items.push(...this.model.licenses.items);
      }
      return items;
    }
  },
  methods: {
    applyFilterChanges() {
      this.filters = this.draftFilters;
      this.filterListings();
    },
    cancelFilterChanges() {
      this.draftFilters = {...this.filters};
    },
    clearExportUrl() {
      URL.revokeObjectURL(this.exportUrl);
    },
    addListing() {
      this.editListing = new Listing(this.model.cityInfo);
    },
    nextListing(isBackward) {
      let index = this.selectedListingIndex + (isBackward ? -1 : 1);
      if (index < 0) {
        index = this.listings.length + index;
      }
      index = index % this.listings.length;
      const listing = this.listings[index];
      this.selectListing(listing ? listing.id : null);
    },
    updateSelectedListingIndex() {
      if (this.selectedListing) {
        const selected = this.listings.indexOf(this.selectedListing);
        if (selected >= 0) {
          this.selectedListingIndex = selected;
        }
      }
    },
    filterListings() {
      this.filtering = true;
      const sources = {
        airbnb: this.filters.showAirbnb,
        vrbo: this.filters.showVrbo
      };
      this.model.listings.search(this.query)
      .then(results => {
        let filteredProperties = {};
        this.listings = results
        .filter(({item:listing}) => {
          if (listing.id === this.selectedListingId) {
            // Always show the selected listing, even if it doesn't match the filter
            return true;
          }
          if (this.filter && !this.filter(listing, this.model)) {
            return false;
          }
          if (this.filters.showPartiallyImported && listing.detailsImported) {
            return false;
          }
          if (this.filters.showUnread && listing.isSeen) {
            return false;
          }
          if (!(this.filters.showWhitelist === listing.isWhitelisted && sources[listing.source])) {
            return false;
          }
          if (listing.verifiedPropertyId && filteredProperties[listing.verifiedPropertyId]) {
            return false;
          }
          filteredProperties[listing.verifiedPropertyId] = true;
          return true;
        })
        .sort((r1, r2) => {
          if (this.query && r1.matchCount !== r2.matchCount) {
            return r2.matchCount - r1.matchCount;
          } else {
            let l1 = r1.item;
            let l2 = r2.item;
            let diff = 0;
            if (this.sortOptions) {
              const option = this.sortOptions.filter(option => option.value === this.filters.sort.field)[0];
              if (option) {
                diff = option.compare(l1, l2, this.model);

                // Custom sorts are supposed to use ascending sort, so if the current ascending flag is false,
                // negate the diff
                if (!this.filters.sort.ascending) {
                  diff *= -1;
                }
              }
            }
            if (diff === 0 && this.filters.sort.field === 'seen') {
              if (l1.isSeen !== l2.isSeen) {
                if (l1.isSeen) diff = -1;
                else if (l2.isSeen) diff = 1;
              }
            }
            if (diff === 0) {
              if (this.filters.sort.field === 'found' || this.filters.sort.field === 'seen') {
                let d1 = l1.dateFirstFound;
                let d2 = l2.dateFirstFound;
                if (d1 && d2) diff = d1 - d2;
                else if (d1) diff = -1;
                else if (d2) diff = 1;
              }
            }
            if (diff === 0) {
              diff = l1.listingTitle.replace(/^[^a-zA-Z0-9]*/, '').localeCompare(l2.listingTitle.replace(/^[^a-zA-Z0-9]*/, ''));
            }
            return this.filters.sort.ascending ? diff : -diff;
          }
        }).map(result => result.item);
      })
      .finally(() => this.filtering = false);
    },
    selectListing(id) {
      this.model.selectListing(id);
    },
    showLocation(latlng) {
      if (this.$refs.map) {
        this.$refs.map.setCenter(latlng, 18);
      }
    },
    openStreetView(latlng) {
      if (this.$refs.map) {
        this.$refs.map.openStreetView(latlng);
      }
    },
    closeDetail(message) {
      this.selectListing(null);
      if (message && message.title && message.body) {
        this.closeMessage = message;
      }
    },
    sync() {
      this.model?.importListingDetails();
    }
  }
}
</script>
