<template>
  <LicenseWorkflowEditorCard :city="city" />
  <LicenseApplicationEditorCard :city="city" />
</template>

<script>
import {City} from "@/models/City";
import LicenseWorkflowEditorCard from "@/components/admin-city/licenses/LicenseWorkflowEditorCard.vue";
import LicenseApplicationEditorCard from "@/components/admin-city/licenses/LicenseApplicationEditorCard.vue";

export default {
  components: {LicenseApplicationEditorCard, LicenseWorkflowEditorCard},
  props: {
    city: City
  }
}
</script>
