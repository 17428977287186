<template>
  <div class="col col-12 col-md-6 col-lg-4 mb-4">
    <div class="rounded-4 border shadow-sm bg-white p-3 h-100 d-flex flex-column gap-3">
      <div>
        <div class="d-flex flex-row gap-1">
          <div class="small fw-bold text-muted">
            {{ title }}
          </div>
          <BTooltip v-if="description" :title="description">
            <i class="bi-info-circle me-1" />
          </BTooltip>
        </div>
        <LinkOrText :to="to" :class="['big-number', variant && count > threshold ? `text-${variant}` : null]">
          {{ formatNumber(count) }}
        </LinkOrText>
      </div>
      <div v-if="$slots.default" class="d-flex flex-row align-items-center gap-3 flex-wrap">
        <slot />
      </div>
      <div v-if="$slots.buttons" class="d-flex flex-row align-items-center flex-wrap gap-3 mt-auto">
        <slot name="buttons" />
      </div>
    </div>
  </div>
</template>

<script>
import BTooltip from "@/components/common/BTooltip.vue";
import LinkOrText from "@/components/dashboard/LinkOrText.vue";

export default {
  components: {LinkOrText, BTooltip},
  props: {
    title: String,
    description: String,
    count: {
      type: Number,
      default: () => 0
    },
    to: Object,
    variant: String,
    threshold: {
      type: Number,
      default: () => 0
    }
  }
}
</script>

<style>
.big-number {
  font-weight: 800;
  font-size: 2.5em;
  line-height: 48px;
  color: var(--bs-dark);
}
a.big-number:hover {
  color: var(--bs-link-color) !important;
}
</style>
