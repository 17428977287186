<template>
  <AppLayout
    :access-status="model.isAuthenticated ? 'granted' : model.currentUserChecked ? 'blocked' : 'pending'"
    :warning-message="cityId === 'scarborough_me' ? 'Scarborough is in demo mode. The licenses are not real, so feel free to play with the data.' : null"
    :wide-left-bar="false"
  >
    <SplashView v-if="!loaded" class="d-flex flex-column align-items-center justify-content-center flex-grow-1 gap-2">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="model">
        <CityButton center class="p-2 h2" invert-colors :city="model.cityInfo" />
      </div>
    </SplashView>
    <div v-else-if="!city">
      City "{{ cityId }}" could not be found
    </div>
    <template v-else>
      <div class="container d-flex flex-row align-items-center gap-2 pt-3">
        <CityButton :city="city" caption="Short-Term Rental Portal" invert-colors center :icon-size="48" />
        <div class="ms-auto small d-flex flex-column align-items-end">
          <div v-if="model.currentUser?.displayName || model.currentUser?.email">
            Welcome, {{ model.currentUser.displayName || model.currentUser.email }}
          </div>
          <div>
            Today is {{ formatDate(new Date()) }}
          </div>
        </div>
      </div>

      <div class="w-100 flex-grow-1">
        <div class="container pt-3 gap-3 d-flex flex-column">
          <div class="row mb-0">
            <CityDashboard :model="model" />
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import {Session} from "@/models/User";
import {AppModel} from "@/models/AppModel";
import AppLayout from "@/components/common/AppLayout.vue";
import CityDashboard from "@/components/dashboard/CityDashboard.vue";
import CityButton from "@/components/common/CityButton.vue";
import SplashView from "@/components/common/SplashView.vue";

export default {
  components: {SplashView, CityButton, CityDashboard, AppLayout},
  props: {
    cityId: String,
    viewMode: String
  },
  data() {
    return {
      Session,
      model: null
    };
  },
  watch: {
    cityId: {
      immediate: true,
      handler() {
        this.model = new AppModel(this.cityId);
        this.model.load();
      }
    }
  },
  computed: {
    loaded() {
      return this.model?.listings.loaded && this.model?.licenses.loaded;
    },
    city() {
      return this.model.cityInfo;
    },
    color() {
      let color = this.city && this.city.branding && this.city.branding.backgroundColor;
      return `#${color || '333333'}`;
    },
    iconStyle() {
      return {
        maxHeight: '48px',
        backgroundColor: '#ffffff',
        padding: '2px',
        border: `2px solid ${this.color}`
      };
    }
  }
}
</script>

<style>
img.bg {
  position: fixed;
  z-index: -6;
  /* aspect-ratio: auto; */
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  object-fit: cover;
  overflow: hidden;
  filter: blur(3px);
  top: -3px;
  left: -3px;
}
</style>
