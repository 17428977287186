export const STATUSES = [
  ['submitted', 'Unpaid'],
  ['issued', 'Active', 'active'],
  ['expired', 'Expired', 'inactive'],
  ['suspended', 'Suspended', 'pending'],
  ['closed', 'Closed', 'active'],
  ['withdrawn', 'Withdrawn', 'inactive'],
  ['deleted', 'Deleted', 'inactive']
].map(status => {
  return {
    code: status[0],
    label: status[1],
    type: status[2] || 'pending',
    builtIn: true
  }
});

export function compareStatuses(s1, s2) {
  let t1 = s1?.type === 'pending' ? 1 : s1?.type === 'active' ? 2 : 3;
  let t2 = s2?.type === 'pending' ? 1 : s2?.type === 'active' ? 2 : 3;
  let d = t1 - t2;
  if (d === 0) {
    d = (s1?.builtIn ? 1 : 0) - (s2?.builtIn ? 1 : 0);
  }
  if (d === 0) {
    d = s1?.label?.localeCompare(s2?.label);
  }
  return d;
}
