<template>
  <AppLayout :access-status="accessStatus" skip-tour>
    <template #top>
      <nav class="navbar navbar-expand-lg border-bottom">
        <div class="container-fluid d-flex flex-row align-items-center">
          <router-link :to="{name:'home'}" class="me-3">
            <img height="36" src="../assets/logo.png"/>
          </router-link>
          <h5 class="me-3 mb-0 fw-semibold text-primary">STR Admin Console</h5>
          <ul class="navbar-nav me-2 flex-grow-1 d-flex flex-row">
            <li class="nav-item me-2">
              <router-link class="nav-link" :to="{name:'admin-cities'}" active-class="active">
                Municipalities
              </router-link>
            </li>
            <li class="nav-item me-2">
              <router-link class="nav-link" :to="{name:'admin-users'}" active-class="active">
                Users
              </router-link>
            </li>
          </ul>
          <ul class="navbar-nav me-2" >
            <UserMenu />
          </ul>
        </div>
      </nav>
    </template>

    <div class="container pt-3">
      <div class="row d-flex justify-content-center">
        <div :class="colClass">
          <div class="d-flex align-items-center mb-3" >
            <slot name="topbar" />
          </div>
          <slot />
        </div>
      </div>
    </div>
  </AppLayout>
</template>
<script>
import {Session} from "@/models/User";
import AppLayout from "@/components/common/AppLayout.vue";
import UserMenu from "@/components/common/UserMenu.vue";
export default {
  props: {
    size: {
      type: String,
      default: () => 'md'
    }
  },
  components: {
    AppLayout,
    UserMenu
  },
  computed: {
    colClass() {
      if (this.size === 'lg') {
        return 'col-sm-12 col-md-12 col-lg-10';
      } else if (this.size === 'sm') {
        return 'col-sm-12 col-md-8 col-lg-6';
      } else {
        return 'col-sm-12 col-md-10 col-lg-8';
      }
    },
    accessStatus() {
      if (!Session.currentUserChecked.value) {
        return 'pending';
      }
      const user = Session.currentUser.value;
      if (user) {
        console.log(`Role is ${user.role}`);
        if (user.role === 'admin') {
          return 'granted';
        }
      }
      return 'blocked';
    }
  }
}
</script>
