import {
  documentId,
  query,
  getDoc,
  getDocs,
  onSnapshot,
  where,
  orderBy,
  limit,
  getCountFromServer
} from "firebase/firestore";
import {PersistentModel} from "@/models/PersistentModel";

/**
 * Base model class for providing a consistent interface to a document within a sub-collection of a city.
 */
export class PersistentCityModel extends PersistentModel {

  static dataConverter(city, collectionName) {
    collectionName = collectionName || this.collectionName;
    return ({id, data}) => {
      return new this(city, {
        ref: city.subDoc(collectionName, id),
        data
      });
    };
  }

  static docConverter(city) {
    return (doc) => new this(city, doc);
  }

  static snapshotConverter(city) {
    return (snapshot) => snapshot.docs.map(this.docConverter(city));
  }

  static list(city, collectionName) {
    collectionName = collectionName || this.collectionName;
    return getDocs(city.subCollection(collectionName))
    .then(this.snapshotConverter(city));
  }

  static one(city, id, collectionName) {
    collectionName = collectionName || this.collectionName;
    return getDoc(city.subDoc(collectionName, id))
    .then(this.docConverter(city));
  }

  static some(city, idPrefix, collectionName) {
    collectionName = collectionName || this.collectionName;
    return getDocs(query(
      city.subCollection(collectionName),
      where(documentId(), '>=', idPrefix),
      where(documentId(), '<', idPrefix+'~')
    ))
    .then(this.docConverter(city));
  }

  static onOne(city, id, handler, collectionName) {
    collectionName = collectionName || this.collectionName;
    const convert = this.docConverter(city);
    return onSnapshot(
      city.subDoc(collectionName, id),
      doc => handler(convert(doc))
    );
  }

  static query(city, { constraints, sort, sortDirection, maxResults }) {
    let qCount = city.subCollection(this.collectionName);
    constraints.forEach(constraint => {
      qCount = query(qCount, constraint);
    });
    const qResults = query(qCount, orderBy(sort, sortDirection), limit(maxResults));
    return Promise.all([
      getCountFromServer(qCount).then(snapshot => snapshot.data().count),
      getDocs(qResults).then(this.snapshotConverter(city))
    ]).then(values => {
      return {
        count: values[0],
        results: values[1]
      };
    });
  }

  constructor(city, doc) {
    super(doc);
    this.city = city;
  }

  async collection() {
    return this.city.subCollection(this.constructor.collectionName);
  }

  copy() {
    return this.constructor.dataConverter(this.city)({
      id: this.id,
      data: JSON.parse(JSON.stringify(this._data))
    });
  }

  doApiCall(name, data) {
    return super.doApiCall(name, {
      ...data,
      cityId: this.city.id
    });
  }

  indexKeys() {
    return [];
  }

}
