<template>
  <div class="text-nowrap">
    <template v-if="license.isExpired">{{ license.expirationDateFormatted }}</template>
    <template v-else-if="license.dateIssuedFormatted">Issued {{ license.dateIssuedFormatted }}</template>
    <template v-else-if="license.dateAppliedFormatted">Applied {{ license.dateAppliedFormatted }}</template>
  </div>
</template>

<script>
import {License} from "@/models/License";

export default {
  props: {
    license: License
  }
}
</script>
