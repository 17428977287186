<template>
  <div class="d-flex flex-row align-items-start">
    <ListingSourceIcon :listing="listing" class="mt-1 me-1" />
    <div class="ms-1 small" v-if="labelOnly">
      {{ label }}
    </div>
    <a
      v-else
      :href="listing.url"
      @click.stop
      target="_blank"
      class="btn-flat small text-muted mb-1 px-1"
      :title="`View on ${listing.listingSource.name}`"
      data-bs-toggle="tooltip"
      :data-bs-title="`View on ${listing.listingSource.name}`">
      {{ label }}
    </a>
  </div>
</template>
<script>
import {Listing} from "@/models/Listing";
import ListingSourceIcon from "@/components/common/ListingSourceIcon.vue";

export default {
  components: {ListingSourceIcon},
  props: {
    listing: Listing,
    labelOnly: Boolean
  },
  computed: {
    label() {
      let label = [this.listing.listingTitle || `View on ${this.listing.listingSource.name}`];
      if (this.listing.listingPrice > 0) {
        label.push(`${this.formatDollars(this.listing.listingPrice).replace(/\..*/, '')} per night`);
      }
      if (this.listing.listingHost) {
        label.push(`Hosted by ${this.listing.listingHost}`);
      }
        return label.join(' • ');
    }
  }
}
</script>

<style>
.btn-flat {
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none;
}
.btn-flat:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.1);
}
</style>
