<template>
  <LayoutCard title="Branding" description="Customize the look of your municipality's portal." class="mb-4">

    <template #footer v-if="changed">
      <spinner-view v-if="saving" small class="me-3" />
      <button type="button" class="btn btn-flat ms-1" @click="reset" :disabled="saving">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit" :disabled="saving">
        Save Changes
      </button>
    </template>

    <div class="row mb-3">
      <label class="col-sm-12 col-md-2 col-form-label">Color</label>
      <div class="col">
        <div class="position-relative w-100">
          <input
            type="text"
            v-model="colorDisplay"
            class="form-control"
            :style="color ? 'padding-left:40px' : ''"
          />
          <div
            v-if="color"
            class="rounded position-absolute"
            :style="`top:50%;left:4px;margin-top:-16px;width:32px;height:32px;background-color:#${color};`"/>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <label class="col-sm-12 col-md-2 col-form-label">Icon</label>
      <div class="col">
        <div class="position-relative w-100">
          <input
            type="text"
            v-model="iconUrl"
            class="form-control"
            :style="iconUrl ? 'padding-left:40px' : ''"
          />
          <img
            v-if="iconUrl"
            :src="iconUrl"
            class="rounded-circle position-absolute"
            :style="`top:50%;left:4px;margin-top:-16px;width:32px;height:32px;object-fit:cover`"/>
        </div>
      </div>
    </div>
  </LayoutCard>
</template>
<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";
export default {
  components: {
    SpinnerView,
    LayoutCard
  },
  props: {
    city: City
  },
  data() {
    return {
      iconUrl: null,
      color: null,
      saving: false
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() { this.reset() }
    }
  },
  computed: {
    changed() {
      return this.iconUrl !== this.city.iconUrl || this.color !== this.city.backgroundColor;
    },
    colorDisplay: {
      get() {
        return this.color;
      },
      set(color) {
        this.color = color.replace(/^#+/, '');
      }
    }
  },
  methods: {
    reset() {
      this.iconUrl = this.city.iconUrl;
      this.color = this.city.backgroundColor;
    },
    commit() {
      this.saving = true;
      this.city.updateBranding({
        iconUrl: this.iconUrl,
        backgroundColor: this.color
      })
      .finally(() => this.saving = false);
    }
  }
}
</script>
