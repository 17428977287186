<template>
  <AdminComponent>
    <template #topbar>
      <h4 class="flex-grow-1 mb-0">
        {{ users.length }} Users
      </h4>
      <button class="btn btn-primary" @click="createNewUser">
        <i class="bi-plus-lg"/> Add User
      </button>
    </template>

    <div v-if="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>
        Loading Users
      </div>
    </div>
    <div class="list-group list-group-flush" v-else>
      <div class="d-none d-md-flex list-group-item row d-flex align-items-center small text-uppercase fw-semibold px-0">
        <div class="col-sm-8 col-md-6">
          Name / Username
        </div>
        <div class="col-sm-4 col-md-2">
          Role
        </div>
        <div class="col-sm-4 col-md-2">
          Municipalities
        </div>
        <div class="col-sm-4 col-md-2 text-end">
          Last Login
        </div>
      </div>
      <template v-for="user in users" :key="user.id">
        <div class="hover-target list-group-item row d-flex align-items-center px-0" :style="`opacity:${user.loading ? '0.5' : '1'}`">
          <div class="col-xs-12 col-sm-8 col-md-6">
            <div>
              <input type="text" v-model.lazy="user.displayName" class="form-control form-control-sm form-control-inline" placeholder="No Display Name" />
            </div>
            <div class="small">
              {{ user.username }}
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2">
            <select class="w-100 form-select-sm" v-model="user.role" :disabled="user.isCurrentUser">
              <option :value="null">Staff</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div class="col-xs-12 col-sm-8 col-md-2 small d-flex flex-row align-items-center flex-wrap">
            <template v-if="user.role === 'admin'">
              <div class="fw-semibold">---</div>
            </template>
            <template v-else>
              <div v-if="user.cities.length === 0" class="me-1">None</div>
              <div
                v-for="(cityId, i) in user.cities"
                :key="cityId"
                :class="`rounded-circle border border-white bg-white ${i >= 9 ? 'd-none' : ''}`"
                :style="i > 0 ? 'margin-left:-8px' : ''">
                <CityIcon v-if="cityForId(cityId)" :city="cityForId(cityId)" :size="30" invert-colors />
              </div>
              <a :ref="`cityDropdown_${user.uid}`"
                 class="dropdown-toggle no-caret btn btn-flat btn-icon btn-sm"
                 href="#"
                 data-bs-toggle="dropdown">
                <i class="bi-plus-lg"/>
              </a>
              <div class="dropdown-menu p-3">
                <template v-for="city in cities" :key="city.id">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      :name="`cities_${user.id}`"
                      :value="city.id"
                      v-model="user.cities"
                    />
                    <label class="form-check-label">
                      {{ city.name }}
                    </label>
                  </div>
                </template>
              </div>
            </template>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2 d-flex flex-column align-items-end">
            <div class="text-muted small hide-on-hover">
              {{ user.lastLogin }}
            </div>
            <a
              class="show-on-hover dropdown-toggle no-caret btn btn-flat btn-icon btn-sm"
              href="#"
              data-bs-toggle="dropdown">
              <i class="bi-three-dots"/>
            </a>
            <ul class="dropdown-menu">
              <li>
                <a href="#" class="dropdown-item small" @click.prevent="resetUserPassword(user, 'send_link')">
                  Send password reset email
                </a>
              </li>
              <li>
                <a href="#" class="dropdown-item small" @click.prevent="resetUserPassword(user, 'copy_link')">
                  Create password reset link
                </a>
              </li>
              <li>
                <a href="#" class="dropdown-item small" @click.prevent="resetUserPassword(user, 'manual')">
                  Set a new password manually
                </a>
              </li>
              <template v-if="!user.isCurrentUser">
                <li>
                  <hr class="dropdown-divider"/>
                </li>
                <li>
                  <a href="#" class="dropdown-item text-danger small" @click.prevent="deleteUser(user)">
                    Delete User
                  </a>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </template>
    </div>
    <CreateUserModal v-model="showNewUserModal" @create="userCreated"/>
    <DeleteUserModal v-model="userToDelete" @delete="userDeleted"/>
    <ResetPasswordModal v-model="userToResetPasswordFor" :mode="passwordResetMode"/>
  </AdminComponent>
</template>

<script>
import {User} from "@/models/User";
import {City} from "@/models/City";
import CityIcon from "@/components/common/CityIcon.vue";
import DeleteUserModal from "@/components/modals/DeleteUserModal.vue";
import CreateUserModal from "@/components/modals/CreateUserModal.vue";
import ResetPasswordModal from "@/components/modals/ResetPasswordModal.vue";
import AdminComponent from "@/components/AdminComponent.vue";

export default {
  components: {AdminComponent, ResetPasswordModal, CreateUserModal, DeleteUserModal, CityIcon},
  data() {
    return {
      loading: false,
      users: [],
      cities: [],
      showNewUserModal: null,
      userToDelete: null,
      userToResetPasswordFor: null,
      passwordResetMode: null
    };
  },
  mounted() {
    this.loadUsers();
    City.loadCities(true)
    .then(cities => this.cities = cities);
  },
  methods: {
    cityForId(cityId) {
      return this.cities.filter(city => city.id === cityId)[0];
    },
    loadUsers() {
      this.loading = true;
      User.loadUsers()
      .then(users => this.users = users)
      .catch(err => {
        console.warn(err);
      })
      .finally(() => this.loading = false);
    },
    createNewUser() {
      this.showNewUserModal = true;
    },
    userCreated(user) {
      this.users.push(user);
    },
    deleteUser(user) {
      this.userToDelete = user;
    },
    userDeleted(user) {
      this.users = this.users.filter(u => u.id !== user.id);
    },
    resetUserPassword(user, mode) {
      this.passwordResetMode = mode;
      this.userToResetPasswordFor = user;
    }
  }
}
</script>

<style>
.form-control-inline {
  border-color: transparent;
  padding-left: 0px;
  transition: padding-left 250ms, border-color 250ms;
}
.form-control-inline:hover, .form-control-inline:focus {
  border-color: revert;
  padding-left: revert;
}
.dropdown-toggle.no-caret::after {
  display: none;
}
</style>
