import { getFirestore, collection, doc, connectFirestoreEmulator } from 'firebase/firestore';
import {getApp} from './firebase';

const EMULATOR_PORT = null; // (usually 8080) TODO Load this from process.env.DB_EMULATOR_PORT

export const db = getApp().then(app => {
  let db = getFirestore(app);
  if (EMULATOR_PORT) {
    connectFirestoreEmulator(db, 'localhost', EMULATOR_PORT);
  }
  return db;
});


export default {
  collection: async name => collection(await db, name),
  doc: async path => doc(await this.collection(name), path)
}

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
// const { Timestamp, GeoPoint } = firebase.firestore
// export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
// db.settings({ timestampsInSnapshots: true })
