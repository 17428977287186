import {BaseModel} from "@/models/BaseModel";

export const TYPE_TEXT = 'text';
export const TYPE_BOOLEAN = 'bool';
export const TYPE_NUMBER = 'number';
export const TYPE_SINGLE = 'single';
export const TYPE_MULTI = 'multi';
export const TYPE_HEADER = 'header';

export default class extends BaseModel{

  constructor(data) {
    super(data, {
      id: `${Math.round(Math.random() * 1000000000)}`,
      label: '',
      description: '',
      required: false,
      filter: false,
      type: TYPE_TEXT,
      options: [],
      defaultValue: null
    });
  }

  get id() {
    return this._get('id');
  }

  get isHeader() {
    return this.type === TYPE_HEADER;
  }

  get isText() {
    return this.type === TYPE_TEXT;
  }

  get isBoolean() {
    return this.type === TYPE_BOOLEAN;
  }

  get isNumber() {
    return this.type === TYPE_NUMBER;
  }

  get isSingle() {
    return this.type === TYPE_SINGLE;
  }

  get isMulti() {
    return this.type === TYPE_MULTI;
  }

  get type() {
    return this._get('type');
  }
  set type(type) {
    this._set('type', type);
  }

  get label() {
    return this._get('label');
  }
  set label(label) {
    this._set('label', label);
  }

  get description() {
    return this._get('description');
  }
  set description(description) {
    this._set('description', description);
  }

  get options() {
    return this._get('options');
  }
  set options(options) {
    this._set('options', options);
  }

  get required() {
    return this._get('required');
  }
  set required(required) {
    this._set('required', required);
  }

  get filterEnabled() {
    return this._get('filter');
  }
  set filterEnabled(filterEnabled) {
    this._set('filter', filterEnabled);
  }

  get defaultValue() {
    return this._get('defaultValue');
  }
  set defaultValue(defaultValue) {
    this._set('defaultValue', defaultValue);
  }

  get hasDefaultValue() {
    return this.defaultValue !== null;
  }

  get hasOptions() {
    return this.type === TYPE_SINGLE || this.type === TYPE_MULTI;
  }

  addOption() {
    let options = this.options;
    options.push('');
    this.options = options;
  }

  removeOption(option) {
    this.options = this.options.filter(o => o !== option);
  }

  get hasValue() {
    return this.type !== TYPE_HEADER;
  }

  get supportsRequiredFlag() {
    return this.type !== TYPE_HEADER;
  }

  get supportsDefault() {
    return this.type !== TYPE_HEADER;
  }
}
