import {addDoc, collection, doc, DocumentSnapshot, getDoc, setDoc, deleteDoc} from "firebase/firestore";
import {db} from "@/db";
import {callApi} from "@/api";
import moment from "moment";
import {Session} from "@/models/User";

/**
 * Base model class for providing a consistent interface to a document in Firestore.
 */
export class PersistentModel {

  static get collectionName() {
    let className = this.name;
    let collectionName = `${className[0].toLowerCase()}${className.substring(1)}`;
    if (collectionName.endsWith('s')) {
      return `${collectionName}es`;
    } else if (collectionName.endsWith('y')) {
      return collectionName.replace(/y$/, 'ies');
    } else {
      return `${collectionName}s`;
    }
  }

  constructor(doc) {
    if (!doc) {
      this._docRef = null;
      this._data = {};
    } else if (doc instanceof DocumentSnapshot) {
      this.doc = doc;
    } else {
      this._docRef = doc.ref;
      this._data = doc.data || {};
    }
    this.requests = [];
    this.updateCount = 0;
  }

  log(message) {
    console.log(`[${this.constructor.name}] ${message}`);
  }

  toMoment(dateOrStringOrTimestamp) {
    if (dateOrStringOrTimestamp) {
      if (typeof dateOrStringOrTimestamp === 'string') {
        return moment(dateOrStringOrTimestamp, 'YYYY-MM-DD');
      } else if (dateOrStringOrTimestamp instanceof Date) {
        return moment(dateOrStringOrTimestamp);
      } else if (dateOrStringOrTimestamp.toDate) {
        return moment(dateOrStringOrTimestamp.toDate());
      }
    }
    return null;
  }

  get db() {
    return this._docRef ? this._docRef.firestore : null;
  }

  set doc(doc) {
    this._docRef = doc.ref;
    this._data = doc.data() || {};
  }

  get doc() {
    return this._docRef;
  }

  async collection() {
    if (this._docRef) {
      return Promise.resolve(this._docRef.parent);
    } else {
      return collection(await db, this.constructor.collectionName);
    }
  }

  reload() {
    if (this._docRef) {
      return getDoc(this._docRef)
      .then(doc => this.doc = doc)
      .then(() => this);
    } else {
      return Promise.resolve(this);
    }
  }

  async update(data, action, updateFirst) {
    if (updateFirst) {
      this._data = {
        ...this._data,
        data
      };
    }
    await setDoc(this._docRef, data, {merge:true});
    this._data = {
      ...this._data,
      data
    };

    // If action is set, log it
    if (action) {
      this.logModification(action, data);
    }

    this.updateCount++;
    return this;
  }

  copyFrom(fromModel) {
    this._data = JSON.parse(JSON.stringify(fromModel._data));
    return this.save();
  }

  async save() {
    if (this._docRef) {
      await setDoc(this._docRef, this._data, {merge:true});
    } else {
      this._docRef = await addDoc(await this.collection(), this._data);
    }
    return this;
  }

  async delete() {
    await deleteDoc(this._docRef);
  }


  _get(field) {
    const fieldPath = field.split(/\./g);
    let value = this._data;
    fieldPath.forEach(field => {
      value = value && value[field];
    });
    return value;
  }

  _set(field, value) {
    const fieldPath = field.split(/\./g);
    let parent = this._data;
    for (let i = 0; i < fieldPath.length; i++) {
      if (i === fieldPath.length - 1) {
        parent[fieldPath[i]] = value;
      } else {
        parent = parent[fieldPath[i]] || {};
      }
    }
  }

  get isNew() {
    return !this.id;
  }

  get id() {
    return this._docRef ? this._docRef.id : null;
  }

  get data() {
    return this._data;
  }

  get hasData() {
    return !!this.data && Object.keys(this.data).length > 0;
  }

  copy() {
    return new this.constructor({
      ref: this._docRef,
      data: JSON.parse(JSON.stringify(this._data))
    });
  }

  indexKeys() {
    return [];
  }

  subCollection(collectionName) {
    return collection(this._docRef, collectionName);
  }

  subDoc(collectionName, docId) {
    return doc(this.db, `${this._docRef.path}/${collectionName}/${docId}`);
  }

  isRequesting(name) {
    return this.requests.indexOf(name) >= 0;
  }

  doApiCall(name, data) {
    this.requests.push(name);
    data = data || {};
    data[this.idFieldNameForApi] = this.id;
    return callApi(name, data)
    .then(() => this.reload())
    .then(() => this.requests = this.requests.filter(request => request !== name));
  }

  get idFieldNameForApi() {
    return 'id';
  }

  logModification(action, data) {
    addDoc(this.subCollection('modifications'), {
      action, data,
      date: new Date(),
      user: this.currentUser
    }).then(docRef => {
      console.log(`Logged modification on ${this.id} at ${docRef.id}`);
    });
  }

  get currentUser() {
    const currentUser = Session.currentUser.value;
    if (currentUser) {
      return {
        id: currentUser.id,
        displayName: currentUser.displayName,
        email: currentUser.email
      };
    } else {
      return null;
    }
  }

}
