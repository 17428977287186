import {PersistentCityModel} from "@/models/PersistentCityModel";
import moment from "moment/moment";
import {Property} from "@/models/Property";
import {deleteDoc} from "firebase/firestore";
import {compareStatuses} from "@/models/LicenseStatus";

function compareDates(d1, d2) {
  if (d1 && d2) return d2 - d1;
  else if (d1) return -1;
  else if (d2) return 1;
  else return 0;
}

function compareAddresses(a1, a2) {
  if (a1 && a2) {
    let diff = a1.street.localeCompare(a2.street);
    if (diff === 0) {
      return a1.streetNumber - a2.streetNumber;
    } else {
      return diff;
    }
  } else if (!a1) {
    return 1;
  } else {
    return -1;
  }
}

function createComparisonFunction(byStatusFirst) {
  return (l1, l2) => {
    let diff = byStatusFirst
      ? compareStatuses(l1.statusWithConfig, l2.statusWithConfig)
      : compareAddresses(l1.property?.address, l2.property?.address);
    if (diff !== 0) {
      return diff;
    }

    diff = compareDates(l1.dateIssued, l2.dateIssued);
    if (diff !== 0) {
      return diff;
    }

    diff = compareDates(l1.dateApplied, l2.dateApplied);
    if (diff !== 0) {
      return diff;
    }

    return !byStatusFirst
      ? compareStatuses(l1.statusWithConfig, l2.statusWithConfig)
      : compareAddresses(l1.property?.address, l2.property?.address);
  };
}

export class LicenseFilter {

  constructor(/* City */ city, /* LicenseFilter */ copyFrom) {
    this.city = city;
    this.status = copyFrom ? {...copyFrom.status} : {};
    this.onlyShowMissingProperties = copyFrom ? copyFrom.onlyShowMissingProperties : false;
    this.sortByStatus = copyFrom ? copyFrom.sortByStatus : true;

    this.customFields = this.city.customFields.filter(field => field.filter);
    this.customFields.forEach(field => {
      this[field.id] = {};
      field.options.forEach(option => {
        this[field.id][option] = copyFrom ? copyFrom[field.id][option] : true;
      });
    });

    // Set default values for license status filters
    let status = {};
    this.statusOptions = this.city.licenseStatuses;
    this.city.licenseStatuses.forEach(s => {
      status[s.code] = copyFrom ? copyFrom.status[s.code] : s.type !== 'inactive';
    });
    this.status = status;
  }

  copy() {
    return new LicenseFilter(this.city, this);
  }

  filter(/* License */ license) {
    if (!this.status[license.status]) {
      return false;
    }
    if ((this.onlyShowMissingProperties) && !!license.property) {
      return false;
    }
    for (let field of this.customFields) {
      const fieldValue = license.getCustomFieldValue(field.id);
      if (!this[field.id][fieldValue]) {
        return false;
      }
    }
    return true;
  }

  reset(statusFilter) {
    this.city.licenseStatuses.forEach(s => {
      this.filter.status[s.code] = statusFilter ? s.type === statusFilter : s.type !== 'inactive';
    });
  }
}

export const compareLicensesByStatus = createComparisonFunction(true);
export const compareLicensesByAddress = createComparisonFunction(false);

export class License extends PersistentCityModel {

  static collectionName = 'licenses';

  constructor(city, data) {
    super(city, data);
    this._data = {
      propertyId: null,
      property: null,
      contact: {
        owners: [],
        address: null,
        email: '',
        phone: '',
      },
      address: null, // Unverified address - use as a hint to the user when linking this to a property
      listings: [],
      customFieldValues: [],
      dateApplied: null,
      status: null,
      paid: false,
      licenseId: null,
      dateIssued: null,
      ...this._data
    };
  }

  get number() {
    return this.data.licenseId || this.id;
  }

  get status() {
    if (this.isExpired) {
      return 'expired';
    } else {
      return this.data.status;
    }
  }

  get statusWithConfig() {
    const statuses = this.statusOptions;
    return statuses.filter(s => s.code === this.status)[0];
  }

  get statusOptions() {
    // TODO We might want to restrict this according to the current status
    return this.city.licenseStatuses;
  }

  get dateApplied() {
    return this.data.dateApplied;
  }

  get dateIssued() {
    return this.data.dateIssued;
  }

  get canRenew() {
    return this.isExpired && !this.isDeleted;
  }

  get dateAppliedFormatted() {
    let applied = this.toMoment(this.dateApplied);
    return applied ? applied.format('M/D/YY') : null;
  }

  get dateIssuedFormatted() {
    let issued = this.toMoment(this.dateIssued);
    return issued ? issued.format('M/D/YY') : null;
  }

  get expirationDateFormatted() {
    const date = this.expirationDate;
    return date ? moment(date).format('M/D/YY') : null;
  }

  get expirationDate() {
    let issued = this.toMoment(this.dateIssued);
    return issued ? this.city.calculateLicenseExpirationDate(issued.toDate()) : null;
  }

  get isExpired() {
    const date = this.expirationDate;
    return date && date < new Date();
  }

  get isDeleted() {
    return this.status === 'deleted';
  }

  get isClosed() {
    return this.status === 'closed';
  }

  get isWithdrawn() {
    return this.status === 'withdrawn';
  }

  get isIssued() {
    return this.status === 'issued';
  }

  get isPending() {
    return this.statusWithConfig?.type === 'pending';
  }

  get isActive() {
    return (this.isIssued || this.isClosed) && !this.isExpired;
  }

  get isInactive() {
    return this.statusWithConfig?.type === 'inactive';
  }

  get isSuspended() {
    return this.status === 'suspended';
  }

  get contact() {
    return this.data.contact || {};
  }

  get address() {
    const property = this.property;
    return property ? property.address : null;
  }

  get unverifiedAddress() {
    return this.data.address;
  }

  get addressFormatted() {
    const property = this.property;
    return property ? property.addressFormatted : null;
  }

  get addressStreetNumber() {
    return this.property?.address?.streetNumber;
  }

  get addressStreetName() {
    return this.property?.address?.street;
  }

  get addressUnit() {
    return this.property?.address?.unit;
  }

  get isLinkedToProperty() {
    return !!this.propertyId;
  }

  get propertyId() {
    return this.data.propertyId || this.data.property?.id;
  }

  set propertyId(propertyId) {
    this.data.propertyId = propertyId;
  }

  get property() {
    const property = this.data.property;
    let propertyId = this.propertyId;
    if (property && !propertyId) {
      // Default property ID to the ID in the nested property object
      propertyId = property.id;
    }
    return property && propertyId ? Property.dataConverter(this.city)({
      id: propertyId,
      data: property
    }) : null;
  }

  set property(property) {
    this.data.property = property ? property._data : null;
  }

  get imageUrl() {
    const property = this.property;
    return property && property.imageUrl;
  }

  get latlng() {
    const property = this.property;
    return property && property.latlng;
  }

  get listings() {
    return this.data.listings || [];
  }

  indexKeys() {
    const address = (this.addressFormatted || '');
    const title = (this.number || '');
    const contact = ((this.contact || {}).owners || []).join(' ');
    return (this.id + ' ' + address + ' ' + title + ' ' + contact).toLowerCase()
    .split(/ /g)
    .map(word => word.trim())
    .filter(word => !!word);
  }

  delete(permanent) {
    if (permanent) {
      return deleteDoc(this._docRef);
    } else {
      return this.update({
        status: 'deleted'
      });
    }
  }

  get originalIssueDate() {
    return this._get('originalIssueDate');
  }

  renew() {
    let originalIssueDate = this.originalIssueDate || this.dateIssued;
    return this.update({
      originalIssueDate,
      dateIssued: new Date()
    });
  }

  getCustomFieldValue(id) {
    const fv = this._data.customFieldValues.filter(fv => fv.id === id)[0];
    return (fv && fv.value) || null;
  }
}
