<template>
  <div ref="tooltip" data-bs-toggle="tooltip" :data-bs-title="title">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data() {
    return {
      tooltip: null
    };
  },
  mounted() {
    this.tooltip = this.bootstrap.Tooltip.getOrCreateInstance(this.$refs.tooltip);
  },
  beforeUnmount() {
    this.tooltip?.dispose();
  }
}
</script>
