<template>
  <form @submit.prevent="submit">
    <div class="mb-3">
      <label class="form-label" for="emailInput">Username or Email</label>
      <input
        type="text"
        id="emailInput"
        class="form-control"
        :disabled="loading"
        v-model="email"/>
    </div>
    <div v-if="!showForgotPassword" class="mb-3">
      <label class="form-label" for="passwordInput">Password</label>
      <input
        type="password"
        id="passwordInput"
        class="form-control"
        :disabled="loading"
        v-model="password"/>
    </div>
    <div v-if="status" class="mt-3 small alert alert-success">
      {{ status }}
    </div>
    <div v-else-if="error" class="mt-3 small alert alert-danger">
      {{ error }}
    </div>
    <button
      type="submit"
      :disabled="!canSubmit"
      class="btn btn-primary w-100 text-nowrap">
      <div v-if="loading" class="spinner-border spinner-border-sm me-1"></div>
      {{ showForgotPassword ? 'Send Password Reset' : 'Login' }}
    </button>
    <button
      class="btn btn-link w-100 text-center"
      @click.prevent="showForgotPassword = !showForgotPassword">
      {{ showForgotPassword ? 'Remembered your password?' : 'Forgot your password?' }}
    </button>
  </form>
</template>

<script>
import {Session} from "@/models/User";
import {formatError} from '@/models/formatError';

export default {
  emits: ['login'],
  data() {
    return {
      email: window.localStorage.getItem('loginEmail') || '',
      password: '',
      showForgotPassword: false,
      loading: false,
      status: '',
      error: ''
    };
  },
  computed: {
    canSubmit() {
      return !this.loading && !!this.email && (this.showForgotPassword || !!this.password);
    }
  },
  methods: {
    submit() {
      this.loading = true;
      if (this.showForgotPassword) {
        return Session.sendPasswordReset(this.email)
        .then(() => {
          this.loading = false;
          this.status = `We have sent password reset instructions to ${this.email} if it is associated with an account.`;
        })
        .catch(error => {
          this.loading = false;
          this.error = formatError(error);
        });
      } else {
        return Session.login(this.email, this.password)
        .then(() => {
          this.loading = false;
          this.$emit('login');
        })
        .catch(error => {
          this.loading = false;
          this.error = formatError(error);
        });
      }
    }
  }
}
</script>
