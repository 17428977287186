<template>
  <div v-for="field in model.customFields" :key="`${field.id}`" :class="`mb-${spacing}`">
    {{ field.label }} <span class="fw-semibold">{{ getCustomFieldValueLabel(field) }}</span>
  </div>
</template>
<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";

export default {
  props: {
    model: LicenseEditorModel,
    spacing: {
      type: Number,
      default: () => 1
    }
  },
  methods: {
    getCustomFieldValueLabel(field) {
      const value = this.model.getCustomFieldValue(field.id);
      if (value !== null) {
        if (field.isBoolean) {
          return value ? 'Yes' : 'No';
        } else if (field.isMulti) {
          return value.join(', ');
        }
      }
      return value;
    }
  }
}
</script>
