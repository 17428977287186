<template>
  <div class="mb-3">
    <label class="form-label">What is the address of the property you would like to register?</label>
    <PropertyInput
      v-if="model.city"
      :city="model.city"
      :show-labels="false"
      v-model="model.propertyId"
      v-model:property="model.property" />
  </div>
  <BCollapse :show="!!model.parcelId">
    <div>
      <AssessorLink v-if="model.property" :property="model.property" class="form-label" />
      <label v-else class="form-label">Parcel #{{ model.parcelId }}</label>
    </div>
  </BCollapse>
</template>

<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import BCollapse from "@/components/common/BCollapse.vue";
import PropertyInput from "@/components/common/PropertyInput.vue";
import AssessorLink from "@/components/common/AssessorLink.vue";

export default {
  components: {AssessorLink, PropertyInput, BCollapse},
  props: {
    model: LicenseEditorModel
  }
}
</script>
