import { initializeApp } from 'firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/functions';

function getConfigJson() {
  if (window.location.hostname === 'localhost') {
    console.log(`Running locally. Trying to load Firebase from config var.`);
    let env = process.env.VUE_APP_FIREBASE_CONFIG_JSON;
    if (env) {
      console.log('Config var is present. Attempting to parse it.');
      return Promise.resolve(JSON.parse(env));
    }
  }

  console.log(`Loading Firebase config...`);
  return fetch('/__/firebase/init.json')
  .then(response => response.json());
}

const app = getConfigJson()
.then(config => initializeApp(config));

export async function getApp() { return await app; }
