<template>
  <component
    :is="button ? 'button' : 'a'"
    :type="button ? 'button' : null"
    :class="['btn btn-flat position-relative', {active}, {'btn-sm':small}]"
    :title="title"
    :data-bs-title="title"
    v-bind="$attrs">
    <i :class="`bi-${icon} ${variant ? `text-${variant}` : ''}`"/>
    <template v-if="text">{{ text }}</template>
    <b-overlay v-if="busy">
      <spinner-view small />
    </b-overlay>
  </component>
</template>

<script>
import BOverlay from "@/components/common/BOverlay.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView, BOverlay},
  props: {
    icon: String,
    title: String,
    text: String,
    active: Boolean,
    button: Boolean,
    small: Boolean,
    variant: String,
    busy: Boolean
  },
  data() {
    return {
      tooltip: null
    };
  },
  watch: {
    title() {
      this.$nextTick(() => {
        this.updateTooltip();
      });
    }
  },
  mounted() {
    this.updateTooltip();
  },
  beforeUnmount() {
    this.updateTooltip(true);
  },
  methods: {
    updateTooltip(skipCreation) {
      if (this.tooltip) {
        this.tooltip.dispose();
      }
      if (!skipCreation && this.title) {
        this.tooltip = new this.bootstrap.Tooltip(this.$el);
      }
    }
  }
}
</script>
