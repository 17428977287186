import {getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import {getApp} from './firebase';

const EMULATOR_PORT = null; // (usually 5000 or 5001) TODO Look in process.env.API_EMULATOR_PORT

const functions = getApp().then(app => {
  let functions = getFunctions(app);
  if (EMULATOR_PORT) {
    connectFunctionsEmulator(functions, 'localhost', EMULATOR_PORT);
  }
  return functions;
});

export async function callApi(name, data, namespace) {
  namespace = namespace || 'api';
  return httpsCallable(await functions, `${namespace}-${name}`)(data)
  .then(response => response.data);
}
