<template>
  <div class="py-4">
    <div class="d-flex flex-row align-items-start">
      <div class="w-25">
        <img v-if="citySealUrl" :src="citySealUrl" class="seal-logo" />
      </div>
      <div class="w-50 text-center fw-bold">
        <div>
          {{ city.formalName }}
        </div>
        <div>
          {{ city.officeTitle }}
        </div>
        <div>
          {{ city.townHallAddress }}
        </div>
        <div>
          {{ city.name }}, {{ city.state }}
        </div>
      </div>
      <div class="w-25">&nbsp;</div>
    </div>
    <div class="mt-4 text-center">
      {{ new Date().toLocaleDateString() }}
    </div>
    <div class="mt-4 d-flex flex-row align-items-start">
      <div class="me-2">To:</div>
      <div>
        <div v-for="owner in model.owners" :key="owner">
          {{ owner }}
        </div>
        <div>{{ model.addressFormatted }}</div>
        <div>{{ city.name }}, ME</div>
      </div>
    </div>
    <div class="my-4 d-flex flex-row align-items-start">
      <div class="me-2">Re:</div>
      <div>
        <div>
          {{ city.violationLetterTitle }}
        </div>
        <div v-if="city.violationLetterSubtitle">
          {{ city.violationLetterSubtitle }}
        </div>
      </div>
    </div>
    <div class="row d-flex flex-row align-items-stretch mt-4 pt-4 border-top">
      <div :class="`col col-${withScreenshot ? 6 : 12}`">
        <p style="word-wrap: break-word">
          We have reason to believe that your property, at
          <b>{{ model.addressFormatted }}</b>, is listed as a short-term rental at
          {{ model.listingUrl }}{{ withScreenshot ? ' (as seen on the right)' : ''}}.
          However, this property is not currently licensed under our Short-Term Rental Ordinance.
        </p>
        <p class="mb-4" style="word-wrap: break-word">
          If this listing does not belong to you, then please disregard this message.
          <template v-if="!voluntary">
            Otherwise, please complete a license application {{ registrationLocation }}. Failure to do so may result in
            fines.
          </template>
          <template v-else>
            Otherwise, we are seeking voluntary compliance from short-term rental hosts with our new Short-Term Rental
            Ordinance. Please consider registering your short-term rental property {{ registrationLocation }}.
          </template>
        </p>
        <br/>
        <div>
          <div class="mb-4">
            Sincerely,
          </div>
          <div>
            {{ city.contactName }}
          </div>
          <div>
            {{ city.contactTitle }}
          </div>
          <div v-if="city.contactPhone">
            {{ formatPhone(city.contactPhone) }}
          </div>
          <div v-if="city.contactEmail">
            {{ city.contactEmail }}
          </div>
        </div>
      </div>
      <div v-if="withScreenshot" class="col col-6 position-relative overflow-hidden">
        <img
          v-if="screenshotUrl"
          class="w-100 position-absolute"
          :src="screenshotUrl"
          alt=""
          @load="$emit('ready')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {LetterModel} from "@/models/LetterModel";

export default {
  props: {
    model: LetterModel,
    withScreenshot: Boolean,
    voluntary: Boolean
  },
  emits: ['ready'],
  data() {
    return {
      screenshotUrl: null
    };
  },
  async mounted() {
    if (this.withScreenshot && this.model.canFetchScreenshot) {
      const base64 = await this.model.fetchScreenshot();
      this.screenshotUrl = `data:image/png;base64,${base64}`;
    } else {
      setTimeout(() => this.$emit('ready'), 500);
    }
  },
  computed: {
    city() {
      return this.model.city;
    },
    citySealUrl() {
      return this.city.townSealUrl;
    },
    registrationLocation() {
      return `either in person at our municipal offices at ${this.city.townHallAddress}, or online at https://app.strenforcement.com/register/${this.model.cityId}`;
    }
  }
}
</script>
