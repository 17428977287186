<template>
  <div class="d-flex flex-column h-100">

    <!-- Top Bar -->
    <div :class="`flex-shrink-0 p-0`">
      <div class="d-flex flex-row align-items-center gap-0">
        <IconButton icon="arrow-left" class="flex-shrink-0" @click="close"/>
        <ScrollerWithBlurMask direction="x" hide-scrollbar class="flex-grow-1" color="#ffffff">
          <div class="d-flex flex-row align-items-center gap-0">
            <IconButton
              v-if="showLicenseButton"
              icon="file-earmark-plus"
              title="Create License"
              class="flex-shrink-0"
              @click="createLicenseForListing"/>
            <PrintLetterButton v-if="showViolationLetterButton" class="flex-shrink-0" :model="violationLetterModel" />
            <IconButton
              :icon="expandMode === 'whitelist' ? 'x-lg' : 'card-checklist'"
              :title="listing.isWhitelisted ? 'Whitelisted' : 'Not whitelisted'"
              :class="`flex-shrink-0 ${listing.isWhitelisted ? 'text-primary' : ''} ${expandMode === 'whitelist' ? 'active' : ''}`"
              @click="expandMode = expandMode === 'whitelist' ? null : 'whitelist'" />
            <IconButton
              :icon="expandMode === 'notes' ? 'x-lg' : 'justify-left'"
              title="Notes"
              :class="`flex-shrink-0 ${!!listing.notes ? 'text-primary' : ''} ${expandMode === 'notes' ? 'active' : ''}`"
              @click="expandMode = expandMode === 'notes' ? null : 'notes'" />
            <IconButton
              :icon="`eye${listing.isSeen ? '-slash' : ''}`"
              :title="`Mark as ${listing.isSeen ? 'Unseen' : 'Seen'}`"
              class="flex-shrink-0 border-left"
              @click="toggleSeen"/>
            <IconButton
              v-if="showSyncButton"
              :icon="expandMode === 'sync' ? 'x-lg' : 'arrow-clockwise'"
              :title="`Sync with ${listing.listingSource.name}`"
              :class="`flex-shrink-0 ${expandMode === 'sync' ? 'active' : ''}`"
              :busy="listing.isImportingDetails"
              @click="toggleExpandMode('sync')"/>
            <IconButton
              icon="geo-alt"
              title="Show on Map"
              class="flex-shrink-0 border-left"
              @click="showLocation"/>
            <IconButton
              v-if="showDeleteButton"
              icon="trash"
              title="Delete"
              class="flex-shrink-0 border-left"
              @click="deleteListing"/>
          </div>
        </ScrollerWithBlurMask>
        <div v-if="$slots.buttons" class="d-flex flex-row align-items-center gap-0 flex-shrink-0 border-left border-gray">
          <slot name="buttons" />
        </div>
      </div>
      <BCollapse :show="expandMode === 'notes'">
        <ListingNotesEditor :listing="listing" @close="expandMode = null" class="p-3 border-top"/>
      </BCollapse>
      <BCollapse :show="expandMode === 'whitelist'" >
        <ListingWhitelistEditor :listing="listing" @close="expandMode = null" class="p-3 border-top" />
      </BCollapse>
      <BCollapse :show="expandMode === 'sync'">
        <ListingSyncView :listing="listing" class="p-3 border-top" />
      </BCollapse>
      <BCollapse :show="scrolled && !!listing.listingTitle">
        <a :href="listing.url" target="_blank" class="btn btn-flat d-flex flex-row align-items-center" style="text-overflow: ellipsis">
          <ListingSourceIcon :listing="listing" class="me-1"/>
          <div class="small fw-semibold text-nowrap ellipsis overflow-hidden me-1">
            {{ listing.listingTitle }}
          </div>
          <div v-if="listing.listingHost" class="small text-nowrap ellipsis">
            by {{ listing.listingHost }}
          </div>
        </a>
      </BCollapse>
    </div>

    <div class="d-flex flex-row flex-grow-1 border-top align-items-start overflow-hidden">
      <div :class="`w-100 h-100 overflow-auto`" @scroll="updateScroll" ref="scroller">
        <div v-if="listing.verifiedProperty" class="border-bottom p-3 mb-3">
          <div class="fw-semibold mb-1">
            Verified Address
          </div>
          <div>
            <SuggestedListingProperty
              :model="model"
              :property="listing.verifiedProperty"
              :listing="listing"
              @clear="resetVerification"
            />
          </div>
        </div>
        <ListingView :listing="listing" />
      </div>

    </div>

    <EditLicenseModal v-model:license="editLicense" />
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import ListingSourceIcon from "@/components/common/ListingSourceIcon.vue";
import BCollapse from "@/components/common/BCollapse.vue";
import IconButton from "@/components/common/IconButton.vue";
import ListingNotesEditor from "@/components/listing/ListingNotesEditor.vue";
import ListingWhitelistEditor from "@/components/listing/ListingWhitelistEditor.vue";
import {AppModel} from "@/models/AppModel";
import ListingSyncView from "@/components/listing/ListingSyncView.vue";
import {Session} from "@/models/User";
import ListingView from "@/components/listing/ListingView.vue";
import SuggestedListingProperty from "@/components/SuggestedListingProperty.vue";
import EditLicenseModal from "@/components/modals/EditLicenseModal.vue";
import ScrollerWithBlurMask from "@/components/common/ScrollerWithBlurMask.vue";
import PrintLetterButton from "@/components/letters/PrintLetterButton.vue";
import {ListingLetterModel} from "@/models/LetterModel";

export default {
  components: {
    PrintLetterButton,
    ScrollerWithBlurMask,
    EditLicenseModal,
    SuggestedListingProperty,
    ListingView,
    ListingSyncView,
    ListingWhitelistEditor,
    ListingNotesEditor,
    IconButton,
    BCollapse,
    ListingSourceIcon,
  },
  emits: ['close','showLocation'],
  props: {
    model: AppModel,
    listing: Listing
  },
  data() {
    return {
      expandMode: null,
      notes: '',
      loading: false,
      scrolled: false,
      printing: false,
      saving: false,
      editLicense: null
    };
  },
  mounted() {
    this.reload(true);
  },
  watch: {
    listing(newListing, oldListing) {
      this.reload(newListing && oldListing && newListing.id !== oldListing.id);
    }
  },
  methods: {
    createLicenseForListing() {
      this.editLicense = this.listing.createLicenseDraft();
    },
    toggleExpandMode(mode) {
      this.expandMode = this.expandMode === mode ? null : mode;
      if (this.expandMode === 'sync') {
        this.listing.importDetails();
      }
    },
    async reload(isDifferentListing) {
      // this.listing.fetchListingDetails();
      this.notes = this.listing.notes;
      if (isDifferentListing) {
        this.showLocation();
      }
      if (this.$refs.scroller) {
        this.$refs.scroller.scrollTo(0, 0);
      }
      if (isDifferentListing) {
        await this.listing.updateSeen(true);
        if (this.showSyncButton && this.listing.isDueForDetailImport) {
          setTimeout(() => {
            this.listing.importDetails();
          }, 500);
        }
      }
    },
    showLocation() {
      this.$emit('showLocation', this.listing.latlng);
    },
    onVerification(result) {
      this.expandMode = null;
      this.close(result);
    },
    close(message) {
      this.$emit('close', message || false);
    },
    updateScroll(e) {
      this.scrolled = e.target.scrollTop >= 50;
    },
    resetVerification({notes}) {
      this.saving = true;
      this.listing.verifyProperty(null, notes)
      .then(() => this.saving = false);
    },
    deleteListing() {
      this.listing.delete()
      .then(() => this.$emit('close'));
    },
    toggleSeen() {
      this.listing.updateSeen(!this.listing.isSeen);
    }
  },
  computed: {
    showViolationLetterButton() {
      return !this.isMobile && this.listing.isPropertyVerified && this.model.isViolation(this.listing);
    },
    violationLetterModel() {
      return new ListingLetterModel(this.listing);
    },
    showLicenseButton() {
      return this.showViolationLetterButton;
    },
    showSyncButton() {
      return true;
      // const user = Session.currentUser.value;
      // return user && user.role === 'admin';
    },
    showDeleteButton() {
      const user = Session.currentUser.value;
      return user && user.role === 'admin';
    }
  }
}
</script>
