<template>
  <button type="button" class="btn btn-flat btn-sm d-block" @click="value = !value">
    <i :class="`bi-${icon} me-1`"/> {{ label }}
  </button>
</template>

<script>
export default {
  props: {
    modelValue: Boolean,
    label: String,
    isSwitch: Boolean
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    },
    icon() {
      if (this.isSwitch) {
        return `toggle-${this.value ? 'on' : 'off'}`;
      } else {
        return `${this.value ? 'check-' : ''}square`;
      }
    }
  }
}
</script>
