<template>
  <BTooltip title="Print a Letter" v-bind="$attrs">
    <button
      type="button"
      ref="button"
      class="btn btn-flat dropdown-toggle no-caret"
      data-bs-toggle="dropdown"
      :disabled="printing"
    >
      <i v-if="!printing" class="bi-printer" />
      <spinner-view v-else small />
    </button>
    <ul class="dropdown-menu position-fixed">
      <li v-for="option in printOptions" :key="option.label">
        <button type="button" @click="printViolationLetter(option)" class="dropdown-item">
          {{ option.label }}
        </button>
      </li>
    </ul>
  </BTooltip>
  <LetterWrapper :model="model"
    ref="letterContent"
    class="print"/>
</template>

<script>
import {LetterModel} from "@/models/LetterModel";
import LetterWrapper from "@/components/letters/LetterWrapper.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import BTooltip from "@/components/common/BTooltip.vue";
export default {
  components: {BTooltip, SpinnerView, LetterWrapper},
  props: {
    model: LetterModel,
  },
  data() {
    return {
      printing: false,
      tooltip: null
    };
  },
  methods: {
    printViolationLetter({template, options}) {
      this.printing = true;
      this.$refs.letterContent.print(template, options)
      .then(() => this.printing = false);
      this.bootstrap.Dropdown.getInstance(this.$refs.button)?.hide();
    },
  },
  computed: {
    printOptions() {
      return this.model.printOptions;
    },
  }
}
</script>
