<template>
  <div :class="['blur-mask', position]" :style="style" />
</template>

<script>
export default {
  props: {
    position: {
      type: String,
      validator: (value) => ['top', 'bottom', 'right', 'left'].indexOf(value) >= 0
    },
    color: {
      type: String,
      default: () => '#f8f9fb'
    }
  },
  computed: {
    angle() {
      switch (this.position) {
        case 'top': return 0;
        case 'right': return 90;
        case 'bottom': return 180;
        case 'left': return 270;
        default: return 0;
      }
    },
    style() {
      return `background: linear-gradient(${this.angle}deg, ${this.color}00 0%, ${this.color}FF 100%)`;
    }
  }
}
</script>

<style>
.blur-mask {
  position: absolute;
  pointer-events: none;
  backdrop-filter: blur(1px);
}
.blur-mask.top, .blur-mask.bottom {
  left: 0;
  right: 0;
  max-height: 10%;
}
.blur-mask.left, .blur-mask.right {
  top: 0;
  bottom: 0;
  max-width: 10%;
}
.blur-mask.top {
  top: 0;
}
.blur-mask.left {
  left: 0;
}
.blur-mask.bottom {
  bottom: 0;
}
.blur-mask.right {
  right: 0;
}
</style>
