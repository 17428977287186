import {License} from "@/models/License";
import {callApi} from "@/api";

export class LicenseParseChange {
  static async commitChanges(changes, force) {
    changes.forEach(change => change.committing = true);
    try {
      let updates = await callApi('commitLicenses', {
        cityId: changes[0].city.id,
        licenses: changes.map(change => change.data.data),
        force: !!force
      }, 'data');
      let index = {};
      updates.forEach(update => {
        index[update.id] = update;
      });
      changes.forEach(change => {
        if (index[change.id]) {
          change.data.error = index[change.id].error || null;
          change.data.updated = index[change.id].updated || false;
        }
      });
    } finally {
      changes.forEach(change => change.committing = false);
    }
  }
  constructor(city, data) {
    this.city = city;
    this.data = data;
    this.committing = false;
    this.data.error = this.data.error || null;
    this.data.updated = this.data.updated || false;
    this.data.fields = (this.data.fields || []).map(field => {
      return {
        ...field,
        originalNewValue: field.newValue || null
      };
    });
    this.hasPropertyIdUpdate = !!this.propertyId && this.fields.filter(f => f.field === 'propertyId').length > 0;
    this.hasStatusUpdate = !!this.propertyId && this.fields.filter(f => f.field === 'status').length > 0;
  }
  get id() {
    return this.data.id;
  }
  get type() {
    return this.data.type;
  }
  get status() {
    return this.data.data?.status;
  }
  get propertyId() {
    return this.data.data?.propertyId;
  }
  set propertyId(propertyId) {
    this.data.data.propertyId = propertyId;
  }
  get fields() {
    return this.data.fields;
  }
  toLicense() {
    return License.dataConverter(this.city)({
      id: this.id,
      data: this.data.data
    });
  }
  commit(force) {
    return LicenseParseChange.commitChanges([this], force);
  }
  get committed() {
    return this.data.updated;
  }
  get error() {
    return this.data.error;
  }
  get isAdded() {
    return this.type === 'add';
  }
  get isUpdated() {
    return this.type === 'update';
  }
}

class LicenseParseError {

  constructor(city, data) {
    this.city = city;
    this.data = data;
  }

  get line() {
    return this.data.line;
  }

  get content() {
    return this.data.content;
  }

  get error() {
    return this.data.error;
  }
}

export default class LicenseParseResult {

  constructor(city, data) {
    this.city = city;
    this.data = data;
    this.errors = this.data.errors.map(d => new LicenseParseError(this.city, d));
    this.changes = this.data.changes.map(d => new LicenseParseChange(this.city, d));
  }

  get totalCount() {
    return this.data.counts?.total || 0;
  }

  get addedCount() {
    return this.data.counts?.add || 0;
  }

  get updatedCount() {
    return this.data.counts?.update || 0;
  }

  get errorCount() {
    return this.data.counts?.error || 0;
  }

  get unchangedCount() {
    return this.data.counts?.none || 0;
  }
}
