<template>
  <component :is="tagName" :class="`collapse ${initialValue ? 'show' : ''}`">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    show: Boolean,
    delay: Number,
    tagName: {
      type: String,
      default: () => 'div'
    }
  },
  watch: {
    show() {
      this.doCollapse();
    }
  },
  data() {
    return { initialValue: this.show };
  },
  beforeUnmount() {
    const collapse = this.bootstrap.Collapse.getInstance(this.$el);
    if (collapse) {
      collapse.dispose();
    }
  },
  methods: {
    doCollapse() {
      const instance = this.bootstrap.Collapse.getOrCreateInstance(this.$el);
      if (this.show) {
        setTimeout(() => {
          instance.show();
          setTimeout(() => {
            if (this.show && this.$el && this.$el.scrollIntoViewIfNeeded) {
              this.$el.scrollIntoViewIfNeeded();
            }
          }, 400);
        }, this.delay || 0);
      } else {
        instance.hide();
      }
    }
  }
}
</script>
