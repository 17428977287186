<template>
  <ListView
    :items="licenses"
    :selected-item-id="model.selectedLicenseId"
    @select="selectLicense"
    @highlight="id => model.setLicenseHighlighted(id, true)"
    @unhighlight="id => model.setLicenseHighlighted(id, false)"
    v-bind="$attrs"
    >
    <template #item="{item}">
      <LicenseItemView
        :model="model"
        :license="item"
        @edit="() => $emit('edit', item)"
      />
    </template>
  </ListView>
</template>

<script>
import {AppModel} from "@/models/AppModel";
import LicenseItemView from "@/components/LicenseItemView";
import ListView from "@/components/common/ListView.vue";
export default {
  components: {ListView, LicenseItemView},
  emits: ['edit'],
  props: {
    model: AppModel,
    licenses: Array
  },
  methods: {
    selectLicense(id) {
      // this.$router.push({
      //   name: 'license',
      //   params: {
      //     cityId: this.model.cityId,
      //     licenseId: id
      //   }
      // });
      this.model.selectLicense(id);
    }
  }
}
</script>
