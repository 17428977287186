<template>
  <div :class="`spinner-border ${small ? 'spinner-border-sm' : ''}`" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>

<script>
export default {
  props: {
    small: Boolean
  }
}
</script>
