<template>
  <div class="mb-3">
    If this property is already listed on short-term rental sites, please provide the link(s) below.
  </div>
  <div class="mb-3 list-group">
    <div v-for="listing in model.listings" :key="listing" class="list-group-item d-flex flex-row align-items-center">
      <ListingRegistrationItem :url="listing" :city-id="model.cityId" class="flex-grow-1 me-3" />
      <button class="btn btn-icon btn-flat" @click="removeListing(listing)">
        <i class="bi-trash"/>
      </button>
    </div>
  </div>
  <div class="mb-3">
    <input type="url" class="form-control " placeholder="https://..." v-model="newListing" @keydown.enter.prevent.stop="addNewListing" />
    <div class="form-text">Press Enter to add new listing</div>
  </div>
</template>

<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import ListingRegistrationItem from "@/components/register/ListingRegistrationItem.vue";

export default {
  components: {ListingRegistrationItem},
  props: {
    model: LicenseEditorModel
  },
  data() {
    return {
      newListing: ''
    };
  },
  computed: {
    listings: {
      get() {
        return this.model.listings.join('\n');
      },
      set(urls) {
        this.model.listings = urls.split(/\n/).map(url => url.trim());
      }
    }
  },
  methods: {
    addNewListing() {
      if (this.newListing) {
        this.model.listings.push(
          this.newListing
          .replace(/(https:\/\/.*\.airbnb\.com\/rooms\/\d+).*/, '$1')
          .replace(/(https:\/\/.*\.vrbo\.com\/[\da-zA-Z]+).*/, '$1')
          .replace(/(https:\/\/.*\.flipkey\.com\/properties\/\d+).*/, '$1')
        );
        this.newListing = '';
      }
    },
    removeListing(listing) {
      this.model.listings = this.model.listings.filter(l => l !== listing);
    }
  }
}
</script>
