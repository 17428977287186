<template>
  <CustomMarker
    :class="`custom-marker ${isSelectedOrHighlighted ? 'highlighted' : ''} ${listing.latlng ? '' : 'd-none'}`"
    :options="options"
    :style="`opacity:1;z-index:99;`"
    @click.stop="click"
    @mouseover.stop="highlight"
    @mouseout.stop="unhighlight"
  >
    <CustomMarkerIcon :icon="listing.listingSource?.iconUrl" color="4171d9" />
  </CustomMarker>
</template>

<script>
import {CustomMarker} from "vue3-google-map";
import CustomMarkerIcon from "@/components/common/CustomMarkerIcon.vue";
import {Listing} from "@/models/Listing";
import {AppModel} from "@/models/AppModel";
export default {
  components: {
    CustomMarker,
    CustomMarkerIcon
  },
  props: {
    listing: Listing,
    model: AppModel
  },
  computed: {
    options() {
      return {
        title: this.listing.addressFormatted,
        position: this.listing.latlng,
        optimized: true
      };
    },
    isSelectedOrHighlighted() {
      return this.selected || this.model.highlightedListings.has(this.listing.id);
    },
    selected() {
      return this.model.selectedListingId === this.listing.id;
    }
  },
  methods: {
    click() {
      this.model.selectListing(this.selected ? null : this.listing.id);
    },
    highlight() {
      this.model.setListingHighlighted(this.listing.id, true);
    },
    unhighlight() {
      this.model.setListingHighlighted(this.listing.id, false);
    }
  }
}
</script>

<style>
.custom-marker {
  z-index: 1;
  transition: scale 250ms, z-index 0ms linear 250ms;
}
.custom-marker.highlighted {
  z-index: 2;
  scale: 2;
  transition: scale 250ms, z-index 0ms linear 0ms;
}
</style>
