<template>
  <div>
    <label v-if="!hideLabel" :class="field.isHeader ? 'form-header' : 'form-label'">
      {{ field.label }}
    </label>
    <div v-if="field.description" class="form-text">
      {{ field.description }}
    </div>
    <template v-if="field.isText">
      <input type="text" v-model="value" class="form-control" />
    </template>
    <template v-else-if="field.isNumber">
      <input type="number" v-model="value" class="form-control" />
    </template>
    <template v-else-if="field.isBoolean">
      <div>
        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" :value="true" v-model="value" :name="field.id" />
          <label class="form-check-label">Yes</label>
        </div>
        <div class="form-check form-check-inline">
          <input type="radio" class="form-check-input" :value="false" v-model="value" :name="field.id" />
          <label class="form-check-label">No</label>
        </div>
      </div>
    </template>
    <template v-else-if="field.isSingle">
      <div>
        <div v-for="option in field.options" :key="option" class="form-check">
          <input type="radio" class="form-check-input" :value="option" v-model="value" :name="field.id" />
          <label class="form-check-label">{{ option }}</label>
        </div>
      </div>
    </template>
    <template v-else-if="field.isMulti">
      <div>
        <div v-for="option in field.options" :key="option" class="form-check">
          <input type="checkbox" class="form-check-input" :value="option" v-model="value" :name="field.id" />
          <label class="form-check-label">{{ option }}</label>
        </div>
      </div>
    </template>
    <template v-else-if="!field.isHeader">
      <div class="text-danger">Unrecognized field type <b>{{ field.type }}</b></div>
    </template>
  </div>
</template>

<script>
import FormField from "@/models/FormField";

export default {
  props: {
    field: FormField,
    hideLabel: Boolean,
    modelValue: {}
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        const value = this.modelValue;
        if (value) return value;
        else if (this.field.defaultValue !== null) return this.field.defaultValue;
        else {
          if (this.field.isMulti) {
            return [];
          } else if (this.field.isBoolean) {
            return null;
          } else {
            return null;
          }
        }
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  }
}
</script>

<style>
.form-header {
  font-weight: 600;
  margin-top: 1.5rem;
}
</style>
