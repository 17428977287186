<template>
  <SimpleTable title="Summary" :rows="summaryRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last checked: {{ formatDate(listing.dateLoaded) }}</div>
    </template>
  </SimpleTable>

  <SimpleTable title="Coordinates" :rows="latlngRows" class="mb-4"/>

  <SimpleTable title="Details" :rows="detailRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last imported: {{ formatDate(listing.dateDetailsImported, 'never') }}</div>
      <button
        class="ms-3 btn btn-sm btn-outline-primary"
        @click="listing.importDetails()"
        :disabled="listing.isRequesting('importListingDetails')"
      >
        <spinner-view v-if="listing.isRequesting('importListingDetails')" small />
        Import Details
      </button>
    </template>
  </SimpleTable>

  <SimpleTable title="Screenshot" :rows="screenshotRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last screenshot: {{ formatDate(listing.dateScreenshotTaken, 'never') }}</div>
      <button
        class="ms-3 btn btn-sm btn-outline-primary"
        @click="listing.takeScreenshot()"
        :disabled="listing.isRequesting('screenshot')"
        >
        <spinner-view v-if="listing.isRequesting('screenshot')" small />
        Take Screenshot
      </button>
    </template>
  </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/common/SimpleTable.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {Listing} from "@/models/Listing";
import {TableBuilder} from "@/components/admin-city/TableBuilder";

export default {
  components: {SpinnerView, SimpleTable},
  props: {
    listing: Listing
  },
  computed: {
    summaryRows() {
      const l = this.listing;
      return new TableBuilder()
      .row('Source ID', l.details.id)
      .row('Source', l.listingSource.name)
      .row('Title', l.title)
      .rows;
    },
    detailRows() {
      if (!this.listing.detailsImported) {
        return [];
      }
      const {
        host, coHosts, bedrooms, bath, description, images, location, price, propertyType
      } = this.listing.details;
      return new TableBuilder()
      .row('Host', host)
      .row('Co-Hosts', coHosts)
      .row('Bedrooms', bedrooms)
      .row('Bathrooms', bath)
      .row('Description', description)
      .row('Location', location && location.title)
      .row('Neighborhood', location && location.content)
      .row('Price', price)
      .row('Property Type', propertyType)
      .row('Pictures', images, 'images')
      .rows;
    },
    latlngRows() {
      const {lat,lng} = this.listing.latlng;
      return new TableBuilder()
      .row('Latitude', `${lat}° ${lat < 0 ? 'S' : 'N'}`)
      .row('Longitude', `${lng}° ${lng < 0 ? 'W' : 'E'}`)
      .row('Map', {lat,lng}, 'map')
      .rows;
    },
    screenshotRows() {
      const url = this.listing.screenshotUrl;
      return new TableBuilder()
      .row('Screenshot', url ? [url] : [], 'images')
      .rows;
    }
  }
}
</script>
