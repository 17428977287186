<template>
  <ul :class="`list-group ${flush ? 'list-group-flush' : 'mx-3 mb-3'}`">
    <li
      v-for="item in items"
      :key="item.id"
      :ref="`item_${item.id}`"
      :class="`py-3 list-group-item list-group-item-action ${item.selected || selectedItemId === item.id ? 'bg-primary-fade bg-opacity-25' : ''} ${flush ? '' : ''}`"
      @click="$emit('select', item.selected ? null : item.id)"
      @mouseover="() => setHighlighted(item.id, true)"
      @mouseout="() => setHighlighted(item.id, false)"
    >
      <slot name="item" :item="item"/>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: Array,
    selectedItemId: String,
    flush: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      highlightTasks: {}
    };
  },
  emits: ['select', 'highlight', 'unhighlight'],
  watch: {
    selectedItemId() {
      this.scrollToSelected();
    }
  },
  mounted() {
    this.scrollToSelected();
  },
  methods: {
    setHighlighted(id, highlighted) {
      clearTimeout(this.highlightTasks[id]);
      this.highlightTasks[id] = setTimeout(() => {
        this.$emit(highlighted ? 'highlight' : 'unhighlight', id);
      }, 100);
    },
    scrollToSelected() {
      if (this.selectedItemId) {
        const ref = this.$refs[`item_${this.selectedItemId}`];
        if (ref && ref.length) {
          window.lastSelected = ref[0];
          ref[0].scrollIntoViewIfNeeded();
        }
      }
    }
  }
}
</script>
