<template>
  <ModalView v-model="show" title="Reset Password" @ok="confirm" :hide-cancel="hideCancel" :ok-title="okTitle" :busy="resetting">
    <template v-if="!!modelValue">

      <template v-if="mode === 'manual'">
        <template v-if="resetting">
          <SpinnerView small /> Resetting password
        </template>
        <form v-else @submit.prevent>
          <label class="form-label">New Password</label>
          <input type="password" v-model="newPassword" class="form-control"/>
        </form>
      </template>

      <template v-else-if="mode === 'send_link'">
        <template v-if="sent">
          A password reset email was sent to <b>{{ modelValue.email }}</b>
        </template>
        <template v-else-if="sending">
          <SpinnerView small /> Sending password reset email to <b>{{ modelValue.email }}</b>
        </template>
        <template v-else>
          <button type="button" class="btn" @click="send"/>
        </template>
      </template>

      <template v-else-if="mode === 'copy_link'">
        <template v-if="linkToCopy">
          <form @submit.prevent>
            <label class="form-label">You can copy this link and then share it directly with the user.</label>
            <div class="input-group input-group-sm mb-3">
              <input ref="linkToCopy" type="text" disabled :value="linkToCopy" class="form-control"/>
              <button type="button" class="btn btn-primary" @click="copyLink">
                {{ copied ? 'Link copied!' : 'Copy Link' }}
              </button>
            </div>
          </form>
        </template>
        <template v-else-if="creatingLink">
          <SpinnerView small /> Generating password reset link
        </template>
      </template>
    </template>
  </ModalView>
</template>

<script>
import ModalView from "@/components/common/ModalView.vue";
import {User} from "@/models/User";
import SpinnerView from "@/components/common/SpinnerView.vue";
export default {
  components: {ModalView,SpinnerView},
  emits: ['update:modelValue'],
  props: {
    modelValue: User,
    mode: {
      type: String,
      default: () => 'manual'
    } // send_link, copy_link, manual
  },
  data() {
    return {
      newPassword: null,
      resetting: false,
      creatingLink: false,
      linkToCopy: null,
      copied: false,
      sending: false,
      sent: false
    };
  },
  watch: {
    mode() {
      this.reload();
    }
  },
  computed: {
    show: {
      get() { return !!this.modelValue; },
      set(s) { if (!s) this.$emit('update:modelValue', null); }
    },
    hideCancel() {
      return this.mode === 'send_link' || this.mode === 'copy_link';
    },
    okTitle() {
      return this.mode === 'manual' ? 'Update Password' : 'OK';
    }
  },
  methods: {
    reload() {
      const user = this.modelValue;
      this.newPassword = null;
      this.resetting = false;
      this.linkToCopy = null;
      this.copied = false;
      this.sending = false;
      this.sent = false;
      if (user) {
        if (this.mode === 'send_link') {
          this.send();
        } else if (this.mode === 'copy_link') {
          this.createLink();
        }
      }
    },
    send() {
      this.sending = true;
      this.sent = false;
      this.modelValue.sendPasswordResetEmail()
      .then(() => this.sent = true)
      .finally(() => this.sending = false);
    },
    createLink() {
      this.creatingLink = true;
      this.linkToCopy = null;
      this.modelValue.generateLink('reset_password')
      .then(url => {
        this.linkToCopy = url;
      })
      .finally(() => this.creatingLink = false);
    },
    copyLink() {
      const el = this.$refs.linkToCopy;
      if (el) {
        el.select();
        navigator.clipboard.writeText(el.value);
        this.copied = true;
      }
    },
    confirm() {
      const user = this.modelValue;
      if (this.mode === 'manual') {
        this.resetting = true;
        user.updatePassword(this.newPassword)
        .finally(() => {
          this.resetting = false;
          this.newPassword = null;
          this.$emit('update:modelValue', null);
        });
      } else {
        this.$emit('update:modelValue', null);
      }
    }
  }
}
</script>
