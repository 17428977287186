<template>
  <CustomMarker
    class="custom-marker"
    :options="options"
    style="opacity:1;z-index:99;">
    <CustomMarkerIcon color="33A852" />
  </CustomMarker>
</template>

<script>
import {CustomMarker} from "vue3-google-map";
import CustomMarkerIcon from "@/components/common/CustomMarkerIcon.vue";
import {City} from "@/models/City";
export default {
  components: {
    CustomMarker,
    CustomMarkerIcon
  },
  props: {
    city: City
  },
  computed: {
    options() {
      return {
        title: this.city.label,
        position: this.city.latlng,
        optimized: true
      };
    }
  }
}
</script>

<style>
.custom-marker {
  z-index: 1;
  transition: scale 250ms, z-index 0ms linear 250ms;
}
</style>
