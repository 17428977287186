<template>
  <div class="mb-3" v-for="field in model.customFields" :key="field.id">
    <FormFieldInput
      :field="field"
      :modelValue="model.getCustomFieldValue(field.id)"
      @update:modelValue="value => model.setCustomFieldValue(field.id, value)"
    />
  </div>
</template>

<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import FormFieldInput from "@/components/common/FormFieldInput.vue";

export default {
  components: {FormFieldInput},
  props: {
    model: LicenseEditorModel
  }
}
</script>
