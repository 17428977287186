<template>
  <AppLayout
    :access-status="model.isAuthenticated ? 'granted' : model.currentUserChecked ? 'blocked' : 'pending'"
    :warning-message="cityId === 'scarborough_me' ? 'Scarborough is in demo mode. The licenses are not real, so feel free to play with the data.' : null"
    :wide-left-bar="false"
  >
    <template #top>
      <TopBar
        :model="model"
        selected-tab="licenses"
        :buttons="buttons" show-search @search="q => query = q" search-placeholder="Search licenses" />
      <EditLicenseModal v-model:license="editLicense" />
      <ImportLicensesModal v-if="model.cityInfo" :model="model" v-model:show="showImportLicenseModal" />
    </template>
    <template #left>
      <FilterableList
        :count="licenses.length"
        :page-start="pageStart"
        :page-end="pageEnd"
        @previous-page="page = page - 1"
        @next-page="page = page + 1"
        @updated="applyFilterChanges"
        @canceled="cancelFilterChanges"
        noun="licenses"
        :query="query"
        :loading="!model.licenses.loaded || filtering"
      >
        <template #filters v-if="draftFilter">
          <LicenseFilterEditor :filter="draftFilter" />
        </template>
        <template #list>
          <div class="d-flex flex-row align-items-stretch h-100">
            <div class="w-100">
              <LoadingMessage v-if="!model.licenses.loaded" message="Loading licenses"/>
              <LoadingMessage v-else-if="filtering" message="Updating licenses" />
              <BCollapse :show="model.licenses.loaded && !filtering && licenses.length === 0">
                <div class="d-flex flex-column align-items-center p-4">
                  <h6 class="text-center">
                    No licenses have been added yet.
                  </h6>
                  <p class="text-center">
                    Once you have added licenses to the database, this area will list them and
                    allow you to edit and delete them.
                  </p>
                </div>
              </BCollapse>

              <LicenseList
                v-if="!filtering"
                :model="model"
                :licenses="pagedLicenses"
                @edit="license => editLicense = license"
              />
            </div>
          </div>
        </template>
      </FilterableList>
    </template>
    <GMap
      ref="map"
      @deselect="selectLicense(null)"
      :city="model.cityInfo"
      :selected-marker-id="model.selectedLicenseId"
      :items="pagedLicenses">
      <template #marker="{item:license}">
        <GMapLicenseMarker :license="license" :model="model" />
      </template>
      <template #info="{data:license}">
        <LicenseItemView :model="model" :license="license" @edit="() => editLicense = license" />
      </template>
      <template v-if="selectedLicense" #left-bar>
        <LicenseDetailView :license="selectedLicense" :model="model" @close="closeDetail" @edit="editLicense = selectedLicense">
          <template #buttons>
            <div class="text-muted small mx-1 text-nowrap">
              {{ selectedLicenseIndex + 1 }} of {{ licenses.length }}
            </div>
            <IconButton icon="chevron-up" class="flex-shrink-0" @click="() => nextLicense(true)" />
            <IconButton icon="chevron-down" class="flex-shrink-0" @click="() => nextLicense(false)" />
          </template>
        </LicenseDetailView>
      </template>
    </GMap>
    <div ref="toast" class="toast bg-primary text-white position-fixed" style="bottom:10px;left:10px;z-index:2">
      <div class="toast-header bg-primary text-white">
        <strong class="me-auto" v-if="closeMessage && closeMessage.title">{{ closeMessage.title }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        {{ (closeMessage && closeMessage.body) || closeMessage }}
      </div>
    </div>
  </AppLayout>
</template>
<script>
import AppLayout from "@/components/common/AppLayout";
import TopBar from "@/components/common/TopBar";
import {AppModel} from "@/models/AppModel";
import LicenseList from "@/components/LicenseList";
import GMap from "@/components/common/GMap";
import BCollapse from "@/components/common/BCollapse";
import LicenseItemView from "@/components/LicenseItemView.vue";
import EditLicenseModal from "@/components/modals/EditLicenseModal.vue";
import {compareLicensesByStatus, compareLicensesByAddress, License, LicenseFilter} from "@/models/License";
import {generateCsvBlob} from "@/csv";

import LicenseDetailView from "@/components/LicenseDetailView.vue";
import {Toast} from "bootstrap";
import IconButton from "@/components/common/IconButton.vue";
import GMapLicenseMarker from "@/components/common/GMapLicenseMarker.vue";
import LoadingMessage from "@/components/common/LoadingMessage.vue";
import ImportLicensesModal from "@/components/modals/ImportLicensesModal.vue";
import FilterableList from "@/components/common/FilterableList.vue";
import LicenseFilterEditor from "@/components/licenses/LicenseFilterEditor.vue";

export default {
  components: {
    LicenseFilterEditor,
    FilterableList,
    ImportLicensesModal,
    LoadingMessage,
    GMapLicenseMarker,
    IconButton,
    LicenseDetailView,
    EditLicenseModal,
    LicenseItemView,
    BCollapse,
    GMap,
    LicenseList,
    TopBar,
    AppLayout
  },
  // TODO Find out how to make the following extensible from a base component
  props: {
    cityId: String,
    selectedLicenseId: String,
    onlyShowPending: Boolean,
    onlyShowMissingPropertyId: Boolean,
    onlyShowActive: Boolean
  },
  data() {
    return {
      model: new AppModel(this.cityId),
      // Custom data props for this component:
      editLicense: null,
      showImportLicenseButton: false,
      showImportLicenseModal: false,
      tour: null,
      query: '',
      filtering: false,
      licenses: [],
      page: 0,
      exportUrl: null,
      filter: null,
      draftFilter: null,
      closeMessage: null,
      selectedLicenseIndex: 0
    };
  },
  watch: {
    cityId() {
      this.model.unload();
      this.model = new AppModel(this.cityId);
      this.model.load();
    },
    query() {
      this.filterLicenses();
    },
    'model.cityInfo'() {
      this.filter = new LicenseFilter(this.model.cityInfo, null);

      // Determine whether license import is supported
      this.model.cityInfo.canImportLicenses()
      .then(canImportLicenses => this.showImportLicenseButton = canImportLicenses);
    },
    'model.licenses.updateCount'() {
      this.filterLicenses();
    },
    onlyShowPending: {
      immediate: true,
      handler(show) {
        this.filter?.reset(show ? 'pending' : null);
      }
    },
    onlyShowActive: {
      immediate: true,
      handler(show) {
        this.filter?.reset(show ? 'active' : null);
      }
    },
    onlyShowMissingPropertyId: {
      immediate: true,
      handler(show) {
        if (this.filter) {
          this.filter.onlyShowMissingProperties = show;
        }
      }
    },
    filter() {
      this.draftFilter = this.filter.copy();
    },
    selectedLicenseId: {
      immediate: true,
      handler() {
        this.model.selectLicense(this.selectedLicenseId);
        this.filterLicenses();
      }
    },
    selectedLicense() {
      this.selectedListing = null;
      let licenseId = this.model.selectedLicenseId;
      if (licenseId) {
        this.$router.push({
          name: 'license-detail',
          params: {
            selectedLicenseId: licenseId
          }
        });
      } else {
        this.$router.push({name:'licenses'});
      }
      this.updateSelectedLicenseIndex();
    },
    licenses() {
      this.clearExportUrl();
      const blob = generateCsvBlob(',',
        ['License Number', 'Date Issued', 'Status', 'Parcel ID', 'Street Number', 'Street Name', 'Unit', 'Address', 'Owner', 'Email', 'Phone'],
        this.licenses.map(license => {
          return [
            license.number + ' ',
            this.formatDate(license.dateIssued, ' '),
            license.status,
            license.propertyId,
            license.addressStreetNumber || '-',
            license.addressStreetName || '-',
            license.addressUnit || '',
            license.addressFormatted,
            (license.contact.owners || []).join(', '),
            license.contact.email,
            license.contact.phone
          ];
        })
      );
      this.exportUrl = URL.createObjectURL(blob);
      this.updateSelectedLicenseIndex();
    },
    closeMessage() {
      let toast = Toast.getOrCreateInstance(this.$refs.toast);
      if (this.closeMessage) {
        toast.show();
      } else {
        toast.hide();
      }
    }
  },
  mounted() {
    this.model.load();
  },
  unmounted() {
    this.clearExportUrl();
  },
  // End extensible piece here
  computed: {
    statuses() {
      return this.model.cityInfo?.licenseStatuses || [];
    },
    buttons() {
      if (this.model.isAuthenticated) {
        return [
          {
            id: 'add',
            text: 'Add License',
            icon: 'plus-lg',
            click: () => this.addLicense(),
            variant: 'primary',
            showOnMobile: true
          },
          !this.showImportLicenseButton ? null : {
            id: 'import',
            icon: 'upload',
            tooltip: 'Import licenses by uploading a spreadsheet',
            click: () => this.showImportLicenseModal = true,
          },
          !this.exportUrl ? null : {
            id: 'export',
            href: this.exportUrl,
            filename: `${this.cityId}_licenses.csv`,
            tooltip: 'Export licenses to CSV file',
            icon: 'download',
            showOnMobile: true
          }
        ].filter(button => !!button);
      } else {
        return [];
      }
    },
    selectedLicense() {
      return this.model.licenses.getById(this.model.selectedLicenseId);
    },
    isShowingAll() {
      return this.model.licenses.items.length === this.licenses.length;
    },
    pageSize() {
      return 100;
    },
    actualPage() {
      const maxPage = Math.floor(this.licenses.length / this.pageSize);
      return Math.min(this.page, maxPage);
    },
    pageStart() {
      return this.actualPage * this.pageSize;
    },
    pageEnd() {
      return Math.min(this.licenses.length, this.pageStart + this.pageSize);
    },
    hasPages() {
      return this.licenses.length > this.pageSize;
    },
    pagedLicenses() {
      return this.licenses.slice(this.pageStart, this.pageEnd);
    }
  },
  methods: {
    applyFilterChanges() {
      this.filter = this.draftFilter;
      this.filterLicenses();
    },
    cancelFilterChanges() {
      this.draftFilter = this.filter.copy();
    },
    nextLicense(isBackward) {
      let index = this.selectedLicenseIndex + (isBackward ? -1 : 1);
      if (index < 0) {
        index = this.licenses.length + index;
      }
      index = index % this.licenses.length;
      const license = this.licenses[index];
      this.selectLicense(license ? license.id : null);
    },
    updateSelectedLicenseIndex() {
      if (this.selectedLicense) {
        const selected = this.licenses.indexOf(this.selectedLicense);
        if (selected >= 0) {
          this.selectedLicenseIndex = selected;
        }
      }
    },
    closeDetail(message) {
      this.selectLicense(null);
      if (message && message.title && message.body) {
        this.closeMessage = message;
      }
    },
    clearExportUrl() {
      URL.revokeObjectURL(this.exportUrl);
    },
    addLicense() {
      this.editLicense = new License(this.model.cityInfo);
    },
    selectLicense(id) {
      this.model.selectLicense(id);
    },
    filterLicenses() {
      this.filtering = true;
      this.licenses = [];

      setTimeout(() => {
        this.model.licenses.search(this.query)
        .then(results => {
          let statusCounts = {};
          results = results.filter(({item:license}) => {
            statusCounts[license.status] = (statusCounts[license.status] || 0) + 1;
            return this.filter?.filter(license) || false;
          });
          console.log(`Status counts: ${JSON.stringify(statusCounts)}`);
          return results;
        })
        .then(results => {
          const useMatchCount = this.query.trim();
          const compareLicenses = this.filter?.sortByStatus ? compareLicensesByStatus : compareLicensesByAddress;
          this.licenses = results.sort((r1, r2) => {
            if (useMatchCount && r1.matchCount !== r2.matchCount) {
              return r2.matchCount - r1.matchCount;
            } else {
              return compareLicenses(r1.item, r2.item);
            }
          }).map(result => result.item);
        })
        .finally(() => this.filtering = false);
      }, 250);
    }
  }
}
</script>
