<template>
  <div>
    <div class="d-flex flex-row">
      <img v-if="imageUrl" :src="imageUrl" :class="`me-${small ? 1 : 3} rounded`" :width="imageSize" :height="imageSize" style="object-fit:cover" alt="" />
      <div class="d-flex flex-column justify-content-around">
        <h6 v-if="title" class="mb-0">{{ title }}</h6>
        <a :href="url" target="_blank" class="text-decoration-none text-dark fw-bold small">
          {{ url }} <i class="bi-box-arrow-up-right"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import {City} from "@/models/City";

export default {
  props: {
    cityId: String,
    url: String,
    small: Boolean
  },
  data() {
    return {
      listing: null
    };
  },
  mounted() {
    this.refresh();
  },
  watch: {
    url() {
      this.refresh();
    }
  },
  methods: {
    async refresh() {
      let id = null;
      if (this.airbnbId) {
        id = `airbnb_${this.airbnbId}`;
      } else if (this.vrboId) {
        id = `vrbo_${this.vrboId}`;
      } else if (this.flipKeyId) {
        // TODO
      }
      if (id) {
        let city = await City.loadCity(this.cityId);
        this.listing = await Listing.one(city, id);
      } else {
        this.listing = null;
      }
    }
  },
  computed: {
    imageSize() {
      return this.small ? 48 : 64;
    },
    title() {
      return ((this.listing || {}).listing || {}).title;
    },
    imageUrl() {
      const images = ((this.listing || {}).listing || {}).images || [];
      return images[0];
    },
    airbnbId() {
      return (this.url.match(/https:\/\/.*\.airbnb\.com\/rooms\/(\d+).*/) || [])[1];
    },
    vrboId() {
      return (this.url.match(/https:\/\/.*\.vrbo\.com\/([\da-zA-Z]+).*/) || [])[1];
    },
    flipKeyId() {
      return (this.url.match(/https:\/\/.*\.flipkey\.com\/properties\/(\d+).*/) || [])[1];
    }
  }
}
</script>
