<template>
  <input type="tel" v-model="value" v-bind="$attrs" />
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: () => ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        let digits = (this.modelValue || '').replace(/[^\d]/g, '');
        return digits.replace(/^(\d{6})/, '$1-').replace(/^(\d{3})/, '($1) ');
      },
      set(value) {
        let digits = value.replace(/[^\d]/g, '');
        this.$emit('update:modelValue', digits);
      }
    }
  }
}
</script>
