<template>
  <div>
    <div v-if="title" class="d-flex flex-row align-items-center mb-1">
      <div class="fw-semibold flex-grow-1">{{ title }}</div>
      <slot name="header" />
    </div>
    <div v-if="!rows || rows.length === 0" class="border p-3 text-center">
      Nothing to display
    </div>
    <table v-else class="table table-bordered">
      <tbody>
      <tr v-for="cell in rows" :key="`row_${cell.label}`">
        <td>
          {{ cell.label }}
        </td>
        <td>
          <template v-if="cell.type === 'custom'">
            <slot name="cell" :cell="cell" />
          </template>
          <template v-else-if="cell.type === 'map'">
            <StaticMapImage
              :latlng="cell.value"
              show-marker
              :zoom="15"
              class="rounded" />
          </template>
          <template v-else-if="cell.type === 'images'">
            <div v-if="cell.value.length === 0">
              None found
            </div>
            <div v-else>
              <img
                v-for="imageUrl in cell.value"
                :key="imageUrl"
                :src="imageUrl"
                class="m-1 rounded"
                style="max-width: 120px; object-fit: cover"
              />
            </div>
          </template>
          <template v-else-if="cell.value instanceof Array">
            <template v-for="(item, i) in cell.value" :key="`item_${cell.label}_${i}`">
              <br v-if="i > 0" />
              {{ item }}
            </template>
          </template>
          <template v-else>
            {{ cell.value }}
          </template>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import StaticMapImage from "@/components/common/StaticMapImage.vue";

export default {
  components: {StaticMapImage},
  props: {
    title: String,
    rows: Array
  }
}
</script>
