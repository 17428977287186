<template>
  <div class="rounded border">
    <div class="p-4 d-flex flex-column gap-0">
      <div class="d-flex flex-row align-items-center gap-3">
        <div class="mb-0 h5 flex-grow-1">
          {{ title }}
        </div>
        <slot name="buttons"/>
        <icon-button :icon="expanded ? 'chevron-up' : 'chevron-down'" @click="expanded = !expanded"/>
      </div>
      <BCollapse :show="expanded">
        <div class="d-flex flex-column gap-3">
          <div class="form-text fw-normal">
            <slot name="description"/>
            {{ description }}
          </div>
          <slot/>
        </div>
      </BCollapse>
    </div>
    <BCollapse v-if="$slots.flush" :show="expanded">
      <slot name="flush"/>
    </BCollapse>
    <div v-if="$slots.footer" class="border-top px-4 py-3 bg-light d-flex flex-row justify-content-end align-items-center">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import BCollapse from "@/components/common/BCollapse.vue";
import IconButton from "@/components/common/IconButton.vue";

export default {
  components: {IconButton, BCollapse},
  props: {
    title: String,
    description: String
  },
  data() {
    return {
      expanded: true
    };
  }
}
</script>
