<template>
  <div ref="modal" :class="`modal fade ${size ? `modal-${size}` : ''}`">
    <div :class="`modal-dialog ${scrollable ? 'modal-dialog-scrollable' : ''} ${fullscreen ? 'modal-fullscreen' : ''} ${size ? `modal-fullscreen-${size}-down` : ''}`">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">{{ title }}</h6>
          <button type="button" class="btn-close btn-flat" data-bs-dismiss="modal"/>
        </div>
        <div class="modal-body">
          <slot />
        </div>
        <div v-if="!hideFooter" class="modal-footer d-flex flex-row align-items-center">
          <slot name="buttons"/>
          <div class="flex-grow-1">
            <div v-if="busy" class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <button v-if="!hideCancel" :disabled="busy" class="btn btn-flat me-2" data-bs-dismiss="modal">
            {{ cancelTitle }}
          </button>
          <button v-if="!hideOk" :disabled="busy || disableOk" :class="`btn btn-${okVariant}`" @click="ok">
            {{ okTitle }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
export default {
  emits: ['update:modelValue', 'ok'],
  props: {
    modelValue: Boolean,
    title: String,
    size: String,
    fullscreen: Boolean,
    scrollable: Boolean,
    busy: Boolean,
    okTitle: {
      type: String,
      default: () => 'OK'
    },
    cancelTitle: {
      type: String,
      default: () => 'Cancel'
    },
    okVariant: {
      type: String,
      default: () => 'primary'
    },
    hideOk: Boolean,
    disableOk: Boolean,
    hideCancel: Boolean,
    hideFooter: Boolean
  },
  mounted() {
    this.showIfNeeded();
  },
  beforeUnmount() {
    let modal = Modal.getInstance(this.$refs.modal);
    if (modal) {
      modal.dispose();
    }
  },
  watch: {
    modelValue() {
      this.showIfNeeded();
    }
  },
  methods: {
    showIfNeeded() {
      let modal = Modal.getInstance(this.$refs.modal);
      if (!modal) {
        modal = new Modal(this.$refs.modal);
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
          this.$emit('update:modelValue', false);
        });
        this.$refs.modal.addEventListener('shown.bs.modal', () => {
          this.$emit('update:modelValue', true);
        });
      }
      if (this.modelValue) {
        modal.show();
      } else {
        modal.hide();
      }
    },
    ok() {
      this.$emit('ok');
    }
  }
}
</script>
