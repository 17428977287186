<template>
  <div class="list-group list-group-flush">

    <div class="h5 mb-0 px-3 pt-2">{{ listing.listingTitle }}</div>

    <!-- Loading Listing Info -->
    <div v-if="listing.fetchingDetails" class="px-3 d-flex flex-rw align-items-center">
      <SpinnerView class="me-2" small /> Loading listing details
    </div>

    <!-- Host -->
    <div v-if="listing.listingHost" class="px-3 py-1">
      Hosted by {{ listing.listingHost }}
    </div>

    <!-- Source URL -->
    <div class="px-3 small">
      <a :href="listing.url" target="_blank" class="text-muted">
        <ListingSourceIcon :listing="listing" class="me-1"/>
        {{ listing.url.replace(/^https?:\/\//, '').replace(/\?.*/, '') }}
      </a>
    </div>

    <!-- Images -->
    <div v-if="listing.images.length > 0" class="list-group-item border-0">
      <div class="position-relative w-100">
        <img v-if="primaryImage" :src="primaryImage" class="w-100 rounded" style="min-height:40px" alt=""/>
        <div class="d-flex flex-row justify-content-end position-absolute bg-white bg-opacity-75 rounded" style="right:4px;bottom:4px;">
          <button type="button" @click="expandImages = !expandImages" class="btn btn-flat btn-sm">
            <template v-if="expandImages">
              <i class="bi-x me-1"/> Hide images
            </template>
            <template v-else>
              <i class="bi-grid-3x3-gap-fill me-1"/> Show all {{ formatCount(listing.images.length, 'image') }}
            </template>
          </button>
        </div>
      </div>
      <BCollapse :show="expandImages || !listing.primaryImage">
        <div class="d-flex flex-row flex-wrap align-items-stretch">
          <a
            href="#"
            v-for="(image, index) in listing.images"
            :key="image"
            class="w-25 d-block p-1 btn-flat position-relative overflow-hidden"
            @click.prevent="selectImage(index)"
          >
            <img :src="image" class="w-100 h-100 rounded" style="object-fit:cover" alt="" />
            <button
              type="button"
              :class="['btn-corner mt-1 me-1', {active:index === listing.primaryImageIndex}]"
              @click.stop.prevent="selectImage(index, true)">
              <i class="bi-check" />
            </button>
          </a>
        </div>
      </BCollapse>
    </div>

    <!-- Description -->
    <div v-if="listingDescription.length > 0" class="px-3 mb-3">
      <div v-for="(line, index) in listingDescription" :key="index" :class="`mt-3 ${line.bold ? 'fw-bold' : ''}`">
        {{ line.text }}
      </div>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import ListingSourceIcon from "@/components/common/ListingSourceIcon.vue";
import BCollapse from "@/components/common/BCollapse.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {AppModel} from "@/models/AppModel";

export default {
  components: {SpinnerView, BCollapse, ListingSourceIcon},
  props: {
    listing: Listing,
    model: AppModel
  },
  data() {
    return {
      expandImages: false,
      selectedImageIndex: null
    };
  },
  computed: {
    primaryImage() {
      let image = this.selectedImageIndex == null ? null : this.listing.images[this.selectedImageIndex];
      return image || this.listing.primaryImage;
    },
    listingDescription() {
      let d = this.listing.listingDescription;
      if (!d) {
        return [];
      }
      d = d.trim().replace(/show more$/i, '');
      d = d.replace(/(\d)\.(\d)/g, '$1#####$2');
      d = d.replace(/\.([^ ])/g, '.\n$1');
      d = d.replace(/#####/g, '.')
      d = d.replace(/<br *\/?>/g, '\n');
      return d.split(/\n/g).map(line => {
        const bold = line.match(/<b>.*<\/b>/);
        return {
          text: line.replace(/<\/?b>/g, ''),
          bold
        };
      });
    }
  },
  methods: {
    selectImage(index, setAsPrimary) {
      this.selectedImageIndex = index;
      if (setAsPrimary) {
        this.listing.updatePrimaryImageIndex(index);
        this.expandImages = false;
      }
    }
  }
}
</script>

<style>
.btn-corner {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: var(--bs-dark);
  padding: 0.125rem 0.25rem;
  border-radius: 0 5px 0 5px;
}
.btn-corner:hover, .btn-corner:focus, .btn-corner:active {
  background-color: rgba(255, 255, 255, 1);
}
.btn-corner.active {
  background-color: var(--bs-primary);
  color: var(--bs-white);
}
</style>
