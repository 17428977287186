<template>
  <div
    :class="klass"
    :style="style">
    <div v-if="!city" class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <template v-else>
      <img
        v-if="city.branding && city.branding.iconUrl"
        :height="iconSize" :width="iconSize"
        :src="city.branding.iconUrl"
        :style="iconStyle"
        class="rounded-circle"/>
      <div class="d-flex flex-column align-items-start ms-2">
        <div class="fw-semibold mb-0">
          <span :class="responsive ? 'd-none d-md-inline-block' : ''">{{ includeState ? city.label : city.name }}</span> <slot v-if="inline"/>
        </div>
        <slot v-if="!inline" />
        <div v-if="caption" class="fw-light small">{{ caption }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import {City} from "@/models/City";

export default {
  props: {
    city: City,
    invertColors: Boolean,
    invertIconColors: Boolean,
    iconSize: {
      type: Number,
      default: () => 24
    },
    inline: Boolean,
    vertical: Boolean,
    showBorder: Boolean,
    responsive: Boolean,
    center: Boolean,
    includeState: Boolean,
    caption: String
  },
  computed: {
    fg() {
      if (!this.city) {
        return '#ffffff';
      }
      return '#' + ((this.city.branding && this.city.branding.foregroundColor) || 'ffffff');
    },
    bg() {
      if (!this.city) {
        return '#333333';
      }
      return '#' + ((this.city.branding && this.city.branding.backgroundColor) || '333333');
    },
    klass() {
      if (this.vertical) {
        return 'd-flex flex-column align-items-center fw-bold';
      } else {
        return `d-flex flex-row align-items-center justify-content-${this.center ? 'center' : 'start'} fw-bold`;
      }
    },
    style() {
      let style = {
        color: this.invertColors ? 'inherit' : this.fg,
        backgroundColor: this.invertColors ? 'transparent' : this.bg
      };
      if (this.invertColors) {
        // style.border = '0px solid transparent !important';
      }
      return style;
    },
    iconStyle() {
      if (this.invertColors || this.invertIconColors) {
        return {
          border: `2px solid ${this.bg}`,
          backgroundColor: this.fg
        };
      } else {
        return {
          backgroundColor: this.bg,
          padding: '2px',
          border: `1px solid ${this.fg}`
        };
      }
    }
  }
}
</script>
