<template>
  <div class="modal modal-xl" ref="tourModal">
    <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-body d-flex flex-column align-items-center">
          <div class="m-4 text-center">
            <div class="d-flex flex-column align-items-center my-4">
              <img alt="STR Enforcement logo" src="../assets/logo.png" height="86" class="mb-4"/>
              <h6 class="text-uppercase text-primary fw-bolder mb-4">Short-term rentals portal</h6>
            </div>
            <h5 class="mb-3 fw-normal">
              Let's start with a brief overview of what municipal staff can do in the STR portal.
            </h5>
          </div>

          <div class="w-100 border-top my-4"></div>

          <div class="container mt-2">
            <MarketingTile
              class="mb-4 py-4"
              title="Licenses"
              subtitle="Manage your short-term rental licenses in one place"
              :route="{name:'licenses'}"
              route-text="Manage licenses here"
              @routeclick="modal.hide()"
            >
              <template #image>
                <img src="../assets/screenshot_licenses.png" class="rounded w-100 shadow"/>
              </template>
              <p class="mb-2">
                The STR portal provides an easy way to add and maintain short-term rental licenses. In the Licenses
                section, municipal staff can:
              </p>
              <ul class="ps-4 mb-4">
                <li class="mb-1">
                  Add new licenses and maintain address and owner contact information to ensure licenses are
                  up-to-date and accurate.
                </li>
                <li class="mb-2">
                  Easily contact a property owner by email or phone with a single click.
                </li>
                <li class="mb-2">
                  Export the license database as a spreadsheet for external processing and presentation.
                </li>
                <li class="mb-2">
                  Quickly look up licenses by address, owner name, or license number.
                </li>
              </ul>
            </MarketingTile>

            <MarketingTile
              class="mb-4 py-4"
              title="Needs Attention"
              subtitle="Monitor active listings on popular short-term rental sites"
              :route="{name:'listings'}"
              route-text="Monitor active listings here"
              @routeclick="modal.hide()"
              image-first
              >
              <template #image>
                <img src="../assets/screenshot_listings.png" class="rounded w-100 shadow"/>
              </template>
              <p class="mb-2">
                The STR portal checks popular short-term rental sites daily in order to assist municipal staff in
                identifying potential violations. When a new listing is added:
              </p>
              <ol class="ps-4 mb-4">
                <li class="mb-2">
                  In the Needs Attention section, the portal automatically suggests a likely address based on the
                  listing's geographical location.
                </li>
                <li class="mb-2">
                  Staff members then verify the addresses with one click or manually override the
                  suggested address.
                </li>
                <li class="mb-2">
                  Once a listing's address is confirmed, it will then be cross-checked
                  against the license database and moved to the Violations section if necessary.
                </li>
              </ol>
            </MarketingTile>

            <MarketingTile
              class="mb-4 py-4"
              title="Violations"
              subtitle="Enforce violations by detecting unlicensed listings and contacting property owners"
              :route="{name:'violations'}"
              route-text="Check violations here"
              @routeclick="modal.hide()"
              >
              <template #image>
                <img src="../assets/screenshot_violations.png" class="rounded w-100 shadow"/>
              </template>
              <p class="mb-2">
                Violations are automatically flagged when an active listing's address doesn't match any licenses. In the
                Violations section, municipal staff can:
              </p>
              <ul class="ps-4 mb-4">
                <li class="mb-2">
                  Correct any errors with an active listing's address.
                </li>
                <li class="mb-2">
                  Print a violation notice with a single click.
                </li>
                <li class="mb-2">
                  Export all current violations as a spreadsheet for external processing and presentation.
                </li>
              </ul>
            </MarketingTile>
          </div>
        </div>

        <div class="modal-footer">
          <div class="d-flex flex-row w-100 justify-content-between align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="dontShowAgain"/>
              <div class="form-check-label">Don't show me this again</div>
            </div>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Got it</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from "bootstrap";
import MarketingTile from "@/components/common/MarketingTile.vue";
export default {
  components: {MarketingTile},
  data() {
    return {
      storageHandler: () => { this.showModal() },
      dontShowAgain: false,
      modal: null
    };
  },
  computed: {
    // title: 'Licenses',
    // subtitle: 'Manage your town\'s short-term rental licenses',
    // icon: 'file-earmark-check',
    // variant: 'success',
    // route: 'licenses',
    // image: '../assets/sreenshot_licenses.png',
    // text: `
    //       The STR portal provides an easy way to add and maintain license information, such as the owner's contact
    //       information, the address, and date issued. If an owner needs to be contacted their email and phone number
    //       are one click away.`

    sections() {
      return [{
        title: 'Manage STR Licenses',
        icon: 'file-earmark-check',
        variant: 'success',
        route: 'licenses',
        text: 'The STR portal provides a database for creating licenses, and for managing those licenses\' status and ' +
          'owner information.'
      },{
        title: 'Monitor Active Listings',
        icon: 'question-circle',
        variant: 'warning',
        route: 'listings',
        text: 'When a new listing is added to an STR service - like Airbnb and Vrbo - the STR portal will automatically ' +
          'flag it as a potential violation if a matching license can\'t be found.'
      },{
        title: 'Enforce Violations',
        icon: 'exclamation-octagon',
        variant: 'danger',
        route: 'violations',
        text: 'Once an unlicensed STR property has been identified, you may use the STR portal to generate a letter to ' +
          'notify the owner. This letter may be a warning or a notice of fees owed to the municipality.'
      },{
        title: 'Respond to Complaints',
        icon: 'emoji-angry',
        variant: 'primary',
        text: '(Coming soon) The STR portal will soon support the ability to log, respond to, and resolve public ' +
          'complaints about STR properties, both licensed and unlicensed.'
      }]
    }
  },
  methods: {
    showModal() {
      if (!this.LocalStorage.get('tourComplete')) {
        if (!this.modal) {
          this.modal = new Modal(this.$refs.tourModal);
          this.$refs.tourModal.addEventListener('hidden.bs.modal', () => {
            if (this.dontShowAgain) {
              this.LocalStorage.set('tourComplete', true);
            }
          });
        }
        this.modal.show();
      }
    },
    navToSection(route) {
      if (route) {
        this.modal.hide();
        this.$router.push({name:route});
      }
    }
  },
  mounted() {
    if (!this.complete && !window.showedStaffPortal) {
      this.showModal();
      window.showedStaffPortal = true;
    }
    this.LocalStorage.on('tourComplete', this.storageHandler);
  },
  beforeUnmount() {
    this.LocalStorage.off('tourComplete', this.storageHandler);
  }
}
</script>

<style scoped>
.clickable {
  cursor: pointer;
  border-radius: 8px;
  background-color: #fff;
}
.clickable:hover {
  filter: drop-shadow(0px 1px 3px #0006);
}
</style>
