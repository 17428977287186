<template>
  <div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row align-items-center gap-3">
      <label class="text-nowrap">Search by</label>
      <select class="form-select" v-model="mode">
        <option value="address">Address</option>
        <option value="owner">Owner</option>
        <option value="propertyId">Parcel #</option>
        <option value="licenseId">License #</option>
      </select>
    </div>
    <input
      type="text"
      :placeholder="placeholder"
      class="form-control"
      v-model="query"
      @keydown.enter="doSearch"
    />
    <button class="btn btn-outline-primary" :disabled="!query" type="button" @click="doSearch">Search</button>
    <BCollapse :show="lookup">
      <SpinnerView small class="me-1"/>Looking up address coordinates...
    </BCollapse>
    <div v-if="searching" class="mt-3">
      <SpinnerView small class="me-1"/>Searching the database. This may take a moment.
    </div>
    <div v-else class="list-group">
      <a
        v-for="record in records"
        :key="record.id"
        href="#"
        target="_blank"
        class="list-group-item list-group-item-action d-flex flex-row align-items-center"
        @click.prevent="selectRecord(record)"
      >
        <div class="flex-grow-1">
          <div class="fw-bold">{{ record.addressFormatted }}</div>
          <div>{{ record.owners.join(', ') }}</div>
        </div>
        <a class="btn btn-flat btn-sm" :href="record.url" target="_blank" @click.stop>
          <i class="bi-box-arrow-up-right"/>
        </a>
      </a>
    </div>
  </div>
</template>

<script>
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";
import BCollapse from "@/components/common/BCollapse.vue";

export default {
  components: {BCollapse, SpinnerView},
  emits: ['select'],
  props: {
    city: City
  },
  data() {
    return {
      mode: 'owner',
      query: '',
      searching: false,
      lookup: false,
      records: []
    };
  },
  methods: {
    doSearch() {
      this.searching = true;
      this.city.searchProperties(this.query, this.mode)
      // .then(properties => properties.map(Property.dataConverter(this.city, 'properties')))
      .then(records => {
        this.$emit('select', records.filter(record => {
          if (!record) {
            return false;
          } else if (!record.addressFormatted) {
            return false;
          }
          return true;
        }));
      })
      .catch(error => {
        console.warn(`Could not search properties`, error);
      })
      .finally(() => this.searching = false);
    },
    selectRecord(record) {
      this.lookup = true;
      this.city.normalizeAddress(record.address)
      .then(address => {
        this.$emit('select', address);
      })
      .finally(() => this.lookup = false);
    }
  },
  computed: {
    placeholder() {
      switch (this.mode) {
        case 'address': return 'e.g. 4 Main St';
        case 'owner': return 'e.g. Jane Smith';
        case 'propertyId': return 'e.g. 351-1-24';
        case 'licenseId': return 'e.g. 891';
        default: return '';
      }
    }
  }
}
</script>
