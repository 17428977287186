<template>
  <div class="d-flex flex-column align-items-stretch">

    <!-- Top Content Row -->
    <div class="d-flex flex-row align-items-stretch">

      <!-- Top-Left Title/Address/Status Labels -->
      <div class="flex-grow-1">
        <template v-if="value.isPropertyVerified">
          <div class="fw-bold mb-1" v-if="!hideAddress">
            {{ verifiedAddressFormatted }}
          </div>
          <div v-if="showLicense" class="d-flex flex-row align-items-center small mb-1">
            <i :class="`me-1 bi-${license && license.isActive ? 'check-circle text-success' : 'exclamation-circle text-danger'}`"
               style="width:14px"/>
            <span :class="`px-1 ${license && license.isActive ? 'text-success' : 'text-danger'} btn-flat`">
              {{ license ? `License ${license.number ? `#${license.number} ` : ''}${license.status}` : 'Not licensed' }}
            </span>
          </div>
          <ListingLink :listing="value" label-only />
        </template>
        <template v-else>
          <div class="d-flex flex-row align-items-start">
            <ListingSourceIcon :listing="value" class="mt-1 me-2"/>
            <div class="d-flex flex-column align-items-start">
              <div class="fw-semibold">
                {{ value.listingTitle }}
              </div>
              <div v-if="value.listingHost" class="small">Hosted by {{ value.listingHost}}</div>
              <div class="d-flex flex-row gap-2 align-items-center" v-if="value.dateFirstFound">
                <div v-if="!value.isSeen" class="rounded-pill bg-primary text-white small px-2">
                  New
                </div>
                <div :class="`text-${value.isSeen ? 'muted' : 'primary'} small`">
                  Found {{ formatDate(value.dateFirstFound) }}
                </div>
              </div>
              <div class="text-muted small" v-else-if="value.dateLoadedFormatted">
                Checked {{ value.dateLoadedFormatted }}
              </div>
            </div>
          </div>
        </template>
      </div>

      <!-- Image -->
      <img :style="`${imageSizeStyle};object-fit:cover`" class="rounded ms-3" :src="primaryImage" alt="" />
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import {AppModel} from "@/models/AppModel";
import ListingLink from "@/components/common/ListingLink.vue";
import ListingSourceIcon from "@/components/common/ListingSourceIcon.vue";

export default {
  components: {
    ListingSourceIcon,
    ListingLink
  },
  props: {
    value: Listing,
    model: AppModel,
    showLicense: Boolean,
    hideAddress: Boolean,
    compact: Boolean
  },
  emits: ['click', 'input', 'street_view'],
  computed: {
    imageSizeStyle() {
      if (this.compact) {
        return 'max-width:56px;max-height:74px;';
      } else {
        return 'max-width:84px;max-height:111px;';
      }
    },
    images() {
      return this.value.images;
    },
    primaryImage() {
      return this.value.primaryImage;
    },
    verifiedAddressFormatted() {
      const property = this.value.verifiedProperty;
      if (property) {
        return property.addressFormatted;
      } else {
        return '';
      }
    },
    license() {
      return this.model.lookupLicenseForListing(this.value);
    }
  }
}
</script>
