<template>
  <SplashView hide-logo>
    <div :class="`col col-${sm} col-md-${md} col-lg-${lg}`">
      <div class="bg-white shadow d-flex flex-column align-items-stretch rounded-2 overflow-hidden">
        <CityButton :city="city" class="p-3 text-uppercase" :icon-size="36" invert-icon-colors>
          Short-term rentals portal
        </CityButton>
        <div class="p-3">
          <slot/>
        </div>
      </div>
    </div>
  </SplashView>
</template>

<script>
import {City} from "@/models/City";
import CityButton from "@/components/common/CityButton.vue";
import SplashView from "@/components/common/SplashView.vue";

export default {
  components: {
    SplashView,
    CityButton
  },
  props: {
    city: City,
    lg: {
      type: Number,
      default: () => 8
    },
    md: {
      type: Number,
      default: () => 10
    },
    sm: {
      type: Number,
      default: () => 12
    }
  }
}
</script>
