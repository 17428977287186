/**
 * Additional stats:
 * - # unique properties listed
 *    - avg. listings per property
 *    - max listings per property
 * - Re: Paul - active listings could show a pie chart distinguishing unverified / licensed / violation
 * - # licenses expiring in next month
 */

import {compareLicensesByStatus, LicenseFilter} from "@/models/License";
import {Timestamp} from "@firebase/firestore";
import moment from "moment";

function fromNow(millis) {
  return Timestamp.fromMillis(Timestamp.now().toMillis() + millis);
}

export function createStats(appModel) {
  const lastWeek = fromNow(-7 * 24 * 60 * 60 * 1000);
  const lastMonth = Timestamp.fromDate(moment().subtract(1, 'month').toDate());
  const today = moment();
  const oneMonthFromNow = moment().add(1, 'month');
  const oneMonthAgo = moment().subtract(1, 'month');

  const defaultLicenseFilter = new LicenseFilter(appModel.cityInfo);
  let stats = {
    listings: {
      counts: {},
      groups: {}
    },
    licenses: {
      counts: {},
      groups: {
        status: {},
        statusCategory: {}
      }
    }
  };

  // Set up custom license status groups
  appModel.cityInfo.licenseStatuses.forEach(status => {
    stats.licenses.groups.status[status.label] = {};
    stats.licenses.groups.statusCategory[status.type] = {};
  });

  function increment(collection, stat) {
    let c = stats[collection];
    c.counts[stat] = (c.counts[stat] || 0) + 1;
  }

  function addToGroup(collection, field, value) {
    if (value instanceof Timestamp) {
      value = moment(value.toDate()).format('YYYY-MM-DD');
    }
    let c = stats[collection];
    let g = c.groups[field] = c.groups[field] || {};
    g[value] = (g[value] || 0) + 1;
  }

  function addObjectToGroup(collection, object, fields) {
    if (!(fields instanceof Array)) {
      fields = [fields];
    }
    fields.forEach(field => {
      addToGroup(collection, field, object[field]);
    });
  }

  // { PropertyID: [ LicenseID, ] }
  let licensesByProperty = {};
  appModel.licenses.items.forEach(license => {
    const propertyId = license.propertyId;
    if (propertyId) {
      licensesByProperty[propertyId] = licensesByProperty[propertyId] || [];
      licensesByProperty[propertyId].push(license);
    }
  });

  // Aggregate licenses
  appModel.licenses.items
  .filter(license => defaultLicenseFilter.filter(license))
  .filter(license => !!license.statusWithConfig)
  .forEach(license => {
    increment('licenses', 'total');
    const status = license.statusWithConfig;
    addToGroup('licenses', 'status', status?.label || 'Invalid');
    addToGroup('licenses', 'statusCategory', status?.type || 'invalid');

    const propertyId = license.propertyId;
    if (!propertyId) {
      increment('licenses', 'missingPropertyId');
    }

    if (license.isExpired) {
      increment('licenses', 'expired');
    } else if (license.expirationDate && license.expirationDate < oneMonthFromNow.toDate()) {
      increment('licenses', 'expiringNextMonth');
    }
  });
  Object.values(licensesByProperty).forEach(licenses => {
    licenses.sort(compareLicensesByStatus);
  });

  // Aggregate listings
  appModel.listings.items.forEach(listing => {
    increment('listings', 'total');
    addObjectToGroup('listings', listing, ['dateFirstFound', 'source']);
    addToGroup('listings', 'source', listing.source);

    if (listing.isWhitelisted) {
      increment('listings', 'whitelisted');
      const expiration = listing.whitelistExpiration;
      if (expiration) {
        addObjectToGroup('listings', listing, 'whitelistExpiration');
        if (expiration.isBefore(today) && expiration.isAfter(oneMonthAgo)) {
          increment('listings', 'whitelistedExpiredLastMonth');
        } else if (expiration.isAfter(today) && expiration.isBefore(oneMonthFromNow)) {
          increment('listings', 'whitelistedExpiringNextMonth');
        }
      } else {
        increment('listings', 'whitelistedWithoutExpiration');
      }
    }

    if (listing.dateFirstFound >= lastWeek) {
      increment('listings', 'foundLastWeek');
    }
    if (listing.dateFirstFound >= lastMonth) {
      increment('listings', 'foundLastMonth');
    }

    if (!listing.verifiedPropertyId) {
      let whitelistExpiration = listing.whitelistExpiration;
      if (!listing.isWhitelisted) {
        increment('listings', 'unverified');
        if (whitelistExpiration && whitelistExpiration.isAfter(oneMonthAgo)) {
          increment('listings', 'whitelistedUnverifiedExpiredLastMonth');
        }
        if (!listing.detailsImported) {
          increment('listings', 'unverifiedPartiallyImported');
        }
      } else {
        increment('listings', 'whitelistedUnverified');
        if (whitelistExpiration && whitelistExpiration.isBefore(oneMonthFromNow)) {
          increment('listings', 'whitelistedUnverifiedExpiringNextMonth');
        }
      }
    } else {
      const license = (licensesByProperty[listing.verifiedPropertyId] || [])[0];
      addObjectToGroup('listings', listing, 'verifiedPropertyId');
      if (!license || license.isInactive) {
        if (!listing.isWhitelisted) {
          console.log(`${listing.id}: ${listing.title} - ${JSON.stringify(license?.statusWithConfig)}`);
          addToGroup('listings', 'violationPropertyId', listing.verifiedPropertyId);
        } else {
          addToGroup('listings', 'whitelistedViolationPropertyId', listing.verifiedPropertyId);
        }
      }

      addToGroup('listings', 'licenseStatus', license ? license?.statusWithConfig?.label || 'Invalid' : 'None');
      addToGroup('listings', 'licenseStatusCategory', license ? license?.statusWithConfig?.type || 'invalid' : 'inactive');
    }

    if (!listing.detailsImported) {
      increment('listings', 'partiallyImported');
    }

    if (!listing.isSeen) {
      increment('listings', 'unseen');
    }
  });
  stats.listings.counts.uniquePropertyIds = Object.keys(stats.listings.groups.verifiedPropertyId || {}).length;
  stats.listings.counts.uniqueViolations = Object.keys(stats.listings.groups.violationPropertyId || {}).length;
  stats.listings.counts.uniqueWhitelistedViolations = Object.keys(stats.listings.groups.whitelistedViolationPropertyId || {}).length;

  return stats;
}
