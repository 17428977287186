<template>
  <div class="d-flex flex-row align-items-end gap-3">
    <div class="w-100">
      <label v-if="showLabels" class="form-label">Label</label>
      <input type="text" class="form-control" v-model="status.label" />
    </div>
    <div class="w-50">
      <label v-if="showLabels" class="form-label">Code</label>
      <input type="text" class="form-control" v-model="status.code" />
    </div>
    <div class="w-25">
      <label v-if="showLabels" class="form-label">Type</label>
      <select class="form-control" v-model="status.type">
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
        <option value="pending">Pending</option>
      </select>
    </div>
    <div class="w-25">
      <BRadio :option-value="status.code" v-model="workflow.defaultStatus" label="Default" class="mb-2"/>
    </div>
    <button type="button" class="btn btn-icon btn-flat flex-shrink-0" @click="removeStatus(status.id)">
      <i class="bi-trash"/>
    </button>
  </div>
</template>

<script>
import {LicenseWorkflow} from "@/models/LicenseWorkflow";
import BRadio from "@/components/common/BRadio.vue";

export default {
  components: {BRadio},
  props: {
    showLabels: Boolean,
    workflow: LicenseWorkflow,
    status: Object /* id, code, label, type */
  }
}
</script>
