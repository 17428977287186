<template>
  <div class="rounded-circle" :style="style">
    <img
      :height="size" :width="size"
      :src="city.branding.iconUrl"
      :title="city.name"
      @error="onImageLoadError"
      @load="onImageLoad"
      :class="`d-block rounded-circle ${isLoaded ? '' : 'invisible'}`"/>
  </div>
</template>

<script>
import {City} from "@/models/City";

export default {
  props: {
    city: City,
    size: {
      type: Number,
      default: () => 24
    },
    thickBorder: Boolean,
    invertColors: Boolean,
    invertIconColors: Boolean
  },
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    fg() {
      return '#' + ((this.city.branding && this.city.branding.foregroundColor) || 'ffffff');
    },
    bg() {
      return '#' + ((this.city.branding && this.city.branding.backgroundColor) || '333333');
    },
    style() {
      if (this.invertColors || this.invertIconColors) {
        return {
          border: `${this.thickBorder ? 4 : 2}px solid ${this.bg}`,
          backgroundColor: this.fg
        };
      } else {
        return {
          backgroundColor: this.bg,
          padding: `${this.thickBorder ? 4 : 2}px`,
          border: `${this.thickBorder ? 2 : 1}px solid ${this.fg}`
        };
      }
    }
  },
  methods: {
    onImageLoadError() {
      this.isLoaded = false;
    },
    onImageLoad() {
      this.isLoaded = true;
    }
  }
}
</script>
