import {PersistentCityModel} from "@/models/PersistentCityModel";
import {toTimestamp} from "@/dateUtils";
import {getDocs, where, query, collectionGroup, collection} from "firebase/firestore";
import {Address} from "@/models/Address";
import Timer from "@/models/Timer";

function distance(p1, p2) {
  if (!p2 || !p1) {
    return null;
  }
  // console.log(`Computing distance between ${p1.lat},${p1.lng} and ${p2.lat},${p2.lng}`)
  const R_EARTH = 3958.8;

  const rlat1 = p1.lat * (Math.PI/180); // Convert degrees to radians
  const rlat2 = p2.lat * (Math.PI/180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflng = (p2.lng-p1.lng) * (Math.PI/180); // Radian difference (longitudes)

  return 2 * R_EARTH * Math.asin(
    Math.sqrt(
      Math.sin(difflat/2) * Math.sin(difflat/2) +
      Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflng/2) * Math.sin(difflng/2)
    ));
}

export class Property extends PersistentCityModel {

  static async findNearby(city, {lat, lng, radius}) {
    const dLat = (radius / 110540);
    const dLng = (radius / (111320 * Math.cos(lat * Math.PI / 180)));
    const minLng = lng - dLng;
    const maxLng = lng + dLng;
    const minLat = lat - dLat;
    const maxLat = lat + dLat;

    const snapshot = await getDocs(
      query(
        city.subCollection('properties'),
        where('latlng.lat', '>=', minLat),
        where('latlng.lat', '<=', maxLat)
      )
    );
    console.log(`Found ${snapshot.docs.length} properties within latitude range`);

    let filtered = snapshot.docs
    .filter(doc => {
      // Manually filter out by longitude because queries can only have range filters on one field
      const latlng = doc.data().latlng;
      return latlng && latlng.lng > minLng && latlng.lng < maxLng;
    });
    console.log(`Found ${filtered.length} properties within latitude/longitude range`);

    const rMiles = radius / 1600;
    filtered = filtered.filter(doc => {
      const latlng = doc.data().latlng;
      const d = distance({lat,lng}, latlng);
      return d !== null && d < rMiles;
    });
    console.log(`Found ${filtered.length} properties within radius`);

    return filtered.map(doc => new Property(city, doc));
  }

  static useCollectionGroups = false;
  static async search(city, terms, field) {
    let t = new Timer();

    terms = terms.trim().toLowerCase();
    // let terms2 = terms.substr(terms.indexOf(' ')).trim();
    let propertyIds = [];
    if (this.useCollectionGroups) {
      let queryArgs = [
        collectionGroup(city.db, 'matches'),
        where('matchValue', '>=', terms),
        where('matchValue', '<', terms+'~'),
      ];
      if (field) {
        queryArgs.push(where('field', '==', field));
      }
      const q = query(...queryArgs);

      const snapshot = await getDocs(q);
      console.log(t.message(`Found ${snapshot.docs.length} property indexes matching "${terms}"`));

      propertyIds = snapshot.docs
        .filter(doc => {
          return doc.ref.path.startsWith(`/cities/${city.id}/properties_index/`);
        })
        .map(doc => doc.id);
    } else {
      const q = query(
        city.subCollection('properties_index'),
        where('matchValue', '>=', terms),
        where('matchValue', '<', terms+'~')
      );

      console.log(`Searching properties_index`, query);
      const snapshot = await getDocs(q);
      console.log(t.message(`Found ${snapshot.docs.length} property indexes matching "${terms}"`));

      const snapshots = await Promise.all(
        snapshot.docs
        .map(doc => {
          return getDocs(collection(doc.ref, 'matches'));
        })
      );

      propertyIds = [];
      snapshots.forEach(snap => {
        snap.docs.forEach(doc => {
          if (!field || field === doc.data().field) {
            propertyIds.push(doc.id);
          }
        });
      });
    }

    console.log(t.message(`Found ${propertyIds.length} properties: ${propertyIds}`));

    if (propertyIds.length === 0) {
      return [];
    }

    let batches = [];
    propertyIds.forEach(id => {
      let batch = batches[batches.length - 1];
      if (!batch || batch.length >= 30) {
        batch = [];
        batches.push(batch);
      }
      batch.push(id);
    });

    const propertyBatches = await Promise.all(batches.map(async batch => {
      return await this.snapshotConverter(city)(await getDocs(
        query(
          city.subCollection('properties'),
          where('id', 'in', batch)
        )
      ));
    }));

    let properties = [];
    propertyBatches.forEach(batch => properties.push(...batch));

    console.log(t.message(`Retrieved ${properties.length} properties`));
    return properties;
  }

  constructor(city, doc) {
    super(city, doc);
  }

  get idFieldNameForApi() {
    return 'propertyId';
  }

  get url() {
    let url = this.details.url || this.data.url;

    // Update Axis URL's
    // https://www.axisgis.com/Old_Orchard_BeachME/Docs/Batch/Vision_Property_Card/4518.pdf
    // https://api.axisgis.com/node/axisapi/document-view/Old_Orchard_BeachME?path=Docs/Batch/Vision_Property_Card/4518.pdf
    url = url?.replace(
      /www\.axisgis\.com\/(.*)\/Docs\/Batch\/Vision_Property_Card\/(.*)\.pdf/,
      'api.axisgis.com/node/axisapi/document-view/$1?path=Docs/Batch/Vision_Property_Card/$2.pdf'
    );

    return url;
  }

  get address() {
    const address = this.data.address;
    return address ? new Address(address) : null;
  }

  get addressFormatted() {
    const address = this.address;
    if (address) {
      return address.formatStreet(true);
    } else {
      return this.details.address || '';
    }
  }

  get dateUpdated() {
    return toTimestamp(this.data.dateUpdated);
  }

  get latlng() {
    return this.data.latlng;
  }

  get latLngFormatted() {
    if (this.data.latlng) {
      return `${this.data.latlng.lat}, ${this.data.latlng.lng}`;
    } else {
      return null;
    }
  }

  distanceFrom(/* { lat, lng } */ p1) {
    const p2 = this.latlng;
    if (!p2 || !p1) {
      return null;
    }
    // console.log(`Computing distance between ${p1.lat},${p1.lng} and ${p2.lat},${p2.lng}`)
    const R_EARTH = 3958.8;

    const rlat1 = p1.lat * (Math.PI/180); // Convert degrees to radians
    const rlat2 = p2.lat * (Math.PI/180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflng = (p2.lng-p1.lng) * (Math.PI/180); // Radian difference (longitudes)

    return 2 * R_EARTH * Math.asin(
      Math.sqrt(
        Math.sin(difflat/2) * Math.sin(difflat/2) +
        Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflng/2) * Math.sin(difflng/2)
      ));
  }

  get details() {
    return {
      stats: {},
      imageUrls: [],
      ...this.data.details
    };
  }

  get owners() {
    return [
      ...(this.data.owners || []),
      ...(this.details.owners || [])
    ];
  }

  get ownerAddress() {
    return this.details.ownerAddress || this.data.ownerAddress || this.addressFormatted;
  }

  get realtorDetails() {
    return this.data.realtorDetails || {};
  }

  get firstAssessorImageUrl() {
    return (this.details.imageUrls || [])[0] || (this.data.imageUrls || [])[0];
  }

  get firstRealtorImageUrl() {
    return (this.realtorDetails.imageUrls || [])[0];
  }

  get realtorUrl() {
    return this.realtorDetails.url;
  }

  get imageUrl() {
    let imageUrl = this.firstRealtorImageUrl || this.firstAssessorImageUrl || this.data.imageUrl;

    // 3/28/24 Jeodonnell upgraded their website recently and switched to S3 for images. Hardcoding the mapping here
    // until we have a chance to port the stored images over.
    imageUrl = imageUrl?.replace('https://jeodonnell.com/cama_files/', 'https://jeo-cama-files.s3.amazonaws.com/');

    // 5/1/24 OOB CAMA images have moved from
    // https://www.axisgis.com/Old_Orchard_BeachME/Docs/Batch/CAMA/00/01/35/53.jpg
    // to
    // https://api.axisgis.com/node/axisapi/document-view/Old_Orchard_BeachME?path=Docs/Batch/CAMA/00\01\35\53.jpg
    imageUrl = imageUrl?.replace(
      /www\.axisgis\.com\/(.*)\/(Docs\/.*\.jpg)/i,
      'api.axisgis.com/node/axisapi/document-view/$1?path=$2'
    );

    // 8/6/24 Change on mygovnow URL format from
    // http://www.mygovnow.com/capeto/Invision/assessing/property/images/R0800106B000_0.jpg
    // to
    // https://www.mygovnow.com/capeto/Invision/assessing/property/images/R0800106B000_0.jpg
    imageUrl = imageUrl?.replace(
      /http:\/\/www\.mygovnow\.com/,
      'https://www.mygovnow.com'
    );

    return imageUrl;
  }

  get imageCount() {
    let count = 0;
    count += (this.details.imagesUrls || []).length;
    count += (this.realtorDetails.imageUrls || []).length;
    return count;
  }

  get detailsImported() {
    return this.data.recordDetailsImported || !!this.data.details;
  }

  get hasDetails() {
    return !!this.data.details;
  }

  get dateDetailsImported() {
    return toTimestamp(this.data.dateRecordDetailsImported);
  }

  get detailsUrl() {
    return this.url;
  }

  importDetails() {
    return this.doApiCall('importPropertyDetails');
  }

  get latLngImported() {
    return this.data.latLngImported || !!this.data.latlng;
  }

  get hasLatLng() {
    return !!this.data.latlng;
  }

  get dateLatLngImported() {
    return toTimestamp(this.data.dateLatLngImported);
  }

  get latLngUrl() {
    const address = this.addressFormatted + ' ' + this.city.label;
    return `https://www.google.com/maps/place/${encodeURIComponent(address)}`;
  }

  importLatLng() {
    return this.doApiCall('importPropertyLatLng');
  }

  get addressId() {
    return (this.data.googlePlace || {}).id;
  }

  get realtorDetailsImported() {
    return this.data.realtorDetailsImported || !!this.data.realtorDetails;
  }

  get hasRealtorDetails() {
    return !!this.data.realtorDetails;
  }

  get dateRealtorDetailsImported() {
    return toTimestamp(this.data.dateRealtorDetailsImported);
  }

  importRealtorDetails() {
    return this.doApiCall('importPropertyRealtorDetails');
  }

}
