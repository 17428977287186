<template>
  <div class="w-100 d-flex flex-column justify-content-center align-items-center flex-grow-1">
    <img class="bg" src="../../assets/bg.png" />

    <div class="w-100">
      <div class="d-flex flex-column align-items-center my-4">
        <template v-if="!hideLogo">

          <img v-if="!$slots.logo" alt="STR Enforcement logo" src="../../assets/logo.png" height="86" class="mb-2"/>
          <h6 class="text-uppercase text-primary fw-bolder h5">Short-term rentals portal</h6>
        </template>
      </div>
      <div class="container container-lg">
        <div class="row d-flex align-items-stretch justify-content-center">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    hideLogo: Boolean
  }
}
</script>
