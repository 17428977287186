<template>
  <template v-if="disabled">
    <slot/>
  </template>
  <div v-else class="position-relative overflow-hidden">
    <div :class="['overflow-auto', {'hide-scrollbar': hideScrollbar}]" @scroll="onScroll" ref="scroller">
      <slot />
    </div>
    <BlurMask
      :position="direction === 'x' ? 'left' : 'top'"
      :color="color"
      :style="startStyle"
    />
    <BlurMask
      :position="direction === 'x' ? 'right' : 'bottom'"
      :color="color"
      :style="endStyle"
    />
  </div>
</template>

<script>
import BlurMask from "./BlurMask.vue";

export default {
  components: {BlurMask},
  props: {
    direction: {
      type: String,
      validator: value => ['x','y'].indexOf(value) >= 0,
      default: () => 'y'
    },
    color: String,
    disabled: Boolean,
    hideScrollbar: Boolean
  },
  data() {
    return {
      scrollPosition: 0
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.onScroll();
    });
  },
  computed: {
    startStyle() {
      return `${this.direction === 'x' ? 'width' : 'height'}: ${this.scrollPosition}px`;
    },
    endStyle() {
      const scroller = this.$refs.scroller;
      const dim = this.direction === 'x' ? 'Width' : 'Height';
      const end = scroller ? (scroller[`scroll${dim}`] - scroller[`offset${dim}`]) : -1;
      let size = end - this.scrollPosition;
      return size < 0 ? '' : `${this.direction === 'x' ? 'width' : 'height'}: ${size}px`;
    }
  },
  methods: {
    onScroll() {
      if (this.$refs.scroller) {
        this.scrollPosition = this.$refs.scroller[`scroll${this.direction === 'x' ? 'Left' : 'Top'}`];
      } else {
        console.log(`Attempted to check scroll position, but scroller isn't ready`);
      }
    },
    scrollBy(inc) {
      const size = this.$refs.scroller[`offset${this.direction === 'x' ? 'Width' : 'Height'}`];
      let scrollOptions = {behavior:'smooth'};
      scrollOptions[this.direction === 'x' ? 'left' : 'top'] = inc * size;
      this.$refs.scroller.scrollBy(scrollOptions);
    }
  }
}
</script>

<style>
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>
