<template>
  <div class="d-flex flex-column gap-1">
    <div class="d-flex flex-row align-items-center gap-1">
      <input
        type="text"
        class="form-control form-control-sm font-monospace mb-0"
        v-model="propertyId"
      />
      <spinner-view v-if="checking" small />
      <icon-button
        v-else
        icon="check"
        small
        @click="check"
        title="Validate parcel ID"
      />
    </div>
    <div v-if="property && property.id === propertyId" class="text-muted small">
      <template v-if="property.hasData">
        <i class="bi-check-circle text-success" /> {{ property.addressFormatted }}
      </template>
      <template v-else>
        <i class="bi-exclamation-circle text-danger"/> Could not find parcel ID {{ propertyId }}
      </template>
    </div>
  </div>
</template>

<script>
import {LicenseParseChange} from "@/models/LicenseParseResult";
import IconButton from "@/components/common/IconButton.vue";
import {Property} from "@/models/Property";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView, IconButton},
  props: {
    change: LicenseParseChange
  },
  data() {
    return {
      propertyId: this.change.propertyId,
      checking: false,
      property: null
    };
  },
  methods: {
    async check() {
      this.checking = true;
      try {
        this.property = await Property.one(this.change.city, this.propertyId);
        this.change.propertyId = this.propertyId;
      } finally {
        this.checking = false;
      }
    }
  }
}
</script>
