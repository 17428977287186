<template>
  <CustomMarker
    :class="`custom-marker ${isSelectedOrHighlighted ? 'highlighted' : ''} ${license.latlng ? '' : 'd-none'}`"
    :options="options"
    :style="`opacity:1;z-index:99;`"
    @click.stop="click"
    @mouseover.stop="highlight"
    @mouseout.stop="unhighlight"
  >
    <CustomMarkerIcon
      :icon-name="iconName"
      :color="color"
      :count="count"
    />
  </CustomMarker>
</template>

<script>
import {CustomMarker} from "vue3-google-map";
import CustomMarkerIcon from "@/components/common/CustomMarkerIcon.vue";
import {AppModel} from "@/models/AppModel";
import {License} from "@/models/License";

export default {
  components: {
    CustomMarker,
    CustomMarkerIcon
  },
  props: {
    license: License,
    model: AppModel
  },
  computed: {
    options() {
      return {
        title: this.license.addressFormatted,
        position: this.license.latlng,
        optimized: true
      };
    },
    isSelectedOrHighlighted() {
      return this.selected || this.model.highlightedLicenses.has(this.license.id);
    },
    selected() {
      return this.model.selectedLicenseId === this.license.id;
    },
    config() {
      const status = this.license.statusWithConfig;
      if (status) {
        switch (status.type) {
          case 'active':
            return {
              color: '33A852',
              icon: 'check-lg'
            };
          case 'inactive':
            return {
              color: '6c757d',
              icon: 'x-lg'
            };
          case 'pending':
            return {
              color: 'ea4345',
              icon: 'plus-lg'
            };
        }
      }
      return {
        color: '6c757d',
        icon: 'x-lg'
      };
    },
    iconName() {
      return this.config.icon;
    },
    color() {
      return this.config.color;
    },
    count() {
      return 0;
    }
  },
  methods: {
    click() {
      this.model.selectLicense(this.selected ? null : this.license.id);
    },
    highlight() {
      this.model.setLicenseHighlighted(this.license.id, true);
    },
    unhighlight() {
      this.model.setLicenseHighlighted(this.license.id, false);
    }
  }
}
</script>

<style>
.custom-marker {
  z-index: 1;
  transition: scale 250ms, z-index 0ms linear 250ms;
}
.custom-marker.highlighted {
  z-index: 100 !important;
  scale: 2;
  transition: scale 250ms, z-index 0ms linear 0ms;
}
</style>
