<template>
  <div :class="['w-100 h-100 file-input d-flex flex-column align-items-center justify-content-center gap-2 position-relative', {highlight}]">
    <i class="bi-cloud-upload h3 text-primary" />
    <input
      :id="inputId"
      type="file"
      class="invisible position-absolute w-100 h-100"
      @change="onFiles"
      :accept="accept"
    />
    <label class="btn btn-primary" :for="inputId">
      Browse
    </label>
    <div>or drag a file here</div>
    <div class="small text-muted" v-if="supportedFormats && supportedFormats.length > 0">
      Supported formats: {{ supportedFormats.join(', ') }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: File,
    accept: String,
    supportedFormats: Array
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputId: `file-input-${Math.round(Math.random() * 100000000000)}`,
      highlight: false,
      preventDefaults: event => {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  },
  mounted() {
    // Prevent default drag behaviors
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(event => {
      this.$el.addEventListener(event, this.preventDefaults, false);
      document.body.addEventListener(event, this.preventDefaults, false);
    });
    // Highlighting drop area when item is dragged over it
    ['dragenter', 'dragover'].forEach(event => {
      this.$el.addEventListener(event, () => this.highlight = true, false);
    });
    ['dragleave', 'drop'].forEach(event => {
      this.$el.addEventListener(event, () => this.highlight = false, false);
    });
    this.$el.addEventListener('drop', event => {
      this.onFiles(event.dataTransfer.files);
    });
  },
  beforeUnmount() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(event => {
      this.$el.removeEventListener(event, this.preventDefaults, false);
      document.body.removeEventListener(event, this.preventDefaults, false);
    });
  },
  methods: {
    onFiles(files = []) {
      this.$emit('update:modelValue', files[0]);
    }
  }
}
</script>

<style>
.file-input {
  border: 2px dashed var(--bs-border-color);
  border-radius: 4px;
  min-height: 300px;
}
.file-input.highlight {
  border-color: var(--bs-primary);
  background-color: var(--bs-light);
}
</style>
