export class TableBuilder {
  constructor() {
    this.rows = [];
  }
  row(label, value, type, hideIfNull) {
    if (value != null || !hideIfNull) {
      this.rows.push({
        label,
        value,
        type
      });
    }
    return this;
  }
}
