<template>
  <AdminComponent size="md">
    <div class="flex-grow-1 d-flex flex-column justify-content-center">
      <div class="text-center h5 fw-normal my-4 text-muted col-sm-12 col-md-10 col-lg-8 mx-auto">
        Please specify a location by searching for the town/city and state. After that, you can customize branding,
        administrative information, and other details.
      </div>
      <MunicipalityInput @city="onCreated" large placeholder="Location e.g. Portland, ME" />
    </div>
  </AdminComponent>
</template>

<script>
import AdminComponent from "@/components/AdminComponent.vue";
import MunicipalityInput from "@/components/common/MunicipalityInput.vue";
export default {
  components: {
    MunicipalityInput,
    AdminComponent
  },
  data() {
    return {};
  },
  methods: {
    onCreated(city) {
      this.$router.push({name:'admin-city',params:{cityId:city.id}});
    }
  }
}
</script>
