<template>
  <div class="print-body">
    <DefaultNoticeOfViolation
      v-if="template === 'default'"
      :model="model"
      :screenshot-url="screenshotUrl"
      :voluntary="options.voluntary"
      @ready="doPrint"
    />
    <OOBNoticeOfCompliance
      v-if="template === 'oob'"
      :model="model"
      :type="options.type"
      :destination="options.destination"
      @ready="doPrint"
    />
  </div>
</template>

<script>
import DefaultNoticeOfViolation from "@/components/letters/DefaultLetter.vue";
import OOBNoticeOfCompliance from "@/components/letters/OOBLetter.vue";
import {LetterModel} from "@/models/LetterModel";

export default {
  components: {OOBNoticeOfCompliance, DefaultNoticeOfViolation},
  props: {
    model: LetterModel
  },
  emits: ['printed'],
  data() {
    return {
      withScreenshot: false,
      screenshotUrl: null,
      template: null,
      options: {},
      onPrinted: null
    };
  },
  methods: {
    doPrint() {
      const el = this.$el;
      const parent = el.parentElement;
      document.body.appendChild(el);
      window.print();
      console.log(`Done printing!`);
      document.body.removeChild(el);
      parent.appendChild(el);
      this.onPrinted && this.onPrinted();
      this.$emit('printed');
      this.template = null;
      this.options = {};
    },
    async print(template, options) {
      this.template = template;
      this.options = options || {};
      return new Promise(resolve => {
        this.onPrinted = resolve;
      });
    }
  }
}

</script>

<style>
img.seal-logo {
  max-height: 120px;
  max-width: 120px;
  object-fit: contain;
}
@media print {
  @page {
    size:auto;
    margin:0.5in 0.75in;
  }
}
</style>
