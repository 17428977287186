<template>
  <div class="d-flex flex-column align-items-stretch overflow-hidden h-100">

    <!-- Header bar (fixed) -->
    <div class="d-flex flex-row align-items-center justify-content-start px-3 py-2 border-bottom gap-2">
      <template v-if="!showFilters">
        <div class="form-text fw-semibold my-0 flex-grow-1 d-flex flex-row align-items-center">
          <spinner-view small v-if="loading" class="me-2"/>
          <div class="me-auto">{{ message }}</div>
          <template v-if="hasPages">
            <IconButton button small icon="chevron-left" @click="$emit('previous-page')" :disabled="pageStart <= 0" />
            <IconButton button small icon="chevron-right" @click="$emit('next-page')" :disabled="pageEnd >= count" />
          </template>
        </div>
        <IconButton small :icon="expandIcon || 'filter'" @click="showFilters = true" />
        <slot name="icons"/>
      </template>
      <template v-else>
        <button type="button" class="btn btn-sm btn-flat" @click="cancel">Cancel</button>
<!--        <button type="button" class="btn btn-sm btn-outline-secondary ms-auto" @click="reset">Reset</button>-->
        <button type="button" class="btn btn-sm btn-primary ms-auto" @click="apply">Apply Filters</button>
      </template>
    </div>


    <!-- Body -->
    <div class="flex-grow-1 overflow-auto">
      <slot v-if="showFilters" name="filters" />
      <slot v-else name="list" />
    </div>

  </div>
</template>

<script>
import IconButton from "@/components/common/IconButton.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView, IconButton},
  emits: ['previous-page', 'next-page', 'updated', 'reset', 'canceled'],
  props: {
    noun: String,
    count: Number,
    pageStart: Number,
    pageEnd: Number,
    query: String,
    loading: Boolean,
    expandIcon: String
  },
  data() {
    return {
      showFilters: false
    };
  },
  computed: {
    hasPages() {
      return this.pageStart >= 0 && this.pageEnd >= this.pageStart && (this.pageStart > 0 || this.pageEnd < this.count);
    },
    message() {
      if (this.loading) {
        return 'Loading...';
      }
      let msg = null;
      if (this.hasPages) {
        msg = `${this.formatNumber(this.pageStart+1)}-${this.formatNumber(this.pageEnd)} of ${this.formatNumber(this.count, this.noun)}`;
      } else {
        msg = this.formatNumber(this.count, this.noun);
      }
      if (this.query) {
        return `Showing ${msg} matching "${this.query}"`;
      } else {
        return msg;
      }
    }
  },
  methods: {
    reset() {
      this.$emit('reset');
      this.showFilters = false;
    },
    apply() {
      this.$emit('updated');
      this.showFilters = false;
    },
    cancel() {
      this.$emit('canceled');
      this.showFilters = false;
    }
  }
}
</script>
