export const TYPE_ACTIVE = 'active';
export const TYPE_INACTIVE = 'inactive';
export const TYPE_PENDING = 'pending';

export class LicenseWorkflow {

  constructor(city) {
    this.city = city;
    this.reset();
  }

  reset() {
    this.data = {
      statuses: [],
      defaultStatus: 'submitted',
      ...(this.city._get('licenseWorkflow'))
    };
  }

  addStatus() {
    this.data.statuses.push({
      id: `${Math.round(Math.random() * 1000000000)}`,
      code: '',
      label: '',
      description: '',
      type: TYPE_PENDING
    });
  }

  removeStatus(id) {
    this.data.statuses = this.data.statuses.filter(status => status.id !== id);
  }

  get statuses() {
    return this.data.statuses;
  }

  get defaultStatus() {
    return this.data.defaultStatus;
  }

  set defaultStatus(defaultStatus) {
    this.data.defaultStatus = defaultStatus;
  }

  async commit() {
    await this.city.updateLicenseWorkflow(this.serialize());
    this.reset();
  }

  serialize() {
    return {
      ...this.data,
    };
  }
}
