import FormField from "@/models/FormField";

export class LicenseApplicationForm {

  constructor(/* City */ city) {
    this.city = city;
    this.reset();
    this.publishing = false;
  }

  get url() {
    return new URL(`/register/${this.city.id}`, document.baseURI).href;
  }

  get published() { return this.data.published; }
  set published(published) { this.data.published = published; }
  updatePublished(published) {
    this.publishing = true;
    return this.city
    .updateLicenseApplicationForm({ published })
    .finally(() => {
      this.reset();
      this.publishing = false;
    });
  }

  get title() { return this.data.title; }
  set title(title) { this.data.title = title; }

  get description() { return this.data.description; }
  set description(description) { this.data.description = description; }

  get fields() {
    return this.data.customFields;
  }
  addField() {
    this.data.customFields.push(new FormField());
  }
  removeField(id) {
    this.data.customFields = this.data.customFields.filter(field => field.id !== id);
  }
  moveField(id, byHowMany) {
    let index = null;
    this.data.customFields.forEach((field, i) => {
      if (field.id === id) {
        index = i;
      }
    });
    if (index !== null) {
      let field = this.data.customFields[index];
      this.data.customFields.splice(index, 1);
      let insertAt = index + byHowMany;
      if (insertAt < 0) {
        insertAt = this.data.customFields.length;
      } else if (insertAt > this.data.customFields.length) {
        insertAt = 0;
      }
      this.data.customFields.splice(insertAt, 0, field);
    }
  }
  reset() {
    this.data = {
      published: false,
      title: 'STR License Application',
      description: '',
      customFields: [],
      ...(this.city._get('licenseApplicationForm'))
    };
    this.data.customFields = this.data.customFields.map(field => {
      if (field instanceof FormField) return field;
      else return new FormField(field);
    });
  }
  commit() {
    return this.city.updateLicenseApplicationForm(this.serialize())
    .then(() => this.reset());
  }

  serialize() {
    return {
      ...this.data,
      customFields: this.fields.map(field => field.serialize())
    };
  }

}
