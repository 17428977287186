<template>
  <AdminComponent size="lg">
    <template #topbar>
      <router-link :to="{name:'admin-cities'}" class="btn btn-flat" style="margin-left:-0.75rem;margin-right:0.25rem">
        <i class="bi-arrow-left" />
      </router-link>
      <div class="h4 mb-0 flex-grow-1 fw-bold" v-if="city">
        {{ city.label }}
      </div>
      <router-link :to="{name:'city',params:{cityId:cityId}}" class="btn btn-flat" title="Dashboard">
        <i class="bi-grid" />
      </router-link>
    </template>
    <SpinnerView v-if="!city" />

    <template v-else-if="city.isNew">
      <div class="flex-grow-1 d-flex flex-column justify-content-center">
        <div class="text-center h5 fw-normal my-4 text-muted col-sm-12 col-md-10 col-lg-8 mx-auto">
          Please specify a location by searching for the town/city and state. After that, you can customize branding,
          administrative information, and other details.
        </div>
        <MunicipalityInput :city="city" large placeholder="Location e.g. Portland, ME" />
      </div>
    </template>

    <template v-else>
      <ul class="nav nav-pills mb-3">
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="nav-item">
          <router-link :to="{name:'admin-city-tab',params:{cityId:this.cityId,tabId:tab.id}}" active-class="active" class="nav-link">
            {{ tab.title }}
          </router-link>
        </li>
      </ul>
      <div class="d-flex flex-column align-items-stretch gap-3 pb-3">
        <template v-if="tabId === 'general'">
          <BrandingCard :city="city" />
          <AdministrativeInfoCard :city="city" />
          <ViolationSettingsCard :city="city" />
          <MapCard :city="city" />
        </template>
        <template v-else-if="tabId === 'license-config'">
          <LicenseManagementTab :city="city" />
        </template>
        <template v-else-if="tabId === 'assessor-db'">
          <AssessorCard :city="city" />
        </template>
        <template v-else-if="tabId === 'listing-services'">
          <ListingServicesCard :city="city" />
        </template>
        <template v-else-if="tabId === 'properties'">
          <PropertiesList :city="city" />
        </template>
        <template v-else-if="tabId === 'listings'">
          <ListingsList :city="city"/>
        </template>
        <template v-else-if="tabId === 'advanced'">
          <LayoutCard title="Delete">
            <template #description>
              <div class="text-danger mb-4">
                <i class="bi-exclamation-triangle" /> WARNING: This cannot be undone!
              </div>
              <button type="button" class="btn btn-danger" @click="city.delete()">
                <i class="bi-trash" /> Delete this municipality
              </button>
            </template>
          </LayoutCard>
        </template>
        <template v-else>
          <div class="text-center p-3 h5">
            An unrecognized tab "{{ selectedTab }}" was selected.
          </div>
        </template>
      </div>
    </template>
  </AdminComponent>
</template>

<script>
import {City} from "@/models/City";
import AdminComponent from "@/components/AdminComponent.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import MunicipalityInput from "@/components/common/MunicipalityInput.vue";
import BrandingCard from "@/components/admin-city/BrandingCard.vue";
import MapCard from "@/components/admin-city/MapCard.vue";
import ListingServicesCard from "@/components/admin-city/ListingServicesCard.vue";
import AdministrativeInfoCard from "@/components/admin-city/AdministrativeInfoCard.vue";
import AssessorCard from "@/components/admin-city/properties/PropertiesConfigTab.vue";
import LicenseManagementTab from "@/components/admin-city/licenses/LicenseManagementTab.vue";
import LayoutCard from "@/components/common/LayoutCard.vue";
import PropertiesList from "@/components/admin-city/PropertiesList.vue";
import ListingsList from "./ListingsList.vue";
import ViolationSettingsCard from "@/components/admin-city/ViolationSettingsCard.vue";

export default {
  components: {
    ViolationSettingsCard,
    ListingsList,
    PropertiesList,
    LayoutCard,
    LicenseManagementTab,
    AssessorCard,
    AdministrativeInfoCard,
    ListingServicesCard, MapCard, BrandingCard, MunicipalityInput, SpinnerView, AdminComponent
  },
  props: {
    cityId: {
      type: String,
      required: true
    },
    tabId: {
      type: String,
      default: () => 'general'
    }
  },
  data() {
    return {
      city: null,
      unsubscribeFromCity: () => null
    };
  },
  unmounted() {
    this.unsubscribeFromCity();
  },
  watch: {
    cityId: {
      immediate: true,
      async handler() {
        this.unsubscribeFromCity();
        this.city = null;
        this.unsubscribeFromCity = await City.onCity(this.cityId, city => this.city = city);
      }
    }
  },
  computed: {
    tabs() {
      return [
        {
          id: 'general',
          title: 'General'
        },
        {
          id: 'license-config',
          title: 'License Management'
        },
        {
          id: 'assessor-db',
          title: 'Property Database'
        },
        {
          id: 'listing-services',
          title: 'Listing Services'
        },
        {
          id: 'properties',
          title: 'Properties'
        },
        {
          id: 'listings',
          title: 'Listings'
        },
        {
          id: 'advanced',
          title: 'Advanced'
        }
      ];
    }
  }
}
</script>
