<template>
  <tr>
    <td valign="middle">{{ label }}</td>
    <td valign="middle">{{ oldValueFormatted }}</td>
    <td valign="middle">
      <LicenseChangePropertyIdField v-if="field.field === 'propertyId'" :change="change" />
      <template v-else>
        {{ newValueFormatted }}
      </template>
    </td>
  </tr>
</template>

<script>
import {LicenseParseChange} from "@/models/LicenseParseResult";
import LicenseChangePropertyIdField from "@/components/licenses/LicenseChangePropertyIdField.vue";

export default {
  components: {LicenseChangePropertyIdField},
  props: {
    change: LicenseParseChange,
    field: Object
  },
  computed: {
    isArray() {
      return this.field.field === 'contactNames';
    },
    label() {
      let customFieldId = (this.field.field.match(/customFieldValues\.(.*)/) || [])[1];
      if (customFieldId) {
        let customField = this.change.city.customFields.find(field => field.id === customFieldId);
        if (customField) {
          return customField.label;
        }
      }
      switch (this.field.field) {
        case 'contactAddress':
          return 'Contact Address';
        case 'status':
          return 'Status';
        case 'propertyId':
          return 'Parcel ID';
        case 'contactNames':
          return 'Contact Names';
        default:
          return this.field.field;
      }
    },
    oldValueFormatted() {
      return this.formatValue(this.field.oldValue);
    },
    newValueFormatted: {
      get() {
        return this.formatValue(this.field.newValue);
      },
      set(v) {
        if (this.isArray) {
          this.field.newValue = v.split(/,/g).map(item => item.trim());
        } else {
          this.field.newValue = v;
        }
      }
    }
  },
  methods: {
    formatValue(value) {
      if (value instanceof Array) {
        return value.join(', ');
      } else {
        return value;
      }
    }
  }
}
</script>
