<template>
  <div class="d-flex flex-row align-items-center">
    <div class="position-relative flex-grow-1">
      <input
        ref="input"
        :class="`${flush ? 'form-control-flush' : ''} form-control ${large ? 'form-control-lg' : ''} mb-0 ${showMatches ? 'rounded-0 rounded-top' : ''}`"
        :placeholder="placeholder"
        :disabled="saving"
        type="text"
        v-model="queryDisplay"
        @focus="onFocus"
        @blur="onBlur"
        @click.stop="onFocus"
        @keyup="onKeyUp"
      />
      <div class="position-absolute" style="right:12px;top:50%;margin-top:-15px;font-size:1.25rem">
        <div v-if="loading" class="spinner-border spinner-border-sm"/>
      </div>
      <BCollapse :show="showMatches" class="position-absolute" style="top:100%;left:0;right:0">
        <div class="card overflow-hidden rounded-0 rounded-bottom border-top-0">
          <div v-if="matches.length === 0" class="form-text mt-0 px-3 py-1 bg-light">
            <template v-if="!query">
              Start typing an address to see matches below
            </template>
            <template v-else>
              We couldn't find any addresses to suggest
            </template>
          </div>
          <ul v-else class="list-group list-group-flush">
            <li
              v-for="(city, index) in matches"
              :key="city.id"
              @click.stop="selectCity(city)"
              :class="`list-group-item list-group-item-action border-0 ${index === selectedIndex ? 'bg-primary bg-opacity-10' : ''}`"
            >
              <div class="nav-link">
                <div>
                  {{ city.label }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </BCollapse>
    </div>
    <template v-if="city">
      <spinner-view v-if="saving" small class="ms-3" />
      <button type="button" class="btn btn-outline-secondary ms-3" @click="cancel" :disabled="saving">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-3" @click="commit" :disabled="saving">
        Save
      </button>
    </template>
  </div>
</template>

<script>
import BCollapse from "@/components/common/BCollapse.vue";
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView, BCollapse},
  emits: ['city','cancel'],
  props: {
    placeholder: {
      type: String,
      default: () => 'e.g. Portland, ME'
    },
    large: Boolean,
    flush: Boolean
  },
  data() {
    return {
      query: '',
      lookupTask: null,
      focused: false,
      matches: [],
      selectedIndex: -1,
      city: null,
      saving: false
    };
  },
  computed: {
    queryDisplay: {
      get() {
        if (this.focused) {
          return this.query;
        } else if (this.city) {
          return this.city.label;
        } else {
          return null;
        }
      },
      set(q) {
        this.query = q;
      }
    },
    loading() {
      return !!this.lookupTask;
    },
    showMatches() {
      return this.focused && this.matches.length > 0;
    }
  },
  methods: {
    commit() {
      console.log(`Saving new city details`);
      this.saving = true;
      this.city.save()
      .then(() => {
        this.$emit('city', this.city);
      })
      .finally(() => this.saving = false);
    },
    cancel() {
      this.city = null;
      this.$emit('cancel');
    },
    selectCity(city) {
      console.log(`Selected ${city.id}: ${city.label}`);
      this.city = city;
      this.onBlur();
    },
    lookupCity() {
      console.log(`Pressed another key. Running a new search.`);
      clearTimeout(this.lookupTask);
      this.lookupTask = null;

      const query = this.query;
      this.lookupTask = setTimeout(() => {
        console.log(`Pressed another key. Running a new search on ${query}.`);
        City.search(query)
        .then(cities => {
          this.matches = cities;
          this.lookupTask = null;
        });
      }, 400);
    },
    onFocus() {
      console.log('onFocus');
      this.focused = true;
      this.$refs.input.select();
    },
    onBlur() {
      setTimeout(() => {
        console.log('onBlur');
        this.$refs.input.blur();
        this.focused = false;
      }, 50);
    },
    onKeyUp(event) {
      console.log('onKeyUp', event);
      if (event.keyCode === 38) {
        // Up arrow
        this.selectedIndex--;
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.matches.length - 1;
        }
      } else if (event.keyCode === 40) {
        // Down arrow
        this.selectedIndex++;
        if (this.selectedIndex >= this.matches.length) {
          this.selectedIndex = -1;
        }
      } else if (event.keyCode === 13) {
        // Enter
        let selected = this.matches[this.selectedIndex];
        console.log(`Pressed enter with selection ${this.selectedIndex}: ${selected}`);
        if (selected) {
          this.selectCity(selected);
        }
      } else {
        this.lookupCity();
      }
    }
  }
}
</script>

<style>
.form-control-flush {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent;
  transition: padding 250ms, border 250ms;
}
.form-control-flush:focus, .form-control-flush:hover {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.form-control-flush:hover {
  background-color: var(--bs-light);
}
.form-control-flush:focus {
  border-color: var(--bs-border-color);
}
</style>
