export default class {

  constructor() {
    this.time = new Date();
  }

  message(m) {
    const t = new Date();
    let message = `[${t - this.time}] ${m}`;
    this.time = t;
    return message;
  }

}
