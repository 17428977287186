/**
 * Base model class for wrapping an object, providing convenience methods for merging data into it, copying it, and
 * getting/setting fields.
 */
export class BaseModel {

  constructor(data, defaults) {
    this.loading = false;
    this._data = { ...defaults, ...data };
  }

  serialize() {
    return {...this._data};
  }

  merge(data) {
    this._data = { ...this._data, ...data };
  }

  copy() {
    return new this.constructor(this._data);
  }

  _get(field) {
    const fieldPath = field.split(/\./g);
    let value = this._data;
    fieldPath.forEach(field => {
      value = value && value[field];
    });
    return value;
  }

  _set(field, value) {
    const fieldPath = field.split(/\./g);
    let parent = this._data;
    for (let i = 0; i < fieldPath.length; i++) {
      if (i === fieldPath.length - 1) {
        parent[fieldPath[i]] = value;
      } else {
        parent = parent[fieldPath[i]] || {};
      }
    }
  }

}
