<template>
  <GeneralPropertiesCard :city="city" />
  <template v-if="city.canRequestPropertyRecordImport">
    <template v-if="city.canImportPropertiesAutomatically">
      <TaskView label="Property Records" count-label="Properties Imported" task-name="importProperties" :city="city">
        <template #description>
          If your municipality uses a supported assessor database, we can automatically import all property records
          from it. This helps us to match properties up to listings.
        </template>
        <template #buttons>
          <router-link :to="{name:'admin-city-tab',params:{cityId:city.id,tabId:'properties'}}" class="btn btn-flat text-primary">
            See the properties
          </router-link>
        </template>
      </TaskView>
      <TaskView label="Property Record Details" import-title="Parse Records" count-label="Records Parsed" task-name="importPropertyDetails" :city="city">
        <template #description>
          To find more detailed information, such as property owners, square footage, and assessor images, we need to
          do a deeper dive and parse each property record individually.
        </template>
      </TaskView>
    </template>
    <TaskView label="Property Coordinates" import-title="Derive Coordinates" count-label="Coordinates Derived" task-name="importLatLng" :city="city">
      <template #description>
        We can derive the lat/lng coordinates of each property to help improve accuracy when matching properties to listings.
      </template>
    </TaskView>
    <TaskView label="Real Estate Records" import-title="Parse Records" count-label="Records Parsed" task-name="importRealtorDetails" :city="city">
      <template #description>
        Real estate sites often use higher-quality images than the assessor database, which can help match properties
        up to listings.
      </template>
    </TaskView>
    <TaskView label="Index" import-title="Index Records" count-label="Records Indexed" task-name="buildPropertyIndex" :city="city">
      <template #description>
        In order to make property records searchable by address, owner, etc, we need to build a search index.
      </template>
    </TaskView>
    <TaskView label="Image Cache" import-title="Cache Images" count-label="Images Cached" task-name="cachePropertyImages" :city="city">
      <template #description>
        All of the property's relevant images exist on other vendors' sites. To improve the performance of our
        matching algorithms, we can download the images to our site.
      </template>
    </TaskView>
    <DefaultPropertyValuesCard :city="city" />
  </template>
</template>

<script>
import {City} from "@/models/City";
import TaskView from "@/components/admin-city/TaskView.vue";
import DefaultPropertyValuesCard from "@/components/admin-city/properties/DefaultPropertyValuesCard.vue";
import GeneralPropertiesCard from "@/components/admin-city/properties/GeneralPropertiesCard.vue";

export default {
  components: {GeneralPropertiesCard, DefaultPropertyValuesCard, TaskView},
  props: {
    city: City
  }
}
</script>
