<template>
  <div class="d-flex flex-column align-items-center gap-2 p-4 m-3 rounded bg-light">
    <spinner-view small />
    <div>{{ message }}</div>
  </div>
</template>

<script>
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView},
  props: {
    message: String
  }
}
</script>
