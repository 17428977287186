import { db } from '@/db';
import {
  collection,
  getDocs,
  where,
  query,
  getDoc,
  doc,
  updateDoc,
  setDoc,
  getCountFromServer,
  onSnapshot,
  orderBy,
  endBefore,
  startAfter,
  limit,
  addDoc
} from "firebase/firestore";
import moment from 'moment';

import FormField from "@/models/FormField";
import {callApi} from "@/api";
import geocoder from "@/geocoder";
import {CurrentTaskRun, LastTaskRun, Task} from "@/models/Task";
import {Property} from "@/models/Property";
import {Listing} from "@/models/Listing";
import {PersistentModel} from "@/models/PersistentModel";
import Timer from "@/models/Timer";
import {License} from "@/models/License";
import {compareStatuses, STATUSES} from "@/models/LicenseStatus";

function isPlaceStreetAddress(result) {
  return result && (
    result.types.indexOf('premise') >= 0 ||
    result.types.indexOf('street_address') >= 0);
}

function converPlaceToAddress(result) {
  let address = {
    id: result.place_id,
    label: result.formatted_address,
    latlng: result.geometry.location.toJSON(),
  };
  result.address_components.forEach(component => {
    if (component.types.indexOf('street_number') >= 0) {
      address.streetNumber = component.long_name;
    } else if (component.types.indexOf('route') >= 0) {
      address.street = component.long_name;
      address.streetAbbrev = component.short_name;
    } else if (component.types.indexOf('locality') >= 0) {
      address.city = component.long_name;
    } else if (component.types.indexOf('administrative_area_level_1') >= 0) {
      address.state = component.long_name;
      address.stateCode = component.short_name;
    } else if (component.types.indexOf('postal_code') >= 0) {
      address.zipCode = component.short_name;
    }
  });
  if (address.streetNumber && address.street) {
    return address;
  } else {
    return null;
  }
}

export class City extends PersistentModel {

  static collectionName = 'cities';

  static async loadCities(includeNonPublic) {
    return getDocs(query(
      collection(await db, 'cities'),
      includeNonPublic ? where('visibility', '!=', 'deleted') : where("visibility", "==", "public")
    ))
    .then(snapshot => {
      return snapshot.docs.map(doc => new City(doc));
    });
  }

  static async loadCity(id, useCache) {
    if (City._cache[id] && useCache) {
      return Promise.resolve(City._cache[id]);
    } else {
      return getDoc(doc(await db, 'cities', id))
      .then(doc => {
        let city = new City(doc);
        City._cache[id] = city;
        return city;
      });
    }
  }

  static async onCity(id, handler) {
    return onSnapshot(doc(await db, 'cities', id), doc => {
      handler(new City(doc));
    });
  }

  static search(query) {
    return geocoder().then(geocoder => {
      return new Promise((resolve) => {
        geocoder.geocode({
          address: query,
          componentRestrictions: {
            country: 'US'
          }
        }, (results, status) => {
          console.log(results);
          console.log(`Status = ${status}`);
          resolve(Promise.all(results.map(async result => {
            if (result.types.indexOf('locality') < 0) {
              return null;
            }
            let latlng = result.geometry.location.toJSON();
            let name = result.address_components
              .filter(ac => ac.types.indexOf('locality') >= 0)
              .map(ac => ac.long_name)[0];
            let state = result.address_components
              .filter(ac => ac.types.indexOf('administrative_area_level_1') >= 0)
              .map(ac => ac.short_name)[0];
            let placeId = result.place_id;
            return new City({
              ref: doc(await db, `cities/${this.createId(name, state)}`),
              data: {name, state, latlng, placeId}
            });
          }).filter(city => !!city)));
        });
      });
    });
  }

  static createId(name, state) {
    return `${name.replace(/[ -]/g, '_').replace(/'/g, '')}_${state}`.toLowerCase();
  }

  static _cache = {};

  constructor(doc) {
    super(doc);
    this._data.admin = {
      url: '',
      sealUrl: '',
      formalName: '',
      officeTitle: '',
      address: '',
      contactTitle: '',
      contactName: '',
      contactEmail: '',
      contactPhone: '',
      violationLetterTitle: '',
      violationLetterSubtitle: '',
      ...this.admin
    };
    this._data.general = {
      treatInactiveLicenseAsViolation: false,
      ...this.general
    };
    this._data.licenseWorkflow = {
      ...this._data.licenseWorkflow
    };
  }

  _update(data) {
    if (this.isNew) {
      data.city = data.name.toLowerCase();
      data.id = City.createId(data.city, this.state);
      this._data = { ...this._data, ...data };
      return setDoc(this._docRef, this._data, {merge:true});
    } else {
      return updateDoc(this._docRef, data || this._data);
    }
  }

  save() {
    let data = this._data;
    data.city = data.name.toLowerCase();
    data.id = City.createId(this.name, this.state);
    return setDoc(this._docRef, data, {merge:true});
  }

  get isComplete() {
    return !!this.name;
  }

  get placeId() {
    return this._data.placeId;
  }

  set placeId(placeId) {
    this._data.placeId = placeId;
  }

  get staticMapUrl() {
    return `https://maps.googleapis.com/maps/api/staticmap?center=${this.latlng.lat},${this.latlng.lng}&zoom=12&size=240x240&key=AIzaSyDOqYud38oaZms1EpdoLsxoBA63oDD-wes`;
  }

  get url() {
    return this._data.url;
  }

  set url(url) {
    this._data.url = url;
  }

  get name() {
    return this._get('name');
  }
  set name(name) {
    this._set('name', name);
  }

  get state() {
    return this._get('state') || 'ME'; // Default to Maine
  }
  set state(state) {
    this._set('state', state);
  }

  get label() {
    if (this.name) {
      return `${this.name}, ${this.state}`;
    } else {
      return '';
    }
  }

  get status() {
    return this._data.status;
  }

  get visibility() {
    return this._data.visibility;
  }
  set visibility(visibility) {
    this._set('visibility', visibility);
  }

  get lastLoadId() {
    return this._get('lastLoadId');
  }

  get paused() {
    return this._get('paused') || false;
  }

  set paused(paused) {
    this._set('paused', paused);
  }

  updatePausedState(paused) {
    return this._update({paused})
    .then(() => {
      this._set('paused', paused);
    });
  }

  get latlng() {
    return this._data.latlng;
  }

  set latlng(latlng) {
    this._data.latlng = latlng;
  }

  updateLocation({name, state, placeId, latlng}) {
    let data = {name, state, placeId, latlng};
    Object.keys(data).forEach(key => {
      if (data[key] === null) delete data[key];
    });
    if (name) {
      data.city = name.toLowerCase();
    }
    return this._update(data)
    .then(() => this._data = { ...this._data, ...data });
  }

  importListings(source) {
    return this.doApiCall('importListings', {source});
  }

  get idFieldNameForApi() {
    return 'cityId';
  }

  get isImportingListings() {
    return this.isRequesting('importListings');
  }

  get dateChecked() {
    return this._data.dateChecked;
  }

  get branding() {
    return this._data.branding || {};
  }

  get backgroundColor() {
    return this.branding.backgroundColor;
  }
  set backgroundColor(backgroundColor) {
    this.branding.backgroundColor = backgroundColor;
  }

  get iconUrl() {
    return this.branding.iconUrl;
  }
  set iconUrl(iconUrl) {
    this.branding.iconUrl = iconUrl;
  }

  updateBranding(branding) {
    return this._update({ branding })
    .then(() => {
      this._set('branding', { ...this.branding, ...branding });
    });
  }

  get admin() {
    return this._data.admin || {};
  }

  updateAdmin(admin) {
    return this._update({ admin })
    .then(() => {
      this._set('admin', { ...this.admin, ...admin });
    });
  }

  get general() {
    return this._data.general || {};
  }

  updateGeneral(general) {
    return this._update({ general })
    .then(() => {
      this._set('general', { ...this.general, ...general });
    });
  }

  get townSealUrl() {
    return this.admin.sealUrl || null;
  }
  set townSealUrl(townSealUrl) {
    this.admin.sealUrl = townSealUrl;
  }

  get townHallAddress() {
    return this.admin.address || '--Town Hall Address--';
  }
  set townHallAddress(townHallAddress) {
    this.admin.address = townHallAddress;
  }

  get townClerkName() {
    return this.admin.clerk || '--Town Clerk Name--';
  }
  set townClerkName(townClerkName) {
    this.admin.clerk = townClerkName;
  }

  get ordinanceName() {
    return this.admin.ordinance || '--Ordinance Number and Name--';
  }
  set ordinanceName(ordinanceName) {
    this.admin.ordinance = ordinanceName;
  }

  get formalName() {
    return this.admin.formalName || `Town of ${this.name}`;
  }
  set formalName(formalName) {
    this.admin.formalName = formalName;
  }

  get officeTitle() {
    return this.admin.officeTitle || 'Office of the Town Clerk';
  }
  set officeTitle(officeTitle) {
    this.admin.officeTitle = officeTitle;
  }

  get contactTitle() {
    return this.admin.contactTitle || 'Town Clerk';
  }
  set contactTitle(contactTitle) {
    this.admin.contactTitle = contactTitle;
  }

  get contactName() {
    return this.admin.contactName || this.townClerkName;
  }
  set contactName(contactName) {
    this.admin.contactName = contactName;
  }

  get contactPhone() {
    return this.admin.contactPhone;
  }
  set contactPhone(contactPhone) {
    this.admin.contactPhone = contactPhone;
  }

  get contactEmail() {
    return this.admin.contactEmail;
  }
  set contactEmail(contactEmail) {
    this.admin.contactEmail = contactEmail;
  }

  get violationLetterTitle() {
    return this.admin.violationLetterTitle;
  }
  set violationLetterTitle(violationLetterTitle) {
    this.admin.violationLetterTitle = violationLetterTitle;
  }

  get violationLetterSubtitle() {
    return this.admin.violationLetterSubtitle;
  }
  set violationLetterSubtitle(violationLetterSubtitle) {
    this.admin.violationLetterSubtitle = violationLetterSubtitle;
  }

  get treatInactiveLicenseAsViolation() {
    return this.general.treatInactiveLicenseAsViolation || false;
  }
  set treatInactiveLicenseAsViolation(treatInactiveLicenseAsViolation) {
    this.general.treatInactiveLicenseAsViolation = treatInactiveLicenseAsViolation;
  }

  get minNightStayExemptionThreshold() {
    return this.general.minNightStayExemptionThreshold || null;
  }
  set minNightStayExemptionThreshold(minNightStayExemptionThreshold) {
    this.general.minNightStayExemptionThreshold = minNightStayExemptionThreshold;
  }

  isListingExempt(listing) {
    // If the city exempts listings with a minimum night stay above a certain threshold, then check if this listing's
    // minimum night stay is above it.
    if (this.minNightStayExemptionThreshold && listing.minNightStay) {
      if (listing.minNightStay > this.minNightStayExemptionThreshold) {
        return true;
      }
    }
    return false;
  }

  get flags() {
    return this._get('flags');
  }

  addFlag(flag) {
    let flags = this.flags;
    if (flags.indexOf(flag) <= 0) {
      flags.push(flag);
      this._set('flags', flags);
    }
  }

  removeFlag(flag) {
    this._set('flags', this.flags.filter(f => f !== flag));
  }

  calculateLicenseExpirationDate(/* Date */ dateIssued) {
    dateIssued = moment(dateIssued);
    if (this.id === 'casco_me') {
      // One year from dateIssued
      return dateIssued.add(1, 'y').toDate();
    } else {
      // December 31 of year date issued, or of the following year if issued in December
      let year = dateIssued.year();
      if (dateIssued.month() === 11) {
        year++;
      }
      return moment({year, month:11, day:31}).toDate();
    }
  }

  get customFields() {
    return this._get('licenseApplicationForm.customFields') || [];
  }

  get licenseStatuses() {
    return [
      ...STATUSES,
      ...(this._get('licenseWorkflow.statuses') || [])
    ].sort(compareStatuses);
  }

  get defaultLicenseStatus() {
    return this._get('licenseWorkflow.defaultStatus') || 'submitted';
  }

  // @deprecated
  addCustomField() {
    const fields = this.customFields;
    fields.push(new FormField());
    this._set('customFields', fields);
  }

  // @deprecated
  removeCustomField(id) {
    const fields = this.customFields.filter(field => field.id !== id);
    this._set('customFields', fields);
  }

  // @deprecated
  updateCustomFields(customFields) {
    return this._update({
      customFields: customFields.map(field => field._data)
    })
    .then(() => {
      this._data.customFields = customFields;
    });
  }

  get licenseApplicationConfirmationText() {
    return this._get('licenseApplicationConfirmationText')
      || `${ this.name } staff will review your application and will be in touch soon regarding its status.`;
  }
  set licenseApplicationConfirmationText(licenseApplicationConfirmationText) {
    this._set('licenseApplicationConfirmationText', licenseApplicationConfirmationText);
  }
  updateLicenseApplicationConfirmationText(text) {
    return this._update({
      licenseApplicationConfirmationText: text
    }).then(() => {
      this._data.licenseApplicationConfirmationText = text;
    });
  }

  updateLicenseApplicationForm(licenseApplicationForm) {
    let promise = Promise.resolve(null);
    if (licenseApplicationForm.customFields) {
      promise = this._update({customFields: []});
    }
    let update = {};
    for (let key in licenseApplicationForm) {
      update[`licenseApplicationForm.${key}`] = licenseApplicationForm[key];
    }
    return promise
    .then(() => this._update(update))
    .then(() => {
      this._data.licenseApplicationForm = {
        ...this._data.licenseApplicationForm,
        licenseApplicationForm // TODO is this wrong?
      };
    });
  }

  async updateLicenseWorkflow(licenseWorkflow) {
    let update = {};
    for (let key in licenseWorkflow) {
      update[`licenseWorkflow.${key}`] = licenseWorkflow[key];
    }
    await this._update(update);
    this._data.licenseWorkflow = {
      ...this._data.licenseWorkflow,
      ...licenseWorkflow
    };
  }

  get geoJson() {
    const geoJsonString = this._get('boundary.geoJson');
    if (geoJsonString) {
      return JSON.parse(geoJsonString);
    } else {
      return null;
    }
  }

  async fetchBoundary() {
    let response = await callApi('importCityBoundary', {cityId: this.id}, 'utils');
    if (response) {
      return true;
    } else {
      return false;
    }
  }

  get recordsSourceType() {
    return this._get('recordsSourceType');
  }

  set recordsSourceType(recordsSourceType) {
    this._set('recordsSourceType', recordsSourceType);
  }

  get customRecordsSourceUrl() {
    return this._get('customRecordsSourceUrl');
  }

  set customRecordsSourceUrl(customRecordsSourceUrl) {
    this._set('customRecordsSourceUrl', customRecordsSourceUrl);
  }

  updateRecordsType(type, customUrl) {
    customUrl = customUrl || '';
    return this._update({
      recordsSourceType: type,
      customRecordsSourceUrl: customUrl
    })
    .then(() => {
      this.recordsSourceType = type;
      this.customRecordsSourceUrl = customUrl;
    });
  }

  getRecordsUrlForType(type) {
    switch (type) {
      case 'cama':
        return `https://jeodonnell.com/cama/${this.id.replace(/_me/, '')}`;
      case 'vision':
        return `https://gis.vgsi.com/${this.id.replace(/[_ ]/g, '')}`;
      case 'mapgeo':
        return `https://${this.id.replace(/[_ ]/g, '').toLowerCase()}.mapgeo.io/`;
      default:
        return this.customRecordsSourceUrl;
    }
  }

  get recordsUrl() {
    let type = this.recordsSourceType;
    return this.getRecordsUrlForType(type);
  }

  onTask(taskName, handler) {
    return Task.onOne(this, taskName, handler);
  }

  onCurrentTaskRun(taskName, handler) {
    return onSnapshot(
      doc(this.db, `cities/${this.id}/tasks/${taskName}/runs/current`),
      doc => handler(new CurrentTaskRun(doc.data()))
    )
  }

  onLastTaskRun(taskName, handler) {
    return onSnapshot(
      doc(this.db, `cities/${this.id}/tasks/${taskName}/runs/last`),
      doc => handler(new LastTaskRun(doc.data()))
    )
  }

  requestTask(taskName) {
    return setDoc(
      doc(this.db, `cities/${this.id}/tasks/${taskName}`),
      {
        status: 'requested',
        dateRequested: new Date()
      },
      { merge: true }
    );
  }

  cancelTask(taskName) {
    return setDoc(
      doc(this.db, `cities/${this.id}/tasks/${taskName}`),
      {
        status: 'canceled',
        dateCanceled: new Date()
      },
      { merge: true }
    )
  }

  async callSearchApi(namespace, klass, {page, pageSize, sort, sortAscending, filters, searchField, searchValue}) {
    return await callApi('search', {
      cityId: this.id,
      page, pageSize, sort, sortAscending, filters, searchField, searchValue
    }, namespace)
      .then(/* { items: Array, total: number, page: number, pageSize: number } */result => {
        result.items = result.items.map(klass.dataConverter(this, namespace));
        return result;
      });
  }

  getCollectionPage(collectionName, klass, options) {
    options = options || {};

    let col = collection(this.db, `cities/${this.id}/${collectionName}`);
    let orderByNotNull = where(options.orderBy || 'id', '!=', null);
    let order = orderBy(options.orderBy || 'id');
    let boundary = options.startAfter ? startAfter(options.startAfter) : options.endBefore ? endBefore(options.endBefore) : startAfter(null);

    let constraints = [orderByNotNull];
    if (options.filters) {
      for (let field in options.filters) {
        if (options.filters[field] !== null) {
          constraints.push(where(field, '==', options.filters[field]));
        }
      }
    }

    return Promise.all([
      getCountFromServer(query(col, ...constraints)),
      getCountFromServer(query(col, ...constraints, order, boundary)),
      getDocs(query(col, ...constraints, order, boundary, limit(options.pageSize || 25)))
    ])
    .then(values => {
      const total = values[0].data().count;
      const boundaryOffset = values[1].data().count;
      const items = values[2].docs.map(doc => new klass(this, doc));
      let offset;
      if (options.endBefore) {
        offset = boundaryOffset - items.length;
      } else {
        offset = total - boundaryOffset;
      }
      return {
        offset,
        total,
        items
      };
    });
  }

  get propertiesSearch() {
    return options => {
      return this.getCollectionPage('properties', Property, options);
      // this.getProperties(options);
    };
  }

  get listingsSearch() {
    return options => {
      return this.getCollectionPage('listings', Listing, options);
      // this.getListings(options);
    };
  }

  getProperties({page, pageSize, sort, sortAscending, filters, searchField, searchValue}) {
    if (searchField === 'address.street' || searchField === 'owner') {
      searchValue = (searchValue || '').toUpperCase();
    }
    return this.callSearchApi('properties', Property, {
      sort: sort || 'address.street',
      page, pageSize, sortAscending, filters, searchField, searchValue
    })
  }

  findNearbyProperties({lat, lng, radius}) {
    return Property.findNearby(this, {lat, lng, radius});
  }

  getListings({page, pageSize, sort, sortAscending, filters, searchField, searchValue}) {
    return this.callSearchApi('listings', Listing, {
      sort: sort || 'listing.title',
      page, pageSize, sortAscending, filters, searchField, searchValue
    });
  }

  get canRequestPropertyRecordImport() {
    return !!this.recordsSourceType;
  }

  get canImportPropertiesAutomatically() {
    return this.recordsSourceType && this.recordsSourceType !== 'custom';
  }

  async searchProperties(query, field) {
    if (field === 'propertyId') {
      const property = await Property.one(this, query);
      return property ? [property] : [];
    } else if (field === 'licenseId') {
      const license = await License.one(this, query)
      return license ? [license.property] : [];
    } else {
      return await Property.search(this, query, field);
    }
  }

  async normalizeProperty(address) {
    let t = new Timer();
    let properties = await Property.search(this, address);
    this.log(t.message(`Found ${properties.length} matching properties`));
    if (properties.length > 0) {
      return properties;
    } else {
      this.log('Trying a google search for the property');
      properties = this.normalizePropertiesWithGoogle(address);
      this.log(t.message(`Found ${properties.length} matching properties on Google`));
      return properties;
    }
  }

  async normalizePropertiesWithGoogle(address) {
    let t = new Timer();
    const addresses = await this.normalizeAddress(address);
    this.log(t.message('Address -> Place'));
    if (addresses.length === 0) {
      return [];
    }
    const addressIds = addresses.map(address => address.id);
    const {docs} = await getDocs(
      query(
        this.subCollection('properties'),
        where('googlePlace.id', 'in', addressIds)
      )
    );
    this.log(t.message('Place -> Property'))
    const properties = docs.map(Property.docConverter(this));
    this.log(t.message('Property Data -> new Property'));
    return properties;
  }

  normalizeAddress(address) {
    return geocoder().then(gc => {
      const start = new Date();
      console.log(`Google API: Searching "${address}"`);
      return new Promise((resolve, reject) => {
        gc.geocode({
          address: address,
          componentRestrictions: {
            country: 'US',
            administrativeArea: this.state,
            locality: this.name
          }
        }, (results, status) => {
          console.log(`[${new Date() - start}ms] Searched Google for "${address}": (${status}) ${results.length} results`);
          console.log(results);
          if (status !== 'OK') {
            reject(status);
          } else {
            const addresses = results.filter(isPlaceStreetAddress);
            resolve(addresses.map(converPlaceToAddress));
          }
        });
      });
    });
  }

  async getProperty(id) {
    return getDoc(this.subDoc('properties', id))
    .then(Property.docConverter(this));
  }

  populatePropertyDefaults() {
    return callApi('ensureDefaultFieldsForCity', {cityId:this.id}, 'properties');
  }

  async fetchFieldCounts(collectionName, fieldName, fieldValue) {
    const col = this.subCollection(collectionName);
    let queries = [getCountFromServer(col)];
    if (fieldName) {
      queries.push(getCountFromServer(query(col, where(fieldName, '==', fieldValue))));
    }
    const snapshots = await Promise.all(queries);
    return snapshots.map(snapshot => snapshot.data().count);
  }


  async fetchDefaultFieldCounts(collectionName, fieldNames) {
    const col = this.subCollection(collectionName);
    let queries = [
      getCountFromServer(col)
    ];
    fieldNames.forEach(fieldName => {
      queries.push(getCountFromServer(query(col, where(fieldName, '!=', 'totallybogusvalue'))));
    });
    const snapshots = await Promise.all(queries);
    let counts = {};
    for (let i = 0; i < snapshots.length; i++) {
      const fieldName = i === 0 ? 'total' : fieldNames[i-1];
      counts[fieldName] = snapshots[i].data().count;
    }
    return counts;
  }

  async ensureDefaultValues(namespace) {
    return callApi('ensureDefaultFieldsForCity', {cityId:this.id}, namespace);
  }

  async fetchBoundaries() {
    const response = await fetch(`https://nominatim.openstreetmap.org/search.php?q=${this.name},+${this.state}&polygon_geojson=1&format=json`);
    const data = await response.json();
    return data[0].geojson;
  }

  async canImportLicenses() {
    return callApi('isLicenseImportSupported', {cityId:this.id}, 'data');
  }

  getLetters(forListing) {
    if (this.id === 'old_orchard_beach_me') {
      let letters = [];
      if (forListing) {
        letters.push({
          label: 'Notice of Compliance',
          template: 'oob',
          options: {type: 'compliance'}
        });
      }
      letters.push(...[
        {
          label: 'Notice of Violation (Home Address)',
          template: 'oob',
          options: {
            type: 'violation',
            destination: 'home'
          }
        },
        {
          label: 'Notice of Violation (Rental Address)',
          template: 'oob',
          options: {
            type: 'violation',
            destination: 'rental'
          }
        }
      ]);
      return letters;
    } else if (this.id === 'kennebunk_me') {
      return forListing ? [
        {
          label: 'Request for Voluntary Compliance',
          template: 'default',
          options: { voluntary: true }
        }
      ] : [];
    } else {
      return forListing ? [
        {
          label: 'Violation Letter (with screenshot)',
          template: 'default',
          options: { screenshot: true }
        },{
          label: 'Violation Letter (without screenshot)',
          template: 'default',
          options: { screenshot: false }
        }
      ] : [];
    }
  }

  async sendErrorReport(errorType, errorData) {
    await addDoc(this.subCollection('error_reports'), {
      type: errorType,
      timestamp: new Date(),
      userAgent: navigator?.userAgent,
      platform: navigator?.userAgentData?.platform,
      screenSize: {
        width: window.outerWidth,
        height: window.outerHeight
      },
      url: window.location.href,
      data: errorData
    });
  }
}
