export class Address {
  constructor(data) {
    this._data = {
      id: '',
      streetNumber: '',
      street: '',
      unit: '',
      city: '',
      state: '',
      zipCode: '',
      latlng: null,
      label: '',
      ...data
    };
  }

  serialize() {
    let data = { ...this._data };
    if (data.latlng) {
      if (typeof data.latlng.lat === 'function') {
        data.latlng = {
          lat: data.latlng.lat(),
          lng: data.latlng.lng()
        };
      }
    }
    return data;
  }

  get id() {
    return this._data.id;
  }

  get streetNumber() {
    return this._data.streetNumber;
  }

  get street() {
    return this._data.street;
  }

  get latlng() {
    let latlng = this._data.latlng;
    if (latlng) {
      if (typeof latlng.lat === 'function') {
        return {
          lat: latlng.lat(),
          lng: latlng.lng()
        };
      }
    }
    return latlng;
  }

  isMatch(address, lenient) {
    if (!address) {
      return false;
    }

    if (this.id === address.id) {
      // Perfect ID match
      return true;
    }

    if (this.streetNumber === address.streetNumber && this.street === address.street) {
      // Same street address, now check unit
      if (this.unit === address.unit) {
        // Unit matches too
        return true;
      } else if (lenient && (!this.unit || !address.unit)) {
        // If lenient matching, don't require units to match if either is blank
        return true;
      }
    }
    return false;
  }

  format() {
    if (this._data.label) {
      return this._data.label;
    } else {
      let parts = [
        this.formatStreet(true), this._data.city, this._data.state
      ];
      return parts.filter(part => !!part).join(', ') || '';
    }
  }

  formatStreet(includeUnit) {
    let parts = [this._data.streetNumber, this._data.streetAbbrev || this._data.street];
    if (this._data.unit && includeUnit) {
      parts.push(this._data.unit);
    }
    return parts.filter(part => !!part).join(' ');
  }
}
