<template>
  <div>
    <div class="mb-3">
      <label class="form-label">Reason for whitelisting this listing:</label>
      <BTextarea v-model="reason" :disabled="saving"/>
    </div>
    <div class="mb-3">
      <BRadio :option-value="false" label="Whitelist indefinitely" v-model="expires" />
      <div class="d-flex flex-row align-items-center">
        <BRadio :option-value="true" label="Whitelist until" v-model="expires" class="text-nowrap" />
        <input type="date" class="form-control ms-3 flex-grow-1" v-model="expiration" :disabled="!expires"/>
      </div>
    </div>
    <div v-if="listing.whitelistUpdatedBy" class="mb-3 small text-muted">
      Updated by {{ listing.whitelistUpdatedBy.displayName }}
      <template v-if="listing.whitelistUpdatedAt">
         on {{ listing.whitelistUpdatedAt.format('M/D/YY h:mm a') }}
      </template>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <button class="ms-1 btn btn-sm btn-flat" @click="close" :disabled="saving">
        Cancel
      </button>
      <button
        class="ms-1 btn btn-sm btn-primary"
        @click="commit(true)"
        :disabled="!canUpdate">
        <SpinnerView v-if="saving" small class="me-1"/>
        {{ listing.isWhitelisted ? 'Update' : 'Add to whitelist' }}
      </button>
      <button
        v-if="listing.isWhitelisted"
        class="ms-1 btn btn-sm btn-light"
        @click="commit(false)"
        :disabled="saving"
      >
        <SpinnerView v-if="saving" small class="me-1"/>
        Remove from whitelist
      </button>
    </div>
  </div>
</template>

<script>
import BTextarea from "@/components/common/BTextarea.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {Listing} from "@/models/Listing";
import BRadio from "@/components/common/BRadio.vue";

export default {
  components: {BRadio, SpinnerView, BTextarea},
  emits: ['close'],
  props: {
    listing: Listing
  },
  data() {
    return {
      reason: null,
      expires: this.list,
      expiration: null,
      saving: false
    };
  },
  watch: {
    listing: {
      immediate: true,
      handler() {
        this.reset();
      }
    }
  },
  computed: {
    canUpdate() {
      if (this.saving) {
        return false;
      }
      return true;
    }
  },
  methods: {
    commit(whitelisted) {
      this.saving = true;
      this.listing.updateWhitelisted(
        whitelisted,
        whitelisted ? this.reason : null,
        whitelisted && this.expires ? this.expiration : null
      ).finally(() => {
        this.saving = false;
        this.close();
      });
    },
    reset() {
      this.reason = this.listing.whitelistReason;
      const expiration = this.listing.whitelistExpiration;
      this.expires = !!expiration;
      this.expiration = expiration ? expiration.format('YYYY-MM-DD') : null;
    },
    cancel() {
      this.reset();
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
