<template>
    <template v-if="!city">
      <spinner-view small />
    </template>

    <template v-else-if="listing.isAddressVerified">
      <div class="fw-semibold mb-3">
        Verified Address
      </div>
      <div class="mb-3">
        <SuggestedListingProperty
          :model="model"
          :property="listing.verifiedProperty"
          :listing="listing"
          @clear="resetVerification"
        />
      </div>
    </template>

    <template v-else>
      <div class="flex-shrink-0 border-bottom p-2 pt-3">
        <div class="fw-semibold px-2">Address Verification Tools</div>
        <div class="d-flex flex-row align-items-center">
          <IconButton
            v-for="t in tabs"
            :key="t.id"
            @click="tab = t.id"
            :icon="t.icon"
            :title="t.label"
            :active="t.id === tab"
          />
        </div>
      </div>

      <div class="flex-grow-1 overflow-auto bg-white" ref="scroller">

        <!-- Nearby Addresses -->
        <!-- @deprecated this is basically useless -->
        <!-- TODO this could be replaced by a geo query looking for properties within X meters of listing's latlng -->
        <VerificationTool
          v-if="tab === 'recommended'"
          :model="model"
          :listing="listing"
          title="Nearby Properties"
          @show-location="args => $emit('showLocation', args)"
          @open-street-view="args => $emit('openStreetView', args)"
          @committed="onVerified"
          :properties="loadingNearbyProperties ? [] : nearbyProperties">
          <template #description>
            Showing
            <template v-if="loadingNearbyProperties"><spinner-view small/> properties</template>
            <template v-else>{{formatNumber(nearbyProperties.length, 'property', 'properties')}}</template>
            within {{formatNumber(nearbyRadiusMiles, 'mile')}} of the listing's coordinates.
          </template>
          <input
            type="range"
            min="0" max="1600" step="100"
            v-model.number="nearbyRadius"
            @change="refreshNearbyProperties"
            class="form-range"
            :disabled="loadingNearbyProperties"/>
        </VerificationTool>

        <!-- License Selection -->
        <VerificationTool
          v-else-if="tab === 'licenses'"
          :model="model"
          :listing="listing"
          title="Existing Licenses"
          description="Select a license on the map, or search the license database."
          @show-location="args => $emit('showLocation', args)"
          @open-street-view="args => $emit('openStreetView', args)"
          @committed="onVerified"
          :properties="licenseProperties">
          <InputWithButton
            placeholder="Search"
            button-title="Search"
            v-model="licenseQuery"
            @go="searchLicenses"
            :loading="isSearchingLicenses"
          />
          <template v-if="!!licenses && !isSearchingLicenses">
            <div v-if="licenseProperties.length === 0" class="mt-1 small">
              We couldn't find any matches
            </div>
            <div v-else class="mt-1 small">
              Found {{ formatCount(licenseProperties.length, 'match', 'matches') }}
            </div>
          </template>
        </VerificationTool>

        <!-- Street View -->
        <!-- TODO We'll need to map the street view address to a PropertyRecord...maybe use placeId? -->
        <VerificationTool
          v-else-if="tab === 'streetview'"
          :model="model"
          :listing="listing"
          title="Street View"
          description="When Street View is open, the details of the viewed property will show up here."
          @show-location="args => $emit('showLocation', args)"
          @open-street-view="args => $emit('openStreetView', args)"
          @committed="onVerified"
          :properties="streetViewProperty ? [streetViewProperty] : []">
          <template #default v-if="streetViewAddressLabel && !streetViewProperty">
            <div class="d-flex flex-row align-items-center rounded border px-3 py-1">
              <div class="flex-grow-1 me-3">
                {{ streetViewAddressLabel }}
              </div>
              <button class="btn btn-flat btn-sm" @click="lookupStreetViewProperty">
                Lookup
              </button>
            </div>
          </template>
        </VerificationTool>

        <!-- Assessor Database -->
        <!-- TODO easy...the output of the AssessorSearch is properties and will flow nicely into the new VerificationTool -->
        <VerificationTool
          v-else-if="tab === 'assessor'"
          :model="model"
          :listing="listing"
          title="Assessor Database"
          @show-location="args => $emit('showLocation', args)"
          @open-street-view="args => $emit('openStreetView', args)"
          @committed="onVerified"
          :properties="assessorProperties"
        >
          <template #description>
            Search the <a :href="listing.assessorUrl" target="_blank" class="text-muted">assessor database</a> for
            information about properties.
          </template>
          <AssessorSearchView :city="city" @select="onAssessorProperties"/>
        </VerificationTool>
      </div>
      <!-- Map -->
      <!-- TODO We don't currently pull in GIS plot geometries, but we could. And if we do, we could show boundaries on the map and correctly choose the property when the user clicks the map -->
<!--      <VerificationTool-->
<!--        :model="model"-->
<!--        :listing="listing"-->
<!--        title="From the Map (coming soon)"-->
<!--        description="Click a location on the map to view its details here."-->
<!--        @show-location="args => $emit('showLocation', args)"-->
<!--        @open-street-view="args => $emit('openStreetView', args)"-->
<!--        @committed="onVerified"-->
<!--        :properties="[]"/>-->
    </template>
</template>

<script>
import {Listing} from "@/models/Listing";
import AssessorSearchView from "@/components/AssessorSearchView.vue";
import VerificationTool from "@/components/listing/VerificationTool.vue";
import {License} from "@/models/License";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {AppModel} from "@/models/AppModel";
import SuggestedListingProperty from "@/components/SuggestedListingProperty.vue";
import IconButton from "@/components/common/IconButton.vue";
import InputWithButton from "@/components/common/InputWithButton.vue";

export default {
  components: {InputWithButton, IconButton, SuggestedListingProperty, SpinnerView, AssessorSearchView, VerificationTool},
  emits: ['committed','showLocation','openStreetView'],
  props: {
    model: AppModel,
    listing: Listing,
    selectedLicense: License,
    streetViewAddressLabel: String
  },
  data() {
    return {
      saving: false,
      streetViewProperty: null,
      assessorProperties: [],
      nearbyRadius: 200,
      nearbyProperties: [],
      loadingNearbyProperties: false,
      licenseQuery: '',
      isSearchingLicenses: false,
      licenses: null,
      tab: 'recommended'
    };
  },
  watch: {
    streetViewAddressLabel() {
      this.streetViewProperty = null;
    },
    listing: {
      immediate: true,
      handler(newListing, oldListing) {
        if (newListing?.id !== oldListing?.id) {
          this.refreshNearbyProperties();
          if (this.$refs.scroller) {
            this.$refs.scroller.scrollTo(0, 0);
          }
        }
      }
    },
    tabs() {
      if (this.tabs.filter(tab => tab.id === this.tab).length === 0) {
        this.tab = this.tabs[0].id;
      }
    }
  },
  methods: {
    async searchLicenses() {
      if (this.licenseQuery) {
        this.isSearchingLicenses = true;
        try {
          const results = await this.model.licenses.search(this.licenseQuery);
          this.licenses = results.map(result => result.item);
        } finally {
          this.isSearchingLicenses = false;
        }
      } else {
        this.licenses = null;
      }
    },
    refreshNearbyProperties() {
      this.loadingNearbyProperties = true;
      this.listing.findNearbyProperties(this.nearbyRadius)
      .then(properties => this.nearbyProperties = properties)
      .finally(() => this.loadingNearbyProperties = false);
    },
    lookupStreetViewProperty() {
      this.city.normalizeProperty(this.streetViewAddressLabel)
      .then(properties => properties[0])
      .then(property => this.streetViewProperty = property);
    },
    resetVerification({notes}) {
      this.saving = true;
      this.listing.verifyProperty(null, notes)
      .then(() => this.saving = false);
    },
    onVerified(result) {
      this.saving = false;
      this.$emit('committed',result);
    },
    onAssessorProperties(properties) {
      this.assessorProperties = properties;
    }
  },
  computed: {
    nearbyRadiusMiles() {
      let miles =  0.000621371 * this.nearbyRadius;
      return Math.round(100 * miles) / 100;
    },
    tabs() {
      let tabs = [
        {
          id: 'recommended',
          label: 'Recommendations',
          icon: 'exclamation-circle'
        },
        {
          id: 'licenses',
          label: 'Existing Licenses',
          icon: 'file-earmark-check'
        },
        {
          id: 'assessor',
          label: 'Assessor Database',
          icon: 'search'
        }
      ];
      if (this.streetViewAddressLabel) {
        tabs.push({
          id: 'streetview',
          label: 'Street View',
          icon: 'binoculars'
        });
      }
      return tabs;
    },
    city() {
      return this.listing.city;
    },
    licenseProperties() {
      let properties = [];
      if (this.selectedLicense) {
        properties.push(this.selectedLicense.property);
      }
      this.licenses?.forEach(license => {
        properties.push(license.property);
      });
      return properties.filter(property => !!property);
    }
  }
}
</script>
