<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/logo.png" :height="`${size}`" />
    <template v-if="showTitle">
      <div class="h1 fw-bold mb-1">STR Enforcement</div>
      <template v-if="showSubTitle">
        <div class="small">Empowering municipalities - one rental at a time</div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: () => 36
    },
    showTitle: Boolean,
    showSubTitle: Boolean
  }
}
</script>
