<template>
  <ListingsComponent
    :city-id="cityId"
    :selected-listing-id="selectedListingId"
    tab="violations"
    :noun="showPendingLicenses ? 'listing' : 'violation'"
    :filter="filter"
    :sort-options="sortOptions"
    :default-show-whitelist="onlyShowWhitelist"
    ref="listings"
  >
    <template #empty>
      <div class="d-flex flex-column align-items-center p-4">
        <h6 class="text-center">
          There are currently no known violations.
        </h6>
        <p class="text-center">
          If a listing's address does not match any licenses in the database, it will be flagged as a violation and
          appear here automatically.
        </p>
      </div>
    </template>
    <template #filters>
      <BCheckbox v-model="showPendingLicenses" :label="`Show listings with pending licenses`" />
    </template>
  </ListingsComponent>
</template>
<script>
import ListingsComponent from "@/components/ListingsComponent.vue";
import {compareStatuses} from "@/models/LicenseStatus";
import BCheckbox from "@/components/common/BCheckbox.vue";
export default {
  components: {BCheckbox, ListingsComponent},
  props: {
    cityId: String,
    selectedListingId: String,
    onlyShowWhitelist: Boolean
  },
  data() {
    return {
      showPendingLicenses: false
    };
  },
  watch: {
    showPendingLicenses() {
      this.$refs.listings.filterListings();
    }
  },
  computed: {
    sortOptions() {
      return [
        {
          value: 'status',
          text: 'License status',
          compare: (l1, l2, model) => {
            const s1 = model.lookupLicenseForListing(l1, true)?.statusWithConfig;
            const s2 = model.lookupLicenseForListing(l2, true)?.statusWithConfig;
            if (s1 && s2) {
              return compareStatuses(s1, s2);
            } else if (s1) {
              return 1;
            } else if (s2) {
              return -1;
            } else {
              return 0;
            }
          }
        },
        {
          value: 'dateVerified',
          text: 'Date verified',
          compare: (l1, l2) => {
            const d1 = l1.dateVerified;
            const d2 = l2.dateVerified;
            console.log(d1, d2);
            if (d1 && d2) {
              return d2 - d1;
            } else if (d1) {
              return -1;
            } else if (d2) {
              return 1;
            } else {
              return 0;
            }
          }
        }
      ];
    }
  },
  methods: {
    filter(listing, model) {
      if (!listing.isPropertyVerified) {
        return false;
      }

      const license = model.lookupLicenseForListing(listing);
      return !license || license.isInactive || (this.showPendingLicenses && license.isPending);
    }
  }
}
</script>
