<template>
  <div class="d-flex flex-column h-100 overflow-hidden">

    <!-- Top Bar -->
    <div :class="`flex-shrink-0 p-0`">
      <div class="d-flex flex-row align-items-center gap-0 w-100">
        <IconButton icon="x-lg" class="flex-shrink-0" @click="close"/>
        <div class="d-flex flex-row align-items-center justify-content-start gap-0 flex-grow-1 overflow-auto">
          <IconButton icon="pencil" title="Edit" @click="edit"/>
          <IconButton
            v-if="license.canRenew"
            icon="arrow-clockwise"
            @click="toggleExpandMode('renew')"
            :class="{active:expandMode === 'renew'}"
          />
          <IconButton
            icon="trash"
            title="Delete"
            @click="toggleExpandMode('delete')"
            :class="{active:expandMode === 'delete'}"
          />
          <PrintLetterButton v-if="showViolationLetterButton" :model="violationLetterModel" />
        </div>
        <div v-if="$slots.buttons" class="d-flex flex-row align-items-center gap-0 flex-shrink-0 border-left border-gray">
          <slot name="buttons" />
        </div>
      </div>
    </div>
    <BCollapse :show="expandMode === 'delete'">
      <div class="p-3 border-top">
        <label class="fw-bold mb-3">
          Are you sure you want to <template v-if="license.isDeleted"><i class="text-danger">permanently </i></template>delete this
          license?
        </label>
        <div class="d-flex flex-row">
          <div class="w-50 pe-1">
            <a class="btn btn-outline-danger w-100" @click="commitDelete">Yes, delete it</a>
          </div>
          <div class="w-50 ps-1">
            <a class="btn btn-outline-dark w-100" @click="expandMode = null">No, keep it</a>
          </div>
        </div>
      </div>
    </BCollapse>
    <BCollapse :show="expandMode === 'renew'">
      <div class="p-3 border-top">
        <label class="fw-bold mb-3">
          Are you sure you want to renew this license?
        </label>
        <div class="d-flex flex-row">
          <div class="w-50 pe-1">
            <a class="btn btn-outline-danger w-100" @click="commitRenew">Yes, renew it</a>
          </div>
          <div class="w-50 ps-1">
            <a class="btn btn-outline-dark w-100" @click="expandMode = null">No</a>
          </div>
        </div>
      </div>
    </BCollapse>

    <div class="flex-grow-1 d-flex overflow-hidden border-top">
      <div class="w-100 overflow-auto d-flex flex-column gap-3 pb-3" ref="scroller">

        <div>
          <b-image
            v-if="license.imageUrl"
            :src="license.imageUrl"
            image-class="w-100"
            auto-size
          />

          <!-- Property Info -->
          <div class="d-flex flex-row gap-1 mx-3 pt-3">
            <div class="d-flex flex-column gap-0">
              <div class="fw-semibold">
                {{ license.addressFormatted }}
              </div>
              <div class="text-muted">
                <template v-if="license.number">License #{{ license.number }}</template>
              </div>
              <AssessorLink :property="license.property" class="text-muted"/>
            </div>
            <div class="d-flex flex-column align-items-end ms-auto">
              <LicenseStatusView :license="license"/>
              <LicenseDateView :license="license"/>
            </div>
          </div>
        </div>

        <!-- Emergency Contact -->
        <div class="d-flex flex-column gap-2 px-3 pt-3 border-top">
          <div class="text-uppercase fw-semibold">
            Emergency Contact(s)
          </div>
          <div class="d-flex flex-column gap-0">
            <div class="d-flex flex-column gap-0">
              <div v-for="owner in license.contact.owners" :key="owner">
                {{ owner }}
              </div>
            </div>
            <div>
              {{ license.contact.address }}
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between" v-if="license.contact.email">
              <div>{{ license.contact.email }}</div>
              <a :href="`mailto:${license.contact.email}`" target="_blank" class="btn btn-flat" title="Send email">
                <i class="bi-send" />
              </a>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-between" v-if="license.contact.phone">
              <div>{{ formatPhone(license.contact.phone) }}</div>
              <a :href="`tel:${license.contact.phone}`" target="_blank" class="btn btn-flat" title="Call">
                <i class="bi-telephone" />
              </a>
            </div>
          </div>
        </div>

        <!-- Details -->
        <div class="d-flex flex-column gap-2 pt-3 border-top">
          <div class="text-uppercase fw-semibold mx-3">
            Rental Details
          </div>
          <table class="table table-striped table-borderless small mb-0">
            <tbody>
            <tr v-for="field in form.fields" :key="field.id">
              <td :class="['ps-3', {'fw-bold':field.isHeader}]">
                {{ field.label }}
              </td>
              <td class="text-end pe-3">
                {{ field.isHeader ? '' : (license.getCustomFieldValue(field.id) || '-') }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- Listings -->
        <div class="d-flex flex-column gap-1 pt-3 border-top">
          <div class="text-uppercase fw-semibold mx-3">
            Listings
          </div>
          <div v-if="listings.length === 0" class="text-muted mx-3">
            This license hasn't been matched to any listings yet.
          </div>
          <div v-else class="list-group list-group-flush">
            <router-link
              v-for="listing in listings"
              :key="listing.id"
              :to="{name:'listing-detail',params:{selectedListingId:listing.id}}"
              class="text-dark text-decoration-none list-group-item list-group-item-action"
            >
              <ListingItemView
                :model="model"
                :value="listing"
                hide-address
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/common/IconButton.vue";
import {License} from "@/models/License";
import {AppModel} from "@/models/AppModel";
import {Session} from "@/models/User";
import LicenseStatusView from "@/components/LicenseStatusView.vue";
import BImage from "@/components/common/BImage.vue";
import {LicenseApplicationForm} from "@/models/LicenseApplicationForm";
import LicenseDateView from "@/components/LicenseDateView.vue";
import BCollapse from "@/components/common/BCollapse.vue";
import ListingItemView from "@/components/ListingItemView.vue";
import AssessorLink from "@/components/common/AssessorLink.vue";
import {LicenseLetterModel} from "@/models/LetterModel";
import PrintLetterButton from "@/components/letters/PrintLetterButton.vue";

export default {
  components: {
    PrintLetterButton,
    AssessorLink,
    ListingItemView,
    BCollapse,
    LicenseDateView,
    BImage,
    LicenseStatusView,
    IconButton,
  },
  emits: ['close', 'edit'],
  props: {
    model: AppModel,
    license: License
  },
  data() {
    return {
      expandMode: null,
      notes: '',
      loading: false,
      scrolled: false,
      printing: false,
      saving: false
    };
  },
  watch: {
    license() {
      this.$refs.scroller.scrollTo({top: 0, behavior: 'instant'});
    }
  },
  methods: {
    close(message) {
      this.$emit('close', message || false);
    },
    updateScroll(e) {
      this.scrolled = e.target.scrollTop >= 50;
    },
    toggleExpandMode(expandMode) {
      this.expandMode = this.expandMode === expandMode ? null : expandMode;
    },
    edit() {
      this.$emit('edit');
    },
    commitDelete() {
      this.license.delete(this.license.isDeleted)
      .then(() => this.expandMode = null);
    },
    commitRenew() {
      this.license.renew()
      .then(() => this.expandMode = null);
    },
  },
  computed: {
    violationLetterModel() {
      return new LicenseLetterModel(this.license);
    },
    showViolationLetterButton() {
      return !this.isMobile && this.violationLetterOptions.length > 0;
    },
    violationLetterOptions() {
      return this.model.cityInfo.getLetters(false);
    },
    listings() {
      return this.model.lookupListingsForLicense(this.license);
    },
    showDeleteButton() {
      const user = Session.currentUser.value;
      return user && user.role === 'admin';
    },
    form() {
      return new LicenseApplicationForm(this.license.city);
    }
  }
}
</script>
