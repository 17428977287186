<template>
  <ModalView
    v-model="showModal"
    title="Import Licenses" size="lg"
    hide-footer
    :scrollable="!!parseResult"
  >
    <FileInput
      v-if="!file"
      v-model="file"
      accept=".csv,.tsv,.xls,.xlsx"
      :supported-formats="['Excel, CSV, TSV']"
    />
    <div v-else class="w-100 d-flex flex-column gap-3">
      <div class="d-flex flex-row align-items-center rounded gap-2 p-2" style="border: 1px dashed var(--bs-border-color)">
        <div class="small">File:</div>
        <div class="fw-bold">{{ file.name }}</div>
        <div class="ms-auto small">{{ formatBytes(file.size) }}</div>
        <button type="button" class="btn-sm btn btn-flat" @click="file = null" :disabled="loading">
          <i class="bi-trash-fill" />
        </button>
      </div>

      <div v-if="loading" class="d-flex flex-column align-items-center gap-3 text-center">
        <spinner-view />
        <div class="h6 mx-5">Uploading, parsing, and comparing these licenses to the existing ones.<br/>This may take a minute...</div>
      </div>
      <div v-else-if="!parseResult">
        Could not parse file
      </div>
      <div v-else class="d-flex flex-column gap-3">
        <div>
          Found <b>{{ formatNumber(parseResult.totalCount, 'license') }}</b>
        </div>
        <div v-if="canCommit">
          Please review the updates and formatting errors below before confirming the import.
        </div>
        <div v-else>
          The file doesn't appear to include any changes since the last import.
        </div>
        <LicenseChangeList :changes="newLicenses" noun="new license" />
        <LicenseChangeList :changes="statusAndPropertyIdUpdates" noun="parcel ID + status update" />
        <LicenseChangeList :changes="statusUpdates" noun="status update" />
        <LicenseChangeList :changes="propertyIdUpdates" noun="parcel ID update" />
        <LicenseChangeList :changes="otherUpdates" noun="other update" />
        <LicenseChangeList :changes="missingPropertyIds" noun="missing parcel ID" />
        <LicenseParseErrorList noun="error" :errors="errors" />
      </div>
    </div>
  </ModalView>
</template>

<script>
import {AppModel} from "@/models/AppModel";
import ModalView from "@/components/common/ModalView.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import FileInput from "@/components/common/FileInput.vue";
import LicenseParseErrorList from "@/components/licenses/LicenseParseErrorList.vue";
import LicenseChangeList from "@/components/licenses/LicenseChangeList.vue";

export default {
  components: {
    LicenseChangeList,
    LicenseParseErrorList,
    FileInput,
    SpinnerView,
    ModalView
  },
  props: {
    show: Boolean,
    model: AppModel
  },
  data() {
    return {
      mode: null,
      loading: false,
      parsing: false,
      file: null,
      parseResult: null
    };
  },
  watch: {
    file() {
      this.loadFile();
    }
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(show) {
        this.$emit('update:show', show);
      }
    },
    city() {
      return this.model.cityInfo;
    },
    canCommit() {
      return this.parseResult?.addedCount || this.parseResult?.updatedCount;
    },
    newLicenses() {
      return this.parseResult?.changes.filter(change => change.isAdded && !!change.propertyId);
    },
    removedLicenses() {
      return this.parseResult?.changes.filter(change => !!change.propertyId && change.type === 'remove');
    },
    statusAndPropertyIdUpdates() {
      return this.parseResult?.changes.filter(change =>
        change.isUpdated && change.hasPropertyIdUpdate && change.hasStatusUpdate
      );
    },
    propertyIdUpdates() {
      return this.parseResult?.changes.filter(change =>
        change.isUpdated && change.hasPropertyIdUpdate && !change.hasStatusUpdate
      );
    },
    statusUpdates() {
      return this.parseResult?.changes.filter(change =>
        change.isUpdated && !change.hasPropertyIdUpdate && change.hasStatusUpdate
      );
    },
    missingPropertyIds() {
      return this.parseResult?.changes.filter(change => change.isUpdated && !change.propertyId);
    },
    otherUpdates() {
      return this.parseResult?.changes.filter(change =>
        change.isUpdated && !change.hasPropertyIdUpdate && !change.hasStatusUpdate
      );
    },
    errors() {
      return this.parseResult?.errors;
    }
  },
  methods: {
    async loadFile() {
      this.loading = true;
      try {
        if (this.file) {
          console.log(`Loading file ${this.file}`);
          this.parseResult = await this.model.parseLicenses(this.file);
        } else {
          console.log('No file to load');
          this.parseResult = null;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
