<template>
  <LayoutCard title="License Workflow">
    <template #description>
      <div>
        The default license workflow includes the following statuses:
      </div>
      <ul>
        <li>
          Pending: The license was applied for but has not been paid for and/or approved yet.
        </li>
        <li>
          Active: License is approved and paid for, and the property is allowed to host an STR.
        </li>
        <li>
          Expired: License was previously active, but has since expired. The license must be renewed to become active
          again.
        </li>
        <li>
          Suspended: License was previously active but has been temporarily suspended. The reasons for suspension must
          be resolved to make the license active again.
         </li>
        <li>
          Withdrawn: License was applied for but later withdrawn by the applicant.
        </li>
      </ul>
      <div>
        If you require additional statuses to support a more complex workflow, you may define these statuses below.
      </div>
    </template>

    <template #footer v-if="changed">
      <button type="button" class="btn btn-flat ms-1" @click="reset">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit">
        Save Changes
      </button>
    </template>

    <template #flush>
      <div v-if="workflow" class="list-group list-group-flush border-top border-bottom">
        <LicenseWorkflowEditorRow
          v-for="(status, i) in workflow.statuses"
          :key="status.id"
          class="list-group-item"
          :show-labels="i === 0"
          :workflow="workflow"
          :status="status"
        />
        <div class="d-flex flex-column align-items-start p-4">
          <button type="button" class="btn btn-primary" @click="addStatus">
            Add Custom Status
          </button>
        </div>
      </div>
    </template>
  </LayoutCard>
</template>

<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import {City} from "@/models/City";
import {LicenseWorkflow} from "@/models/LicenseWorkflow";
import LicenseWorkflowEditorRow from "@/components/admin-city/licenses/LicenseWorkflowEditorRow.vue";
export default {
  components: {LicenseWorkflowEditorRow, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      workflow: null,
      changed: false
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        this.workflow = new LicenseWorkflow(this.city);
      }
    },
    workflow: {
      deep: true,
      handler() {
        this.changed = true;
      }
    }
  },
  methods: {
    reset() {
      this.workflow.reset();
      this.$nextTick(() => this.changed = false);
    },
    commit() {
      this.workflow.commit().then(() => this.changed = false);
    },
    addStatus() {
      this.workflow.addStatus();
    },
    removeStatus(id) {
      this.workflow.removeStatus(id);
    }
  }
}
</script>
