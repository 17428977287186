<template>
  <AdminComponent>

    <template #topbar>
      <h4 class="flex-grow-1 mb-0">
        {{ cities.length }} Municipalities
      </h4>
      <router-link class="btn btn-primary" :to="{name:'admin-new-city'}">
        <i class="bi-plus-lg"/> Add Municipality
      </router-link>
    </template>

    <div v-if="loading" class="text-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div>
        Loading Municipalities
      </div>
    </div>
    <div class="list-group list-group-flush border-top">
      <template v-for="city in cities" :key="city.id">
        <router-link
          :to="{name:'admin-city',params:{cityId:city.id}}"
          style="cursor:pointer"
          class="list-group-item py-3 list-group-item-action">
          <div class="d-flex flex-row align-items-center">
            <CityIcon :size="36" :city="city" thick-border class="me-3" />
            <div class="me-3 fw-semibold">
              {{ city.label }}
            </div>
            <div class="flex-grow-1"></div>
            <div class="text-muted small">
              <template v-if="city.dateChecked">
                Listings updated: {{ city.dateChecked.toDate().toLocaleString() }}
              </template>
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </AdminComponent>
</template>

<script>
import {City} from "@/models/City";
import AdminComponent from "@/components/AdminComponent.vue";
import CityIcon from "@/components/common/CityIcon.vue";
export default {
  components: {
    CityIcon,
    AdminComponent
  },
  data() {
    return {
      loading: false,
      cities: [],
      editing: null
    };
  },
  mounted() {
    console.log('Loading cities');
    this.loading = true;
    City.loadCities(true)
    .then(cities => this.cities = cities.sort((c1, c2) => (c1.name || '').localeCompare(c2.name)))
    .then(() => this.loading = false);
  }
}
</script>
