<template>
  <textarea
    ref="textarea"
    v-bind="$attrs"
    v-model="value"
    class="form-control"
    :style="`resize:none; min-height:${minRows*24+12}px; max-height:${maxRows*24+12}px`"/>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: String,
    maxRows: {
      type: Number,
      default: () => 4
    },
    minRows: {
      type: Number,
      default: () => 2
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    this.updateSize();
  },
  watch: {
    modelValue() {
      this.updateSize();
    }
  },
  methods: {
    updateSize() {
      this.$refs.textarea.style.height = "auto";
      this.$nextTick(() => {
        this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
      });
    }
  }
}
</script>
