import {getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import {getApp} from '@/firebase';
import {BaseModel} from "@/models/BaseModel";
import {ref} from "vue";
import {callApi} from "@/api";

const auth = getApp().then(app => getAuth(app));

function isEmail(text) {
  return text.match(/.+@.+\..+/);
}

function usernameToEmail(email) {
  if (!isEmail(email)) {
    return `${email}@gp-cog.web.app`;
  } else {
    return email;
  }
}

function emailToUsername(email) {
  return email.replace('@gp-cog.web.app', '');
}

async function convertUser(user, forceRefresh = false) {
  const tokenResult = await user.getIdTokenResult(forceRefresh) || {};
  const customClaims = tokenResult.claims;
  return new User({
    id: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName,
    photoURL: user.photoURL,
    phoneNumber: user.phoneNumber,
    disabled: user.disabled,
    dateCreated: user.metadata.creationTime,
    dateLastLoggedIn: user.metadata.lastSignInTime,
    role: (customClaims || {}).role,
    cities: (customClaims || {}).cities || []
  });
}


export const Session = {
  currentUserChecked: ref(false),
  currentUser: ref(null),
  login: async function(username, password) {
    return signInWithEmailAndPassword(await auth, usernameToEmail(username), password);
  },
  async logout() {
    return signOut(await auth);
  },
  async sendPasswordReset(email) {
    return sendPasswordResetEmail(await auth, email)
  }
};

auth.then(auth => {
  onAuthStateChanged(auth, fbUser => {
    if (fbUser) {
      convertUser(fbUser).then(user => {
        Session.currentUserChecked.value = true;
        Session.currentUser.value = user;
        doCall('logUserActivity');
      });
    } else {
      Session.currentUserChecked.value = true;
      Session.currentUser.value = null;
    }
  });
});



function doCall(name, data) {
  return callApi(name, data);
}

export class NewUser extends BaseModel {

  constructor() {
    super({});
  }

  get username() {
    return this._get('username');
  }

  set username(username) {
    this._set('username', username);
  }

  get password() {
    return this._get('password');
  }

  set password(password) {
    this._set('password', password);
  }

  get displayName() {
    return this._get('displayName');
  }

  set displayName(displayName) {
    this._set('displayName', displayName);
  }

  save() {
    let email = usernameToEmail(this.username);
    return doCall('createUser', {
      email,
      password: this.password,
      displayName: this.displayName
    })
    .then(data => new User(data));
  }
}

export class User extends BaseModel {

  static loadUsers() {
    return doCall('listUsers')
    .then(users => users.map(user => new User(user)));
  }

  constructor(data) {
    super(data);
  }

  updateUser(data) {
    this.loading = true;
    this.merge(data);
    return doCall('updateUser', {...data, id:this.id})
    .then(d => {
      console.log(d);
      this.merge(d);
    })
    .finally(() => this.loading = false);
  }

  get isCurrentUser() {
    const currentUser = Session.currentUser.value;
    return currentUser && this.id === currentUser.id;
  }

  get id() {
    return this._get('id');
  }

  get initials() {
    if (this.displayName) {
      const names = this.displayName.split(/ /g);
      if (names.length === 1) {
        return names[0].substr(0, 2);
      } else {
        return `${names[0][0]}${names[1][0]}`;
      }
    } else {
      return this.email.substr(0, 2);
    }
  }

  get username() {
    return emailToUsername(this.email);
  }

  get email() {
    return this._get('email');
  }

  get displayName() {
    return this._get('displayName');
  }

  set displayName(displayName) {
    this.updateUser({displayName});
  }

  get cities() {
    return this._get('cities') || [];
  }

  set cities(cities) {
    this.updateUser({cities});
  }

  get role() {
    return this._get('role');
  }

  set role(role) {
    this.updateUser({role});
  }

  get isAdmin() {
    return this.role === 'admin';
  }

  get lastLogin() {
    const date = this._get('dateLastLoggedIn');
    return date ? new Date(date).toLocaleDateString({year:'numeric'}) : 'Never';
  }

  async sendPasswordResetEmail() {
    this.loading = true;
    return sendPasswordResetEmail(await auth, this.email)
    .finally(() => this.loading = false);
  }

  generateLink(type) {
    this.loading = true;
    return doCall('generateLink', {id:this.id, type})
    .finally(() => this.loading = false);
  }

  save(draft) {
    return callApi('updateUser', {
      userId: this.id,
      ...draft._data
    })
    .then(data => {
      this._data = {
        ...this._data,
        ...data
      };
    });
  }

  delete() {
    return doCall('deleteUser', {id:this.id});
  }

  updatePassword(password) {
    return this.updateUser({password});
  }
}
