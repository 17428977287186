export class LetterModel {

  get city() {
    return this.property?.city;
  }

  get owners() {
    const property = this.property;
    return (property && property.owners) || [];
  }

  get addressFormatted() {
    const property = this.property;
    return property ? property.addressFormatted : '';
  }

  get ownerAddressFormatted() {
    const property = this.property;
    return property ? property.ownerAddress : '';
  }

  get listingUrl() {
    // By default, there is no listing URL
    return null;
  }

  get property() {
    throw new Error('Method not implemented');
  }

  get canFetchScreenshot() {
    // By default, screenshots aren't supported
    return false;
  }

  fetchScreenshot() {
    return Promise.reject(new Error('Method not implemented'));
  }

}

export class ListingLetterModel extends LetterModel {

  constructor(/* Listing */ listing) {
    super();
    this.listing = listing;
  }

  get property() {
    return this.listing.verifiedProperty;
  }

  get listingUrl() {
    return this.listing.url;
  }

  get canFetchScreenshot() {
    return true;
  }

  fetchScreenshot() {
    return this.listing.fetchScreenshot();
  }

  get printOptions() {
    return this.city?.getLetters(true) || []
  }
}

export class LicenseLetterModel extends LetterModel {

  constructor(/* License */ license) {
    super();
    this.license = license;
  }

  get property() {
    return this.license.property;
  }

  get printOptions() {
    return this.city?.getLetters(false) || []
  }
}
