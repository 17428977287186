<template>
  <BCollapsible class="border rounded">
    <template #title>
      <div>{{ formatNumber(uncommittedChanges.length, noun) }}</div>
      <button v-if="uncommittedChanges.length > 0" type="button" class="ms-auto btn btn-sm btn-primary" @click="commit" :disabled="!canCommit">
        <i v-if="canCommit" class="bi-cloud-upload me-1" />
        <spinner-view v-else small />
        Confirm{{ uncommittedChanges.length > 1 ? ` all ${uncommittedChanges.length}` : '' }}
      </button>
    </template>
    <div class="d-flex flex-column gap-3 align-items-stretch" v-if="uncommittedChanges.length > 0">
      <div class="d-flex flex-row align-items-center">
        <BCheckbox v-model="showCommitted" label="Show confirmed licenses" />
      </div>
      <LicenseChangeView
        v-for="change in (showCommitted ? changes : uncommittedChanges)"
        :key="change.id"
        :change="change"
      />
    </div>
  </BCollapsible>
</template>

<script>
import BCollapsible from "@/components/common/BCollapsible.vue";
import LicenseChangeView from "@/components/licenses/LicenseChangeView.vue";
import {LicenseParseChange} from "@/models/LicenseParseResult";
import SpinnerView from "@/components/common/SpinnerView.vue";
import BCheckbox from "@/components/common/BCheckbox.vue";

export default {
  components: {BCheckbox, SpinnerView, BCollapsible, LicenseChangeView},
  props: {
    noun: String,
    changes: /* LicenseParseChange[] */ Array
  },
  data() {
    return {
      showCommitted: false
    };
  },
  methods: {
    commit() {
      LicenseParseChange.commitChanges(this.uncommittedChanges);
    }
  },
  computed: {
    uncommittedChanges() {
      return this.changes.filter(change => !change.committed);
    },
    canCommit() {
      return this.uncommittedChanges.length > 0 && !this.committing;
    },
    committing() {
      return !!this.changes.find(change => change.committing);
    }
  }
}
</script>
