<template>
  <div class="mb-3">
    Please review the license information below. If everything looks accurate, click "Finish" to complete your license
    application.
  </div>
  <div class="mb-3">
    <h6 class="fw-semibold">Property</h6>
    <div>{{ model.formattedAddress }}</div>
    <div v-if="model.parcelId">Parcel #{{ model.parcelId }}</div>
  </div>
  <div class="mb-3">
    <h6 class="fw-semibold">Contact</h6>
    <div>{{ model.contact.name }}</div>
    <div>{{ model.contact.address }}</div>
    <div>{{ model.contact.email }}</div>
    <div>{{ formatPhone(model.contact.phone) }}</div>
  </div>
  <div class="mb-3">
    <h6 class="fw-semibold">Listings</h6>
    <div v-if="model.listings.length === 0">None</div>
    <div v-for="listing in model.listings" :key="listing" class="mb-3">
      <ListingRegistrationItem small :city-id="model.cityId" :url="listing" />
    </div>
  </div>
  <div v-if="model.customFields.length > 0" class="mb-3">
    <h6 class="fw-semibold">Additional Details</h6>
    <LicenseApplicationCustomFields :model="model" />
  </div>
</template>

<script>
import {LicenseEditorModel} from "@/models/LicenseEditorModel";
import ListingRegistrationItem from "@/components/register/ListingRegistrationItem.vue";
import LicenseApplicationCustomFields from "@/components/register/LicenseApplicationCustomFields.vue";

export default {
  components: {LicenseApplicationCustomFields, ListingRegistrationItem},
  props: {
    model: LicenseEditorModel
  }
}
</script>
