<template>
  <div v-if="accessStatusDelayed && accessStatusDelayed !== 'granted'" class="d-flex flex-column align-items-center justify-content-center flex-grow-1 ">
    <SplashView>
      <div class="d-flex flex-column align-items-center">
        <template v-if="accessStatusDelayed === 'pending'">
          <div class="spinner-border mb-3"/>
          <h4>Checking your access to this page.</h4>
        </template>
        <template v-else>
          <h4>
            We're sorry. You don't have access to this page.
          </h4>
          <router-link :to="{name:'home'}" class="btn btn-lg">Go Back</router-link>
        </template>
      </div>
    </SplashView>
  </div>
  <template v-else>
    <BCollapse v-if="warningMessage" :show="!warningDismissed">
      <div class="bg-danger text-white px-3 py-1 small d-flex flex-row align-items-center">
        <div class="flex-grow-1 text-center fw-semibold">
          {{ warningMessage }}
        </div>
        <button type="button" class="text-white btn btn-sm btn-icon flex-shrink-0" @click="warningDismissed = true">
          <i class="bi-x-lg"/>
        </button>
      </div>
    </BCollapse>

    <slot v-if="hasTopBar" name="top" />

    <div v-if="!hasLeftBar" class="flex-grow-1 overflow-auto w-100 d-flex flex-column">
      <slot />
    </div>

    <div v-else class="flex-grow-1 d-flex flex-row align-items-stretch overflow-hidden justify-content-center">
      <div :class="`${wideLeftBar?'wide':''} d-md-flex d-${showRight ? 'none' : 'flex'} h-100 overflow-hidden left-bar `">
        <div class="h-100 w-100 d-flex flex-column">
          <slot name="header" />
          <div class="flex-grow-1 overflow-auto" style="scroll-behavior: smooth">
            <slot name="left" />
          </div>
        </div>
      </div>
      <div :class="`flex-grow-1 d-md-flex d-${ showRight ? 'flex' : 'none'} flex-column overflow-hidden`">
        <div class="d-block d-md-none">
          <slot name="header" />
        </div>
        <div class="flex-grow-1 overflow-auto">
          <slot />
        </div>
      </div>
    </div>

    <div v-if="hasLeftBar" class="d-flex flex-row d-md-none px-4 py-2 border-top">
      <button type="button" :class="`me-2 btn btn-flat flex-grow-1 fw-semibold ${showRight ? '' : 'text-primary'}`" @click.prevent="showRight = false">
        <i class="bi-list me-1"/> List
      </button>
      <button type="button" :class="`ms-2 btn btn-flat flex-grow-1 fw-semibold ${showRight ? 'text-primary' : ''}`" @click.prevent="showRight = true">
        <i class="bi-map me-1"/> Map
      </button>
    </div>

    <StaffPortalTourModal v-if="!skipTour" />
  </template>
</template>

<script>
import StaffPortalTourModal from "@/components/StaffPortalTourModal.vue";
import BCollapse from "@/components/common/BCollapse.vue";
import SplashView from "@/components/common/SplashView.vue";
export default {
  components: {SplashView, BCollapse, StaffPortalTourModal},
  props: {
    accessStatus: String,
    skipTour: Boolean,
    warningMessage: String,
    wideLeftBar: Boolean
  },
  data() {
    return {
      warningDismissed: false,
      showRight: false,
      accessStatusDelayed: this.accessStatus,
      transitionDate: new Date().getTime() + 1200
    };
  },
  mounted() {
    this.showRight = this.LocalStorage.get('showRight') || false;
  },
  watch: {
    accessStatus: {
      handler() {
        const delay = this.transitionDate - new Date();
        setTimeout(() => {
          this.accessStatusDelayed = this.accessStatus;
        }, Math.max(0, delay));
      },
      immediate: true
    },
    showRight() {
      this.LocalStorage.set('showRight', this.showRight);
    }
  },
  computed: {
    hasLeftBar() {
      return !!this.$slots.left;
    },
    hasTopBar() {
      return !!this.$slots.top;
    }
  }
}
</script>

<style>
.left-bar {
  transition: width 250ms;
}
@media (max-width: 767px) {
  .left-bar {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .left-bar {
    width: 25%;
    min-width: 300px;
    max-width: 500px;
  }
  .left-bar.wide {
    min-width: 500px;
    max-width: 800px;
    width: 50%;
  }
}
</style>
