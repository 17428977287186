<template>
  <LayoutCard
    title="Database"
    description="We need to know how to find the assessor's property records. This helps to efficiently verify listing addresses."
    class="mb-4">

    <div class="row mb-3">
      <label class="col-sm-12 col-md-2 col-form-label">Database Type</label>
      <div class="col">
        <select v-model="type" class="form-select">
          <option disabled>Select one:</option>
          <option value="cama">CAMA</option>
          <option value="vision">Vision</option>
          <option value="mapgeo">MapGeo</option>
          <option value="custom">Custom</option>
        </select>
      </div>
    </div>
    <div class="row" v-if="type">
      <label class="col-sm-12 col-md-2 col-form-label">Database URL</label>
      <div class="col">
        <input v-if="type === 'custom'" type="text" v-model="customUrl" class="form-control" />
        <a v-else :href="city.getRecordsUrlForType(type)" target="_blank" class="btn btn-flat btn-flush">
          {{ city.getRecordsUrlForType(type) }} <i class="bi-box-arrow-up-right ms-1"/>
        </a>
      </div>
    </div>

    <template v-if="changed" #footer>
      <button type="button" class="btn btn-flat ms-1" @click="reset">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit">
        Save
      </button>
    </template>
  </LayoutCard>
</template>
<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import {City} from "@/models/City";
export default {
  components: {LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      type: null,
      customUrl: null
    }
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        this.reset();
      }
    }
  },
  computed: {
    changed() {
      return this.city.recordsSourceType !== this.type || this.city.customRecordsSourceUrl !== this.customUrl;
    }
  },
  methods: {
    reset() {
      this.type = this.city.recordsSourceType;
      this.customUrl = this.city.customRecordsSourceUrl;
    },
    commit() {
      this.city.updateRecordsType(this.type, this.customUrl);
    }
  }
}
</script>
