import { Loader } from '@googlemaps/js-api-loader';

// TODO consider how to load this API key as an env var
const loader = new Loader({
  apiKey: "AIzaSyDOqYud38oaZms1EpdoLsxoBA63oDD-wes",
  version: "weekly",
  libraries: ["places"]
});

export default function() {
  return loader.load().then(google => {
    return new google.maps.Geocoder();
  });
}
