<template>
  <img :src="url" v-bind="$props" />
</template>

<script>
export default {
  props: {
    latlng: Object,
    showMarker: Boolean,
    zoom: {
      type: Number,
      default: () => 12
    },
    size: {
      type: Number,
      default: () => 240
    }
  },
  computed: {
    url() {
      return `https://maps.googleapis.com/maps/api/staticmap?` +
      `center=${this.latlng.lat},${this.latlng.lng}&` +
      `zoom=${this.zoom}&` +
      (this.showMarker ? `markers=color:red|${this.latlng.lat},${this.latlng.lng}&` : '') +
      `size=${this.size}x${this.size}&` +
      `key=AIzaSyDOqYud38oaZms1EpdoLsxoBA63oDD-wes`;
    }
  }
}
</script>
