<template>
  <LayoutCard :title="`Application Form Status - ${form.published ? 'Published' : 'Not Published'}`">
    <template #description>
      <template v-if="form.published">
        The license application form is currently accessible to the public at
        <a :href="form.url" target="_blank">{{ form.url }} <i class="bi-box-arrow-up-right"/></a>. Unpublishing it will hide it from public view.
      </template>
      <template v-else>
        The license application form is currently hidden from public view. Publishing it will make it accessible to the public.
      </template>
    </template>
    <button type="button" :class="`btn btn-${form.published ? 'outline-primary' : 'primary'}`" @click="togglePublished" :disabled="form.publishing">
      <spinner-view small v-if="form.publishing" /> {{ form.published ? 'Unpublish' : 'Publish' }}
    </button>
  </LayoutCard>

  <LayoutCard title="Application Payment Message">
    <template #description>
      After a user submits a license application, the message below is displayed to instruct the applicant how and where
      to pay for the license.
    </template>
    <template #footer v-if="confirmationTextChanged">
      <button type="button" class="btn btn-flat ms-1" @click="resetConfirmationText">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commitConfirmationText">
        Save Changes
      </button>
    </template>
    <textarea class="form-control" v-model="confirmationText" rows="4" />
  </LayoutCard>

  <LayoutCard title="Application Custom Fields">

    <template #description>
      By default, we ask applicants for the property address, contact information, and links to relevant short-term
      rental listings. You can also customize your own fields to gather additional information required for your
      licenses.
    </template>

    <template #footer v-if="changed">
      <button type="button" class="btn btn-flat ms-1" @click="reset">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit">
        Save Changes
      </button>
    </template>

    <template #flush>
      <div class="list-group list-group-flush border-top border-bottom">
        <div v-for="(field, i) in form.fields" :key="field.id" class="list-group-item d-flex flex-row p-4">
          <div class="py-2">{{ i+1 }}.</div>
          <div class="flex-grow-1 mx-3">
            <FormFieldEditor :field="field"/>
          </div>
          <div class="d-flex flex-column">
            <button type="button" class="btn btn-icon btn-flat" @click="removeCustomField(field.id)">
              <i class="bi-trash"/>
            </button>
            <button type="button" class="btn btn-icon btn-flat" @click="moveCustomField(field.id, -1)">
              <i class="bi-chevron-up"/>
            </button>
            <button type="button" class="btn btn-icon btn-flat" @click="moveCustomField(field.id, 1)">
              <i class="bi-chevron-down"/>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row px-4 py-2">
        <button class="btn btn-primary" type="button" @click="addCustomField">
          Add Field
        </button>
      </div>
    </template>
  </LayoutCard>
</template>

<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {LicenseApplicationForm} from "@/models/LicenseApplicationForm";
import FormFieldEditor from "@/components/common/FormFieldEditor.vue";
export default {
  components: {FormFieldEditor, SpinnerView, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      form: null,
      confirmationText: null,
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        console.log(`Building application form`);
        this.form = new LicenseApplicationForm(this.city);
        this.confirmationText = this.city.licenseApplicationConfirmationText;
        console.log(`Done building application form`);
      }
    }
  },
  computed: {
    confirmationTextChanged() {
      return this.confirmationText !== this.city.licenseApplicationConfirmationText;
    },
    changed() {
      return true;
    }
  },
  methods: {
    addCustomField() {
      this.form.addField();
    },
    removeCustomField(id) {
      this.form.removeField(id);
    },
    moveCustomField(id, byHowMany) {
      this.form.moveField(id, byHowMany);
    },
    reset() {
      this.form.reset();
    },
    commit() {
      this.form.commit();
    },
    togglePublished() {
      this.form.updatePublished(!this.form.published);
    },
    resetConfirmationText() {
      this.confirmationText = this.city.licenseApplicationConfirmationText;
    },
    commitConfirmationText() {
      this.city.updateLicenseApplicationConfirmationText(this.confirmationText);
    }
  }
}
</script>
