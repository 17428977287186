<template>
  <SearchList
    :search-fields-options="searchFieldOptions"
    :sort-options="sortOptions"
    :do-search="city.propertiesSearch">

    <template #table-header>
      <th>PID</th>
      <th>Address</th>
      <th>Owner</th>
      <th class="text-end">Sqft</th>
      <th class="text-end">Beds</th>
      <th class="text-end">Baths</th>
      <th class="text-end">Images</th>
      <th class="text-center">Details</th>
      <th class="text-center">Lat/Lng</th>
      <th class="text-center">Realtor Details</th>
    </template>

    <template #table-row="{item:property}">
      <td>
        <a :href="property.url" target="_blank" class="btn-link" @click.stop>
          {{ property.id }}
        </a>
      </td>
      <td>{{ property.addressFormatted }}</td>
      <td>
        <div v-for="owner in property.owners" :key="owner">
          {{ owner }}
        </div>
      </td>
      <td class="text-end">{{ property.realtorDetails.sqft || '--' }}</td>
      <td class="text-end">{{ property.realtorDetails.beds || '--' }}</td>
      <td class="text-end">{{ property.realtorDetails.baths || '--' }}</td>
      <td class="text-end">{{ property.imageCount }}</td>
      <td class="text-center">
        <i v-if="property.detailsImported" class="bi-check-lg" />
      </td>
      <td class="text-center">
        <i v-if="property.latLngImported" class="bi-check-lg" />
      </td>
      <td class="text-center">
        <i v-if="property.realtorDetailsImported" class="bi-check-lg" />
      </td>
    </template>

    <template #table-detail="{item:property}">
      <td colspan="10">
        <div class="border p-4 mx-2 mb-2 bg-white rounded">
          <PropertyDetailView :property="property" />
        </div>
      </td>
    </template>

  </SearchList>
</template>

<script>
import {City} from "@/models/City";
import PropertyDetailView from "@/components/admin-city/PropertyDetailView.vue";
import SearchList from "@/components/admin-city/SearchList.vue";

export default {
  components: {SearchList, PropertyDetailView},
  props: {
    city: City
  },
  computed: {
    searchFieldOptions() {
      return [
        {value:'address.street',text:'Street'},
        {value:'owner',text:'Owner'},
        {value:'id',text:'Parcel ID'}
      ];
    },
    sortOptions() {
      return this.searchFieldOptions;
    }
  }
}
</script>
