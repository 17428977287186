<template>
  <div class="d-flex flex-column gap-3">
    <div class="d-flex flex-row">
      <div class="flex-grow-1">
        <input type="text" placeholder="Question" v-model="field.label" class="form-control" />
      </div>
      <div class="w-25 ms-3">
        <!-- Type picker -->
        <select class="form-select" v-model="field.type">
          <option v-for="type in types" :key="type.value" :value="type.value">{{ type.label }}</option>
        </select>
      </div>
    </div>
    <div>
      <label class="form-label">Description</label>
      <textarea class="form-control" v-model="field.description" />
    </div>
    <div v-if="field.supportsRequiredFlag" class="form-check">
      <label class="form-check-label">Required</label>
      <input type="checkbox" class="form-check-input" v-model="field.required" />
    </div>
    <div v-if="field.hasOptions">
      <label class="form-label">Provide at least two options (enter each option on a separate line)</label>
      <textarea class="form-control" rows="4" v-model="options" />
      <div class="mt-3 form-check">
        <label class="form-check-label">Allow Filtering</label>
        <input type="checkbox" class="form-check-input" v-model="field.filterEnabled" />
      </div>
    </div>
    <div v-if="field.supportsDefault">
      <label class="form-label d-flex flex-row align-items-center">
        <div>Default Value</div>
        <button v-if="field.hasDefaultValue" type="button" class="ms-3 btn btn-sm btn-light" @click="field.defaultValue = null">
          <i class="bi-x-lg"/> Clear
        </button>
      </label>
      <FormFieldInput :field="field" v-model="field.defaultValue" hide-label />
    </div>
  </div>
</template>

<script>
import FormField, {TYPE_HEADER} from "@/models/FormField";
import {TYPE_TEXT,TYPE_MULTI,TYPE_SINGLE,TYPE_BOOLEAN,TYPE_NUMBER} from "@/models/FormField";
import FormFieldInput from "@/components/common/FormFieldInput.vue";
export default {
  components: {FormFieldInput},
  props: {
    field: FormField
  },
  computed: {
    types() {
      return [
        { value: TYPE_TEXT, label: 'Text' },
        { value: TYPE_BOOLEAN, label: 'Yes/No' },
        { value: TYPE_SINGLE, label: 'Single Select (radios)' },
        { value: TYPE_MULTI, label: 'Multi Select (checkboxes)'},
        { value: TYPE_NUMBER, label: 'Number' },
        { value: TYPE_HEADER, label: 'Section Header' }
      ];
    },
    options: {
      get() {
        return this.field.options.join('\n');
      },
      set(options) {
        this.field.options = options.split(/\n/g);
      }
    }
  }
}
</script>
