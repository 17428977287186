<template>
  <div v-if="!filter">
    No filter provided
  </div>
  <div v-else class="p-3 d-flex flex-column gap-3">
    <div class="small d-flex flex-row align-items-center gap-1">
      <label class="form-label mb-0">Sort by:</label>
      <select class="form-select-sm" v-model="filter.sortByStatus">
        <option :value="true">Status</option>
        <option :value="false">Street</option>
      </select>
    </div>
    <div class="small">
      <label class="form-label">Show licenses with status:</label>
      <BCheckbox
        v-for="status in filter.statusOptions"
        :key="status.code"
        v-model="filter.status[status.code]"
        :label="status.label"/>
    </div>
    <template v-if="filter.customFields.length > 0">
      <div v-for="field in filter.customFields" :key="field.id" class="small">
        <label class="form-label">Show licenses with {{ field.label.toLowerCase() }}:</label>
        <BCheckbox
          v-for="option in field.options"
          :key="`${field.id}_${option}`"
          v-model="filter[field.id][option]"
          :label="option" />
      </div>
    </template>
    <div class="small">
      <BCheckbox
        v-model="filter.onlyShowMissingProperties"
        label="Only show licenses with unverified addresses" />
    </div>
  </div>
</template>

<script>
import {LicenseFilter} from "@/models/License";
import BCheckbox from "@/components/common/BCheckbox.vue";

export default {
  components: {BCheckbox},
  props: {
    filter: LicenseFilter
  }
}
</script>
