<template>
  <LayoutCard
    title="Administrative Information"
    description="This information is used when generating violation letters."
    class="mb-4">

    <template #footer v-if="changed">
      <spinner-view v-if="saving" small class="me-3" />
      <button type="button" class="btn btn-flat ms-1" @click="reset" :disabled="saving">
        Cancel
      </button>
      <button type="button" class="btn btn-primary ms-1" @click="commit" :disabled="saving">
        Save Changes
      </button>
    </template>

    <div class="d-flex fled-row">
      <div class="flex-grow-1 flex-column d-flex">

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Website</label>
          <div class="col">
            <input type="text" v-model="admin.url" class="form-control"/>
          </div>
        </div>

        <div class="row mb-3 d-flex flex-row align-items-center">
          <label class="col-sm-12 col-md-2 col-form-label">Seal</label>
          <div class="col flex-grow-1 col-auto">
            <input type="text" v-model="admin.sealUrl" class="form-control"/>
          </div>
          <img v-if="admin.sealUrl" :src="admin.sealUrl" class="rounded overflow-hidden" style="object-fit: contain;max-width: 100px"/>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Formal Town/City Name</label>
          <div class="col">
            <input type="text" v-model="city.formalName" class="form-control"/>
            <div class="form-text">
              e.g. Town of {{ city.name }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Department Title</label>
          <div class="col">
            <input type="text" class="form-control" v-model="admin.officeTitle" />
            <div class="form-text">
              e.g. Office of the Town Clerk
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Department Address</label>
          <div class="col">
            <input type="text" v-model="admin.address" class="form-control"/>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Contact Person's Title</label>
          <div class="col">
            <input type="text" v-model="admin.contactTitle" class="form-control"/>
            <div class="form-text">
              e.g. {{ city.name }} Town Clerk
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Contact Person's Name</label>
          <div class="col">
            <input type="text" v-model="admin.contactName" class="form-control"/>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Contact Person's Phone Number</label>
          <div class="col">
            <PhoneInput v-model="admin.contactPhone" class="form-control"/>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Contact Person's Email</label>
          <div class="col">
            <input type="email" v-model="admin.contactEmail" class="form-control"/>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Violation Letter Title</label>
          <div class="col">
            <input type="text" class="form-control" v-model="admin.violationLetterTitle" />
            <div class="form-text">
              e.g. Notice of Potential Violation
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <label class="col-sm-12 col-md-2 col-form-label">Violation Letter Subtitle</label>
          <div class="col">
            <input type="text" class="form-control" v-model="admin.violationLetterSubtitle" />
            <div class="form-text">
              e.g. Short-Term Rental Ordinance
            </div>
          </div>
        </div>
      </div>

    </div>
  </LayoutCard>
</template>

<script>
import {City} from "@/models/City";
import LayoutCard from "@/components/common/LayoutCard.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import PhoneInput from "@/components/common/PhoneInput.vue";

export default {
  components: {PhoneInput, SpinnerView, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      admin: {
        url: null,
        sealUrl: null,
        formalName: '',
        officeTitle: '',
        address: '',
        contactTitle: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        violationLetterTitle: '',
        violationLetterSubtitle: '',
        clerk: null,
        ordinance: null,
      },
      saving: false
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        this.reset();
      }
    }
  },
  computed: {
    changed() {
      let changed = false;
      Object.keys(this.admin).forEach(key => {
        if (this.admin[key] !== this.city.admin[key]) {
          changed = true;
        }
      });
      return changed;
    }
  },
  methods: {
    reset() {
      this.admin = { ...this.admin, ...this.city.admin };
    },
    commit() {
      this.saving = true;
      this.city.updateAdmin(this.admin)
      .finally(() => this.saving = false);
    }
  }
}
</script>
