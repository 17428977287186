<template>
  <router-link v-if="to" :to="to" class="text-decoration-none">
    <slot/>
  </router-link>
  <span class="link-or-text" v-else>
    <slot/>
  </span>
</template>

<script>
export default {
  props: {
    to: Object
  }
}
</script>
