<template>
  <LayoutCard title="Daily Listing Monitoring">
    <template #description>
      We automatically monitor listing services daily for new listings. You can disable the automated monitoring
      function and opt instead to run it manually.
    </template>
    <div class="form-check form-switch">
      <input type="checkbox" class="form-check-input" v-model="running" />
      <label class="form-check-label">Listing monitoring is {{ city.paused ? 'disabled' : 'enabled' }}</label>
    </div>
    <div class="d-flex flex-row align-items-center">
      <div class="btn-group">
        <button type="button" class="btn btn-primary" @click="city.importListings()" :disabled="city.isImportingListings">
          Check now
        </button>
        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" :disabled="city.isImportingListings">
          <span class="visually-hidden">Toggle</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a href="#" class="dropdown-item" @click.prevent="city.importListings('airbnb')">
              Check Airbnb only
            </a>
          </li>
          <li>
            <a href="#" class="dropdown-item" @click.prevent="city.importListings('vrbo')">
              Check Vrbo only
            </a>
          </li>
        </ul>
      </div>
      <div v-if="city.isImportingListings" class="ms-3">
        <spinner-view small class="me-3" /> Checking...
      </div>
      <div v-else-if="city.dateChecked" class="ms-3">
        The last check was performed on {{ formatDate(city.dateChecked) }}.
      </div>
    </div>
  </LayoutCard>
</template>

<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {City} from "@/models/City";

export default {
  components: {SpinnerView, LayoutCard},
  props: {
    city: City
  },
  computed: {
    running: {
      get() {
        return !this.city.paused;
      },
      set(running) {
        this.city.updatePausedState(!running);
      }
    },
  }
}
</script>
