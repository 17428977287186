<template>
  <div>
    <div class="mb-3">
      Date of last sync: {{ formatDate(listing.dateDetailsImported, 'never') }}
    </div>
    <div class="d-flex flex-row align-items-center">
      <button type="button" class="btn btn-primary btn-sm" :disabled="loading" @click="sync">
        Sync Now
      </button>
      <template v-if="loading">
        <spinner-view small class="ms-3" />
        <div class="text-muted small ms-1">
          Syncing...
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView},
  props: {
    listing: Listing
  },
  computed: {
    loading() {
      return this.listing.isImportingDetails;
    }
  },
  methods: {
    sync() {
      this.listing.importDetails();
    }
  }
}
</script>
