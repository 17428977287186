<template>
  <div class="d-flex flex-column gap-4">
    <div>
      <img class="noc-seal" src="https://www.oobmaine.com/wp-content/uploads/2022/11/logo-3.png" />
    </div>
    <div>
      {{ moment().format('D MMMM, YYYY') }}
    </div>
    <div>
      <div v-for="(line, i) in destinationAddressLines" :key="`address_line_${i}`">
        {{ line }}
      </div>
    </div>
    <div>
      Certified and First Class Mail
    </div>

    <div class="d-flex flex-row gap-2">
      <div>RE:</div>
      <div>
        <b>
          Notice of {{ isCompliance ? 'Compliance' : 'Violations and Order'}} to Correct Violations of Old Orchard
          Beach Code of Ordinances.
        </b>
        <span>Property located at {{ property.addressFormatted }}, {{ model.city.label }}.</span>
      </div>
    </div>

    <div>
      Dear {{ property.owners.join(', ') || 'Sir or Madam' }},
    </div>
    <div v-if="isCompliance">
      The town of Old Orchard Beach is contacting you to work through the violation at {{ property.addressFormatted }}
      of renting without an active business license. Prior to further enforcement action,
      we are writing to you with the hope of voluntary compliance to resolve this violation.
    </div>
    <div v-else>
      The town of Old Orchard Beach contacted you to work through the violation at {{ property.addressFormatted }}
      of renting without an active business license. Unfortunately, we did not receive a viable response from you and
      due to your inaction, the Town has no other choice than to find resolution through formal enforcement for
      non-compliance.
    </div>

    <div class="text-decoration-underline fw-bold">
      <template v-if="isCompliance">
        We have found the following violation of the Old Orchard Beach Code of Ordinances on
        the above-referenced property:
      </template>
      <template v-else>
        You are hereby notified of the following violation of the Old Orchard Beach Code of
        Ordinances on the above-referenced property:
      </template>
    </div>

    <div>
      <b>1. <u>License Required</u> (Sec. 18-31.)</b>
      <ol start="5" class="ordinance-list">
        <li>
          No person shall operate or conduct any business or activity identified in the schedule
          set forth in section 18-32 without first obtaining a license therefor, nor shall any person
          operate or conduct any business or activity identified in the schedule set forth in section
          18-32 except in compliance with the terms of this article and any conditions imposed upon
          the license issued. (Ord. of 6-13-1961, § 1; Ord. of 2-16-1988; Ord. of 6-18-1991; Ord. of 3-2-
          1999; Ord. of 4-20-1999; Ord. of 5-4-10(1)).
        </li>
      </ol>
    </div>

    <div v-if="isCompliance">
      We seek your voluntary compliance at this time. Your compliance efforts shall include the
      following actions:
    </div>
    <div v-else>
      <b>You are hereby ORDERED to take the following actions to correct the above violations:</b>
      Although the Town is prepared to proceed with an enforcement action, the Town prefers
      to work with you to comply with the ordinances and we seek your voluntary compliance at
      this time. Your compliance efforts shall include the following actions:
    </div>

    <ol class="mb-0">
      <li>
        To continue renting your property, you must apply for and obtain an active business
        license within the Town of Old Orchard Beach. All information regarding this can be
        found in the Business Licensing section of Old Orchard Beach’s website.
        <template v-if="isCompliance">
          We ask that the application be submitted to the Town within thirty (30) days of the date of this letter.
        </template>
        <template v-else>
          The application shall be submitted to the Town within thirty (30) days of the date of this letter. Once
          it is received, I will <u>temporarily</u> halt enforcement actions and evaluate your progress.
        </template>
      </li>
    </ol>

    <div v-if="isCompliance">
      We would like to inform you that renting without an active license is a violation of our
      ordinance and subject to daily fines.
    </div>
    <div v-else>
      Renting without an active license is a violation of our ordinance and subject to daily fines.
    </div>

    <div>
      <b>Sec. 18-28. - Violation and Penalties.</b>
      <ol class="ordinance-list">
        <li>
          Any person who operates or conducts any business or activity for which a license is
          required under this article without first obtaining such license commits a civil
          violation and shall be subject to a fine not to exceed $100.00 for the first day the
          offense occurs. The second day the offense occurs, the fine amount shall not exceed
          $250.00. The third day and subsequent days thereafter, the fine amount shall not
          exceed $500.00. Each day such violation continues shall be considered a separate
          violation.
        </li>
      </ol>
    </div>

    <template v-if="!isCompliance">
      <div>
        Unless these violations are corrected by the dates indicated above, the Town may
        commence legal action in the Maine District Court or the Maine Superior Court. If the
        Town is the prevailing party in enforcement litigation, you may be liable for the Town's
        attorney fees and costs plus civil penalties.
      </div>
      <div>
        If you wish to dispute this Notice and Order, you may appeal to the Old Orchard Beach
        Board of Appeals pursuant to Section 78-111 of the Zoning Ordinance. Such appeal must be
        filed in the office of the Code Enforcement Officer on forms provided by the Town,
        together with the appropriate filing fee, within thirty (30) days after the date of issuance of
        this Notice and Order. Failure to appeal within thirty (30) days may deprive you of your
        ability to contest the contents of this Notice and Order in any subsequent proceedings.
        However, filing an appeal to the Board of Appeals does not relieve you of your
        responsibility to correct the violations or of your liability for civil penalties.
      </div>
    </template>

    <div>
      Please feel free to contact me if you have any questions. If you feel this is a mistake and
      have record of an active license, please contact me as soon as possible.
    </div>
    <div>
      Sincerely,
    </div>
    <div>
      Zack Heffernan<br/>
      Old Orchard Beach Deputy Code Enforcement Officer<br/>
      207.937.5638<br/>
      zheffernan@oobmaine.com<br/>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {LetterModel} from "@/models/LetterModel";

export default {
  props: {
    model: LetterModel,
    type: {
      type: String,
      validator: value => ['compliance','violation'].indexOf(value) >= 0
    },
    destination: String /* home, rental */
  },
  emits: ['ready'],
  mounted() {
    setTimeout(() => this.onReady(), 1000);
  },
  methods: {
    onReady() {
      this.$emit('ready');
    }
  },
  computed: {
    moment() {
      return moment
    },
    property() {
      return this.model.property;
    },
    isCompliance() {
      return this.type === 'compliance';
    },
    destinationAddressLines() {
      let lines = [...this.model.owners];
      if (this.destination !== 'rental') {
        let address = this.model.ownerAddressFormatted;
        let commaIndex = address.indexOf(',');
        lines.push(address.substring(0, commaIndex).trim());
        lines.push(address.substring(commaIndex+1).trim());
      } else {
        lines.push(...[
          this.model.addressFormatted,
          this.model.city.label
        ]);
      }
      return lines;
    }
  }
}
</script>

<style>
img.noc-seal {
  max-height: 64px;
  object-fit: contain;
}
ol.ordinance-list {
  list-style-type: lower-alpha;
  margin-bottom: 0;
}
</style>
