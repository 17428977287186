<template>
  <ul :class="`${citiesForUser.length === 0 ? 'd-none' : ''} list-group list-group-flush`">
    <li class="list-group-item border-0 p-0">
      <div :class="`text-muted fw-semibold small px-3 ${compact ? 'py-1 mb-0' : 'py-2'}`">
        Switch to:
      </div>
    </li>
    <li v-for="city in citiesForUser" :key="city.id" class="list-group-item list-group-item-action border-0 p-0 text-nowrap">
      <router-link :to="getRouteForCity(city.id)" :class="`nav-link d-flex flex-row align-items-center px-3  ${compact ? 'py-1 mb-0' : 'py-2 mb-1'}`" @click="$emit('select', city)">
        <CityIcon :city="city" class="me-2" invert-icon-colors /> {{ city.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import {City} from "@/models/City";
import {Session} from "@/models/User";
import CityIcon from "@/components/common/CityIcon.vue";

export default {
  components: {CityIcon},
  emits: ['select'],
  props: {
    compact: Boolean
  },
  data() {
    return {
      Session,
      cities: []
    };
  },
  mounted() {
    City.loadCities(true)
    .then(cities => this.cities = cities);
  },
  computed: {
    currentUser() {
      return Session.currentUser.value;
    },
    currentCityId() {
      return this.$route.params.cityId;
    },
    citiesForUser() {
      if (this.currentUser) {
        if (this.currentUser.role === 'admin') {
          return this.cities.filter(city => city.id !== this.currentCityId);
        } else {
          const cityIds = this.currentUser.cities;
          return this.cities.filter(city => cityIds.indexOf(city.id) >= 0 && this.currentCityId !== city.id);
        }
      } else {
        return [];
      }
    }
  },
  methods: {
    getRouteForCity(cityId) {
      return {
        name: 'city',
        params: { cityId }
      };
    }
  }
}
</script>
