<template>
  <div class="d-flex flex-row align-items-center mb-3">
    <div class="small flex-grow-0 text-nowrap">
      Showing {{ formatNumber(offset+1) }} - {{ formatNumber(offset+(items || []).length) }} of {{ formatNumber(total) }}
    </div>

    <div class="d-flex flex-row align-items-center flex-shrink-0 flex-nowrap flex-grow-0">
      <button :disabled="!(page > 0)" type="button" class="btn border-0 btn-flat btn-sm ms-1" @click="back">
        <i class="bi-chevron-left" />
      </button>
      <div v-if="useApi">
        <select class="form-select form-select-sm" v-model="searchOptions.page">
          <option v-for="index in pageCount" :key="`page_${index}`" :value="index-1">{{ index }}</option>
        </select>
      </div>
      <button v-if="page < pageCount" type="button" class="btn border-0 btn-flat btn-sm me-1" @click="next">
        <i class="bi-chevron-right" />
      </button>
    </div>

    <template v-if="useApi">
      <div class="input-group input-group-sm ms-3">
        <div class="input-group-text bg-white"><i class="bi-search"/></div>
        <div>
          <select class="form-select form-select-sm rounded-0" v-model="searchField">
            <option
              v-for="option in searchFieldsOptions"
              :key="`sf_${option.value}`"
              :value="option.value"
            >{{ option.text }}</option>
          </select>
        </div>
        <input type="text" class="form-control flex-grow-1" v-model="searchValue" @keyup.enter="refresh(true)" placeholder="Search" />
      </div>
      <button v-if="searchValue" class="btn btn-sm btn-flat" @click="clearSearch">
        <i class="bi-x-lg"/>
      </button>

      <div class="d-flex flex-row align-items-center flex-shrink-0 flex-nowrap flex-grow-0 ms-3">
        <div class="small text-muted px-2">Sort</div>
        <div>
          <select class="form-select form-select-sm" v-model="searchOptions.sort">
            <option
              v-for="option in sortOptions"
              :key="`sort_${option.value}`"
              :value="option.value"
            >{{ option.text }}</option>
          </select>
        </div>
      </div>
      <button class="btn btn-flat btn-sm me-3" @click="searchOptions.sortAscending = !searchOptions.sortAscending">
        <i :class="`bi-arrow-${searchOptions.sortAscending ? 'up' : 'down'}`" />
      </button>
    </template>
    <div v-else class="flex-grow-1"/>

    <spinner-view v-if="loading" small class="flex-shrink-0 mx-2" />
    <button :disabled="loading" type="button" class="btn btn-flat btn-sm" @click="refresh">
      <i class="bi-arrow-clockwise" />
    </button>
  </div>

  <table class="table small table-bordered" v-if="items">
    <thead>
    <tr>
      <slot name="table-header"/>
    </tr>
    </thead>
    <tbody>
    <template v-for="item in items" :key="item.id">
      <tr
        @click="selectedId = selectedId === item.id ? null : item.id"
        :class="['hoverable', {'table-active':selectedId === item.id}, itemClass ? itemClass(item) : '']">
        <slot name="table-row" :item="item" />
      </tr>
      <tr v-if="item.id === selectedId" class="table-active">
        <slot name="table-detail" :item="item" />
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script>
import SpinnerView from "@/components/common/SpinnerView.vue";

export default {
  components: {SpinnerView},
  props: {
    searchFieldsOptions: Array,
    sortOptions: Array,
    doSearch: Function,
    useApi: Boolean,
    itemClass: Function
  },
  data() {
    return {
      loading: false,
      items: null,
      searchOptions: {
        page: 0,
        pageSize: 50,
        startAfter: null,
        endBefore: null,
        filters: {},
        sort: this.sortOptions[0].value,
        sortAscending: true
      },
      searchField: this.searchFieldsOptions[0].value,
      searchValue: '',
      offset: 0,
      total: 0,
      selectedId: null,
      showFilters: false
    };
  },
  watch: {
    doSearch: {
      immediate: true,
      handler() {
        this.refresh();
      }
    },
    searchOptions: {
      deep: true,
      handler() {
        this.refresh();
      }
    },
    searchField() {
      this.searchValue = '';
    }
  },
  computed: {
    page() {
      return this.searchOptions.page;
    },
    // offset() {
    //   return this.searchOptions.page * this.searchOptions.pageSize;
    // },
    pageCount() {
      return Math.ceil(this.total / this.searchOptions.pageSize);
    }
  },
  methods: {
    refresh(resetPage) {
      this.loading = true;

      if (resetPage) {
        this.searchOptions.page = 0;
      }

      this.doSearch({
        ...this.searchOptions,
        searchField: this.searchField,
        searchValue: this.searchValue
      })
      .then(({offset, total, items}) => {
        this.offset = offset;
        this.total = total;
        this.items = items;
      })
      .finally(() => this.loading = false);
    },
    clearSearch() {
      this.searchValue = '';
      this.refresh(true);
    },
    back() {
      if (this.useApi) {
        this.searchOptions.page--;
      } else {
        this.searchOptions.endBefore = this.items[0].doc;
      }
    },
    next() {
      if (this.useApi) {
        this.searchOptions.page++;
      } else {
        this.searchOptions.startAfter = this.items[this.items.length - 1].id;
      }
    }
  }
}
</script>

<style scoped>
tr.hoverable:hover {
  background-color: var(--bs-table-hover-bg);
  cursor: pointer;
}
</style>
