<template>
  <LayoutCard title="Default Field Values">
    <template #description>
      When properties are imported, their data needs to be merged with our internal data model. Sometimes,
      fields don't get populated like they're supposed to. This issue can be tracked and resolved here.
    </template>
    <div v-if="counts" class="d-flex flex-column gap-2">
      <div>{{ formatNumber(counts.total, 'property', 'properties') }} found</div>
      <div v-if="fieldsWithMissingValues.length === 0">
        All default values have been set
      </div>
      <template v-else>
        <div v-for="field in fieldsWithMissingValues" :key="field">
          {{ formatNumber(counts.total - counts[field], 'property', 'properties') }} missing a value for <span class="text-monospace">{{ field }}</span>
        </div>
      </template>
      <div class="d-flex flex-row align-items-center gap-2">
        <button type="button" class="btn btn-primary" @click="fillDefaults" :disabled="loading">
          Fill in default values
        </button>
        <template v-if="loading">
          <spinner-view small />
          <div>Filling in default values</div>
        </template>
      </div>
    </div>
    <div v-else-if="loading" class="mt-3 d-flex flex-row align-items-center gap-2">
      <spinner-view small />
      <div>Loading properties stats</div>
    </div>
  </LayoutCard>
</template>
<script>
import LayoutCard from "@/components/common/LayoutCard.vue";
import {City} from "@/models/City";
import SpinnerView from "@/components/common/SpinnerView.vue";
export default {
  components: {SpinnerView, LayoutCard},
  props: {
    city: City
  },
  data() {
    return {
      loading: false,
      counts: null
    };
  },
  watch: {
    city: {
      immediate: true,
      handler() {
        this.reload();
      }
    }
  },
  computed: {
    fieldsWithMissingValues() {
      if (this.counts) {
        return this.fields.filter(field => this.counts[field] < this.counts.total);
      } else {
        return [];
      }
    },
    fields() {
      return [
        'recordDetailsImported',
        'imagesCached',
        'latLngImported',
        'realtorDetailsImported',
        'indexed'
      ];
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      try {
        this.counts = await this.city.fetchDefaultFieldCounts('properties', this.fields);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async fillDefaults() {
      this.loading = true;
      try {
        await this.city.ensureDefaultValues('properties');
        await this.reload();
      } finally {
        this.loading = false;
      }
    }
  }
}
</script>
