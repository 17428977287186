<template>
  <div :class="[{'d-none':!show}, 'position-relative']">
    <img
      :src="src"
      :class="[imageClass, loading ? 'opacity-25' : '']"
      :style="autoSize ? '' : `width:${size}px;height:${size}px;object-fit:cover;`"
      @error="onLoadError"
      @load="onLoad"
    />
    <div v-if="loading" class="small position-absolute top-50 start-50 translate-middle">
      Loading
    </div>
  </div>
</template>

<script>
export default {
  props: {
    src: String,
    imageClass: String,
    size: {
      type: Number,
      default: () => 64
    },
    autoSize: Boolean
  },
  data() {
    return {
      loaded: null,
      error: null
    };
  },
  watch: {
    src(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.loaded = null;
        this.error = null;
      }
    }
  },
  computed: {
    loading() {
      return !this.loaded && !this.error;
    },
    show() {
      return !!this.src && !this.error;
    }
  },
  methods: {
    onLoad(event) {
      this.loaded = event;
    },
    onLoadError(error) {
      this.error = error;
    }
  }
}
</script>
