<template>
  <BCollapsible :class="{'opacity-50':change.committed}">
    <template #title>
      <div class="small">
        <div>License #{{ change.id }}</div>
        <div :class="['d-flex flex-row align-items-center gap-1', {'text-danger':change.error === 'property_not_found'}]">
          <div>
            Parcel #{{ change.propertyId }}
            <template v-if="change.error === 'property_not_found'"> not found</template>
          </div>
          <button
            v-if="change.error === 'property_not_found'"
            type="button"
            class="ms-1 btn btn-sm btn-danger"
            @click="commit(true)">
            <i class="bi-cloud-upload me-1"/>
            Confirm anyway
          </button>
        </div>
      </div>
      <LicenseStatusView :license="license" class="ms-auto" />
      <spinner-view v-if="change.committing" small class="mx-2" />
      <icon-button v-else-if="change.committed" icon="check-circle" variant="success" small disabled @click="commit" button />
      <icon-button v-else-if="change.error" icon="exclamation-circle" variant="danger" small disabled title="Property could not be found" button />
      <icon-button v-else icon="cloud-upload" small @click="commit" />
    </template>
    <div v-if="change.fields" class="m-3 d-flex flex-column align-items-start gap-3">
      <table class="table small font-monospace table-bordered">
        <thead>
          <tr>
            <th>Updated Field</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
        <LicenseChangeField
          v-for="field in change.fields"
          :key="field.field"
          :change="change"
          :field="field"
        />
        </tbody>
      </table>
    </div>
    <div v-else class="m-3">
      <table class="table small font-monospace table-bordered">
        <thead>
        <tr>
          <th>Field</th>
          <th>Value</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="fv in fieldValues" :key="fv.id">
          <td>{{ fv.label }}</td>
          <td>{{ fv.value }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </BCollapsible>
</template>

<script>
import {LicenseParseChange} from "@/models/LicenseParseResult";
import LicenseStatusView from "@/components/LicenseStatusView.vue";
import BCollapsible from "@/components/common/BCollapsible.vue";
import IconButton from "@/components/common/IconButton.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import LicenseChangeField from "@/components/licenses/LicenseChangeField.vue";

export default {
  components: {LicenseChangeField, SpinnerView, IconButton, BCollapsible, LicenseStatusView},
  props: {
    change: LicenseParseChange
  },
  data() {
    return {
      collapsed: true
    };
  },
  computed: {
    license() {
      return this.change.toLicense();
    },
    fieldValues() {
      let data = this.change.data.data;
      return [
        ['owners', 'Contact Names', data.contact?.owners?.join(', ')],
        ['ownerAddress', 'Contact Address', data.contact?.address],
        ...data.customFieldValues.map(cfv => {
          return [cfv.id, cfv.label, cfv.value];
        })
      ].map(a => { return {
        id: a[0], label: a[1], value: a[2]
      }});
    }
  },
  methods: {
    labelForField(field) {
      let customFieldId = (field.match(/customFieldValues\.(.*)/) || [])[1];
      if (customFieldId) {
        let customField = this.change.city.customFields.find(field => field.id === customFieldId);
        if (customField) {
          return customField.label;
        }
      }
      switch (field) {
        case 'contactAddress':
          return 'Contact Address';
        case 'status':
          return 'Status';
        case 'propertyId':
          return 'Parcel ID';
        case 'contactNames':
          return 'Contact Names';
        default:
          return field;
      }
    },
    formatValue(value) {
      if (value instanceof Array) {
        return value.join(', ');
      } else {
        return value;
      }
    },
    commit(force) {
      this.change.commit(force);
    }
  }
}
</script>
