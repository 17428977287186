<template>
  <div class="row align-items-center">
    <div :class="`col col-${breakpoint}-6 col-12 order-2 order-${breakpoint}-${imageFirst ? '2' : '1'} mb-4`">
      <div class="mx-4">
        <div class="text-uppercase fw-bold mb-2">{{ title }}</div>
        <div class="h4 mb-2 fw-normal">{{ subtitle }}</div>
        <div style="font-size:16px" class="mb-4">
          <slot />
        </div>
        <router-link :to="route" class="btn btn-primary" @click="$emit('routeclick')">{{ routeText }}</router-link>
      </div>
    </div>
    <div :class="`col col-${breakpoint}-6 col-12 order-1 order-${breakpoint}-${imageFirst ? '1' : '2'} mb-4`">
      <div class="mx-4">
        <slot name="image"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['routeclick'],
  props: {
    title: String,
    subtitle: String,
    route: Object,
    routeText: String,
    imageFirst: Boolean,
    breakpoint: {
      type: String,
      default: () => 'lg'
    }
  },
  methods: {
    click() {

    }
  }
}
</script>
