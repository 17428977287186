<template>
  <div :class="['px-2 text-uppercase text-nowrap fw-bold rounded fw-normal bg-opacity-10', `border-${config.bg}`, `bg-${config.bg}`, `text-${config.bg}`]">
    {{ config.label }}
  </div>
</template>

<script>
import {License} from "@/models/License";

export default {
  props: {
    license: License
  },
  computed: {
    config() {
      const status = this.license.statusWithConfig;
      if (status) {
        return {
          bg: status.type === 'active' ? 'success' : status.type === 'pending' ? 'danger' : 'dark',
          text: 'white',
          label: status.label
        };
      } else {
        return {
          bg: 'dark',
          text: 'white',
          label: `Unknown: ${this.license.status}`
        };
      }
    }
  }
}
</script>
