import moment from 'moment';
import { Timestamp } from "@firebase/firestore";

export function toMoment(s) {
  if (!s) {
    return null;
  } else if (s instanceof Timestamp) {
    return moment(s.toDate());
  } else if (s instanceof moment) {
    return s;
  } else if (s instanceof Date) {
    return moment(s);
  } else if (typeof s === 'string') {
    if (s.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return moment(s, 'YYYY-MM-DD');
    } else if (s.match(/^\d{8}$/)) {
      return moment(s, 'YYYYMMDD');
    } else {
      return moment(new Date(s));
    }
  } else if (typeof s._seconds === 'number' && typeof s._nanoseconds === 'number') {
    return moment(new Timestamp(s._seconds, s._nanoseconds).toDate());
  }
}

export function toTimestamp(s) {
  if (!s) {
    return null;
  } else if (s instanceof Timestamp) {
    return s;
  } else if (s instanceof Date) {
    return Timestamp.fromDate(s);
  } else if (s instanceof moment) {
    return Timestamp.fromDate(s.toDate());
  } else if (typeof s._seconds === 'number' && typeof s._nanoseconds === 'number') {
    return new Timestamp(s._seconds, s._nanoseconds).toDate();
  } else if (typeof s === 'string') {
    return Timestamp.fromDate(new Date(s));
  } else {
    return null;
  }
}
