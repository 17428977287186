<template>
  <SimpleTable title="Summary" :rows="summaryRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last checked: {{ formatDate(property.dateUpdated) }}</div>
    </template>
  </SimpleTable>

  <SimpleTable title="Coordinates" :rows="latlngRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last derived: {{ formatDate(property.dateLatLngImported, 'never') }}</div>
      <button
        class="ms-3 btn btn-sm btn-outline-primary"
        @click="property.importLatLng()"
        :disabled="property.isRequesting('importPropertyLatLng')"
      >
        <spinner-view v-if="property.isRequesting('importPropertyLatLng')" small />
        Derive Coordinates
      </button>
    </template>
  </SimpleTable>

  <SimpleTable title="Details (from assessor records)" :rows="detailRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last imported: {{ formatDate(property.dateDetailsImported, 'never') }}</div>
      <button
        class="ms-3 btn btn-sm btn-outline-primary"
        @click="property.importDetails()"
        :disabled="property.isRequesting('importPropertyDetails')"
      >
        <spinner-view v-if="property.isRequesting('importPropertyDetails')" small />
        Import Details
      </button>
    </template>
  </SimpleTable>

  <SimpleTable title="Details (from real estate records)" :rows="realtorRows" class="mb-4">
    <template #header>
      <div class="text-muted">Last imported: {{ formatDate(property.dateRealtorDetailsImported, 'never') }}</div>
      <button
        class="ms-3 btn btn-sm btn-outline-primary"
        @click="property.importRealtorDetails()"
        :disabled="property.isRequesting('importPropertyRealtorDetails')"
      >
        <spinner-view v-if="property.isRequesting('importPropertyRealtorDetails')" small />
        Import Details
      </button>
    </template>
  </SimpleTable>
</template>

<script>
import {Property} from "@/models/Property";
import SimpleTable from "@/components/common/SimpleTable.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";

class TableBuilder {
  constructor() {
    this.rows = [];
  }
  row(label, value, type, hideIfNull) {
    if (value != null || !hideIfNull) {
      this.rows.push({
        label,
        value,
        type
      });
    }
    return this;
  }
}

export default {
  components: {SpinnerView, SimpleTable},
  props: {
    property: Property
  },
  computed: {
    summaryRows() {
      const p = this.property;
      return new TableBuilder()
      .row('Parcel ID', p.id)
      .row('Address', p.addressFormatted)
      .rows;
    },
    detailRows() {
      if (!this.property.hasDetails) {
        return [];
      }
      const {
        owners, buildingCount, useCode, imageUrls, ownerAddress, yearBuilt, sqft, acres, stats
        // stats: {
        //   'Building Style': buildingStyle,
        //   'Buliding Style': buildingStyle2,
        //   'Stories': stories,
        //   'Exterior Wall 1': exteriorWalls,
        //   'Roof Type': roofType,
        //   'Interior Wall 1': interiorWalls,
        //   'Bedroom(s)': beds,
        //   'Full Bath(s)': baths,
        //   'Half Bath(s)': halfBaths,
        //   'Interior Floor 1': interiorFloors
        // }
      } = this.property.details;
      let table = new TableBuilder()
      .row('Owners', owners)
      .row('Owner Address', ownerAddress)
      .row('Acres', acres)
      .row('Square Footage', sqft)
      // .row('Bedrooms', beds)
      // .row('Bathrooms', baths)
      // .row('Half Bathrooms', halfBaths)
      .row('Year Built', yearBuilt)
      // .row('Building Style', buildingStyle || buildingStyle2)
      .row('Building Count', buildingCount, 'number')
      // .row('Stories', stories)
      // .row('Exterior Walls', exteriorWalls)
      // .row('Interior Walls', interiorWalls)
      // .row('Floors', interiorFloors)
      // .row('Roof Style', roofType)
      .row('Use Code', useCode)
      for (let key in stats) {
        table = table.row(key, stats[key]);
      }
      return table.row('Pictures', imageUrls, 'images')
      .rows;
    },
    latlngRows() {
      if (!this.property.hasLatLng) {
        return [];
      } else {
        const { lat, lng } = this.property.latlng;
        return new TableBuilder()
        .row('Latitude', `${lat}° ${lat < 0 ? 'S' : 'N'}`)
        .row('Longitude', `${lng}° ${lng < 0 ? 'W' : 'E'}`)
        .row('Map', {lat,lng}, 'map')
        .rows;
      }
    },
    realtorRows() {
      if (!this.property.hasRealtorDetails) {
        return [];
      } else {
        const {
          sqft, beds, baths, description, imageUrls, stats
          // stats: {
          //   'Lot Size': lotSize,
          //   'Year Built': yearBuilt,
          //   'Stories': stories
          // }
        } = this.property.realtorDetails;
        let table = new TableBuilder()
        .row('Square Footage', sqft)
        // .row('Lot Size', lotSize)
        .row('Bedrooms', beds)
        .row('Bathrooms', baths)
        // .row('Stories', stories)
        // .row('Year Built', yearBuilt)
        for (let key in stats) {
          table = table.row(key, stats[key]);
        }
        return table.row('Description', description)
        .row('Pictures', imageUrls, 'images')
        .rows;
      }
    }

  }
}
</script>
