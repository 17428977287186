<template>
  <SplashView>
    <!-- Checking for current user, show spinner -->
    <div v-if="!currentUserChecked" class="col col-12 col-md-8 col-lg-4 text-center">
      <div class="bg-white shadow d-flex flex-column align-items-center p-4 rounded-2">
        <SpinnerView class="d-block mb-3" />
        <h6>Checking session</h6>
      </div>
    </div>

    <!-- User is logged in -->
    <div v-else-if="!!currentUser" class="col col-12 col-md-8 col-lg-4 text-center">
      <div class="bg-white shadow d-flex flex-column align-items-center p-4 rounded-2">
        <template v-if="!citiesLoaded">
          <SpinnerView class="d-block mb-3">
            <h6>Checking which municipalities you have access to</h6>
          </SpinnerView>
        </template>
        <div v-else-if="!currentUser.isAdmin && citiesForUser.length === 0" class="text-danger">
          We're sorry. You don't seem to have access to any of the municipalities in our system. Please contact an
          administrator.
        </div>
        <div v-else-if="!currentUser.isAdmin && citiesForUser.length === 1">
          <div class="mb-2 text-muted"><SpinnerView small /> Redirecting to:</div>
          <router-link :to="{name:'city',params:{cityId:citiesForUser[0].id}}" class="text-decoration-none">
            <CityButton :city="citiesForUser[0]" class="py-2 px-3 rounded-pill" include-state />
          </router-link>
        </div>
        <div v-else>
          <template v-if="citiesForUser.length > 0">
            <div class="mb-3">
              Which municipality would you like to view?
            </div>
            <div v-for="city in citiesForUser" :key="city.id" class="mb-3">
              <router-link :to="{name:'city',params:{cityId:city.id}}" class="text-decoration-none btn-highlight">
                <CityButton :city="city" class="py-2 px-3 rounded-pill" include-state />
              </router-link>
            </div>
          </template>
          <div v-if="currentUser.isAdmin" class="border-top mt-4 pt-4">
            <router-link :to="{name:'admin'}" class="btn btn-outline-dark rounded-pill d-block">
              Admin Console
            </router-link>
          </div>
        </div>

        <div class="text-muted mb-2 mt-4">Logged in as {{ currentUser.displayName || currentUser.email }}</div>
        <button type="button" class="btn btn-outline-dark btn-sm" @click="Session.logout()">Log out</button>
      </div>
    </div>

    <!-- User is not logged in, and they've opened the login form -->
    <div v-else-if="showLogin" class="col col-12 col-md-8 col-lg-4">
      <div class="bg-white shadow">
        <div class="p-4">
          <LoginForm :model="model" @login="onLogin"/>
        </div>
        <div class="px-4 py-2 bg-gray border-top">
          <router-link :to="{query:{mode:'welcome'}}" class="text-muted small text-decoration-none">
            <i class="bi-chevron-left"/> Back
          </router-link>
        </div>
      </div>
    </div>

    <!-- User is not logged in, and they've opened the license registration form -->
    <div v-else-if="showRegister" class="col col-12 col-md-8 col-lg-4">
      <div class="bg-white shadow">
        <div class="p-4">
          <template v-if="!citiesLoaded">
            <SpinnerView class="d-block mb-3">
              <h6>Checking available towns</h6>
            </SpinnerView>
          </template>
          <template v-else>
            <div class="mb-3">
              Where would you like to register for a short-term rental license?
            </div>
            <div v-for="city in cities" :key="city.id" class="mb-3">
              <router-link :to="{name:'register',params:{cityId:city.id}}" class="text-decoration-none">
                <CityButton :city="city" class="py-2 px-3 rounded-pill" />
              </router-link>
            </div>
          </template>
        </div>
        <div class="px-4 py-2 bg-gray border-top">
          <router-link :to="{query:{mode:'welcome'}}" class="text-muted small text-decoration-none">
            <i class="bi-chevron-left"/> Back
          </router-link>
        </div>
      </div>
    </div>

    <!-- User is not logged in, by default show the welcome tiles -->
    <div v-else class="col col-12 col-lg-8">
      <div class="bg-white shadow p-4">
        <div class="row">
          <div v-for="tile in tiles" :key="tile.id" class="col col-12 col-lg-6 p-4">
            <div class="h-100 p-4">
              <div class="h-100 d-flex flex-column align-items-center">
                <h5 class="card-title text-center fw-semibold mb-3">{{ tile.title }}</h5>
                <p class="card-text flex-grow-1 text-center mb-4">{{ tile.text }}</p>
                <template v-if="tile.action && !tile.action.disabled">
                  <router-link v-if="tile.action.to" :to="tile.action.to" class="btn btn-primary w-100">
                    {{ tile.action.text }}
                  </router-link>
                  <a v-else-if="tile.action.click" href="#" @click.prevent="tile.action.click" class="btn btn-primary w-100">
                    {{ tile.action.text }}
                  </a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SplashView>
</template>

<script>
import {Session} from "@/models/User";
import {City} from "@/models/City";
import LoginForm from "@/components/LoginForm.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import CityButton from "@/components/common/CityButton.vue";
import SplashView from "@/components/common/SplashView.vue";

export default {
  components: {SplashView, SpinnerView, LoginForm, CityButton},
  props: {
    viewMode: String
  },
  data() {
    return {
      Session,
      cities: null
    };
  },
  mounted() {
    City.loadCities(true)
    .then(cities => this.cities = cities);
  },
  watch: {
    currentUser: {
      handler() {
        this.onLogin();
      },
      immediate: true
    }
  },
  computed: {
    currentUserChecked() {
      return Session.currentUserChecked.value;
    },
    currentUser() {
      return Session.currentUser.value;
    },
    citiesLoaded() {
      return !!this.cities;
    },
    citiesForUser() {
      if (this.currentUser) {
        if (this.currentUser.role === 'admin') {
          return this.cities;
        } else {
          const cityIds = this.currentUser.cities;
          return this.cities.filter(city => cityIds.indexOf(city.id) >= 0);
        }
      } else {
        return null;
      }
    },
    showLogin() {
      return this.$route.query.mode === 'login';
    },
    showRegister() {
      return this.$route.query.mode === 'register';
    },
    tiles() {
      return [
        {
          id: 'staff',
          title: 'For Municipal Staff',
          text: 'Staff members may login to the administrative portal to add manage licenses, contact licensees, and address potential STR violations.',
          action: {
            text: 'Login to the Staff Portal',
            click: () => {
              this.$router.push({
                query: {
                  mode: 'login'
                }
              });
            }
          }
        },
        {
          id: 'public',
          title: 'For Property Owners',
          text: '(Coming soon) Property owners who would like to use their property as a short-term rental can use the STR portal to register for a short-term rental license.',
          action: {
            text: 'Register for a license',
            disabled: true,
            click: () => {
              this.$router.push({
                query: {
                  mode: 'register'
                }
              })
            }
          }
        }
      ];
    }
  },
  methods: {
    onLogin() {
      if (this.currentUser) {
        if (this.currentUser.cities.length === 1) {
          setTimeout(() => {
            this.$router.push({
              name:'city',
              params:{
                cityId: this.currentUser.cities[0]
              }
            });
          }, 2000);
        }
      }
    }
  }
}
</script>

<style>
img.bg {
  position: fixed;
  z-index: -6;
  /* aspect-ratio: auto; */
  width: calc(100% + 6px);
  height: calc(100% + 6px);
  object-fit: cover;
  overflow: hidden;
  filter: blur(3px);
  top: -3px;
  left: -3px;
}
</style>
