<template>
  <div :class="['d-flex flex-column gap-1 align-items-stretch p-2 border rounded', {shadow:!collapsed}]">
    <div class="d-flex flex-row align-items-center gap-1">
      <icon-button :icon="`chevron-${collapsed ? 'right' : 'down'}`" @click="collapsed = !collapsed" />
      <div :class="['flex-grow-1 d-flex flex-row align-items-center gap-3', {'fw-bold':!collapsed}]">
        <slot name="title" />
      </div>
    </div>
    <BCollapse :show="!collapsed">
      <slot />
    </BCollapse>
  </div>
</template>

<script>
import IconButton from "@/components/common/IconButton.vue";
import BCollapse from "@/components/common/BCollapse.vue";

export default {
  components: {BCollapse, IconButton},
  data() {
    return {
      collapsed: true
    };
  },
}
</script>
