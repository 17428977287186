<template>
  <div class="mb-3 py-3">
    <div class="px-3">
      <div class="mb-3">
        <div class="fw-semibold">
          {{ title }}
        </div>
        <div class="form-text mt-0" v-if="$slots.description || description">
          <slot v-if="$slots.description" name="description" />
          <template v-else>{{ description }}</template>
        </div>
      </div>
      <div v-if="$slots.default" class="mb-3">
        <slot />
      </div>
    </div>
    <div v-if="properties.length > 0" class="list-group list-group-flush overflow-auto border-top" ref="scroller">
      <SuggestedListingProperty
        v-for="property in propertiesSorted"
        class="list-group-item"
        :key="property.id"
        :model="model"
        :listing="listing"
        :property="property"
        @verify="commitProperty"
      >
        <template #buttons>
          <IconButton icon="geo-alt" @click="$emit('showLocation', property.latlng)" title="Center map on this address" />
          <IconButton icon="binoculars" @click="$emit('openStreetView', property.latlng)" title="Open street view at this address"/>
        </template>
      </SuggestedListingProperty>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import IconButton from "@/components/common/IconButton.vue";
import SuggestedListingProperty from "@/components/SuggestedListingProperty.vue";
import {AppModel} from "@/models/AppModel";

export default {
  components: {SuggestedListingProperty, IconButton},
  emits: ['committed', 'showLocation', 'openStreetView'],
  props: {
    model: AppModel,
    listing: Listing,
    title: String,
    description: String,
    properties: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    listing() {
      this.$refs.scroller?.scrollTo(0, 0);
    }
  },
  methods: {
    commitProperty({property, notes}) {
      this.saving = true;
      this.listing.verifyProperty(property, notes)
      .then(() => this.onCommitted())
      .finally(() => this.saving = false);
    },
    onCommitted() {
      this.saving = false;
      this.$emit('committed',{
        title: 'Listing verified!',
        body: `Listing address verified for "${this.listing.listingTitle}"`
      });
    }
  },
  computed: {
    propertiesSorted() {
      let properties = [...this.properties];
      properties.sort((p1, p2) => {
        const host = (this.listing.listingHost || '').toLowerCase();
        if (host) {
          const h1 = p1.owners.join(', ').toLowerCase().indexOf(host) >= 0;
          const h2 = p2.owners.join(', ').toLowerCase().indexOf(host) >= 0;
          if (h1 && !h2) {
            return -1;
          } else if (h2 && !h1) {
            return 1;
          }
        }
        const d1 = p1.distanceFrom(this.listing.latlng);
        const d2 = p2.distanceFrom(this.listing.latlng);
        return d1 - d2;
      });
      return properties;
    }
  }
}
</script>
