<template>
  <div>
    <div class="mb-3">
      <div class="form-label">Notes</div>
      <BTextarea v-model="notes" :disabled="saving"/>
    </div>
    <div class="d-flex flex-row justify-content-end">
      <button class="btn btn-flat btn-sm" @click="cancel" :disabled="saving">Cancel</button>
      <button class="ms-1 btn btn-sm btn-primary" @click="commit" :disabled="notes === listing.notes || saving">
        <SpinnerView v-if="saving" class="me-1" small />Update notes
      </button>
    </div>
  </div>
</template>

<script>
import BTextarea from "@/components/common/BTextarea.vue";
import SpinnerView from "@/components/common/SpinnerView.vue";
import {Listing} from "@/models/Listing";

export default {
  components: {SpinnerView, BTextarea},
  emits: ['close'],
  props: {
    listing: Listing
  },
  data() {
    return {
      notes: this.listing.notes,
      saving: false
    };
  },
  methods: {
    commit() {
      this.saving = true;
      this.listing.updateNotes(this.notes)
      .finally(() => {
        this.saving = false;
        this.close();
      });
    },
    cancel() {
      this.notes = this.listing.notes;
      this.close();
    },
    close() {
      this.$emit('close');
    }
  }
}
</script>
