<template>
  <div class="form-check">
    <input type="radio" class="form-check-input" v-model="value" :value="optionValue" />
    <label class="form-check-label text-nowrap">{{ label }}</label>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {},
    optionValue: {
      required: true
    },
    label: String
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit('update:modelValue', v);
      }
    }
  }
}
</script>
