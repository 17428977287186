import moment from "moment";
import {PersistentCityModel} from "@/models/PersistentCityModel";

export class LastTaskRun {

  constructor(data) {
    this.data = {
      completed: null,
      remaining: null,
      duration: null,
      error: null,
      ...data
    };
  }

  get completed() {
    return this.data.completed;
  }

  get duration() {
    return this.data.duration;
  }

  get error() {
    return this.data.error;
  }
}

export class CurrentTaskRun {

  constructor(data) {
    this.data = {
      status: null,
      count: null,
      total: null,
      progress: null,
      description: null,
      ...data
    };
  }

  get count() {
    return this.data.count;
  }

  get total() {
    return this.data.total;
  }

  get progress() {
    return this.data.progress;
  }

  get status() {
    return this.data.status;
  }

  get description() {
    let d = this.data.description;
    if (d) {
      return d;
    } else if (this.status === 'running') {
      return 'Running';
    } else if (this.status === 'completed') {
      return 'Completed';
    } else if (this.status === 'failed') {
      return 'Failed';
    } else {
      return 'Ready';
    }
  }

  get progressPercent() {
    if (this.progress >= 0) {
      return Math.round(100 * this.progress);
    } else if (this.total > 0) {
      return Math.round(this.count / this.total);
    } else {
      return 0;
    }
  }
}

export class Task extends PersistentCityModel {

  static collectionName = 'tasks';

  constructor(city, doc) {
    super(city, doc);
  }

  get completed() {
    return this.data.completed || 0;
  }

  get remaining() {
    return this.data.remaining || 0;
  }

  get total() {
    return this.completed + this.remaining;
  }

  get progressPercent() {
    return this.total > 0 ? Math.round(100 * this.completed / this.total) : 0;
  }

  get isRepeated() {
    return this.data.remaining != null;
  }

  get runCount() {
    return this.data.runCount || 0;
  }

  get duration() {
    return this.data.totalDuration || 0;
  }

  get durationPerRun() {
    return this.runCount > 0 ? this.duration / this.runCount : 0;
  }

  request() {
    return this.update({
      status: 'requested',
      dateRequested: new Date()
    });
  }

  cancel() {
    return this.update({
      status: 'canceled',
      dateCanceled: new Date()
    });
  }

  get canRequest() {
    return !this.isStarted && !this.isRequested;
  }

  get canCancel() {
    return this.isRequested || this.isStarted;
  }

  get status() {
    return this.data.status;
  }

  get hasNotStarted() {
    return !this.status;
  }

  get isRequested() {
    return this.status === 'requested';
  }

  get isCanceled() {
    return this.status === 'canceled';
  }

  get isStarted() {
    return this.status === 'started';
  }

  get isCompleted() {
    return this.status === 'completed';
  }

  get isFailed() {
    return this.status === 'failed';
  }

  get error() {
    return this.data.error || '';
  }

  get dateCompleted() {
    return this.data.dateCompleted;
  }

  get lastRunFormatted() {
    let date = this.data.dateCompleted;
    if (date) {
      return moment(date.toDate()).fromNow();
    } else {
      return 'never';
    }
  }

  get itemCollectionName() {
    switch (this.id) {
      case 'importProperties':
      case 'importPropertyDetails':
      case 'importLatLng':
      case 'importRealtorDetails':
      case 'buildPropertyIndex':
      case 'cachePropertyImages':
        return 'properties';
      case 'importListings':
      case 'importListingDetails':
      case 'cacheListingImages':
      case 'takeListingScreenshots':
        return 'listings';
    }
    return null;
  }

  get itemDoneFieldName() {
    switch (this.id) {
      case 'importPropertyDetails':
        return 'recordDetailsImported';
      case 'importListingDetails':
        return 'detailsImported';
      case 'importLatLng':
        return 'latLngImported';
      case 'importRealtorDetails':
        return 'realtorDetailsImported';
      case 'buildPropertyIndex':
        return 'indexed';
      case 'cachePropertyImages':
      case 'cacheListingImages':
        return 'imagesCached';
      case 'takeListingScreenshots':
        return 'screenshotTaken';
    }
    return null;
  }

  async fetchLiveCounts() {
    const collectionName = this.itemCollectionName;
    if (collectionName) {
      const counts = await this.city.fetchFieldCounts(collectionName, this.itemDoneFieldName, true);
      return {
        total: counts[0],
        completed: counts[1]
      };
    } else {
      return null;
    }
  }
}
